import React, { Component, Fragment } from "react";
import { Col, FormGroup, Input } from 'reactstrap';
import "./asesorias.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRemissionSended, setCount } from "../../../redux/actions/consultanciesAction";
import Pagination from "react-js-pagination";

import Header from "../../header/Header";
import { Link } from "react-router-dom";

function CandidateRow(props) {
  const consultancies = props.consultancies;
  const candidate = consultancies.candidate;
  const manager = consultancies.manager;
  const path = props.path;
  var route = `new/detalle-remision/${consultancies.id}`;

  return (
    <tr id="tr">
      <td className="titleItemRow">
        {consultancies.remissionDate}
      </td>
      <td className="titleItemRow">
        {`${candidate.fullName}`}
      </td>
      <td className="titleItemRow">
        {`${candidate.document}`}
      </td>
      <td className="titleItemRow">
        {consultancies.reason}
      </td>
      <td className="titleItemRow">
        {`${candidate.cellphone} - ${candidate.cellphoneTwo}`}
      </td>
      <td className="titleItemRow">{manager.fullName}</td>
      <td className="titleItemRow">
        <Link className="btnHV" to={`${route}`}>
          <i className="fas fa-eye text-info"></i>
        </Link>
      </td>
    </tr>
  );
}

class AsesoriasEspecializadas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  componentDidMount = () => {
    this.props.setCount(0);
    this.props.getRemissionSended();
  };

  componentDidUpdate = () => { };

  handlePageChange = (e) => {
    window.scroll(0, 0);
    this.props.getRemissionSended(e);

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if (this.props.pageCount !== 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Asesorias Especializadas (Remisiones)
                </strong>
              </p>
            </div>

            <div className="col-12 backgroundAsesorias">
              <div className="row containerHeaderAsesorias">
                <div className="col-2">
                  <button className="btn btn-info">
                    <Link
                      to={`advisory/new/remisiones`}
                      style={{ textDecoration: "none" }}
                    >
                      <strong id="btnNew">Nuevo</strong>
                    </Link>
                  </button>
                </div>
              </div>

              <div className="table-responsiveAsesoria containerTable">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="titleTable">
                        Fecha Remisión
                      </th>
                      <th scope="col" className="titleTable">
                        Nombre
                      </th>
                      <th scope="col" className="titleTable">
                        Documento
                      </th>
                      <th scope="col" className="titleTable">
                        Motivo
                      </th>
                      <th scope="col" className="titleTable">
                        Teléfonos
                      </th>
                      <th scope="col" className="titleTable">
                        Gestor
                      </th>
                      <th scope="col" className="titleTable"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.remissionsData.length !== 0
                      ? this.props.remissionsData.results.map(
                        (consultancies, index) => {
                          return (
                            <CandidateRow
                              consultancies={consultancies}
                              key={index.toString()}
                              path={this.props.modulePath}
                            />
                          );
                        }
                      )
                      : null}
                  </tbody>
                </table>
              </div>

              <Col md={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={50}
                  totalItemsCount={this.props.pageCount}
                  pageRangeDisplayed={5}
                  onChange={(e) => this.handlePageChange(e)}
                  itemClass="page-item"
                  linkClass="page-link"
                  hideDisabled
                  pageRangeDisplayed={5}
                  pageRangeDisplayed={10}
                />
                {this.renderOptionsPage()}
              </Col>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    remissionsData: state.consultanciesReducer.remissionsData,
    modulePath: state.allyReducer.modulePath,
    allyData: state.allyReducer.allyData,
    pageCount: state.consultanciesReducer.pageCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRemissionSended: bindActionCreators(getRemissionSended, dispatch),
    setCount: bindActionCreators(setCount, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsesoriasEspecializadas);
