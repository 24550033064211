import { ISLOADING, GET_VACANTE, ADD_VACANTE, PAGE_COUNT } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

import swal from 'sweetalert';
import { setCount } from './allyActions';

export const isLoading = bool => ({
  type: ISLOADING,
  isLoading: bool
});

export const getVacantes = vacanteData2 => ({
  type: GET_VACANTE,
  vacanteData2
});

export const addVacantes = vacanteData3 => ({
  type: ADD_VACANTE,
  vacanteData3
});

export const setPage = pageCount => ({
  type: PAGE_COUNT,
  pageCount
});

export const getAllVacantes = (page = 1) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseVacantes = await axios.get(`${urls.url_base}/VmFjYW50/?page=${page}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseVacantes.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(getVacantes(data));
        dispatch(setCount(count));
      } else {
        dispatch(getVacantes([]));
        swal('No hay información disponible');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getRequestVacantes = (id, page = 1, city = 3, date) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseVacantes = await axios.get(`${urls.url_base}/VmFjYW50/?partner=${id}&city=${city}&endDate=${date}&page=${page}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseVacantes.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(getVacantes(data));
        dispatch(setCount(count));
      } else {
        dispatch(getVacantes([]));
        swal('No hay información disponible');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getClientVacancies = (id, page = 1) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseVacantes = await axios.get(`${urls.url_base}/VmFjYW50/?business=${id}&page=${page}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseVacantes.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(getVacantes(data));
        dispatch(setCount(count));
      } else {
        dispatch(getVacantes([]));
        swal('No hay información de vacantes disponible');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const add_vacante = (obj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseTimelines = await axios.post(`${urls.url_base}/VmFjYW50/`, obj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseTimelines.data;
      if (data.length !== 0) {
        dispatch(getAllVacantes());
        dispatch(isLoading(false));
        swal('La vacante fue creada correctamente.');
      } else {
        dispatch(isLoading(false));
        dispatch(addVacantes([]));
        swal('Upss! a ocurrido un error, por favor intenta más tarde!');
      }
    } catch (err) {
      swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}