import React, { Component, Fragment } from "react";
import { FormGroup, Input } from 'reactstrap';
import "./jefe.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add_consultancies } from "../../../../redux/actions/consultanciesAction";
import { getAllCities } from "../../../../redux/actions/citiesActions";
import { getAllClient } from "../../../../redux/actions/clientsActions";
import { getAllBusiness } from "../../../../redux/actions/businessActions";
import { Redirect } from "react-router-dom";

import Header from "../../../header/Header";

class JefeAcompanamiento extends Component {
  constructor(props) {
    super(props);
    this.state = this.setInitialState();
  }

  componentDidMount = () => {
    this.props.getAllCities();
    this.props.getAllClient();
    this.props.getAllBusiness();
  };

  setInitialState = () => {
    var id = this.props.match.params.advi;
    if (id !== undefined) {
      var findAdvisory = this.props.consultanciesData2.results.filter(
        (el) => el.id == id
      )[0];
      console.log("findAdvisory", findAdvisory);
      return {
        consultanciesType: findAdvisory.consultanciesType,
        mediaType: findAdvisory.mediaType,
        theme: findAdvisory.theme,
        advisoryDate: findAdvisory.advisoryDate,
        duration: findAdvisory.duration,
        summary: findAdvisory.summary,
        immediateBoss: findAdvisory.immediateBoss,
        city: findAdvisory.city,
        deal: findAdvisory.deal,
        idCandidate: findAdvisory.idCandidate,
        idClient: findAdvisory.idClient,
        otherTheme: "",
        isEdit: true,
      };
    } else {
      return {
        consultanciesType: 1,
        mediaType: "",
        theme: "",
        advisoryDate: "",
        duration: "1",
        summary: "",
        immediateBoss: "",
        city: "",
        deal: "",
        idCandidate: null,
        idClient: "",
        otherTheme: "",
        isEdit: false,
      };
    }
  };

  handleChange = (e) => {
    const newState = this.state;
    newState[e.target.id] = e.target.value;
    this.setState(newState);
  };

  onSaveConsultancies = () => {
    if (this.state.isEdit == false) {
      var obj = {
        consultanciesType: this.state.consultanciesType,
        mediaType: this.state.mediaType,
        theme: this.state.theme,
        advisoryDate: this.state.advisoryDate,
        duration: this.state.duration,
        summary: this.state.summary,
        immediateBoss: this.state.immediateBoss,
        city: this.state.city,
        deal: this.state.deal,
        idCandidate: this.state.id,
        idClient: this.state.idClient,
        manager: this.props.allyData[0].managerData.url,
        otherTheme: this.state.otherTheme
      };
      console.log(obj);
      this.props.add_consultancies(obj);
    } else {
      console.log("Actualizar info");
    }
  };

  render() {
    return (
      <Fragment>
        <Header />

        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Nueva asesoria para jefe acompanamiento
                </strong>
              </p>
            </div>
          </div>
        </div>

        <div className="bachgroundAddCalendar">
          {this.props.isSave === true ? (
            <Redirect to={`/${this.props.modulePath}/advisory`} />
          ) : (
            ""
          )}
          

          <div className="containerFormularioAsesoriaJefeAcompanamiento">
            <form className="formularioAddAsesoria" style={{height: '50vh'}}>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"><p className="labelForm">Jefe acompanamiento</p></label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="immediateBoss"
                    value={this.state.immediateBoss}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"><p className="labelForm">Ciudad</p> </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    id="city"
                    value={this.state.city}
                    onChange={this.handleChange}
                  >
                    <option value="" defaultValue="" disabled>
                      Seleccione tu ciudad
                    </option>
                    {this.props.citiesData.length != 0
                      ? this.props.citiesData.results.map((item, index) => {
                          return (
                            <option key={index.toString()} value={item.url}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"><p className="labelForm">Cliente</p> </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    id="idClient"
                    value={this.state.idClient}
                    onChange={this.handleChange}
                  >
                    <option value="" defaultValue="" disabled>
                      Seleccione el cliente
                    </option>
                    {this.props.clientData.length != 0
                      ? this.props.clientData.results.map((item, index) => {
                          return (
                            <option key={index.toString()} value={item.url}>
                              {item.fullName}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"><p className="labelForm">Negocio</p> </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    id="deal"
                    value={this.state.deal}
                    onChange={this.handleChange}
                  >
                    <option value="" defaultValue="" disabled>
                      Seleccione el negocio
                    </option>
                    {this.props.businessData && this.props.businessData.length != 0
                      ? this.props.businessData.results.map((item, index) => {
                          return (
                            <option key={index.toString()} value={item.url}>
                              {item.name}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                    <p className="labelForm">
                    Medio de Comunicacion
                    </p>
                  
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    id="mediaType"
                    value={this.state.mediaType}
                    onChange={this.handleChange}
                  >
                    <option value="" defaultValue="" disabled>
                      Selecciona el medio de comunicacion
                    </option>
                    <option value="0">Whatsapp</option>
                    <option value="1">Video llamada</option>
                    <option value="2">Personal</option>
                    <option value="4">Teléfonico</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  <p className="labelForm">Tema</p>
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    id="theme"
                    value={this.state.theme}
                    onChange={this.handleChange}
                  >
                    <option value="" disabled defaultValue="">
                      Selecciona el tema
                    </option>
                    <option value="Adaptación laboral">Adaptación laboral</option>
                    <option value="Acompañamiento Individual">Acompañamiento Individual</option>
                    <option value="Casos de Seguridad">Casos de Seguridad</option>
                    <option value="Educación">Educación</option>
                    <option value="Familia">Familia</option>
                    <option value="Reglamento Interno">Reglamento Interno</option>
                    <option value="Salud">Salud</option>
                    <option value="Otros">Otros</option>
                  </select>
                </div>
              </div>
              {this.state.theme === 'Otros' && <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  <p className="labelForm">
                    Nombre del tema
                  </p>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="otherTheme"
                    value={this.state.otherTheme}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>}
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  <p className="labelForm">
                    Fecha
                  </p>
                </label>
                <div className="col-sm-9">
                  <input
                    type="date"
                    className="form-control"
                    id="advisoryDate"
                    value={this.state.advisoryDate}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  <p className="labelForm">
                    Duracion
                  </p>
                </label>
                <div className="col-sm-9">
                  <div className="range range-info">
                    <Input 
                      id="duration"
                      type="range"
                      min="1"
                      max="5"
                      step="1"
                      value={this.state.duration}
                      onChange={this.handleChange}
                    />
                    <output id="range">{`${this.state.duration} hora`}</output>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                    <p className="labelForm">
                        Resumen
                    </p>
                    </label>
                <div className="col-sm-9">
                  <Input
                    type="textarea"
                    className="form-control"
                    id="summary"
                    value={this.state.summary}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="containerBtnEnviarAsesorias">
            <button
              className="btn btn-info"
              style={{width: 200, backgroundColor: '#204d6a'}}
              onClick={() => this.onSaveConsultancies()}
            >
              {this.state.isEdit == false ? "Enviar" : "Actualizar"}
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.consultanciesReducer.isLoading,
    consultanciesData: state.consultanciesReducer.consultanciesData,
    citiesData: state.citiesReducer.citiesData,
    clientData: state.clientsReducer.clientData,
    businessData: state.businessReducer.businessData,
    isSave: state.consultanciesReducer.isSave,
    consultanciesData2: state.consultanciesReducer.consultanciesData2,
    modulePath: state.allyReducer.modulePath,
    allyData: state.allyReducer.allyData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    add_consultancies: bindActionCreators(add_consultancies, dispatch),
    getAllCities: bindActionCreators(getAllCities, dispatch),
    getAllClient: bindActionCreators(getAllClient, dispatch),
    getAllBusiness: bindActionCreators(getAllBusiness, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JefeAcompanamiento);
