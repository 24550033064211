import { FIND_ALERT, FIND_REMITIDOS } from '../constants/action-type';

const getInitialState = () => ({
  alertRelacionamiento: {results: []},
  alertRemitidos: {results: []}
});

const alertsReducer = (state = getInitialState(), action) => {
  switch(action.type){
    case FIND_ALERT:
      return { ...state, alertRelacionamiento: action.alertRelacionamiento };
    case FIND_REMITIDOS:
      return { ...state, alertRemitidos: action.alertRemitidos };
    default:
      return state;
  }
}

export default alertsReducer;