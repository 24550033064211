import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  add_Tracing,
  getTracingCandidate,
} from "../../../redux/actions/acompanamientoActions";
import { Redirect } from "react-router-dom";
import "./Seguimientos.styles.css";
import swal from "sweetalert";

class ViewSeguimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      month: "",
      year: "",
      adaptationCohesion: 0,
      regulationRules: 0,
      relationships: 0,
      personalPresentation: 0,
      communication: 0,
      dispositionTask: 0,
      observations: "",
      origen: "",
      idCandidate: null,
      total: 0,
    };
  }

  componentDidMount = () => {
    var id = this.props.match.params.candidate;
    let candidate = this.props.linkedList;
    this.props.getTracingCandidate(id);
    let findCandidate = candidate.results.find((p) => p.id === parseInt(id));
    if (typeof findCandidate !== "undefined") {
      this.setState({
        fullname: findCandidate.fullName,
        idCandidate: findCandidate.url,
      });
    }
  };

  handleChange = (e) => {
    if (e.target.name == "month") {
      let month = e.target.value;
      let findTracing = this.props.candidateTracing.results.find(
        (t) => t.month === month
      );
      if (typeof findTracing !== "undefined") {
        this.setState({
          month: month,
          adaptationCohesion: findTracing.adaptationCohesion,
          regulationRules: findTracing.regulationRules,
          relationships: findTracing.relationships,
          personalPresentation: findTracing.personalPresentation,
          communication: findTracing.communication,
          dispositionTask: findTracing.dispositionTask,
          observations: findTracing.observations,
          total: findTracing.total,
        });
      } else {
        this.setState({
          month: month,
          adaptationCohesion: 0,
          regulationRules: 0,
          relationships: 0,
          personalPresentation: 0,
          communication: 0,
          dispositionTask: 0,
          observations: "",
          total: 0,
        });
        swal(
          "Lo sentimos, no se encuentran seguimientos para el mes seleccionado"
        );
      }
    }

    // newState[e.target.name] = e.target.value;
    // this.setState(newState)
  };

  addTracingData = () => {
    let year = new Date().getFullYear();
    let obj = {
      month: this.state.month,
      year: year,
      adaptationCohesion: this.state.adaptationCohesion,
      regulationRules: this.state.regulationRules,
      relationships: this.state.relationships,
      personalPresentation: this.state.personalPresentation,
      communication: this.state.communication,
      dispositionTask: this.state.dispositionTask,
      observations: this.state.observations,
      origen: "Cliente",
      idCandidate: this.state.idCandidate,
    };

    this.props.add_Tracing(obj);
  };

  render() {
    return (
      <div className="backgroundRegistros">
        <div className="clientPicture">
          {this.props.allyData.length != 0 && (
            <img
              src={this.props.allyData[0].businessData.profilePicture}
              width="100%"
            />
          )}
        </div>

        <div className="customRow mb-5 text-left">
          <p>
            <strong className="titleSeguimiento text-left">Seguimientos</strong>
          </p>
          <p className="subtitleSeguimiento">
            {`Seguimientos realizados a ${this.state.fullname}`}
          </p>
        </div>

        <div className="cardContent">
          {this.props.candidateTracing.results.length !== 0 ? (
            <form className="contentForm">
              <div className="form-group row">
                <label className="col-sm-7 col-form-label">Mes</label>
                <div className="col-sm-5">
                  <select
                    className="form-control"
                    name="month"
                    value={this.state.month}
                    onChange={this.handleChange}
                  >
                    <option value="" selected="" disabled>
                      Seleccionar mes
                    </option>
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  Adaptación y cohesión al equipo de trabajo
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="adaptationCohesion"
                    disabled
                    type="range"
                    list="adaptacion"
                    min="0"
                    max="3"
                    step="1"
                    value={this.state.adaptationCohesion}
                    onChange={this.handleChange}
                  />

                  <datalist id="adaptacion">
                    <option value="0" />
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                  </datalist>

                  <span>{this.state.adaptationCohesion}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  Cumplimiento de normas y reglamentos
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="regulationRules"
                    disabled
                    type="range"
                    list="cumplimiento"
                    min="0"
                    max="3"
                    step="1"
                    value={this.state.regulationRules}
                    onChange={this.handleChange}
                  />

                  <datalist id="cumplimiento">
                    <option value="0" />
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                  </datalist>

                  <span>{this.state.regulationRules}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  Relaciones interpersonales
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="relationships"
                    disabled
                    type="range"
                    list="relaciones"
                    min="0"
                    max="3"
                    step="1"
                    value={this.state.relationships}
                    onChange={this.handleChange}
                  />

                  <datalist id="relaciones">
                    <option value="0" />
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                  </datalist>

                  <span>{this.state.relationships}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  Presentación personal
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="personalPresentation"
                    disabled
                    type="range"
                    list="personal"
                    min="0"
                    max="3"
                    step="1"
                    value={this.state.personalPresentation}
                    onChange={this.handleChange}
                  />

                  <datalist id="personal">
                    <option value="0" />
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                  </datalist>

                  <span>{this.state.personalPresentation}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">Comunicación</label>
                <div className="col-sm-5 customRange">
                  <input
                    name="communication"
                    disabled
                    type="range"
                    list="comunicacion"
                    min="0"
                    max="3"
                    step="1"
                    value={this.state.communication}
                    onChange={this.handleChange}
                  />

                  <datalist id="comunicacion">
                    <option value="0" />
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                  </datalist>

                  <span>{this.state.communication}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  Disposición a la tarea
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="dispositionTask"
                    disabled
                    type="range"
                    list="tarea"
                    min="0"
                    max="3"
                    step="1"
                    value={this.state.dispositionTask}
                    onChange={this.handleChange}
                  />

                  <datalist id="tarea">
                    <option value="0" />
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                  </datalist>

                  <span>{this.state.dispositionTask}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">Total</label>
                <div className="col-sm-5">
                  <span style={{ float: "right" }}>{this.state.total}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">Observaciones</label>
                <div className="col-sm-5">
                  <textarea
                    className="form-control"
                    disabled
                    name="observations"
                    rows="3"
                    value={this.state.observations}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
              </div>
            </form>
          ) : (
            <p style={{ textAlign: "center" }}>Cargando...</p>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allyData: state.allyReducer.allyData,
    linkedList: state.clientsReducer.linkedList,
    isLoading: state.accompanimentReducer.isLoading,
    saveTracing: state.accompanimentReducer.saveTracing,
    candidateTracing: state.accompanimentReducer.candidateTracing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    add_Tracing: bindActionCreators(add_Tracing, dispatch),
    getTracingCandidate: bindActionCreators(getTracingCandidate, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSeguimiento);
