import { combineReducers } from 'redux';
import candidateReducer from './candidateReducer';
import allyReducer from './allyReducer';
import citiesReducer from './citiesReducer';
import timelinesReducer from './timelinesReducer';
import consultanciesReducer from './consultanciesReducer';
import clientsReducer from './clientsReducer';
import vacanteReducer from './vacanteReducer';
import businessReducer from './businessReducer';
import selectionReducer from './selectionReducer';
import accompanimentReducer from './acompanamientoReducer';
import summariesReducer from './sumaryReducer';
import interviewReducer from './interviewsReducer';
import managersReducer from './managersReducer';
import traceabilityReducer from './traceabilityReducer';
import alertsReducer from './alertsReducer';
import emprendimientoReducer from './emprendReducer';
import coursesReducer from './coursesReducer';

const rootReducer = combineReducers({
    candidateReducer,
    allyReducer,
    citiesReducer,
    timelinesReducer,
    consultanciesReducer,
    clientsReducer,
    vacanteReducer,
    businessReducer,
    selectionReducer,
    accompanimentReducer,
    summariesReducer,
    interviewReducer,
    managersReducer,
    traceabilityReducer,
    alertsReducer,
    emprendimientoReducer,
    coursesReducer
});
export default rootReducer;