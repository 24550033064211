import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { setAllCandidatesStatus } from "../../../redux/actions/candidateActions";
import { bindActionCreators } from "redux";
import urls from "../../../apiConf";

class ModalDeleteMessageAcompanamiento extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    var obj = {
      idCandidate: event.target.candidato.value,
      candidateState: event.target.estado.value,
      candidateSubstate: event.target.SubEstado.value,
      reentry: event.target.causa.value,
      reentryObs: event.target.causReal.value,
    };
    this.props.setAllCandidatesStatus(obj);
    this.setState({ show: false });
  };

  render() {
    const idCan = this.props.idCan;
    const idStatus = this.props.idStatus;
    const idSubStatus = this.props.subStatus;

    return (
      <>
        <i
          className="fas fa-minus-circle text-danger"
          onClick={this.handleShow}
        ></i>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Motivo de la desvinculación</Modal.Title>
          </Modal.Header>
          <form onSubmit={this.handleSubmit}>
            <Modal.Body className="text-center">
              <input type="hidden" name="candidato" value={idCan} />
              <input type="hidden" name="estado" value={idStatus} />
              <input type="hidden" name="SubEstado" value={idSubStatus} />

              <div className="row">
                <div className="col-12 mb-2">
                  <select name="causa" className="form-control">
                    <option value="1">Presentación Personal</option>
                  </select>
                </div>
                <div className="col-12 mb-2">
                  <textarea
                    className="form-control"
                    name="causReal"
                    placeholder="Causa real ..."
                  ></textarea>
                </div>
              </div>
              <Button
                variant="danger"
                className="mr-3"
                onClick={this.handleClose}
              >
                Cerrar
              </Button>
              <Button type="submit" variant="danger">
                Eliminar
              </Button>
            </Modal.Body>
          </form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.selectionReducer.isLoading,
    setCandidateStatus: state.selectionReducer.candidatesStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllCandidatesStatus: bindActionCreators(
      setAllCandidatesStatus,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalDeleteMessageAcompanamiento);
