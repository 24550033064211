import React, { Component } from "react";
import { Col, FormGroup, Input } from 'reactstrap';
import "./vacantes.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add_vacante } from '../../../redux/actions/vacanteActions';
import { getClientVacancies } from "../../../redux/actions/vacanteActions";
import swal from 'sweetalert';
import Pagination from "react-js-pagination";

function CandidateRow(props) {
  const vacante = props.vacante;
  const date = props.date;
  return (
    <tr id="tr" key={vacante.id.toString()}>
      <td className="titleItemRow">{vacante.name}</td>
      <td className="titleItemRow">{date}</td>
      {/* <td className="titleItemRow">
        <input type="checkbox" checked={vacante.vacantState} />
      </td> */}
    </tr>
  );
}

class Vacantes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameVacant: '',
      vacantState: false,
      idClient: '',
      idBusiness: '',
      activePage: 1
    };
  }

  formatDate(date) {
    var fecha = date.split("T");
    var resultado = fecha[0];
    return resultado;
  }

  componentDidMount = () => {
    if(this.props.allyData.length !== 0){
      let id = this.props.allyData[0].businessData.id;
      this.setState({
        id_current_client: id
      });
      this.props.getClientVacancies(id);
    }
  };

  handleChange = (e) => {
    const newState = this.state;
    newState[e.target.id] = e.target.value;
    this.setState(newState);
  }

  onRequest = () => {
    if(this.state.nameVacant !== ""){
      var obj = {
        nameVacant: this.state.nameVacant,
        vacantState: this.state.vacantState,
        idClient: this.props.allyData[0].idClient,
        idBusiness: this.props.allyData[0].businessData.url
      }
      this.props.add_vacante(obj, this.state.id_current_client);
      this.setState({
        nameVacant: ""
      });
    }else{
      swal('Por favor ingresa el nombre de la vacante.');
    }
  }

  handlePageChange = (e) => {
    window.scroll(0,0);
    let id = this.props.allyData[0].businessData.id;
    this.props.getClientVacancies(id, e);

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if(this.props.pageCount !== 0){
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i+1);
      }
    }
    return(
      <FormGroup>
        <Input
          style={{marginLeft: 10}}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return(
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  render() {
    return (
      <div className="bgVacantes">
        <div className="clientPicture">
          {this.props.allyData.length != 0 && <img src={this.props.allyData[0].businessData.profilePicture} width="100%" />}
        </div>

        <div className="customRow mb-5 text-left">
          <p>
            <strong className="titleSeguimiento text-left">Vacantes</strong>
          </p>
          <p className="subtitleSeguimiento">
            Listado de vacantes
          </p>
        </div>
        
        <div className="table-responsiveVacantes containerTable" style={{marginTop: 0}}>
          <table className="table tableViewSeguimientos">
            <thead>
              <tr>
                <th scope="col" className="titleTable">
                  Vacante
                </th>
                <th scope="col" className="titleTable">
                  Fecha de solicitud
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.vacanteData2.length != 0
                ? this.props.vacanteData2.results.map((vacante, index) => {
                    return (
                      <CandidateRow
                        vacante={vacante}
                        key={index.toString()}
                        date={this.formatDate(vacante.timestamp)}
                      />
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        <Col md={12} style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={50}
            totalItemsCount={this.props.pageCount}
            pageRangeDisplayed={5}
            onChange={(e) => this.handlePageChange(e)}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
            pageRangeDisplayed={5}
            pageRangeDisplayed={10}
          />
          {this.renderOptionsPage()}
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allyData: state.allyReducer.allyData,
    isLoading: state.candidateReducer.isLoading,
    vacanteData2: state.vacanteReducer.vacanteData2,
    pageCount: state.vacanteReducer.pageCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientVacancies: bindActionCreators(getClientVacancies, dispatch),
    add_vacante: bindActionCreators(add_vacante, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vacantes);
