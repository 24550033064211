import React, { Component, Fragment } from "react";
import { Col, Row, FormGroup, Input } from 'reactstrap';
import "./cursostalleres.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import { getAllCourses } from "../../../redux/actions/courseActions";
import ModalDeleteAcompanamiento from '../../acompanamiento/popUpDelete/popUpDelete';
import Pagination from "react-js-pagination";

function TallerRow(props) {
  const taller = props.list;
  const path = props.path;
  return (
    <tr id="tr" key={taller.id.toString()}>
      <td className="titleItemRow">{taller.sector}</td>
      <td className="titleItemRow">{`${taller.date} - ${taller.hour}`}</td>
      <td className="titleItemRow">{taller.thematic}</td>
      <td className="titleItemRow">{taller.place}</td>
      <td className="titleItemRow">
        <a href={taller.medology} target="blank" style={{ color: 'blue' }}><p>Ver</p></a>
      </td>
      <td>
        <Row style={{ width: '100%' }}>
          <Col xs="12" md="6">
            <Link to={`/${path}/cursos/edit/${taller.id}`}>
              <i className="fa fa-eye" style={{ color: '#17a2b8' }} aria-hidden="true"></i>
            </Link>
          </Col>
          <Col xs="12" md="6">
            <ModalDeleteAcompanamiento
              taller={taller.id}
              type={1}
            />
          </Col>
        </Row>
      </td>
    </tr>
  );
}

class CursosTalleres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  componentDidMount = () => {
    this.props.getAllCourses();
  };

  componentDidUpdate = () => { };

  handlePageChange = (e) => {
    window.scroll(0, 0);
    this.props.getAllCourses(e);

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if (this.props.countCourse !== 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.countCourse / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="customRow mb-1 text-left">
            <p>
              <strong className="titleTaller text-left">Talleres o Cursos</strong>
            </p>
            <p className="subtitleTaller">Listado de Talleres / Cursos</p>
          </div>
          <div className="col-12 table-responsiveSeguimiento containerResumenSeleccion">
            <button className="btn btn-info">
              <Link
                to={`/${this.props.modulePath}/cursos/new`}
                style={{ textDecoration: "none" }}
              >
                <strong id="btnNew">Nuevo </strong>
              </Link>
            </button>
            <hr />
            <div className="table-responsiveTalleres">
              <table className="table tableResumenSeleccion">
                <thead>
                  <tr>
                    <th scope="col" className="titleTable">
                      Sector Económico
                    </th>
                    <th scope="col" className="titleTable">
                      Fecha
                    </th>
                    <th scope="col" className="titleTable">
                      Temática
                    </th>
                    <th scope="col" className="titleTable">
                      Lugar
                    </th>
                    <th scope="col" className="titleTable">
                      Metodología
                    </th>
                    <th scope="col" className="titleTable"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.courses.results.map((item, index) => {
                    return (
                      <TallerRow
                        key={index.toString()}
                        list={item}
                        path={this.props.modulePath}
                      />
                    );
                  })
                  }
                </tbody>
              </table>
            </div>
          </div>

          <Col md={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.countCourse}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={5}
              pageRangeDisplayed={10}
            />
            {this.renderOptionsPage()}
          </Col>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.coursesReducer.isLoading,
    courses: state.coursesReducer.courses,
    modulePath: state.allyReducer.modulePath,
    countCourse: state.coursesReducer.countCourse
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCourses: bindActionCreators(getAllCourses, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CursosTalleres);
