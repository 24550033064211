import { ISLOADING, GET_CLIENT, GET_BUSINESS_BY_CLIENT, GET_LINKED, PAGE_COUNT, SET_CLIENT_MENU } from '../constants/action-type';

const getInitialState = () => ({
    isLoading: false,
    clientData: [],
    linkedList: {results: []},
    businessClientData: [],
    pageCount: 0,
    menuClient: {id: 3, name: ''}
});

const clientsReducer = (state = getInitialState(), action) => {
    switch(action.type) {
        case ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case PAGE_COUNT:
            return { ...state, pageCount: action.pageCount };
        case GET_CLIENT:
            return { ...state, clientData: action.clientData, isLoading: false };
        case GET_BUSINESS_BY_CLIENT:
            return { ...state, businessClientData: action.businessClientData, isLoading: false };
        case GET_LINKED:
            return { ...state, linkedList: action.linkedList, isLoading: false };
        case SET_CLIENT_MENU:
            return { ...state, menuClient: action.menuClient };
        default:
            return state;
     }
}

export default clientsReducer;