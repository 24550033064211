import { ISLOADING, ADD_CONSULTANCIES, GET_CONSULTANCIES, ISSAVE, GET_CONSOLIDATED, GET_REMISSIONS, ADD_REMISSIONS, PAGE_COUNT } from '../constants/action-type';

const getInitialState = () => ({
    consultanciesData: [],
    consultanciesData2: [],
    remissionsData: [],
    consolidate: [],
    isSave: false,
    pageCount: 0
});

const consultanciesReducer = (state = getInitialState(), action) => {
    switch(action.type) {
        case ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case PAGE_COUNT:
            return { ...state, pageCount: action.pageCount };
        case ADD_CONSULTANCIES:
            return { ...state, consultanciesData: action.consultanciesData, isLoading: false, isSave: false };
        case GET_CONSULTANCIES:
            return { ...state, consultanciesData2: action.consultanciesData2, isLoading: false, isSave: false };
        case ISSAVE:
            return { ...state, isSave: action.isSave };
        case GET_CONSOLIDATED:
            return { ...state, consolidate: action.consolidate, isLoading: false };
        case GET_REMISSIONS:
            return { ...state, remissionsData: action.remissionsData, isLoading: false, isSave: false };
        case ADD_REMISSIONS:
            return { ...state, remissionsData: [],  isSave: true };
        default:
            return state;
     }
}

export default consultanciesReducer;