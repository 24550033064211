import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { FormGroup, FormGruop, Input } from 'reactstrap';
import { connect } from "react-redux";
import { updatePositionsSubstates } from '../../../redux/actions/candidateActions';
import { updateInterviewAnswered } from '../../../redux/actions/interviewActions';
import { bindActionCreators } from "redux";

class ModalCitaciones extends Component {
	constructor(props) {
        super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
            show: false,
            causa: "",
            showCauses: false,

        };
    }

    componentDidMount = () => {
        this.setState({
            show: false,
            causa: "",
            showCauses: false,

        });
    }
    
	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        if(event.target.contesto.value === "true"){
            let id = event.target.interview.value;
            let candidate = event.target.candidato.value;
            this.props.updateInterviewAnswered(id, {answered: true, idCandidate: candidate});
        }else{
            var obj = {
                position: event.target.position.value,
                proccessSubstate: event.target.substate.value,
                idCandidate: event.target.candidato.value,
                reentry: event.target.causa.value,
                reentryObs: event.target.causReal.value
            }
            var traceObj = {
                action: `Cambia estado a No Citados por ${this.validateIdentity().name}`,
                candidate: event.target.candidato.value,
                manager: this.validateIdentity() .url
            }
            this.props.updatePositionsSubstates(obj, true, traceObj);
        }
        this.setState({ show: false });
    }


    validateIdentity = () => {
        if(this.props.allyData.length !== 0){
          let obj = {};
          let data = this.props.allyData[0];
          if(data.businessData){
            obj = {
              name: data.businessData.name,
              url: data.businessData.url
            }
          }else if(data.advisorData){
            obj = {
              name: data.advisorData.fullName,
              url: data.advisorData.url
            }
          }else if(data.managerData) {
            obj = {
              name: data.managerData.fullName,
              url: data.managerData.url
            }
          }
          return obj;
        }
    }

    handleChange = (e) => {
        if(e.target.value == 'false'){
            this.setState({
                showCauses: true
            });
        }else{
            this.setState({
                showCauses: false
            });
        }
    }

	render() {
        const idCan = this.props.idCan;
        const idStatus = this.props.idStatus;
        const idSubStatus = this.props.idSubStatus;
		return (
			<>
                <i className="fas fa-plus-circle text-success" onClick={this.handleShow}></i>


				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.titleModal}</Modal.Title>
					</Modal.Header>
                    <form onSubmit={this.handleSubmit}>
                        <Modal.Body className="text-center">
                            <input type="hidden" name="candidato" value={idCan}/>
                            <input type="hidden" name="estado" value={idStatus} />
                            <input type="hidden" name="substate" value={idSubStatus} />
                            <input type="hidden" name="position" value={this.props.position} />
                            <input type="hidden" name="interview" value={this.props.idInter} />
                            
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <FormGroup>
                                        <label>Contesto?</label>
                                        <Input type="select"
                                            name="contesto"
                                            onChange={this.handleChange}
                                        >
                                            <option value="true">Si</option>
                                            <option value="false">No</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                                {this.state.showCauses && <div style={{width: '100%'}}>
                                    <div className="col-12 mb-2">
                                        <FormGroup>
                                            <Input type="select" name="causa">
                                                <option defaultValue="" disabled>Seleccionar Motivo</option>
                                                <option value="No le interesa">No le interesa</option>
                                                <option value="No cumple con el perfil">No cumple con el perfil</option>
                                                <option value="Actitud">Actitud</option>
                                                <option value="Inasistencia">Inasistencia</option>
                                                <option value="No citado">No citado</option>
                                                <option value="Muerte">Muerte</option>
                                                <option value="No contactado">No contactado</option>
                                                <option value="Número errado">Número errado</option>
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    <div className="col-12 mb-2">
                                        <textarea className="form-control" name="causReal" placeholder="Causa real ..."></textarea>
                                    </div>
                                </div>}
                            </div>
                            <Button variant="secondary" className="mr-3" onClick={this.handleClose}>
                                Cerrar
                            </Button>
                            <Button type="submit" variant="success">
                                Actualizar
                            </Button>
                        </Modal.Body>
                    </form>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
    return {
        isLoading: state.selectionReducer.isLoading,
        setCandidateStatus: state.selectionReducer.candidatesStatus,
        allyData: state.allyReducer.allyData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updatePositionsSubstates: bindActionCreators(updatePositionsSubstates, dispatch),
        updateInterviewAnswered: bindActionCreators(updateInterviewAnswered, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCitaciones);