import { ISLOADING, GET_CLIENT, GET_BUSINESS_BY_CLIENT, GET_LINKED, PAGE_COUNT, SET_CLIENT_MENU } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

import swal from 'sweetalert';

export const isLoading = bool => ({
    type: ISLOADING,
    isLoading: bool
});

export const getClient = clientData => ({
  type: GET_CLIENT,
  clientData
});

export const getBusinessByClient = businessClientData => ({
  type: GET_BUSINESS_BY_CLIENT,
  businessClientData
});

export const setLinkedList = linkedList => ({
  type: GET_LINKED,
  linkedList
});

export const setCount = pageCount => ({
  type: PAGE_COUNT,
  pageCount
});

export const setInfoClient = obj => ({
  type: SET_CLIENT_MENU,
  menuClient: obj
});

export const getAllClient = () => {
  return async(dispatch) => {
      // let candidate = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseProgram = await axios.get(`${urls.url_base}/Y2xpZW50ZXM/`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseProgram.data;
          if (data.results.length !== 0) {
            console.log('DATA', data);
            dispatch(getClient(data));
          } else {
            dispatch(getClient([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getBusinessClient = (obj) => {
  return async(dispatch) => {
      // let candidate = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseTimeline = await axios.get(`${urls.url_base}/QnVzaW5lc3M/?client=6`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseTimeline.data;
          if (data.length !== 0) {
            dispatch(getBusinessByClient(data));
          } else {
            dispatch(getBusinessByClient([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getLinkedCandidates = (id, page=1) => {
  return async(dispatch) => {
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseData = await axios.get(`${urls.url_base}/Q2FuZGlkYXRvcw/?business=${id}&state=2&substate=11&page=${page}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseData.data;
          const count = data.count;
          if (data.results.length !== 0) {
            dispatch(setLinkedList(data));
            dispatch(setCount(count));
          } else {
            dispatch(setLinkedList({results: []}));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}