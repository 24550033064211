import {
  ISLOADING,
  GET_RESUM_GENDER_BY_BUSINESS,
  GET_TOTAL_SUMMARY,
  GET_SUMMARY_TRACING,
  GET_SUMMARY_TRACING_NUEVO,
  GET_TOTAL_EMPRENDIMIENTO,
} from "../constants/action-type";
import urls from "../../apiConf";
import axios from "axios";
import swal from "sweetalert";

export const isLoading = (bool) => ({
  type: ISLOADING,
  isLoading: bool,
});

export const setSummaries = (summaryGender) => ({
  type: GET_RESUM_GENDER_BY_BUSINESS,
  summaryGender,
});

export const setTotalSummary = (summaryTotal) => ({
  type: GET_TOTAL_SUMMARY,
  summaryTotal,
});

export const setTotalEmprend = (emprendTotal) => ({
  type: GET_TOTAL_EMPRENDIMIENTO,
  emprendTotal,
});

export const setSummarySegui = (summaryTracing) => ({
  type: GET_SUMMARY_TRACING,
  summaryTracing,
});

export const setSummarySeguiNuevo = (summaryTracingNuevo) => ({
  type: GET_SUMMARY_TRACING_NUEVO,
  summaryTracingNuevo,
});

export const getSummariesTracing = (month, year) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseSuma = await axios.get(
        `${urls.url_base}/gf4342/?month=${month}&year=${year}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseSuma.data;
      if (data.consolidado.length !== 0) {
        dispatch(setSummarySegui(data));
      } else {
        dispatch(
          setSummarySegui({
            consolidado: [
              {
                month: month,
                year: year,
                totalconsolidado: 0,
                rango1: 0,
                rango2: 0,
                rango3: 0,
                rango4: 0,
                rango5: 0,
              },
            ],
          })
        );
        // swal('No hay información disponible');
      }
    } catch (err) {
      swal("Lo sentimos, ha ocurrido un error");
    }
  };
};

export const getSummariesTracingNuevo = (month, year) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      if (month.charAt(0) === "0") month = month.replace("0", "");
      const responseSuma = await axios.get(
        `${urls.url_base}/gf4342_2/?month=${month}&year=${year}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseSuma.data;
      if (data.consolidado.length !== 0) {
        dispatch(setSummarySegui(data));
      } else {
        dispatch(
          setSummarySegui({
            consolidado: [
              {
                month: month,
                year: year,
                totalconsolidado: 0,
                rango1: 0,
                rango2: 0,
                rango3: 0,
                rango4: 0,
                rango5: 0,
              },
            ],
          })
        );
        // swal('No hay información disponible');
      }
    } catch (err) {
      swal("Lo sentimos, ha ocurrido un error");
    }
  };
};

export const getSummariesGender = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseSuma = await axios.get(
        `${urls.url_base}/dds98798/?business=${id}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseSuma.data;
      if (data.length !== 0) {
        dispatch(setSummaries(data));
      } else {
        dispatch(setSummaries([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      swal("Lo sentimos, ha ocurrido un error");
    }
  };
};

export const getSummariesClient = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseSuma = await axios.get(
        `${urls.url_base}/dds98798/?client=${id}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseSuma.data;
      if (data.length !== 0) {
        dispatch(setSummaries(data));
      } else {
        dispatch(setSummaries([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      swal("Lo sentimos, ha ocurrido un error");
    }
  };
};

export const getSummariesGenderProgram = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseSuma = await axios.get(
        `${urls.url_base}/dds98798/?partner=${id}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseSuma.data;
      if (data.length !== 0) {
        dispatch(setSummaries(data));
      } else {
        dispatch(setSummaries([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      swal("Lo sentimos, ha ocurrido un error");
    }
  };
};

export const getSummaryTotal = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseSuma = await axios.get(`${urls.url_base}/dG90YWxlcw/`, {
        headers: { Authorization: `JWT ${token}` },
      });
      const data = await responseSuma.data;
      if (data.length !== 0) {
        dispatch(setTotalSummary(data));
      } else {
        dispatch(setTotalSummary([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      swal("Lo sentimos, ha ocurrido un error");
    }
  };
};

export const getEmprendConsolidado = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseSuma = await axios.get(`${urls.url_base}/emds132/`, {
        headers: { Authorization: `JWT ${token}` },
      });
      const data = await responseSuma.data;
      if (data.length !== 0) {
        dispatch(setTotalEmprend(data));
      } else {
        dispatch(setTotalEmprend([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      swal("Lo sentimos, ha ocurrido un error");
    }
  };
};
