export const convertTimestampToDate = (timestamp) => {
  var dateParts = timestamp.split("/");
  console.log(timestamp)
  var d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    min = d.getMinutes();

  console.log(d)
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  const fullHour = `${hour}:${min}`

  return `${[year, month, day].join("-")}`;
}

export const formatDate = (inputDate) =>{
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

    date = date
        .toString()
        .padStart(2, '0');

    month = month
        .toString()
        .padStart(2, '0');

  return `${date}/${month}/${year}`;
}