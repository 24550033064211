import { GET_EMPRENDIMIENTOS, GET_EMPRENDIMIENTOS_BY_ALLY, ADD_EMPRENDIMIENTO, EDIT_EMPRENDIMIENTO, ISLOADING, SEARCH_EMPRENDIMIENTO } from '../constants/action-type';

const getInitialState = () => ({
  emprendimientos: { results: [] },
  emprendimientosAlly: { results: [] },
  searchEmprend: { results: [] },
  countEmpred: 0,
  isSaveEmprend: false,
  isLoading: false,
});

const emprendimientoReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ISLOADING:
      return { ...state, isLoading: action.isLoading };
    case GET_EMPRENDIMIENTOS:
      return { ...state, emprendimientos: action.emprendimientos, isLoading: false, isSaveEmprend: false, countEmpred: action.countEmpred };
    case GET_EMPRENDIMIENTOS_BY_ALLY:
      return { ...state, emprendimientosAlly: action.emprendimientosAlly, isLoading: false, isSaveEmprend: false, countEmpred: action.countEmpred };
    case SEARCH_EMPRENDIMIENTO:
      return { ...state, searchEmprend: action.searchEmprend };
    case ADD_EMPRENDIMIENTO:
      return { ...state, isSaveEmprend: true };
    case EDIT_EMPRENDIMIENTO:
      return { ...state, isSaveEmprend: true };
    default:
      return state;
  }
}

export default emprendimientoReducer;