import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import "./resumenSeleccion.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import NotificacionSeleccion from "./notificaciones/notificaciones";
import { Modal, Button } from "react-bootstrap";
import { countAllStatesGender } from "../../../redux/actions/selectionActions";
import { getRelAlerts } from "../../../redux/actions/alertActions";
import CustomAlert from "../../alerts/alerts";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import {
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

function RowSeleccion(props) {
  if (props.consolidado.consolidado.length > 0) {
    const data = props.data;
    const consolidatos = props.consolidado;

    var itemAmount = {
      total: 0,
      hombres: 0,
      mujeres: 0,
      arn: 0,
      victimas: 0,
    };

    itemAmount = consolidatos.consolidado.find(
      (el) => el.subState === data.sub
    );
    return (
      <tr key={data.sub.toString()}>
        <td>{data.name}</td>
        <td>
          {itemAmount !== undefined
            ? `${itemAmount.hombres} / ${itemAmount.mujeres}`
            : `0 / 0`}
        </td>
        <td>
          {itemAmount !== undefined
            ? `${itemAmount.programas} / ${itemAmount.agencias}`
            : `0 / 0`}
        </td>
       {/*  <td>
          {itemAmount !== undefined
            ? `${itemAmount.arn} / ${itemAmount.victimas}`
            : `0 / 0`}
        </td> */}
        <td>{itemAmount !== undefined ? itemAmount.total : 0}</td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td></td>
        <td>0 / 0</td>
        <td>0 / 0</td>
        <td>0 / 0</td>
        <td>0 / 0</td>
      </tr>
    );
  }
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

class ResumenGeneralSeleccion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      citys: [],
      years: [],
      yearsSelected: [],
      months: [],
      consolidadoSeleccion: [
        {
          name: "Remitidos",
          state: 1,
          sub: 1,
        },
        {
          name: "Entrevista",
          state: 1,
          sub: 2,
        },
        {
          name: "No citados",
          state: 1,
          sub: 3,
        },
        {
          name: "No asistieron",
          state: 1,
          sub: 4,
        },
        {
          name: "No continuan proceso",
          state: 1,
          sub: 5,
        },
        {
          name: "No pasaron proceso",
          state: 1,
          sub: 6,
        },
        {
          name: "Pendientes",
          state: 1,
          sub: 7,
        },
        {
          name: "Aprobados por la Alianza",
          state: 1,
          sub: 8,
        },
      ],
      enRemitidos: 0,
      enRemitidosM: 0,
      enRemitidosF: 0,
      enCandidatos: 0,
      enCandidatosM: 0,
      enCandidatosF: 0,
      enAprobados: 0,
      enAprobadosM: 0,
      enAprobadosF: 0,
      enNoPasaronProceso: 0,
      enNoPasaronProcesoM: 0,
      enNoPasaronProcesoF: 0,
      enNoCitados: 0,
      enNoCitadosM: 0,
      enNoCitadosF: 0,
      enNoAsistieron: 0,
      enNoAsistieronM: 0,
      enNoAsistieronF: 0,
      enPendientes: 0,
      enPendientesM: 0,
      enPendientesF: 0,
    };
  }

  componentDidMount = async () => {
    this.props.getRelAlerts(new Date(), 1);
    await this.props.countAllStatesGender();
    this.setInitialData();
    this.getListYears();
  };

  getListYears = () => {
    let currentYear = new Date().getFullYear();
    let tempYears = [currentYear];
    var prevYear = new Date();
    for (let i = 0; i < 5; i++) {
      prevYear.setFullYear(prevYear.getFullYear() - 1);
      let year = prevYear.getFullYear();
      tempYears.push(year);
      this.setState({
        years: tempYears,
      });
    }
  };

  handleChange2 = (event) => {
    console.log(event.target.value);
    this.setState({
      citys: event.target.value,
    });
  };
  handleChange3 = (event) => {
    console.log(event.target.value);
    this.setState({
      yearsSelected: event.target.value,
    });
  };
  handleChange4 = (event) => {
    console.log(event.target.value);
    this.setState({
      months: event.target.value,
    });
  };

  setInitialData = () => {
    try {
      var remitidos = this.props.getSummaryState.find(
        (el) => el.subState === "Remitidos"
      );
      var candidatos = this.props.getSummaryState.find(
        (el) => el.subState === "Candidatos"
      );
      var aprobados = this.props.getSummaryState.find(
        (el) => el.subState === "Aprobados"
      );
      var noPasaronProceso = this.props.getSummaryState.find(
        (el) => el.subState === "No pasaron proceso"
      );
      var noAsistieron = this.props.getSummaryState.find(
        (el) => el.subState === "Asistieron"
      );
      var pendientes = this.props.getSummaryState.find(
        (el) => el.subState === "Pendientes"
      );
      var noCitados = this.props.getSummaryState.find(
        (el) => el.subState === "No citados"
      );

      this.setState({
        enRemitidos: remitidos.total,
        enRemitidosM: remitidos.totalM,
        enRemitidosF: remitidos.totalF,
        enCandidatos: candidatos.total,
        enCandidatosM: candidatos.totalM,
        enCandidatosF: candidatos.totalF,
        enAprobados: aprobados.total,
        enAprobadosM: aprobados.totalM,
        enAprobadosF: aprobados.totalF,
        enNoPasaronProceso: noPasaronProceso.total,
        enNoPasaronProcesoM: noPasaronProceso.totalM,
        enNoPasaronProcesoF: noPasaronProceso.totalF,
        enNoCitados: noCitados.total,
        enNoCitadosM: noCitados.totalM,
        enNoCitadosF: noCitados.totalF,
        enNoAsistieron: noAsistieron.total,
        enNoAsistieronM: noAsistieron.totalM,
        enNoAsistieronF: noAsistieron.totalF,
        enPendientes: pendientes.total,
        enPendientesM: pendientes.totalM,
        enPendientesF: pendientes.totalF,
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  render() {
    const idParam = this.props.match.params.ally;

    const data = [
      {
        name: "Ago",
        hombres: 15,
        mujeres: 40,
      },
      {
        name: "Sep",
        hombres: 22,
        mujeres: 54,
      },
      {
        name: "Oct",
        hombres: 12,
        mujeres: 41,
      },
      {
        name: "Nov",
        hombres: 15,
        mujeres: 40,
      },
      {
        name: "Dic",
        hombres: 53,
        mujeres: 25,
      },
    ];
    const data2 = [
      {
        name: "Bogota",
        hombres: 60,
        mujeres: 15,
      },
      {
        name: "Medellin",
        hombres: 12,
        mujeres: 24,
      },
      {
        name: "Cali",
        hombres: 15,
        mujeres: 40,
      },
      {
        name: "Barranquilla",
        hombres: 34,
        mujeres: 45,
      },
      {
        name: "Codazzi",
        hombres: 34,
        mujeres: 45,
      },
    ];

    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion" style={{ height: "100vh" }}>
          <div style={{ position: "absolute", zIndex: 99, right: 10 }}>
            {this.props.alertRemitidos.results.length !== 0 && (
              <CustomAlert
                color="info"
                msg={`Se han remitido ${this.props.alertRemitidos.results.length} candidatos a proceso de selección`}
                data={this.props.alertRemitidos.results}
                history={this.props.history}
                modulePath={this.props.modulePath}
              />
            )}
          </div>
          <div className="customRow mb-2 text-left">
            <p>
              <strong className="titleCiudad text-left">
                Resumen Modulo de Selección
              </strong>
            </p>
          </div>

          <div className="container-fluid bgResumenGeneralSeleccion">
            <div className="col-12">
              <NotificacionSeleccion />
            </div>

            <Row className="filterRow">
              <Col xs="12" md="3">
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-name-label">Ciudad</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    input={<OutlinedInput label="Name" />}
                    multiple
                    value={this.state.citys}
                    onChange={this.handleChange2}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="3">Medellín</MenuItem>
                    <MenuItem value="4">Bogotá</MenuItem>
                    <MenuItem value="5">Cali</MenuItem>
                    <MenuItem value="6">Barranquilla</MenuItem>
                    <MenuItem value="7">Barrancabermeja</MenuItem>
                    <MenuItem value="8">Cartagena</MenuItem>
                    <MenuItem value="9">Codazzi</MenuItem>
                    <MenuItem value="10">Bucaramanga</MenuItem>
                    <MenuItem value="11">Manizales</MenuItem>
                    <MenuItem value="12">Pereira</MenuItem>
                  </Select>
                </FormControl>

                {/* <label>
                  <strong>Ciudad</strong>
                </label>
                <select className="form-control" name="" id="" multiple>
                  <option>Todos</option>
                  <option>Medellin</option>
                  <option>Bogota</option>
                  <option>Barranquilla</option>
                </select> */}
              </Col>
              <Col xs="12" md="2">
                <FormControl sx={{ m: 1, width: 200 }}>
                  <InputLabel id="demo-multiple-name-label">Año</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    input={<OutlinedInput label="Name" />}
                    value={this.state.yearsSelected}
                    onChange={this.handleChange3}
                    MenuProps={MenuProps}
                  >
                    {this.state.years.map((year, index) => (
                      <MenuItem
                        value={String(year).substring(2, 4)}
                        key={index.toString()}
                      >
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/*  <label>
                  <strong>Año</strong>
                </label>
                <select className="form-control" name="" id="">
                  
                </select> */}
              </Col>
              <Col xs="12" md="2">
                <FormControl sx={{ m: 1, width: 150 }}>
                  <InputLabel id="demo-multiple-name-label">Mes</InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    input={<OutlinedInput label="Name" />}
                    value={this.state.months}
                    onChange={this.handleChange4}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="01">Enero</MenuItem>
                    <MenuItem value="02">Febrero</MenuItem>
                    <MenuItem value="03">Marzo</MenuItem>
                    <MenuItem value="04">Abril</MenuItem>
                    <MenuItem value="05">Mayo</MenuItem>
                    <MenuItem value="06">Junio</MenuItem>
                    <MenuItem value="07">Julio</MenuItem>
                    <MenuItem value="08">Agosto</MenuItem>
                    <MenuItem value="09">Septiembre</MenuItem>
                    <MenuItem value="10">Octubre</MenuItem>
                    <MenuItem value="11">Noviembre</MenuItem>
                    <MenuItem value="12">Diciembre</MenuItem>
                  </Select>
                </FormControl>

                {/* <label>
                  <strong>Mes</strong>
                </label>
                <select className="form-control" name="" id="">
                  
                </select> */}
              </Col>
              <Col
                xs="12"
                md="5"
                style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
              >
                <div className="text-right">
                  <Link to={`cities`}>
                    <Button variant="info">
                      <strong>Ver detalle</strong>{" "}
                    </Button>
                  </Link>
                </div>
                <div style={{ textAlign: "right", marginLeft: 20 }}>
                  <a
                    href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?years=${this.state.yearsSelected}&month=${this.state.months}&citys=${this.state.citys}`}
                    target="_blank"
                    style={{ display: "inline" }}
                  >
                    <Button variant="info">
                      Descargar
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>

            <div className="table-responsiveResumenSeleccion containerResumenGeneralSeleccion">
              <table className="table tableResumenGeneralSeleccion">
                <thead>
                  <tr style={{ backgroundColor: "white" }}>
                    <th scope="col" className="titleTable">
                      Estados
                    </th>
                    <th scope="col" className="titleTable">
                      Genero (M / F)
                    </th>
                    <th scope="col" className="titleTable">
                      Programas / Poblacion
                    </th>
                   {/*  <th scope="col" className="titleTable">
                      ARN / Victimas
                    </th> */}
                    <th scope="col" className="titleTable">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.getSummaryState.length !== 0
                    ? this.state.consolidadoSeleccion.map((item, i) => {
                        return (
                          <RowSeleccion
                            key={i.toString()}
                            data={item}
                            consolidado={this.props.getSummaryState}
                          />
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>

            <div className="row">
              <div className="col-6">
                <h5 className="card-title">
                  Candidatos Remitidos x Genero y mes
                </h5>
                <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
                  <LineChart
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="hombres"
                      stroke="#204d6a"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="mujeres" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="col-6">
                <h5 className="card-title">Candidatos x Ciudad y Genero</h5>
                <ResponsiveContainer width="100%" aspect={4.0 / 3.0}>
                  <BarChart
                    height={300}
                    data={data2}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="hombres" fill="#204d6a" />
                    <Bar dataKey="mujeres" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div style={{ height: "130px" }}></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.selectionReducer.isLoading,
    getSummaryState: state.selectionReducer.getSummaryState,
    alertRemitidos: state.alertsReducer.alertRemitidos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    countAllStatesGender: bindActionCreators(countAllStatesGender, dispatch),
    getRelAlerts: bindActionCreators(getRelAlerts, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumenGeneralSeleccion);
