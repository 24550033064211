import { ISLOADING, GET_RESUM_GENDER_BY_BUSINESS, GET_TOTAL_SUMMARY, GET_SUMMARY_TRACING, GET_SUMMARY_TRACING_NUEVO, GET_TOTAL_EMPRENDIMIENTO } from '../constants/action-type';

const getInitialState = () => ({
  isLoading: false,
  summaryGender: [],
  summaryTotal: [],
  summaryTracing: [],
  summaryTracingNuevo: [],
  emprendTotal: []
});

const summariesReducer = (state = getInitialState(), action) => {
  switch(action.type) {
    case ISLOADING:
      return { ...state, isLoading: action.isLoading };
    case GET_RESUM_GENDER_BY_BUSINESS:
      return { ...state, summaryGender: action.summaryGender, isLoading: false };
    case GET_TOTAL_SUMMARY:
      return { ...state, summaryTotal: action.summaryTotal, isLoading: false };
    case GET_SUMMARY_TRACING:
      return { ...state, summaryTracing: action.summaryTracing, isLoading: false };
      case GET_SUMMARY_TRACING_NUEVO:
        return { ...state, summaryTracingNuevo: action.summaryTracingNuevo, isLoading: false };
    case GET_TOTAL_EMPRENDIMIENTO:
      return { ...state, emprendTotal: action.emprendTotal, isLoading: false };
    default:
      return state;
  }
}

export default summariesReducer;