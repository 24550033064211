import React, { Component, Fragment } from "react";
import { FormGroup, Input, Col, Button } from "reactstrap";
import "./seguimiento.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import { getSelectionByState } from "../../../redux/actions/selectionActions";
import { getSummariesTracing } from "../../../redux/actions/summaryActions";
import NotificacionAcompanamiento from "../notificaciones/notificaciones";
import ModalUpOk from "../../seleccion/popUpOk/popUpOk";

function LinkedRow(props) {
  const candidates = props.list;

  return (
    <tr id="tr" key={candidates.id.toString()}>
      <td className="titleItemRow">{candidates.document}</td>
      <td className="titleItemRow">{candidates.fullName}</td>
      <td className="titleItemRow">{candidates.email}</td>
      <td className="titleItemRow">{candidates.positions[0].nameClient}</td>
      <td className="titleItemRow">{candidates.positions[0].nameVacancy}</td>
      <td>
        <div className="actionIcons">
          <Link to={`seguimiento/view/${candidates.id}`}>
            <i
              className="fa fa-eye"
              style={{ color: "#17a2b8" }}
              aria-hidden="true"
            ></i>
          </Link>
          <Link to={`seguimiento/new/${candidates.id}`}>
            <i
              className="fa fa-plus-circle"
              style={{ color: "#28a745" }}
              aria-hidden="true"
            ></i>
          </Link>
        </div>
      </td>
    </tr>
  );
}

class ResumenSeguimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDate: sessionStorage.getItem("seguimientoDateFilter")
        ? sessionStorage.getItem("seguimientoDateFilter")
        : this.formatDate(new Date()),
      criterios: [
        {
          bg: 0,
          color: "#fff",
          icon: "fa-frown-o",
          range: "0 - 6",
          min: 0,
          max: 6,
          type: "rango1",
        },
        {
          bg: 1,
          color: "#fff",
          icon: "fa-frown-o",
          range: "7 - 12",
          min: 7,
          max: 12,
          type: "rango2",
        },
        {
          bg: 2,
          color: "#fff",
          icon: "fa-meh-o",
          range: "13 - 14",
          min: 13,
          max: 14,
          type: "rango3",
        },
        {
          bg: 3,
          color: "#000",
          icon: "fa-meh-o",
          range: "15 - 16",
          min: 15,
          max: 16,
          type: "rango4",
        },
        {
          bg: 4,
          color: "#000",
          icon: "fa-smile-o",
          range: "17 - 18",
          min: 17,
          max: 18,
          type: "rango5",
        },
      ],
    };
  }

  componentDidMount = () => {
    let month;
    let year;
    if (sessionStorage.getItem("seguimientoDateFilter")) {
      this.setState({
        filterDate: sessionStorage.getItem("seguimientoDateFilter"),
      });
      let today = sessionStorage.getItem("seguimientoDateFilter").split("-");
      month = today[1];
      year = today[0];
    } else {
      this.props.getSelectionByState([]);
      year = new Date().getFullYear();
      month = new Date().getMonth() + 1;
    }

    //if (month.length < 2) month = "0" + month;
    console.log(month, year);
    if (typeof month === "string") {
      if (month.charAt(0) === "0") month = month.replace("0", "");
     }
    this.props.getSummariesTracing(month, year);
    console.log(this.props.summaryTracing);
  };

  componentDidUpdate = () => {};

  goToDetail(min, max) {
    let today = this.state.filterDate.split("-");
    let month = today[1];
    let year = today[0];
    this.props.history.push(
      `acompanamiento/seguimiento/${min}/${max}/${month}/${year}`
    );
  }

  handleChange = (e) => {
    this.setState({
      filterDate: e.target.value,
    });

    let today = e.target.value.split("-");
    let month = today[1];
    let year = today[0];

    sessionStorage.setItem("seguimientoDateFilter", e.target.value);

    this.props.getSummariesTracing(month, year);
  };

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    console.log("date", [year, month, day].join("-"));

    return [year, month, day].join("-");
  }
  returnMonth(month) {
    let montEdit;
    if (month.charAt(0) === "0") {
      montEdit = month.replace("0", "");
    } else {
      montEdit = month;
    }
    return montEdit;
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div
          className="col-12 bgResumenSeleccion"
          style={{ height: "100vh", paddingBottom: "200px" }}
        >
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Seguimientos Historico
              </strong>
            </p>
            <p className="subtitleSeguimiento">
              Resumen general total de seguimientos hasta el año 2023.
            </p>
            <div className="row ">
              <Col xs="12" md="3" style={{ marginBottom: 10 }}>
                <FormGroup>
                  <label>Mes / Año</label>
                  <Input
                    id="filterTime"
                    type="date"
                    value={this.state.filterDate}
                    onChange={this.handleChange}
                  />
                  {/* <br></br>
                <button className="btn btn-info">Nuevo Seguimiento</button> */}
                </FormGroup>
              </Col>
              <Col xs="12" md="2" style={{ marginBottom: 10 }}>
                {this.state.filterDate && (
                  <div style={{ marginTop: 30 }}>
                    <a
                      href={`https://alianza.multimedialab.dev/UHJvamVjdA/gf4349/?year=${
                        this.state.filterDate.split("-")[0]
                      }&month=${this.returnMonth(
                        this.state.filterDate.split("-")[1]
                      )}`}
                      target="_blank"
                      style={{ display: "inline" }}
                    >
                      <Button color="info">Descargar mes/año</Button>
                    </a>
                  </div>
                )}
              </Col>
              <Col xs="12" md="2" style={{ marginBottom: 10 }}>
                {this.state.filterDate && (
                  <div style={{ marginTop: 30 }}>
                    <a
                      href={`https://alianza.multimedialab.dev/UHJvamVjdA/gf4349/?year=${
                        this.state.filterDate.split("-")[0]
                      }`}
                      target="_blank"
                      style={{ display: "inline" }}
                    >
                      <Button color="info">Descargar año</Button>
                    </a>
                  </div>
                )}
              </Col>
            </div>
          </div>

          <div className="row" style={{ width: "90%", margin: "0 auto" }}>
            {this.props.summaryTracing.length !== 0 &&
              this.state.criterios.map((item, index) => {
                return (
                  <div className="col-sm-4" key={index.toString()}>
                    <div
                      className="inforide"
                      onClick={() => this.goToDetail(item.min, item.max)}
                    >
                      <div className="row">
                        <div
                          className={`col-lg-3 col-md-4 col-sm-4 col-4 rideone bgColor${item.bg}`}
                        >
                          <i
                            className={`fa ${item.icon}`}
                            aria-hidden="true"
                            style={{ color: item.color }}
                          ></i>
                          <span style={{ color: item.color }}>
                            {item.range}
                          </span>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                          <h4>Total</h4>
                          <h2>
                            {
                              this.props.summaryTracing.consolidado[0][
                                item.type
                              ]
                            }
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.selectionReducer.isLoading,
    getSelectionByState: state.selectionReducer.getSelectionByState,
    summaryTracing: state.summariesReducer.summaryTracing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSelectionByState: bindActionCreators(getSelectionByState, dispatch),
    getSummariesTracing: bindActionCreators(getSummariesTracing, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumenSeguimiento);
