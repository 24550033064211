import { LOGIN_ALIADO, LOGOUT_ALIADO, SET_MODULE_PATH, PUT_ALLYDATA, ISLOADING, LOGIN_GESTORES, LOGIN_CLIENTES, GET_CANDIDATES_BY_ALLY, GET_TIMELINE_BY_ALLY, GET_ALL_ALLYS, GET_ALLYS_BY_CITY, PAGE_COUNT } from '../constants/action-type';

const getInitialState = () => ({
    allyData: [],
    timeAlly: [],
    allyCities: [],
    isLoading: false,
    candidatesByAlly: [],
    allAllys: [],
    modulePath: "",
    pageCount: 0
});

const allyReducer = (state = getInitialState(), action) => {
    switch(action.type) {
        case ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case PAGE_COUNT:
            return { ...state, pageCount: action.pageCount };
        case LOGIN_ALIADO:
            return { ...state, allyData: action.allyData, isLoading: false };
        case LOGOUT_ALIADO:
            return { ...state, allyData: action.allyData };
        case PUT_ALLYDATA:
            return { ...state, allyData: action.allyData, isLoading: false };
        case LOGIN_GESTORES:
            return { ...state, allyData: action.allyData, isLoading: false };
        case LOGIN_CLIENTES:
                return { ...state, allyData: action.allyData, isLoading: false };
        case GET_CANDIDATES_BY_ALLY:
            return { ...state, candidatesByAlly: action.candidatesByAlly, isLoading: false };
        case GET_TIMELINE_BY_ALLY:
            return { ...state, timeAlly: action.timeAlly, isLoading: false };
        case GET_ALL_ALLYS:
            return { ...state, allAllys: action.allAllys, isLoading: false };
        case GET_ALLYS_BY_CITY:
            return { ...state, allyCities: action.allyCities, isLoading: false };
        case SET_MODULE_PATH:
            return { ...state, modulePath: action.modulePath };
        default:
            return state;
    }
}
export default allyReducer;