import React, { Component, Fragment } from "react";
import "./addAsesoria.styles.css";
import { connect } from 'react-redux';
import Header from "../../../header/Header";
import { Link } from "react-router-dom";

class AddAsesoriasAcompanamiento extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  navigateTo = route => {
    this.props.history.push(route)
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Tipo de Asesoria
                </strong>
              </p>
            </div>
          </div>
          <div className="backgroundTypesAsesoarias">
            <div className="row containerTypesAsesoarias">
              <div className="col-3">
                <div className="itemAsesoria"
                  style={{cursor: 'pointer'}}
                  onClick={() => this.navigateTo(`new/employee`)}
                >
                  <h6>Colaborador</h6>
                </div>
              </div>
              <div className="col-3">
                <div className="itemAsesoria"
                  style={{cursor: 'pointer'}}
                  onClick={() => this.navigateTo(`new/jefe`)}
                >
                  <h6>Jefe Inmediato</h6>
                </div>
              </div>
              <div className="col-3">
                <div className="itemAsesoria"
                  style={{cursor: 'pointer'}}
                  onClick={() => this.navigateTo(`new/remisiones`)}
                >
                  <h6>Remisiones</h6>
                </div>
              </div>
              {/* {this.props.modulePath === 'acompanamiento' || this.props.modulePath === 'practicante' ? <div className="col-3">
                <div className="itemAsesoria"
                  style={{cursor: 'pointer'}}
                  onClick={() => this.navigateTo(`new/consolidado`)}
                >
                  <h6>Consolidados</h6>
                </div>
              </div> : null} */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    modulePath: state.allyReducer.modulePath
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAsesoriasAcompanamiento);
