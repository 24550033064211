import React, { Component, Fragment } from "react";
import "./addAsesoria.styles.css";

import Header from "../../header/Header";
import { Link } from "react-router-dom";

class AddAsesorias extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Tipo de Asesoria
                </strong>
              </p>
            </div>
          </div>
          <div className="backgroundTypesAsesoarias">
            <div className="row containerTypesAsesoarias">
              <div className="col-3">
                <div className="itemAsesoria">
                  <Link
                    to={`/relacionamiento/advisory/new/employee`}
                    style={{ textDecoration: "none" }}
                  >
                    <h6>Colaborador</h6>
                  </Link>
                </div>
              </div>
              <div className="col-3">
                <div className="itemAsesoria">
                  <Link
                    to={`/relacionamiento/advisory/new/jefe`}
                    style={{ textDecoration: "none" }}
                  >
                    <h6>Jefe Inmediato</h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AddAsesorias;
