import React, { Component, Fragment } from "react";
import { Col, FormGroup, Input } from "reactstrap";
import "./aliadosExternos.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getCandidatesByAllyRel,
  setTabs,
  setCandidate,
  updatePositionsSubstates,
} from "../../../redux/actions/candidateActions";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import Pagination from "react-js-pagination";

import Swal from "sweetalert2";
import swal from "sweetalert";

function CandidateRow(props) {
  const candidate = props.candidate;
  const date = props.candidate.admissionDate;
  const state = props.candidateState;
  return (
    <tr id="tr" key={candidate.id.toString()}>
      <td className="titleItemRow">
        <input
          type="checkbox"
          checked={props.isCheck}
          onChange={(e) => props.onChangeCheck(e, candidate)}
        />
      </td>
      <td className="titleItemRow">{candidate.document}</td>
      <td className="titleItemRow">{candidate.fullName}</td>
      <td className="titleItemRow">
        {candidate.positions.length != 0
          ? candidate.positions[0].nameVacancy
          : "N/A"}
      </td>
      <td className="titleItemRow">
        {candidate.positions.length != 0
          ? candidate.positions[0].nameClient
          : "N/A"}
      </td>
      <td className="titleItemRow">
        {candidate.allyType == "0" ? candidate.allyName : "N/A"}
      </td>
      <td className="titleItemRow">
        {candidate.allyType == "1" ? candidate.allyName : "N/A"}
      </td>
      <td className="titleItemRow">{date}</td>
      <td className="titleItemRow">
        <button
          style={{ minWidth: 210 }}
          className={`btn ${
            candidate.positions[0].proccessSubstate == 12
              ? "btn-warning"
              : "btn-success"
          }`}
        >
          {candidate.positions[0].psubstateName}
        </button>
      </td>
      <td>
        <Link to={`/${props.path}/viewCandidate/${candidate.id}`}>
          <i className="fas fa-pencil-alt text-info"></i>
        </Link>
      </td>
    </tr>
  );
}

class AliadosExternos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      multipleCandidate: [],
      program: ["Agencia", "Programa"],
      checkAll: false,
      activePage: 1,
    };
  }

  componentDidMount = () => {
    var idAlly = this.props.match.params.ally;
    if (this.props.modulePath === "acompanamiento") {
      this.props.getCandidatesByAllyRel(idAlly, 0, 1, 1, "True");
    } else {
      this.props.getCandidatesByAllyRel(idAlly, 0, 1, 1);
    }
    this.props.setTabs([
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
    ]);
    this.props.setCandidate([]);
  };

  changeValidateChange = (e, c) => {
    let tempCandidate = this.state.multipleCandidate;
    let i = tempCandidate.indexOf(c);
    if (i !== -1) {
      tempCandidate.splice(i, 1);
      this.setState({
        multipleCandidate: tempCandidate,
      });
    } else {
      tempCandidate.push(c);
      this.setState({
        multipleCandidate: tempCandidate,
      });
    }
  };

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  sendToSelection = () => {
    if (this.state.multipleCandidate.length !== 0) {
      swal({
        title: "Advertencia!",
        text: "Estas seguro de enviar a procesos de selección?",
        icon: "warning",
        buttons: true,
      }).then((willSend) => {
        if (willSend) {
          this.sendCandidates();
        }
      });
    } else {
      swal({
        icon: "warning",
        title:
          "Por favor selecciona al menos un candidao para ser enviado a selección",
      });
    }
  };

  sendCandidates = () => {
    this.state.multipleCandidate.forEach((item, index) => {
      let obj = {
        idCandidate: item.url,
        position: item.positions[0].id,
        proccessSubstate: 1,
        proccessState: 1,
      };
      let traceObj = {
        action: `Remitido a proceso de selección por ${
          this.validateIdentity().name
        }`,
        candidate: item.url,
        manager: this.validateIdentity().url,
      };
      this.props.updatePositionsSubstates(obj, false, traceObj);
      if (this.state.multipleCandidate.length == index + 1) {
        Swal.fire({
          icon: "success",
          title: "Los candidatos fueron enviados a selección correctamente.",
          timer: 3000,
        }).then((result) => {
          if (result.isConfirmed || result.dismiss == "timer") {
            var idAlly = this.props.match.params.ally;
            this.props.getCandidatesByAllyRel(idAlly, 0, 1);
          }
        });
      }
    });
  };

  validateIdentity = () => {
    if (this.props.allyData.length !== 0) {
      let obj = {};
      let data = this.props.allyData[0];
      if (data.businessData) {
        obj = {
          name: data.businessData.name,
          url: data.businessData.url,
        };
      } else if (data.advisorData) {
        obj = {
          name: data.advisorData.fullName,
          url: data.advisorData.url,
        };
      } else if (data.managerData) {
        obj = {
          name: data.managerData.fullName,
          url: data.managerData.url,
        };
      }
      return obj;
    }
  };

  onClickSendToRevision = () => {
    Swal.fire({
      icon: "success",
      title: "Candidatos Enviados A Revision con Alianza",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  onClickSendAllToRevision = () => {
    Swal.fire({
      icon: "success",
      title: "Todos Los Candidatos Fueron Enviados A Revision con Alianza",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  sendAll = (e) => {
    if (e) {
      this.setState({
        multipleCandidate: this.props.candidatesAlly.results,
        checkAll: true,
      });
    } else {
      this.setState({
        multipleCandidate: [],
        checkAll: false,
      });
    }
  };

  handlePageChange = (e) => {
    window.scroll(0, 0);
    var idAlly = this.props.match.params.ally;
    this.props.getCandidatesByAllyRel(idAlly, 0, 1, e);

    this.setState({
      activePage: e,
    });
  };

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value),
    });
  };

  renderOptionsPage = () => {
    let options = [];
    if (this.props.pageCount !== 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>
                {i}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundAliadosExternos">
          <div className="row containerHeader">
            <div className="col-2 containerInbox">
              <Link to="#" className="btnInbox">
                <i className="fas fa-inbox text-info fa-3x"></i>
              </Link>
            </div>
            <div className="customRow mb-5 text-left">
              <p>
                <strong className="titleSeguimiento text-left">
                  Candidatos Remitidos
                </strong>
              </p>
              <p className="subtitleSeguimiento">
                Listado de candidatos remitidos
              </p>
            </div>

            <div className="containerBoton">
              <button
                className="btn btn-info"
                style={{ marginBottom: 15 }}
                onClick={() => this.sendToSelection()}
              >
                ENVIAR A PROCESO DE SELECCIÓN
              </button>
            </div>
          </div>

          <div className="table-responsiveAliados containerTable">
            <table className="table tableRelacionamientoAliados">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="titleTable"
                    style={{ alignItems: "center" }}
                  >
                    Todos{" "}
                    <input
                      type="checkbox"
                      onChange={() => this.sendAll(!this.state.checkAll)}
                    />
                  </th>
                  <th scope="col" className="titleTable">
                    Cédula
                  </th>
                  <th scope="col" className="titleTable">
                    Nombre
                  </th>
                  <th scope="col" className="titleTable">
                    Cargo
                  </th>
                  <th scope="col" className="titleTable">
                    Empresa
                  </th>
                  <th scope="col" className="titleTable">
                    Agencia
                  </th>
                  <th scope="col" className="titleTable">
                    Programa
                  </th>
                  <th scope="col" className="titleTable">
                    Fecha Ingreso
                  </th>
                  <th scope="col" className="titleTable">
                    Estado
                  </th>
                  <th scope="col" className="titleTable"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.candidatesAlly.length != 0
                  ? this.props.candidatesAlly.results.map(
                      (candidate, index) => {
                        return (
                          <CandidateRow
                            candidate={candidate}
                            key={index.toString()}
                            date={this.formatDate(
                              parseInt(candidate.admissionDate) * 1000
                            )}
                            candidateState={
                              candidate.positions[0].psubstateName
                            }
                            onChangeCheck={(e, c) => {
                              this.changeValidateChange(e, c);
                            }}
                            isCheck={this.state.multipleCandidate.includes(
                              candidate
                            )}
                            path={this.props.modulePath}
                          />
                        );
                      }
                    )
                  : null}
              </tbody>
            </table>
          </div>

          <Col
            md={12}
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.pageCount}
              pageRangeDisplayed={5}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={5}
              pageRangeDisplayed={10}
            />
            {this.renderOptionsPage()}
          </Col>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    candidatesAlly: state.candidateReducer.candidatesAlly,
    isLoading: state.candidateReducer.isLoading,
    pageCount: state.candidateReducer.pageCount,
    modulePath: state.allyReducer.modulePath,
    allyData: state.allyReducer.allyData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidatesByAllyRel: bindActionCreators(
      getCandidatesByAllyRel,
      dispatch
    ),
    setTabs: bindActionCreators(setTabs, dispatch),
    setCandidate: bindActionCreators(setCandidate, dispatch),
    updatePositionsSubstates: bindActionCreators(
      updatePositionsSubstates,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AliadosExternos);
