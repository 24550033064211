import React, { Component, Fragment } from 'react';
import { Col, FormGroup, Input } from 'reactstrap';
import './reingreso.styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllRequestsReentry, updateStateCandidate } from '../../../redux/actions/candidateActions';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Pagination from "react-js-pagination";

import Header from '../../header/Header';

import swal from 'sweetalert';

function CandidateRow(props){
    const candidate = props.candidate;
    const date = props.date;
    const state = props.candidateState;
    return(
        <tr id="tr" key={candidate.id.toString()}>
            <td className="titleItemRow">{candidate.document}</td>
            <td className="titleItemRow">{candidate.fullName}</td>
            <td className="titleItemRow">{date}</td>
            <td className="titleItemRow">{candidate.allyName}</td>
            <td className="titleItemRow">{'No cumple perfil'}</td>
            <td className="tdIcons">
                <a className="btnHV" href="#">
                    <img src={require('../../../assets/IconAliadosExternos/hv.png')} alt="Hoja de vida" className="iconHojaVida" ></img>
                </a>
                <div className="btnHV" href="#"
                    onClick={props.confirmReentry}
                >
                    <img src={require('../../../assets/IconAliadosExternos/refresh.png')} alt="Hoja de vida" className="iconHojaVida" ></img>
                </div>
            </td>
        </tr>   
    )
}

class Reingreso extends Component {
    constructor(props) {
        super(props);
        this.state= {
            confirmReentry: '0',
            reentryObservations: '',
            activePage: 1
        };
    }

    componentDidMount = () => {
        this.props.getAllRequestsReentry();
    }

    handleChange = (e) => {
        console.log(e);
        const newState = this.state;
        newState[e.target.id] = e.target.value;
        this.setState(newState);
    }

    confirmReintry = (candidate) => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui' className="customAlert">
                  <h4>{`${candidate.allyName} a solicitado reingresar este candidato`}</h4>
                  <p>Por favor completa la siguiente información:</p>
                  <form>
                      <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Aceptar</label>
                          <div className="col-sm-9">
                              <select  
                                    className="form-control" 
                                    id="confirmReentry" 
                                    onChange={this.handleChange}
                                    // value={this.state.confirmReentry}
                                >
                                    <option value="0">Si</option>
                                    <option value="1">No</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Motivo</label>
                          <div className="col-sm-9">
                              <textarea 
                                    className="form-control" 
                                    id="reentryObservations" 
                                    // value={this.state.reentryObservations}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                  </form>
                  <button
                    className="customButtons"
                    onClick={() => {
                      this.updateStateCandidate(candidate);
                      onClose();
                    }}
                  >
                    Confirmar
                  </button>
                  <button className="customButtons" onClick={onClose}>Cerrar</button>
                </div>
              );
            }
        });
    }

    updateStateCandidate = (candidate) => {
        if(this.state.reentryObservations !== ''){
            var obj = {
                candidateState: parseInt(this.state.confirmReentry),
                candidateSubstate: 0,
                reentryObservations: this.state.reentryObservations,
                requestsReentry: false
            }
            console.log(obj);
            this.props.updateStateCandidate(candidate.id, obj, `El candidato fue nuevamente remitido.`);
        }else{
            swal('Por favor ingresa el motivo');
        }
    }

    formatDate(date) {
        var fecha = date.split('T');
        var resultado = fecha[0];
        return resultado
    }

    handlePageChange = (e) => {
        window.scroll(0,0);
        this.props.getAllRequestsReentry(e);
    
        this.setState({
          activePage: e
        });
      }
    
      changeSelectPage = (e) => {
        this.handlePageChange(e.target.value);
        this.setState({
          activePage: parseInt(e.target.value)
        });
      }
    
      renderOptionsPage = () => {
        let options = [];
        if(this.props.pageCount !== 0){
          let base = 50;
          let pages = 0;
          pages = Math.ceil(this.props.pageCount / base);
    
          for (let i = 0; i < pages; i++) {
            options.push(i+1);
          }
        }
        return(
          <FormGroup>
            <Input
              style={{marginLeft: 10}}
              type="select"
              value={this.state.activePage}
              onChange={this.changeSelectPage}
            >
              {options.map((i) => {
                return(
                  <option key={i.toString()} value={i}>{i}</option>
                )
              })}
            </Input>
          </FormGroup>
        )
      }

    render() {
        return(
            <Fragment>
            <Header />
            <div className="backgroundReingreso">
                <div className="customRow containerHeader">

                <div className="col-12 containerTitleSeleccion mb-5 text-left">
                    <p>
                        <strong className="titleSeguimiento text-left">Reingresos</strong>
                    </p>
                    <p className="subtitleSeguimiento">Listado de candidatos retirados</p>
                </div>

                   
                </div>

                <div className="table-responsiveReingreso containerTable">
                    <table className="table tableReingresos">
                        <thead>
                            <tr>
                                <th scope="col" className="titleTable">Cédula</th>
                                <th scope="col" className="titleTable">Nombre Completo</th>
                                <th scope="col" className="titleTable">Fecha Retiro</th>
                                <th scope="col" className="titleTable">Aliado</th>
                                <th scope="col" className="titleTable">Motivo</th>
                                <th scope="col" className="titleTable">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.requestReentry.length != 0 ? this.props.requestReentry.results.map((candidate, index) => {
                                return(
                                    <CandidateRow
                                        candidate={candidate}
                                        key={index.toString()}
                                        date={this.formatDate(candidate.timestamp)}
                                        confirmReentry={() => this.confirmReintry(candidate)}
                                    />
                                )
                            }): null }
                        </tbody>
                    </table>
                </div>

                <Col md={12} style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
                    <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={50}
                    totalItemsCount={this.props.pageCount}
                    pageRangeDisplayed={5}
                    onChange={(e) => this.handlePageChange(e)}
                    itemClass="page-item"
                    linkClass="page-link"
                    hideDisabled
                    pageRangeDisplayed={5}
                    pageRangeDisplayed={10}
                    />
                    {this.renderOptionsPage()}
                </Col>
            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        requestReentry: state.candidateReducer.requestReentry,
        pageCount: state.candidateReducer.pageCount
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllRequestsReentry: bindActionCreators(getAllRequestsReentry, dispatch),
        updateStateCandidate: bindActionCreators(updateStateCandidate, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reingreso);