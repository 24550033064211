import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { setAllCandidatesStatus } from '../../../redux/actions/candidateActions';
import { updatePositionsSubstates } from '../../../redux/actions/candidateActions';
import { bindActionCreators } from "redux";
import urls from '../../../apiConf';


class ModalAprobadoOk extends Component {
	constructor(props) {
        super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
			show: false,
        };
    }
    
	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        this.props.updatePositionsSubstates({
            position: event.target.position.value,
            proccessState: 3,
            proccessSubstate: 11,
            idCandidate: urls.url_base+'/Q2FuZGlkYXRvcw/'+event.target.candidato.value+'/'
        }, true)
        this.setState({ show: false });
    }

	render() {

        const idCap = this.props.idCan;
        const position = this.props.position;
        const idSubStatus = this.props.subStatus;
        const idStatus = this.props.state;
        
		return (
			<>
                <i className="fas fa-check-circle text-success" onClick={this.handleShow}></i>

				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Aprobar candidato?</Modal.Title>
					</Modal.Header>
                    <form onSubmit={this.handleSubmit}>
                        <Modal.Body className="text-center">
                            <div className="row">
                                <input type="hidden" name="candidato" value={idCap}/>
                                <input type="hidden" name="position" value={this.props.position} />
                                <input type="hidden" name="estado" value={idStatus} />
                                <input type="hidden" name="SubEstado" value={idSubStatus} />
                            </div>
                            <Button variant="danger" className="mr-3" onClick={this.handleClose}>
                                No
                            </Button>
                            <Button type="submit" variant="success">
                                Si
                            </Button>
                        </Modal.Body>
                    </form>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
    return {
        isLoading: state.selectionReducer.isLoading,
        setCandidateStatus: state.selectionReducer.candidatesStatus
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAllCandidatesStatus: bindActionCreators(setAllCandidatesStatus, dispatch),
        updatePositionsSubstates: bindActionCreators(updatePositionsSubstates, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAprobadoOk);