import {
  ISLOADING,
  ADD_WORKSHOP,
  GET_WORKSHOPS,
  ADD_TRACING,
  ADD_TRACING_NUEVO,
  SAVETRACING,
  SAVETRACING_NUEVO,
  GET_TRACING_CANDIDATE,
  GET_TRACING_CANDIDATE_NUEVO,
  GET_WORKSHOPS_BY_COLLABORATOR,
  PAGE_COUNT,
  GET_WORKSHOPSFOCUS,
} from "../constants/action-type";

const getInitialState = () => ({
  allyData: [],
  tracing: [],
  workshop: [],
  workshops: [],
  workshopsUser: [],
  workshopsFocus: [],
  workshopsFocusCount: 0,
  isLoading: false,
  saveTracing: false,
  saveTracingNuevo: false,
  getSelectionByState: [],
  interviewByState: [],
  allAllys: [],
  candidateTracing: { results: [] },
  candidateTracingNuevo: { results: [] },
  pageCount: 0,
});

const accompanimentReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ISLOADING:
      return { ...state, isLoading: action.isLoading };
    case PAGE_COUNT:
      return { ...state, pageCount: action.pageCount };
    case ADD_WORKSHOP:
      return { ...state, workshop: action.workshop, isLoading: false };
    case GET_WORKSHOPS:
      return { ...state, workshops: action.workshops, isLoading: false };
    case ADD_TRACING:
      return {
        ...state,
        tracing: action.tracing,
        isLoading: false,
        saveTracing: false,
      };
    case ADD_TRACING_NUEVO:
      return {
        ...state,
        tracing: action.tracing,
        isLoading: false,
        saveTracingNuevo: false,
      };
    case SAVETRACING:
      return { ...state, saveTracing: action.saveTracing };
      case SAVETRACING_NUEVO:
        return { ...state, saveTracingNuevo: action.saveTracingNuevo };
    case GET_TRACING_CANDIDATE:
      return {
        ...state,
        candidateTracing: action.candidateTracing,
        isLoading: false,
        saveTracing: false,
      };
      case GET_TRACING_CANDIDATE_NUEVO:
        return {
          ...state,
          candidateTracingNuevo: action.candidateTracingNuevo,
          isLoading: false,
          saveTracingNuevo: false,
        };
    case GET_WORKSHOPS_BY_COLLABORATOR:
      return {
        ...state,
        workshopsUser: action.workshopsUser,
        isLoading: false,
      };
    case GET_WORKSHOPSFOCUS:
      return {
        ...state,
        workshopsFocus: action.workshopsFocus,
        workshopsFocusCount: action.workshopsFocusCount,
        isLoading: false,
      };
    default:
      return state;
  }
};
export default accompanimentReducer;
