import React, { Component } from "react";
import { Col, FormGroup, Input } from 'reactstrap';
import "./viewVacante.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRequestVacantes } from "../../../redux/actions/vacanteActions";
import { formatDateYYMMDD } from '../../../utils/formatDate';
import Pagination from "react-js-pagination";

function CandidateRow(props) {
  const vacante = props.vacante;
  const date = props.date;
  return (
    <tr id="tr" key={vacante.id.toString()}>
      <td className="titleItemRow">{vacante.nameCity}</td>
      <td className="titleItemRow">{vacante.name}</td>
      <td className="titleItemRow">{vacante.nameClient}</td>
      <td className="titleItemRow">{vacante.nameBusiness}</td>
      <td className="titleItemRow">{date}</td>
      <td className="titleItemRow">{vacante.endDate}</td>
    </tr>
  );
}

class ViewVacante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  formatDate(date) {
    var fecha = date.split("T");
    var resultado = fecha[0];
    return resultado;
  }

  componentDidMount = () => {
    if (this.props.allyData.length !== 0) {
      let today = formatDateYYMMDD(new Date());
      let id = this.props.allyData[0].advisorData.id;
      let city = this.props.allyData[0].advisorData.idCity.split('/')[5];
      this.props.getRequestVacantes(id, this.state.activePage, city, today);
    }
  };

  handlePageChange = (e) => {
    window.scroll(0, 0);
    let today = formatDateYYMMDD(new Date());
    let id = this.props.allyData[0].advisorData.id;
    let city = this.props.allyData[0].advisorData.idCity.split('/')[5];
    this.props.getRequestVacantes(id, e, city, today);

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if (this.props.pageCount != 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  render() {
    return (
      <div className="bgViewVacante">
        <div className="headerInicio">
          <div className="allyPicture">
            {this.props.allyData.length != 0 && <img src={this.props.allyData[0].advisorData.profilePicture} width="100%" />}
          </div>

          <a className="btnNotification" href="#">
            <img src={require('../../../assets/Icons/bell.png')} alt="Notificaciones" className="iconAlerts" />
          </a>
        </div>
        <div className="customRow mb-5 text-left">
          <p>
            <strong className="titleSeguimiento text-left">Vacantes</strong>
          </p>
          <p className="subtitleSeguimiento">
            Listado de las vacantes solicitadas
          </p>
        </div>

        <div className="table-responsiveVacante containerTable">
          <table className="table tableViewAliados">
            <thead>
              <tr>
                <th scope="col" className="titleTable">Ciudad</th>
                <th scope="col" className="titleTable">Vacante</th>
                <th scope="col" className="titleTable">Cliente</th>
                <th scope="col" className="titleTable">Negocio</th>
                <th scope="col" className="titleTable">Fecha apertura</th>
                <th scope="col" className="titleTable">Fecha de cierre</th>
              </tr>
            </thead>
            <tbody>
              {this.props.vacanteData2.length != 0
                ? this.props.vacanteData2.results.map((vacante, index) => {
                  return (
                    <CandidateRow
                      vacante={vacante}
                      key={index.toString()}
                      date={this.formatDate(vacante.timestamp)}
                    />
                  );
                })
                : null}
            </tbody>
          </table>
        </div>

        <Col md={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={50}
            totalItemsCount={this.props.pageCount}
            pageRangeDisplayed={5}
            onChange={(e) => this.handlePageChange(e)}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
            pageRangeDisplayed={5}
            pageRangeDisplayed={10}
          />
          {this.renderOptionsPage()}
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    vacanteData2: state.vacanteReducer.vacanteData2,
    allyData: state.allyReducer.allyData,
    pageCount: state.vacanteReducer.pageCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRequestVacantes: bindActionCreators(getRequestVacantes, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewVacante);
