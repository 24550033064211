import { GET_INTERVIEWS_BY_TYPE, ISLOADING, PAGE_COUNT } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

import swal from 'sweetalert';

export const isLoading = bool => ({
  type: ISLOADING,
  isLoading: bool
});

export const setInterviews = interviewList => ({
  type: GET_INTERVIEWS_BY_TYPE,
  interviewList
});

export const setCount = pageCount => ({
  type: PAGE_COUNT,
  pageCount
});

export const getInterviewsByType = (type, substate, interview, page=1) => {
  return async(dispatch) => {
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/SW50ZXJ2aWV3/?type=${type}&substate=${substate}&typeinterview=${interview}&page=${page}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          const count = data.count;

          if (data.results.length !== 0) {
            dispatch(setInterviews(data));
            dispatch(setCount(count));
          } else { 
            dispatch(setInterviews([]));
            swal('Lo sentimos, no hay información disponible.');
          }
      } catch (err) {
          dispatch(isLoading(false))
          swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getInterviewsByManager = (type, substate, interview, id, page=1) => {
  return async(dispatch) => {
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/SW50ZXJ2aWV3/?type=${type}&substate=${substate}&typeinterview=${interview}&manager=${id}&page=${page}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          const count = data.count;
          if (data.results.length !== 0) {
            dispatch(setInterviews(data));
            dispatch(setCount(count));
          } else { 
            dispatch(setInterviews([]));
            swal('Lo sentimos, no hay información disponible.');
          }
      } catch (err) {
          dispatch(isLoading(false))
          swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getInterviewsByCandidate = (candidate, interview) => {
  return async(dispatch) => {
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/SW50ZXJ2aWV3/?candidate=${candidate}&typeinterview=${interview}`, { headers: {'Authorization': `JWT ${token}` }});
          const data = await responseCandidates.data;
          if (data.results.length !== 0) {
            dispatch(setInterviews(data));
          } else {
            dispatch(setInterviews([]));
            swal('Lo sentimos, no hay información disponible.');
          }
      } catch (err) {
          dispatch(isLoading(false))
          swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const updateInterviewAnswered = (id, data) => {
  return async(dispatch) => {
    dispatch(isLoading(true));
    try{
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      axios.put(`${urls.url_base}/SW50ZXJ2aWV3/${id}/`, data, {headers: {'Authorization': `JWT ${token}`}}).then((resp) => {
        swal({
          title: 'Entrevista actualizada correctamente!',
          type: 'success'
        });
      });
    }catch (err){
      dispatch(isLoading(false))
      swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const createInterviewAnswered = (data) => {
  return async(dispatch) => {
    dispatch(isLoading(true));
    try{
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      axios.post(`${urls.url_base}/SW50ZXJ2aWV3/`, data, {headers: {'Authorization': `JWT ${token}`}}).then((resp) => {
        swal({
          title: 'Entrevista programada correctamente!',
          type: 'success'
        });
      });
    }catch (err){
      dispatch(isLoading(false))
      swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}