import React, { Component, Fragment } from "react";
import "./ciudades.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCities } from "../../../redux/actions/citiesActions";

import Header from "../../header/Header";
import { Link } from "react-router-dom";

class CiudadesSeleccion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.getAllCities();
  };

  componentDidUpdate = () => {
    console.log("Ciudades", this.props);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
            <div className="col-12">
                <div className="col-12 containerTitleSeleccion mb-5 text-left">
                <p>
                    <strong className="titleCiudad text-left">Ciudades</strong>
                </p>
                <p className="subtitleCiudad">Selecciona una ciudad</p>
                </div>
                <div className="backgroundCiudadesSeleccion">
                <div className="row p-5 containerCiudadesSeleccion">
                    {this.props.citiesData.length !== 0
                    ? this.props.citiesData.results.map((item, index) => {
                        return (
                            <div className="col-2" key={index.toString()}>
                            <div className="itemCiudad">
                                <Link
                                to={`/seleccion/filter/cities/aliados/${item.id}`}
                                style={{textDecoration: 'none'}}
                                >
                                <h6>{item.name}</h6>
                                </Link>
                            </div>
                            </div>
                        );
                        })
                    : ""}
                </div>
                </div>







            </div>
        </div>


      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    citiesData: state.citiesReducer.citiesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCities: bindActionCreators(getAllCities, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CiudadesSeleccion);
