import React, { Component, Fragment } from "react";
import "./asesorias.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllConsultancies } from "../../redux/actions/consultanciesAction";

import Header from "../header/Header";
import { Link } from "react-router-dom";

function CandidateRow(props) {
  const consultancies = props.consultancies;
  var route =
    consultancies.consultanciesType == 0
      ? `advisory/new/employee/${consultancies.id}`
      : `advisory/new/jefe/${consultancies.id}`;
  return (
    <tr id="tr">
      <td className="titleItemRow">
        {consultancies.consultanciesType === 0
          ? consultancies.docCandidate
          : null}
      </td>
      <td className="titleItemRow">
        {consultancies.consultanciesType === 0
          ? consultancies.nameCandidate
          : consultancies.immediateBoss}
      </td>
      <td className="titleItemRow">
        {consultancies.consultanciesType === 0 ? "Empleado" : "Jefe inmediato"}
      </td>
      <td className="titleItemRow">{consultancies.theme}</td>
      <td className="titleItemRow">{consultancies.advisoryDate}</td>
      <td className="titleItemRow">
        <Link className="btnHV" to={route}>
          <div>
            <i className="far fa-eye text-info"></i>
          </div>
        </Link>
      </td>
    </tr>
  );
}

class Asoserias extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.getAllConsultancies();
  };

  componentDidUpdate = () => {
    console.log(this.props);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Consolidado de las Asesorias
                </strong>
              </p>
            </div>

            <div className="col-12 backgroundAsesorias">
              <div className="row containerHeaderAsesorias">
                <div className="col-2">
                  <button className="btn btn-info">
                    <Link
                      to={`/relacionamiento/advisory/new`}
                      style={{ textDecoration: "none" }}
                    >
                      <strong id="btnNew">Nuevo</strong>
                    </Link>
                  </button>
                </div>
              </div>

              <div className="table-responsiveAsesoria containerTable">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="titleTable">
                        Cédula
                      </th>
                      <th scope="col" className="titleTable">
                        Nombre
                      </th>
                      <th scope="col" className="titleTable">
                        Tipo
                      </th>
                      <th scope="col" className="titleTable">
                        Tema
                      </th>
                      <th scope="col" className="titleTable">
                        Fecha
                      </th>
                      <th scope="col" className="titleTable">
                        Opciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.consultanciesData2.length != 0
                      ? this.props.consultanciesData2.results.map(
                          (consultancies, index) => {
                            return (
                              <CandidateRow
                                consultancies={consultancies}
                                key={index.toString()}
                              />
                            );
                          }
                        )
                      : null}
                  </tbody>
                </table>

              </div>
                {/* PAGINATION */}

                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>

                {/* END PAGINATION */}
            </div>
          </div>




        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    consultanciesData2: state.consultanciesReducer.consultanciesData2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllConsultancies: bindActionCreators(getAllConsultancies, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Asoserias);
