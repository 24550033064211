import React, { Component, Fragment } from "react";
import "./aliados.styles.css";

import Header from "../../header/Header";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllysByCity } from "../../../redux/actions/allyActions";

class AliadosSeleccionFiltro extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var id = this.props.match.params.city;
    console.log("city id", id);
    this.props.getAllysByCity(id);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">Negocios</strong>
              </p>
              <p className="subtitleCiudad">Selecciona un negocio</p>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="col-12 backgroundCiudadesResumenSeleccion">
            {/* 
            <div className="col-4">
              <div className="card-body bg-white" style={{border: '1px solid #000', borderRadius: '5px'}}>Negocio 1</div>
            </div>

            <br/>

            <div className="col-4">
              <div className="card-body bg-white" style={{border: '1px solid #000', borderRadius: '5px'}}>Negocio 2</div>
            </div>

            <br/>

            <div className="col-4">
              <div className="card-body bg-white" style={{border: '1px solid #000', borderRadius: '5px'}}>Negocio 3</div>
            </div>

            <br/>
            */}
            



            

            <div className="row containerAgeniasFiltro">
              {this.props.allyCities.length !== 0
                ? this.props.allyCities.results.map((item, index) => {
                    return (
                      <div className="col-2" key={item.id.toString()}>
                        <div className="itemAgencias">
                          <Link
                            to={`/seleccion/resumen/${item.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            {/*<h6>{item.fullName}</h6>*/}
                            <h6> Negocio {index+1} </h6>
                          </Link>
                          {/* <Link to={`/seleccion/filter/cities/aliados/remitted/${item.id}`}>
                                                    <h6>{item.fullName}</h6>
                                                </Link> */}
                                                
                        </div>
                      </div>
                    );
                  })
                : ""}
                </div>






          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allyCities: state.allyReducer.allyCities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllysByCity: bindActionCreators(getAllysByCity, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AliadosSeleccionFiltro);
