import React, { Component, Fragment } from "react";
import { Row, Col, Input, FormGroup, Form } from 'reactstrap';
import Header from "../../../header/Header";
import { connect } from "react-redux";
import { candidatesByArr } from '../../../../redux/actions/candidateActions';
import { saveAssistanceFocus } from '../../../../redux/actions/acompanamientoActions'
import { bindActionCreators } from "redux";
import './addTalleresFoco.css';
import swal from "sweetalert";
import SelectSearch from 'react-select-search';

function TallerRow(props) {
  const candidate = props.list;
  const assistance = [{
    name: 'Asistio',
    type: 'group',
    items: [{
      value: "Asistio",
      name: 'Asistio'
    }]
  }, {
    name: 'No Asistio',
    type: 'group',
    items: [{
      value: 'Nuevo',
      name: 'Nuevo'
    }, {
      value: 'Incapacidad',
      name: 'Incapacidad'
    }, {
      value: 'Permiso',
      name: 'Permiso'
    }, {
      value: 'Vacaciones',
      name: 'Vacaciones'
    }, {
      value: 'Sin Justa Causa',
      name: 'Sin Justa Causa'
    }]
  }];

  return (
    <tr id="tr" key={candidate.id.toString()}>
      <td className="titleItemRow">{candidate.document}</td>
      <td className="titleItemRow">{candidate.fullName}</td>
      <td className="titleItemRow">
        <FormGroup row style={{ width: '90%', margin: '0 auto' }}>
          <SelectSearch
            options={assistance}
            placeholder="Tomar asistencia"
            id="assistance"
            onChange={props.handleChangeSelect}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup row style={{ width: '90%', margin: '0 auto' }}>
          <Input
            type="textarea"
            id="observations"
            onChange={props.handleChange}
          />
        </FormGroup>
      </td>
      <td className="titleItemRow" style={{ cursor: 'pointer' }}>
        <div style={{ paddingTop: 10 }}>
          <i className="fa fa-check-circle text-success" aria-hidden="true" onClick={props.saveAsistencia}></i>
        </div>
      </td>
    </tr>
  );
}

class EditTalleresFoco extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupWorkshop: "",
      assistance: "",
      observations: ""
    };
  }

  componentDidMount = () => {
    if (this.props.workshopsFocus.length !== 0) {
      let id = this.props.match.params.id;
      let candidates = this.props.workshopsFocus.results.find(item => item.id == id).candidates;
      this.props.candidatesByArr(candidates);
    }
  };

  componentDidUpdate = () => { };

  updateAsistencia = (item) => {
    console.log(item)
    if (this.state.assistance !== "" && this.state.observations !== "") {
      let obj = {
        reason: this.state.assistance,
        notes: this.state.observations,
        workshopFocus: `https://alianza.multimedialab.dev/UHJvamVjdA/Zm9jdXM/${this.props.match.params.id}/`,
        candidate: item.url
      }
      this.props.saveAssistanceFocus(obj);
    } else {
      swal('Por favor ingresa la informacion de asistencia y Observaciones')
    }
  }

  handleChangeSelect = (e) => {
    this.setState({
      assistance: e
    });
  }

  handleChange = (e) => {
    this.setState({
      observations: e.target.value
    })
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleTaller text-left">Control Asistencia Talleres Foco</strong>
            </p>
            <p className="subtitleTaller">{`Listado de candidatos que participan en el taller`}</p>
          </div>

          <div className="col-12 table-responsiveSeguimiento containerResumenSeleccion">
            <div className="table-responsiveTalleres">
              <table className="table tableResumenSeleccion">
                <thead>
                  <tr>
                    <th scope="col" className="titleTable">Cédula</th>
                    <th scope="col" className="titleTable">Nombre completo</th>
                    <th scope="col" className="titleTable">Control asistencia</th>
                    <th scope="col" className="titleTable">Observaciones</th>
                    <th scope="col" className="titleTable"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.candidatesGroup.length !== 0
                    ? this.props.candidatesGroup.results.map((item, index) => {
                      return (
                        <TallerRow
                          key={index.toString()}
                          list={item}
                          saveAsistencia={() => this.updateAsistencia(item)}
                          handleChangeSelect={this.handleChangeSelect}
                          assistance={this.state.assistance}
                          handleChange={this.handleChange}
                        />
                      );
                    })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.accompanimentReducer.workshops);
  return {
    isLoading: state.accompanimentReducer.isLoading,
    workshopsFocus: state.accompanimentReducer.workshopsFocus,
    candidatesGroup: state.candidateReducer.candidatesGroup
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    candidatesByArr: bindActionCreators(candidatesByArr, dispatch),
    saveAssistanceFocus: bindActionCreators(saveAssistanceFocus, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTalleresFoco);
