import React, { Component, Fragment } from "react";
import "./resumenSeleccion.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import NotificacionSeleccion from "./notificaciones/notificaciones";
import { countAllStatesGender } from "../../../redux/actions/selectionActions";
import { Modal, Button } from "react-bootstrap";


class ResumenGeneralAprendiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enRemitidos: 0,
      enRemitidosM: 0,
      enRemitidosF: 0,
      enCandidatos: 0,
      enCandidatosM: 0,
      enCandidatosF: 0,
      enAprobados: 0,
      enAprobadosM: 0,
      enAprobadosF: 0,
      enNoPasaronProceso: 0,
      enNoPasaronProcesoM: 0,
      enNoPasaronProcesoF: 0,
      enNoCitados: 0,
      enNoCitadosM: 0,
      enNoCitadosF: 0,
      enNoAsistieron: 0,
      enNoAsistieronM: 0,
      enNoAsistieronF: 0,
      enPendientes: 0,
      enPendientesM: 0,
      enPendientesF: 0,
    };
  }

  componentDidMount = async () => {
    await this.props.countAllStatesGender();
    this.setInitialData();
  };

  setInitialData = () => {
    try {
      var remitidos = this.props.getSummaryState.filter(
        (el) => el.estado == "Remitidos"
      )[0];
      var candidatos = this.props.getSummaryState.filter(
        (el) => el.estado == "Candidatos"
      )[0];
      var aprobados = this.props.getSummaryState.filter(
        (el) => el.estado == "Aprobados"
      )[0];
      var noPasaronProceso = this.props.getSummaryState.filter(
        (el) => el.estado == "No pasaron proceso"
      )[0];
      var noAsistieron = this.props.getSummaryState.filter(
        (el) => el.estado == "Asistieron"
      )[0];
      var pendientes = this.props.getSummaryState.filter(
        (el) => el.estado == "Pendientes"
      )[0];
      var noCitados = this.props.getSummaryState.filter(
        (el) => el.estado == "No citados"
      )[0];

      this.setState({
        enRemitidos: remitidos.total,
        enRemitidosM: remitidos.totalM,
        enRemitidosF: remitidos.totalF,
        enCandidatos: candidatos.total,
        enCandidatosM: candidatos.totalM,
        enCandidatosF: candidatos.totalF,
        enAprobados: aprobados.total,
        enAprobadosM: aprobados.totalM,
        enAprobadosF: aprobados.totalF,
        enNoPasaronProceso: noPasaronProceso.total,
        enNoPasaronProcesoM: noPasaronProceso.totalM,
        enNoPasaronProcesoF: noPasaronProceso.totalF,
        enNoCitados: noCitados.total,
        enNoCitadosM: noCitados.totalM,
        enNoCitadosF: noCitados.totalF,
        enNoAsistieron: noAsistieron.total,
        enNoAsistieronM: noAsistieron.totalM,
        enNoAsistieronF: noAsistieron.totalF,
        enPendientes: pendientes.total,
        enPendientesM: pendientes.totalM,
        enPendientesF: pendientes.totalF,
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  render() {
    const idParam = this.props.match.params.ally;

    return (
      <Fragment>
        <Header />
        <div className="container-fluid bgResumenGeneralAprendiz">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Resumen Modulo de Selección
              </strong>
            </p>
          </div>

          <div className="col-12">
            <NotificacionSeleccion />
          </div>
          <div className="row">
            <div className="col-3">
     

              <label>
                <strong>Año</strong>
              </label>
              <select className="form-control" name="" id="" multiple>
                <option value="">2020</option>
                <option value="">2019</option>
                <option value="">2018</option>
                <option value="">2017</option>
                <option value="">2016</option>
                <option value="">2015</option>
                <option value="">2014</option>
                <option value="">2013</option>
                <option value="">2013</option>
                <option value="">2012</option>
                <option value="">2011</option>
                <option value="">2010</option>
                <option value="">2009</option>
                <option value="">2008</option>
                <option value="">2007</option>
                <option value="">2006</option>
                <option value="">2005</option>
                <option value="">2004</option>
                <option value="">2003</option>
                <option value="">2002</option>
                <option value="">2001</option>
                <option value="">2000</option>
              </select>
            </div>
            <div className="col-3">
              <label>
                <strong>Mes</strong>
              </label>
              <select className="form-control" name="" id="">
                <option value="">Enero</option>
                <option value="">Febrero</option>
                <option value="">Marzo</option>
                <option value="">Abril</option>
                <option value="">Mayo</option>
                <option value="">Junio</option>
                <option value="">Julio</option>
                <option value="">Agosto</option>
                <option value="">Septiembre</option>
                <option value="">Octubre</option>
                <option value="">Noviembre</option>
                <option value="">Diciembre</option>
              </select>
            </div>

            <div className="col-3 text-right">
              <Link
                to={`/aprendiz/aprendiz/filter/summary/cities`}
                style={{ textDecoration: "none" }}
              >
                <Button variant="info">
                  <strong>Ver Detalle</strong>
                </Button>
              </Link>
            </div>

          </div>
          <div className="col-12 table-responsiveAprendiz containerResumenGeneralAprendiz">
            <table className="table tableResumenGeneralAprendiz">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Estados
                  </th>
                  <th scope="col" className="titleTable">
                    Genero (M / F)
                  </th>
                  <th scope="col" className="titleTable">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "white" }}>
                  <td>
                    <p className="titleItemRow">Remitidos</p>
                  </td>
                  <td>
                    <p className="titleItemRow">
                      {this.state.enRemitidosM} / {this.state.enRemitidosF}
                    </p>
                  </td>
                  <td>
                    <p className="titleItemRow">{this.state.enRemitidos}</p>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "white" }}>
                  <td>
                    <p className="titleItemRow">Candidatos</p>
                  </td>
                  <td>
                    <p className="titleItemRow">
                      {this.state.enCandidatosM} / {this.state.enCandidatosF}
                    </p>
                  </td>
                  <td>
                    <p className="titleItemRow">{this.state.enCandidatos}</p>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "white" }}>
                  <td>
                    <p className="titleItemRow">Aprobados</p>
                  </td>
                  <td>
                    <p className="titleItemRow">
                      {this.state.enAprobadosM} / {this.state.enAprobadosF}
                    </p>
                  </td>
                  <td>
                    <p className="titleItemRow">{this.state.enAprobados}</p>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "white" }}>
                  <td>
                    <p className="titleItemRow">No pasaron proceso</p>
                  </td>
                  <td>
                    <p className="titleItemRow">
                      {this.state.enNoPasaronProcesoM} /{" "}
                      {this.state.enNoPasaronProcesoF}
                    </p>
                  </td>
                  <td>
                    <p className="titleItemRow">
                      {this.state.enNoPasaronProceso}
                    </p>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "white" }}>
                  <td>
                    <p className="titleItemRow">No citados</p>
                  </td>
                  <td>
                    <p className="titleItemRow">
                      {this.state.enNoCitadosM} / {this.state.enNoCitadosF}
                    </p>
                  </td>
                  <td>
                    <p className="titleItemRow">{this.state.enNoCitados}</p>
                  </td>
                </tr>

                <tr style={{ backgroundColor: "white" }}>
                  <td>
                    <p className="titleItemRow">No Asistio</p>
                  </td>
                  <td>
                    <p className="titleItemRow">
                      {this.state.enNoAsistieronM} /{" "}
                      {this.state.enNoAsistieronF}
                    </p>
                  </td>
                  <td>
                    <p className="titleItemRow">{this.state.enNoAsistieron}</p>
                  </td>
                </tr>

                <tr style={{ backgroundColor: "white" }}>
                  <td>
                    <p className="titleItemRow">Pendientes</p>
                  </td>
                  <td>
                    <p className="titleItemRow">
                      {this.state.enPendientesM} / {this.state.enPendientesF}
                    </p>
                  </td>
                  <td>
                    <p className="titleItemRow">{this.state.enPendientes}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.selectionReducer.isLoading,
    getSummaryState: state.selectionReducer.getSummaryState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    countAllStatesGender: bindActionCreators(countAllStatesGender, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumenGeneralAprendiz);
