import {
  ISLOADING,
  GET_CANDIDATES_BY_GROUP,
  SET_VISIBLE_TABS,
  GET_CANDIDATES,
  GET_CANDIDATE,
  ADD_CANDIDATE,
  GET_ALL_PROGRAMS,
  GET_ALL_AGENCIES,
  GET_ALL_CANDIDATES_BY_ALLY,
  GET_CANDIDATES_REENTRY,
  UPDATE_STATUS_CANDIDATES,
  GET_CANDIDATES_BY_BUSINESS,
  SEARCH_CANDIDATE,
  PAGE_COUNT,
} from "../constants/action-type";
//Traceability Actions
import { addTraceability } from "./traceabilityActions";
import urls from "../../apiConf";
import axios from "axios";
import swal from "sweetalert";

export const setTabs = (tabs) => ({
  type: SET_VISIBLE_TABS,
  visibleTabs: tabs,
});

export const isLoading = (bool) => ({
  type: ISLOADING,
  isLoading: bool,
});

export const setCandidates = (candidates) => ({
  type: GET_CANDIDATES,
  candidates,
});

export const setCandidate = (candidate) => ({
  type: GET_CANDIDATE,
  candidate,
});

export const addCandidate = (candidate) => ({
  type: ADD_CANDIDATE,
  candidate,
});

export const setPrograms = (programs) => ({
  type: GET_ALL_PROGRAMS,
  programs,
});

export const setAgencies = (agencies) => ({
  type: GET_ALL_AGENCIES,
  agencies,
});

export const setAllyCandidates = (candidatesAlly) => ({
  type: GET_ALL_CANDIDATES_BY_ALLY,
  candidatesAlly,
});

export const setCandidateReentry = (requestReentry) => ({
  type: GET_CANDIDATES_REENTRY,
  requestReentry,
});

export const setCandidateStatus = (candidatesStatus) => ({
  type: UPDATE_STATUS_CANDIDATES,
  candidatesStatus,
});

export const setCandidateBus = (candidatesBusiness) => ({
  type: GET_CANDIDATES_BY_BUSINESS,
  candidatesBusiness,
});

export const setCandidateGroup = (candidatesGroup) => ({
  type: GET_CANDIDATES_BY_GROUP,
  candidatesGroup,
});

export const setDataSearch = (searchCandidate) => ({
  type: SEARCH_CANDIDATE,
  searchCandidate,
});

export const setCount = (pageCount) => ({
  type: PAGE_COUNT,
  pageCount,
});

export const existCandidateDoc = (doc) => {
  return async (dispatch) => {
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?doc=${doc}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.results.length !== 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      dispatch(isLoading(false));
      swal(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const searchCandidateDoc = (doc) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?search=${doc}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.results.length !== 0) {
        dispatch(setDataSearch(data));
        return data.results;
      } else {
        dispatch(setDataSearch([]));
        swal("Lo sentimos, no hay información disponible.");
        return [];
      }
    } catch (err) {
      dispatch(isLoading(false));
      swal(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const candidatesByArr = (arr) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?arr=[${arr}]`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.results.length !== 0) {
        dispatch(setCandidateGroup(data));
      } else {
        dispatch(setCandidateGroup([]));
        swal("Lo sentimos, no hay información disponible.");
      }
    } catch (err) {
      dispatch(isLoading(false));
      swal(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const candidatesByGroup = (group) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?group=${group}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.results.length !== 0) {
        dispatch(setCandidateGroup(data));
      } else {
        dispatch(setCandidateGroup([]));
        swal("Lo sentimos, no hay información disponible.");
      }
    } catch (err) {
      dispatch(isLoading(false));
      swal(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getCandidatesByBusiness = (complement) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/${complement}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setCandidateBus(data));
        dispatch(setCount(count));
      } else {
        dispatch(setCandidateBus([]));
        swal("Lo sentimos, no hay información disponible.");
      }
    } catch (err) {
      dispatch(isLoading(false));
      swal(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getAllCandidatesBD = (e) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?page=${e}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setCandidateBus(data));
        dispatch(setCount(count));
      } else {
        dispatch(setCandidateBus([]));
        swal("Lo sentimos, no hay información disponible.");
      }
    } catch (err) {
      dispatch(isLoading(false));
      swal(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getCandidateByID = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/${id}/`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(setCandidate(data));
      } else {
        dispatch(setCandidate([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getAllCandidates = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(setCandidates(data));
        return data.results;
      } else {
        dispatch(setCandidates([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const add_Candidate = (dataObj, trace) => {
  return async (dispatch) => {
    var count = 0;
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.post(
        `${urls.url_base}/Q2FuZGlkYXRvcw/`,
        dataObj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseAllyData.data;
      console.log("data candidate", data);
      trace.candidate = data.url;
      dispatch(addTraceability(trace));
      if (data.length !== 0) {
        dataObj.positionsArr.forEach((item) => {
          var posData = {
            idVacancy: item.positionName,
            companyApply: item.companyApply,
            idCandidate: data.url,
            proccessState: 1,
            proccessSubstate: 1,
          };
          axios
            .post(`${urls.url_base}/Q2FyZ29z/`, posData, {
              headers: { Authorization: `JWT ${token}` },
            })
            .then(() => {
              count += 1;
              if (dataObj.positionsArr.length === count) {
                swal("El candidato se guardo correctamente!");
                dispatch(addCandidate([]));
              }
            });
        });
      } else {
        dispatch(addCandidate([]));
        swal("Upss! correo y / o contraseña incorrectos!");
      }
    } catch (err) {
      if (err.response.data.document) {
        swal(
          "Oops!",
          `Ya se encuentra registrado un usuario con este documento.`,
          "warning"
        );
      } else {
        swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err}`);
      }
    }
  };
};

export const update_Candidate = (idCan, dataObj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.put(
        `${urls.url_base}/Q2FuZGlkYXRvcw/${idCan}/`,
        dataObj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseAllyData.data;
      if (data.length !== 0) {
        console.log("se actualizo grupo");
      }
    } catch (err) {
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const updateStateCandidate = (id, putObj, msg) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.put(
        `${urls.url_base}/Q2FuZGlkYXRvcw/${id}/`,
        putObj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        alert(msg);
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const get_all_programs = () => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/QWxpYWRvcw/?type=1`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(setPrograms(data));
      } else {
        dispatch(setPrograms([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const get_all_agencies = () => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/QWxpYWRvcw/?type=0`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(setAgencies(data));
      } else {
        dispatch(setAgencies([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getCandidatesByAlly = (id) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?ally=${id}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.results.length !== 0) {
        dispatch(setAllyCandidates(data));
      } else {
        dispatch(setAllyCandidates([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getCandidatesByAllyRel = (
  id,
  state,
  stateTwo,
  page = 1,
  tutor = "False"
) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?ally=${id}&state=${state},${stateTwo}&tutor=${tutor}&page=${page}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setAllyCandidates(data));
        dispatch(setCount(count));
      } else {
        dispatch(setAllyCandidates([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getAllRequestsReentry = (page = 1) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?state=1&reentry=True&page=${page}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setCandidateReentry(data));
        dispatch(setCount(count));
      } else {
        dispatch(setCandidateReentry([]));
        swal("No hay información de reingresos disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const addInfoContract = (obj) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.post(
        `${urls.url_base}/aGlyaW5n/`,
        obj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        swal("La información de contratación se actualizó correctamente.");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const updateInfoContract = (id, obj) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.put(
        `${urls.url_base}/aGlyaW5n/${id}/`,
        obj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        swal("La información de contratación se actualizó correctamente.");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const setAllCandidatesStatus = (obj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.put(
        `${urls.url_base}/Q2FuZGlkYXRvcw/${obj.idCandidate}/`,
        obj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(setCandidateStatus(data));
        swal("Se ha modificado el estado del candidato");
      } else {
        dispatch(setCandidateStatus([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const updatePositionsSubstates = (obj, show, traceObj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (obj.proccessSubstate > 0 && obj.proccessSubstate <= 8) {
        obj.proccessState = 1;
      } else if (obj.proccessSubstate > 8 && obj.proccessSubstate <= 13) {
        obj.proccessState = 2;
      } else if (obj.proccessSubstate > 14) {
        obj.proccessState = 3;
      }
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.put(
        `${urls.url_base}/Q2FyZ29z/${obj.position}/`,
        obj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      dispatch(addTraceability(traceObj));
      if (data.length !== 0) {
        dispatch(setCandidateStatus(data));
        if (show) {
          swal("El estado del candidado fue modificado correctamente!");
        }
      } else {
        dispatch(setCandidateStatus([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      swal(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const deleteCandidate = (idCandidate) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.delete(
        `${urls.url_base}/Q2FuZGlkYXRvcw/${idCandidate}/`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      swal("El candidato fue eliminado correctamente");
    } catch (err) {
      dispatch(isLoading(false));
      swal(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};
