import React, { Component, Fragment } from 'react';
import './graficas.styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import {
    ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  } from 'recharts';

  
class GraficosSeleccion extends Component {
    constructor(props) {
        super(props);
        this.state={
          data: []
        };
        fetch(`https://alianza.multimedialab.dev/UHJvamVjdA/stats1/?client=${this.props.idBussines}`)
        .then(response => response.json())
        .then((jsonData) => {
          // jsonData is parsed json object received from url
          console.log(jsonData);
          this.setState({
            data: jsonData
          })
          
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })

    }

    render() {

      
       

        return (
            <ResponsiveContainer width='100%' aspect={4.0/3.0}>
          <BarChart
            
            height={300}
            data={this.state.data}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="hombres" fill="#204d6a" />
            <Bar dataKey="mujeres" fill="#82ca9d" />
          </BarChart>
          </ResponsiveContainer>
        );
      }
}

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GraficosSeleccion);