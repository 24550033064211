import React, { Component } from "react";
import "./login.styles.css";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Carousel from "react-elastic-carousel";
import {
 loginAlly,
 updateData,
 loginManage,
 loginCustomer,
 setModule,
} from "../../redux/actions/allyActions";
import { setTabs } from "../../redux/actions/candidateActions";
import { setInfoClient } from "../../redux/actions/clientsActions";

import swal from "sweetalert";

class Login extends Component {
 constructor(props) {
  super(props);
  this.state = {
   username: "",
   password: "",
   type: "seleccion",
   submit: false,
   login_path: "/",
   reset: 0,
   errors: {
    username: "",
   },
  };
 }

 componentDidMount = () => {};

 handleChange = (e) => {
  const newState = this.state;
  newState[e.target.id] = e.target.value;
  this.setState(newState);
 };

 onLogin = async () => {
  if (this.state.username !== "" && this.state.password !== "") {
   clearTimeout(this.clearTime);
   var obj = {
    email: this.state.username,
    password: this.state.password,
   };
   this.props.loginAlly(obj);
  } else {
   swal("Por favor ingresa todos los campos.");
  }
 };

 componentDidUpdate = (prevProps, prevState) => {
  if (this.props.allyData.length !== prevProps.allyData.length) {
   console.log(this.props.allyData);
   switch (this.props.allyData[0].module) {
    case 1: {
     // this.props.loginAlly(obj);
     sessionStorage.setItem("position", "Aliado");
     this.props.history.push("aliados/aliados");
     this.props.setModule("aliados");
     break;
    }
    case 2: {
     // this.props.loginManage(obj);
     sessionStorage.setItem("position", "Gestor");
     this.props.setTabs([
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
     ]);
     this.props.history.push("relacionamiento/graphicsEmprend");
     this.props.setModule("relacionamiento");
     break;
    }
    case 3: {
     sessionStorage.setItem("position", "Cliente");
     this.props.history.push("clientes/vacantes");
     this.props.setModule("clientes");
     break;
    }
    case 4: {
     // this.props.loginManage(obj);
     sessionStorage.setItem("position", "Seleccion");
     this.props.setTabs([
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
     ]);
     this.props.history.push("seleccion/seleccion");
     this.props.setModule("seleccion");
     break;
    }
    case 5: {
     // this.props.loginManage(obj);
     sessionStorage.setItem("position", "Aprendiz");
     this.props.setTabs([
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
     ]);
     this.props.history.push("aprendiz/aprendiz");
     this.props.setModule("aprendiz");
     break;
    }
    case 6: {
     // this.props.loginManage(obj);
     sessionStorage.setItem("position", "Practicante");
     this.props.setTabs([
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
     ]);
     this.props.history.push("practicante/entrevista");
     this.props.setModule("practicante");
     break;
    }
    case 7: {
     // this.props.loginManage(obj);
     sessionStorage.setItem("position", "Acompanamiento");
     this.props.setInfoClient({ id: 12, name: "Sura" });
     this.props.setTabs([
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
     ]);
     this.props.history.push("acompanamiento/grafica");
     this.props.setModule("acompanamiento");
     break;
    }
   }
  }
 };

 isFinished = (item, page) => {
  if (item.index == 3) {
   this.clearTime = setTimeout(() => {
    try {
     this.carousel.goTo(Number(0));
    } catch {}
   }, 3000);
  }
 };

 render() {
  return (
   <div className="backgroungLogin row">
    <div className="backloginside col-6">
     <div style={{ textAlign: "center" }}>
      <img
src={require("../../assets/imgs/logo.png")}
       width="50%"
      />
     </div>

     {/* <h1 className="textLogo">Alianza Soluciones</h1>
					<h3 style={{ position: 'relative', zIndex: 1 }}>Plataforma de gestión de procesos</h3> */}
     <div
      style={{
       width: "100%",
       margin: "50px auto 0",
       position: "relative",
       zIndex: 1,
      }}>
      <Carousel
       ref={(ref) => (this.carousel = ref)}
       itemsToShow={3}
       initialFirstItem={this.state.reset}
       itemPadding={[5, 10]}
       enableAutoPlay={true}
       autoPlaySpeed={3000}
       showArrows={false}
       onNextEnd={(item, page) => this.isFinished(item, page)}>
 
       <img
        src={require("../../assets/imgs/nutresa.png")}
        width="100%"
        className="imgClients"
       />
       <img
        src={require("../../assets/imgs/sura.png")}
        width="100%"
        className="imgClients"
       />
       <img
        src={require("../../assets/imgs/sodexo.png")}
        width="100%"
        className="imgClients"
       />
       <img
        src={require("../../assets/imgs/bancolombia.png")}
        width="100%"
        style={{ marginTop: 30 }}
        className="imgClients"
       />
       <img
        src={require("../../assets/imgs/comfama.png")}
        width="100%"
        className="imgClients"
       />

      </Carousel>
     </div>
     <div className="photoback"></div>
    </div>
    <div className="col-6 backform">
     <div className="containerLogin">
      <div>
       <h2 className="titleSignIn text-center">Ingresar</h2>
       <p className="titleSignIn">Ingresa en tu cuenta</p>
      </div>
      <form>
       <div className="form-group col">
        <label
         htmlFor=""
         className="titleLabelLogin">
         Usuario
        </label>
        <div className="">
         <input
          id="username"
          type="text"
          className="form-control"
          placeholder="Ingresa tu usuario"
          autoComplete=""
          onChange={this.handleChange}
         />
        </div>
       </div>
       <div className="form-group col">
        <label
         htmlFor=""
         className="titleLabelLogin">
         Contraseña
        </label>
        <div className="">
         <input
          id="password"
          type="password"
          className="form-control"
          placeholder="Ingresa tu contraseña"
          autoComplete=""
          onChange={this.handleChange}
         />
        </div>
       </div>
      </form>
      <div className="containerBtn">
       <button
        className="btn btnIngresar"
        onClick={this.onLogin}>
        Ingresar
       </button>
      </div>
     </div>
    </div>
   </div>
  );
 }
}

const mapStateToProps = (state) => {
 return {
  allyData: state.allyReducer.allyData,
  isLoading: state.allyReducer.isLoading,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  loginAlly: bindActionCreators(loginAlly, dispatch),
  updateData: bindActionCreators(updateData, dispatch),
  loginManage: bindActionCreators(loginManage, dispatch),
  loginCustomer: bindActionCreators(loginCustomer, dispatch),
  setModule: bindActionCreators(setModule, dispatch),
  setTabs: bindActionCreators(setTabs, dispatch),
  setInfoClient: bindActionCreators(setInfoClient, dispatch),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
