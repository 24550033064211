import React, { Component } from "react";
import { FormGroup, Input, Col, Row, Spinner, Button } from 'reactstrap';
import Header from "../../../header/Header";
import { connect } from "react-redux";
import { addWorkshopFocus } from '../../../../redux/actions/acompanamientoActions';
import { getAllCandidates, searchCandidateDoc } from "../../../../redux/actions/candidateActions";
import { bindActionCreators } from "redux";
import { uploadSingleImage, uploadMultipleDocs } from '../../../../services/uploadFile';
import './addTalleresFoco.css';
import swal from "sweetalert";

class AddTalleresFoco extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePath: [],
      loading: false,
      selectedCandidates: [],
      colaboradores: [],
      searchInput: ""
    }
  }

  componentDidMount = () => {
    this.props.getAllCandidates().then(results => {
      if (results.length !== 0) {
        let optionsSelect = results.map((item) => {
          return {
            name: item.fullName,
            value: item.id
          }
        });
        this.setState({ colaboradores: optionsSelect });
      }
    });
  }

  handleChangeFile = e => {
    console.log(e.target.files)
    if (e.target.files.length !== 0) {
      const imageFile = e.target.files;
      this.setState({
        imagePath: imageFile
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true
    })

    var count = 0;
    var obj = {
      date: event.target.fecha.value,
      hour: event.target.hora.value,
      responsable: event.target.responsable.value,
      place: event.target.lugar.value,
      thematic: event.target.tematica.value
    }

    console.log(this.state.selectedCandidates)

    if (this.state.imagePath.length !== 0) {
      uploadMultipleDocs(this.state.imagePath).then((data) => {
        obj.medology = JSON.stringify(data);
        this.props.addWorkshopFocus({
          date: obj.date,
          hour: obj.hour,
          responsable: obj.responsable,
          place: obj.place,
          thematic: obj.thematic,
          candidates: this.state.selectedCandidates,
          medology: obj.medology
        }).then(resp => {
          if (resp) {
            this.props.history.goBack();
            this.setState({
              loading: false
            });
          }
        });
      });
    } else {
      this.setState({
        loading: false
      })
      swal('Por favor adjunta al menos una metodología');
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  handleChangeSelect = (e) => {
    const selected = [];
    let selectedOption = (e.target.selectedOptions);
    for (let i = 0; i < selectedOption.length; i++) {
      selected.push(selectedOption.item(i).value)
    }

    this.setState({ selectedCandidates: selected });
  }

  searchCandidate = () => {
    if (this.state.searchInput.length !== 0) {
      this.props.searchCandidateDoc(this.state.searchInput).then(resp => {
        if (resp.length !== 0) {
          let optionsSelect = resp.map((item) => {
            return {
              name: item.fullName,
              value: item.id
            }
          });
          this.setState({ colaboradores: optionsSelect });
        }
      });
    } else {
      swal('Oops!', 'Por favor ingresa un nombre o documento para buscar.', 'warning')
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="col-12  mb-5 text-left">
          <strong className="titleTaller text-center">
            Nuevo Taller Temática Foco
          </strong>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 bgResumenSeleccion">
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 containerTitleSeleccion mb-5">
            <br />

            <div className="row">
              <div className="col">
                <div className="card cardNewTaller col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card-body">

                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group row">
                            <label>Personas para el taller</label>
                            <div className="col-sm-6">
                              <Input
                                className="form-control"
                                id="searchInput"
                                value={this.state.searchInput}
                                onChange={(e) => this.setState({
                                  searchInput: e.target.value
                                })}
                                placeholder="Buscar por documento o nombre"
                              />
                            </div>
                            <div className="col-sm-2" style={{ textAlign: 'right' }}>
                              <Button
                                color="info"
                                type="button"
                                onClick={() => { this.searchCandidate() }}
                              >
                                Buscar
                              </Button>
                            </div>
                            <Input
                              style={{ marginTop: 20 }}
                              type="select"
                              className="form-control"
                              name="groupSelect"
                              multiple
                              onChange={this.handleChangeSelect}
                            >
                              {this.state.colaboradores.length !== 0 && this.state.colaboradores.map((item, index) => (
                                <option key={index.toString()} value={item.value}>{item.name}</option>
                              ))}
                            </Input>
                          </div>

                          <FormGroup>
                            <label>Temática</label>
                            <Input
                              type="text"
                              name="tematica"
                              required
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Fecha</label>
                            <Input
                              min={this.formatDate(new Date())}
                              type="date"
                              name="fecha"
                              required
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Hora</label>
                            <Input
                              type="time"
                              name="hora"
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Responsable</label>
                            <Input
                              type="select"
                              name="responsable"
                            >
                              <option>Alianza</option>
                              <option>Psicóloga Coach</option>
                              <option>ARN</option>
                              <option>Victimas</option>
                              <option>Otros</option>
                            </Input>
                          </FormGroup>

                          <FormGroup>
                            <label>Metodología</label>
                            <Input
                              type="file"
                              name="metodologia"
                              accept="image/png,.doc,.docx,.pptx,.pdf,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={this.handleChangeFile}
                              multiple
                            />
                            <span style={{ fontSize: 12, fontWeight: '200', color: '#204d6a' }}>Por favor cargar la metodología en formato PDF</span>
                          </FormGroup>

                          <FormGroup>
                            <label>Lugar</label>
                            <Input type="text" name="lugar" />
                          </FormGroup>
                        </div>
                      </div>

                      {!this.state.loading && <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mr-5">
                          <button type="submit" className="btn btn-info ">
                            <strong>Guardar</strong>
                          </button>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 ml-12">
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => this.props.history.goBack()}
                          >
                            <strong>Cancelar</strong>
                          </button>
                        </div>
                      </div>}

                      <br />

                      {this.state.loading && <div className="row" style={{ width: '100%', justifyContent: 'center', flexDirection: 'column' }}>
                        <Spinner type="grow" color="info" size="md" />
                        <span>Cargando...</span>
                      </div>}

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    addWorkshop: state.accompanimentReducer.workshop,
    searchCandidate: state.candidateReducer.searchCandidate,
    candidates: state.candidateReducer.candidates,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addWorkshopFocus: bindActionCreators(addWorkshopFocus, dispatch),
    getAllCandidates: bindActionCreators(getAllCandidates, dispatch),
    searchCandidateDoc: bindActionCreators(searchCandidateDoc, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTalleresFoco);
