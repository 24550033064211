import React, { Component, Fragment } from "react";
import "./colaborador.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add_consultancies } from "../../../redux/actions/consultanciesAction";
import { getAllCandidates } from "../../../redux/actions/candidateActions";
import { Redirect } from "react-router-dom";

import Header from "../../header/Header";

class Colaborador extends Component {
  constructor(props) {
    super(props);
    this.state = this.setInitialState();
  }

  componentDidMount = () => {
    this.props.getAllCandidates();
  };

  setInitialState = () => {
    var id = this.props.match.params.advi;
    if (id !== undefined) {
      var findAdvisory = this.props.consultanciesData2.results.filter(
        (el) => el.id == id
      )[0];
      console.log("findAdvisory", findAdvisory);
      return {
        consultanciesType: findAdvisory.consultanciesType,
        mediaType: findAdvisory.mediaType,
        theme: findAdvisory.theme,
        advisoryDate: findAdvisory.advisoryDate,
        duration: findAdvisory.duration,
        summary: findAdvisory.summary,
        immediateBoss: findAdvisory.immediateBoss,
        city: findAdvisory.city,
        deal: findAdvisory.deal,
        idCandidate: findAdvisory.idCandidate,
        idProgram: findAdvisory.idProgram,
        isEdit: true,
      };
    } else {
      return {
        consultanciesType: 0,
        mediaType: "",
        theme: "",
        advisoryDate: "",
        duration: "",
        summary: "",
        immediateBoss: null,
        city: null,
        deal: null,
        idCandidate: "",
        idProgram: null,
        isEdit: false,
      };
    }
  };

  handleChange = (e) => {
    const newState = this.state;
    newState[e.target.id] = e.target.value;
    this.setState(newState);
  };

  onSaveConsultancies = () => {
    if (this.state.isEdit == false) {
      var obj = {
        mediaType: this.state.mediaType,
        theme: this.state.theme,
        advisoryDate: this.state.advisoryDate,
        duration: this.state.duration,
        summary: this.state.summary,
        immediateBoss: this.state.immediateBoss,
        city: this.state.city,
        deal: this.state.deal,
        idCandidate: this.state.idCandidate,
        idProgram: this.state.idProgram,
      };
      console.log(obj);
      alert('saved')
      this.props.add_consultancies(obj);
    } else {
      console.log("Actualizacion info");
    }
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Nueva asesoria para el empleado
                </strong>
              </p>
            </div>
          </div>
        </div>

        <div className="bachgroundAddCalendar">
          {this.props.isSave === true ? (
            <Redirect to="/relacionamiento/advisory" />
          ) : (
            ""
          )}
          

          <div className="containerFormularioAsesoriaJefe">
            <form className="formularioAddAsesoria" style={{height: '50vh'}}>
              <div className="form-group row">
                <label className="col-sm-3"><p className="labelForm">Colaborador</p> </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    id="idCandidate"
                    onChange={this.handleChange}
                    value={this.state.idCandidate}
                  >
                    <option value="">Seleccione el colaborador</option>
                    {this.props.candidates.length != 0
                      ? this.props.candidates.results.map((item, index) => {
                          return (
                            <option key={index.toString()} value={item.url}>
                              {item.fullName}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  <p className="labelForm">Medio de Comunicacion</p> 
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    id="mediaType"
                    value={this.state.mediaType}
                    onChange={this.handleChange}
                  >
                    <option value="" defaultValue="" disabled>
                      Selecciona el medio de comunicacion
                    </option>
                    <option value="0">Whatsapp</option>
                    <option value="1">Video llamada</option>
                    <option value="2">Personal</option>
                    <option value="4">Teléfonico</option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"><p className="labelForm">Tema</p></label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    id="theme"
                    value={this.state.theme}
                    onChange={this.handleChange}
                  >
                    <option value="" disabled defaultValue="">
                      Selecciona el tema
                    </option>
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"><p className="labelForm">Fecha</p></label>
                <div className="col-sm-9">
                  <input
                    type="date"
                    className="form-control"
                    id="advisoryDate"
                    value={this.state.advisoryDate}
                    onChange={this.handleChange}
                  ></input>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"><p className="labelForm">Duracion</p> </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="duration"
                    value={this.state.duration}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label"><p className="labelForm">Resumen</p> </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="summary"
                    value={this.state.summary}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="containerBtnEnviarAsesorias">
            <button
              className="btnEnviarRegistro btn-info"
              onClick={() => this.onSaveConsultancies()}
            >
              {this.state.isEdit === false ? "Enviar" : "Actualizar"}
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.consultanciesReducer.isLoading,
    consultanciesData: state.consultanciesReducer.consultanciesData,
    candidates: state.candidateReducer.candidates,
    isSave: state.consultanciesReducer.isSave,
    consultanciesData2: state.consultanciesReducer.consultanciesData2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    add_consultancies: bindActionCreators(add_consultancies, dispatch),
    getAllCandidates: bindActionCreators(getAllCandidates, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Colaborador);
