import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { updatePositionsSubstates } from '../../../redux/actions/candidateActions';
import { bindActionCreators } from "redux";
import urls from '../../../apiConf';


class ModalDeleteMessage extends Component {
	constructor(props) {
        super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
			show: false,
        };
    }
    
	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        var obj = {
            position: event.target.position.value,
            proccessState: 3,
            proccessSubstate: 14,
            idCandidate: event.target.candidato.value,
            reentry: event.target.causa.value,
            reentryObs: event.target.causReal.value
        }
        var traceObj = {
            action: `Cambio estado a Desvinculados por ${this.validateIdentity().name}`,
            candidate: event.target.candidato.value,
            manager: this.validateIdentity().url
        }
        this.props.updatePositionsSubstates(obj, true, traceObj);
        this.setState({ show: false });
    }

    validateIdentity = () => {
        if(this.props.allyData.length !== 0){
          let obj = {};
          let data = this.props.allyData[0];
          if(data.businessData){
            obj = {
              name: data.businessData.name,
              url: data.businessData.url
            }
          }else if(data.advisorData){
            obj = {
              name: data.advisorData.fullName,
              url: data.advisorData.url
            }
          }else if(data.managerData) {
            obj = {
              name: data.managerData.fullName,
              url: data.managerData.url
            }
          }
          return obj;
        }
    }

	render() {

        const idCan = this.props.idCan;
        const idStatus = this.props.idStatus;
        const idSubStatus = this.props.subStatus;
        
		return (
			<>
                <i className="fas fa-minus-circle text-danger" onClick={this.handleShow}></i>


				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Motivo de la desvinculación</Modal.Title>
					</Modal.Header>
                    <form onSubmit={this.handleSubmit}>
                        <Modal.Body className="text-center">                                
                            <input type="hidden" name="candidato" value={idCan}/>
                            <input type="hidden" name="estado" value={idStatus} />
                            <input type="hidden" name="SubEstado" value={idSubStatus} />
                            <input type="hidden" name="position" value={this.props.position} />
                            
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <select name="causa" className="form-control">
                                        <option value="Despido con justa causa">Despido con justa causa</option>
                                        <option value="Muerte">Muerte</option>
                                        <option value="Renuncia voluntaria">Renuncia voluntaria</option>
                                        <option value="Terminación del contrato">Terminación del contrato</option>
                                    </select>
                                </div>
                                <div className="col-12 mb-2">
                                    <textarea className="form-control" name="causReal" placeholder="Descripción motivo ..."></textarea>
                                </div>
                            </div>
                            <Button variant="danger" className="mr-3" onClick={this.handleClose}>
                                Cerrar
                            </Button>
                            <Button type="submit" variant="danger">
                                Eliminar
                            </Button>
                        </Modal.Body>
                    </form>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
    return {
        isLoading: state.selectionReducer.isLoading,
        setCandidateStatus: state.selectionReducer.candidatesStatus,
        allyData: state.allyReducer.allyData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updatePositionsSubstates: bindActionCreators(updatePositionsSubstates, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteMessage);