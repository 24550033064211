import React, { useState, useEffect } from 'react';
import { UncontrolledAlert, Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';

function CustomAlert(props) {
  const { color, msg, data, history, modulePath } = props;
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    if(data.length !== 0){
      setOpenModal(!openModal);
    }
  }

  const viewCandidate = (id) => {
    toggleModal();
    history.push(`/${modulePath}/viewCandidate/${id}`);
  }

  const newsCandidates = () => {
    return (
      <Modal isOpen={openModal} toggle={() => toggleModal()} size={'lg'}>
        <ModalHeader toggle={() => toggleModal()}>Usuarios</ModalHeader>
        <ModalBody>
          {data.length !== 0 && <div>
            <Table striped responsive>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Documento</th>
                  <th>Nombre completo</th>
                  <th>Negocio</th>
                  <th>Programa / Agencia</th>
                  <th>Tipo población</th>
                  <th>Estado</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr key={item.id.toString()}>
                      <th scope="row">{item.id}</th>
                      <th scope="row">{item.document}</th>
                      <td>{item.fullName}</td>
                      <td>{item.positions[0].nameBussiness}</td>
                      <td>{item.allyName}</td>
                      <td>{item.arnPoblation}</td>
                      <td>{item.positions[0].psubstateName}</td>
                      <td onClick={() => { viewCandidate(item.id) }} style={{ cursor: 'pointer' }}>
                        <i className="fa fa-eye" style={{ color: '#17a2b8' }} aria-hidden="true"></i>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggleModal()}>Cerrar</Button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <>
      {newsCandidates()}
      <UncontrolledAlert style={{ cursor: 'pointer' }} color={color} onClick={() => toggleModal()}>
        {msg}
      </UncontrolledAlert>
    </>
  );
}

export default CustomAlert;