import React, { Component, Fragment } from 'react';
import './aprobados.styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Header from '../../header/Header';
import { getAllSelectionByState } from '../../../redux/actions/selectionActions';

class AprobadosAprendiz extends Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    componentDidMount = () => {
        var obj = {
            all_id: this.props.match.params.ally,
            idSubState: this.props.match.params.substatus
        }
        this.props.getAllSelectionByState(obj);
    }

    componentDidUpdate = () => {
        console.log('Candidatos', this.props);
    }

    render() {
        return(
            <Fragment>
            <Header />
            <div className="col-12 bgResumenSeleccion">


            <div className="col-12 containerTitleSeleccion mb-5 text-left">
                    <p>
                        <strong className="titleSeguimiento text-left">Aprobados</strong>
                    </p>
                    <p className="subtitleSeguimiento">Listado de candidatos aprobados</p>
                </div>


                
                <div className="table-responsiveAprobados containerResumenSeleccion">
                    <table className="table tableResumenSeleccion">
                        <thead>
                            <tr>
                                <th scope="col" className="titleTable">Cédula</th>
                                <th scope="col" className="titleTable">Nombre</th>
                                <th scope="col" className="titleTable">Cargo</th>
                                <th scope="col" className="titleTable">Género</th>
                                <th scope="col" className="titleTable">Fecha</th>
                                <th scope="col" className="titleTable">Programa</th>
                                <th scope="col" className="titleTable">Estado</th>
                                <th scope="col" className="titleTable">Opción</th>
                            </tr>
                        </thead>
                            <tbody>
                                {this.props.getSelectionByState.length !== 0 ? this.props.getSelectionByState.results.map((item, index) => {
                                    return (
                                        <tr id="tr" key={index.toString()}>
                                            <td className="titleItemRow">{item.document}</td>
                                            <td className="titleItemRow">{item.fullName}</td>
                                            <td className="titleItemRow">{item.positions[0].positionName}</td>
                                            <td className="titleItemRow">{item.gender}</td>
                                            <td className="titleItemRow">{item.timestamp}</td>
                                            <td className="titleItemRow">{item.allyName}</td>
                                            <td className="titleItemRow">{item.candidateSubstate}</td>
                                            <td>    <Link to={`/aprendiz/aprendiz/viewCandidate/${item.id}`}>
                                                    <img src={require('../../../assets/IconAliadosExternos/hv.png')} alt="Hoja de vida" className="iconHojaVida" ></img>
                                                </Link>
                                            </td>

                                        </tr>
                                    )
                                }) : '' }
                        </tbody>
                    </table>
                </div>

            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.selectionReducer.isLoading,
        getSelectionByState: state.selectionReducer.getSelectionByState
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllSelectionByState: bindActionCreators(getAllSelectionByState, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AprobadosAprendiz);