import React, { Component, Fragment } from "react";
import { Table, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getInterviewsByType, getInterviewsByManager, setCount } from '../../../../redux/actions/interviewActions';
import { setCandidate } from '../../../../redux/actions/candidateActions';
import Header from "../../../header/Header";
import NotificacionAprendiz from "../../notificaciones/notificaciones";
import { Link } from "react-router-dom";
import ModalCitaciones from '../../../seleccion/popUpCitaciones/popUpCitaciones';
import Pagination from "react-js-pagination";

function CandidateRow(props) {
  const interview = props.interview
  const candidate = interview.candidate;
  const date = props.date;
  const module = props.module;

  return (
    <tr id="tr" key="">
      <td scope="row">{candidate.document}</td>
      <td>{candidate.fullName}</td>
      <td>{candidate.positions[0].nameVacancy}</td>
      <td>{date}</td>
      <td>{candidate.positions[0].nameClient}</td>
      <td>
        {interview.answered === true ? 'Si' : '--'}
      </td>
      <td>
        <Row style={{width: '100%'}}>
          <Col xs="12" md="6">
            <Link
              to={`/${module}/viewCandidate/${candidate.id}`}
              className="f-left"
            >
              <i className="fas fa-pencil-alt text-info"></i>
            </Link>
          </Col>
          <Col xs="12" md="6">
            <ModalCitaciones 
              titleModal="Citaciones"
              idStatus={1}
              idSubStatus={3}
              position={candidate.positions[0].id}
              idCan={candidate.url}
              idInter={interview.id}
            />
          </Col>
        </Row>
      </td>
    </tr>
  )
}

class AsignarEntrevista extends Component {
  constructor(props){
    super(props);
    this.state = {
      activePage: 1
    }
  }

  componentDidMount = () => {
    this.props.setCount(0);
    let type = this.props.modulePath == 'aprendiz' ? 0 : this.props.modulePath == 'practicante' ? 1 : 2;
    if(type === 2 && this.props.allyData.length !== 0) {
      let id = this.props.allyData[0].managerData.id;
      this.props.getInterviewsByManager(type, 2, 0, id);
    }else{
      this.props.getInterviewsByType(type, 2, 0);
    }
    this.props.setCandidate([]);
  }

  goToDetail = () => {

  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  handlePageChange = (e) => {
    window.scroll(0,0);
    let type = this.props.modulePath == 'aprendiz' ? 0 : this.props.modulePath == 'practicante' ? 1 : 2;
    if(type === 2 && this.props.allyData.length !== 0) {
      let id = this.props.allyData[0].managerData.id;
      this.props.getInterviewsByManager(type, 2, 0, id, e);
    }else{
      this.props.getInterviewsByType(type, 2, 0, e);
    }

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if(this.props.pageCount !== 0){
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i+1);
      }
    }
    return(
      <FormGroup>
        <Input
          style={{marginLeft: 10}}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return(
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="row mb-1 text-left" style={{width: '100%', alignItems: 'center'}}>
            <Col>
              <p>
                <strong className="titleSeguimiento text-left">
                  Candidatos a citar
                </strong>
              </p>
              <p className="subtitleSeguimiento">
                Listado de candidatos que deben ser notificados.
              </p>
            </Col>
            <Col>
              <NotificacionAprendiz />
            </Col>
          </div>

          <div className="table-responsiveAprobados containerResumenSeleccion">
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Cédula</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Cargo</th>
                  <th scope="col">Fecha asignada</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Contesto</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.interviewList.length !== 0 ? 
                  this.props.interviewList.results.map((item, index) => {
                    return (
                      <CandidateRow
                        key={index.toString()}
                        module={this.props.modulePath}
                        interview={item}
                        // date={this.formatDate(
                        //   parseInt(item.admissionDate) * 1000
                        // )}
                        date={`${item.dateInterview} - ${item.hourInterview}`}
                        onEdit={() => this.goToDetail()}
                      />
                    )
                  })
                  : ''
                }
              </tbody>
            </Table>
          </div>

          <Col md={12} style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.pageCount}
              pageRangeDisplayed={5}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={5}
              pageRangeDisplayed={10}
            />
            {this.renderOptionsPage()}
          </Col>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    interviewList: state.interviewReducer.interviewList,
    isLoading: state.interviewReducer.isLoading,
    modulePath: state.allyReducer.modulePath,
    allyData: state.allyReducer.allyData,
    pageCount: state.interviewReducer.pageCount
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getInterviewsByType: bindActionCreators(getInterviewsByType, dispatch),
    setCandidate: bindActionCreators(setCandidate, dispatch),
    getInterviewsByManager: bindActionCreators(getInterviewsByManager, dispatch),
    setCount: bindActionCreators(setCount, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AsignarEntrevista);
