import React, { Component } from "react";
import { Col, FormGroup, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLinkedCandidates } from '../../../redux/actions/clientsActions';
import { Link } from 'react-router-dom';
import "./Seguimientos.styles.css";
import Pagination from "react-js-pagination";

function LinkedRow(props) {
  const candidates = props.list;

  return (
    <tr id="tr" key={candidates.id.toString()}>
      <td className="titleItemRow">{candidates.fullName}</td>
      <td className="titleItemRow">{candidates.document}</td>
      <td className="titleItemRow">{candidates.email}</td>
      <td className="titleItemRow">{candidates.cellphone}</td>
      <td className="titleItemRow">{candidates.positions[0].nameVacancy}</td>
      <td>
        <div className="actionIcons">
          <Link to={`seguimientos-nuevo/editar/${candidates.id}`}>
            <i className="fa fa-eye" style={{color: '#17a2b8'}} aria-hidden="true"></i>
          </Link>
          <Link to={`seguimientos-nuevo/agregar/${candidates.id}`}>
            <i className="fa fa-plus-circle" style={{color: '#28a745'}} aria-hidden="true"></i>
          </Link>
        </div>
      </td>
    </tr>
  );
}

class SeguimientosNuevo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  componentDidMount = () => {
    if(this.props.allyData.length !== 0){
      let id = this.props.allyData[0].businessData.id;
      this.props.getLinkedCandidates(id);
    }
  }

  handlePageChange = (e) => {
    window.scroll(0,0);
    let id = this.props.allyData[0].businessData.id;
      this.props.getLinkedCandidates(id, e);

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if(this.props.pageCount !== 0){
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i+1);
      }
    }
    return(
      <FormGroup>
        <Input
          style={{marginLeft: 10}}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return(
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  render() {
    return (
      <div className="backgroundRegistros">
        <div className="clientPicture">
          {this.props.allyData.length != 0 && <img src={this.props.allyData[0].businessData.profilePicture} width="100%" />}
        </div>

        <div className="customRow mb-5 text-left">
          <p>
            <strong className="titleSeguimiento text-left">Seguimientos</strong>
          </p>
          <p className="subtitleSeguimiento">
            Listado de los vinculados para seguimiento
          </p>
        </div>

        <div className="table-responsiveVinculados containerTable">
          <table className="table tableViewSeguimientos">
            <thead>
              <tr>
                <th scope="col" className="titleTable">
                  Nombre
                </th>
                <th scope="col" className="titleTable">
                  Cédula
                </th>
                <th scope="col" className="titleTable">
                  Correo
                </th>
                <th scope="col" className="titleTable">
                  Teléfono
                </th>
                <th scope="col" className="titleTable">
                  Cargo
                </th>
                <th scope="col" className="titleTable"></th>
              </tr>
            </thead>
            <tbody>

              {this.props.linkedList.results.map((data, index) => {
                return (
                  <LinkedRow
                    key={index.toString()}
                    list={data}
                  />
                )
              })}

            </tbody>
          </table>
        </div>

        <Col md={12} style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={50}
            totalItemsCount={this.props.pageCount}
            pageRangeDisplayed={5}
            onChange={(e) => this.handlePageChange(e)}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
            pageRangeDisplayed={5}
            pageRangeDisplayed={10}
          />
          {this.renderOptionsPage()}
        </Col>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allyData: state.allyReducer.allyData,
    linkedList: state.clientsReducer.linkedList,
    pageCount: state.clientsReducer.pageCount
  }
}

const mapDispatchToProps = dispatch => {
  return{
    getLinkedCandidates: bindActionCreators(getLinkedCandidates, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeguimientosNuevo);
