import React, { Component, Fragment } from "react";
import { Button } from 'reactstrap';
import "./tabla.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { getSummariesClient } from '../../../../redux/actions/summaryActions';

function RowClientes(props){
  const data = props.data;
  const consolidatos = props.consolidado

  var itemAmount = {
    "total": 0,
    "hombres": 0,
    "mujeres": 0,
    "arn": 0,
    "victimas": 0
  }

  itemAmount = consolidatos.consolidado.find(el => el.subState == data.sub)
  return(
    <tr key={data.sub.toString()}>
      <td>{data.name}</td>
      <td>{itemAmount !== undefined ? `${itemAmount.hombres} / ${itemAmount.mujeres}` : `0 / 0`}</td>
      <td>{itemAmount !== undefined ? `${itemAmount.programas} / ${itemAmount.agencias}` : `0 / 0`}</td>
      {/* <td>{itemAmount !== undefined ? `${itemAmount.arn} / ${itemAmount.victimas}` : `0 / 0`}</td> */}
      <td>{itemAmount !== undefined ? itemAmount.total : 0}</td>
    </tr>
  )
}

class TablaSeleccion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consolidadoCliente: [{
        name: 'Candidatos',
        state: 1,
        sub: 8,
      },{
        name: 'Entrevista',
        state: 2,
        sub: 9,
      },{
        name: 'Pendientes contratación',
        state: 2,
        sub: 10,
      },{
        name: 'No continuan proceso',
        state: 2,
        sub: 5,
      },{
        name: 'Vinculados',
        state: 2,
        sub: 11,
      },{
        name: 'Vinculados aprendiz',
        state: 2,
        sub: 12,
      },{
        name: 'Vinculados reintegro',
        state: 2,
        sub: 13,
      },{
        name: 'Desvinculados',
        state: 3,
        sub: 14,
      }],
    };
  }

  componentDidMount = async() => {
    await this.props.getSummariesClient(this.props.idBussines);
    console.log('consolidado', this.props.summaryGender)
  }

  render() {
    return (
      <div className="table-responsiveTabla containerTablaSeleccion">
        <table
          className="table table-bordered"
          style={{ borderCollapse: "collapse", border: "1px solid" }}
        >
          <thead>
            <tr style={{ backgroundColor: "white" }}>
              <th scope="col" className="titleTable">
                Estados
              </th>
              <th scope="col" className="titleTable">
                Genero (M / F)
              </th>
              <th scope="col" className="titleTable">
                Programas / Agencias
              </th>
             {/*  <th scope="col" className="titleTable">
                ARN / Victimas
              </th> */}
              <th scope="col" className="titleTable">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.summaryGender.length !== 0 ? this.state.consolidadoCliente.map((item, i) => {
              return(
                <RowClientes 
                  key={i.toString()}
                  data={item}
                  consolidado={this.props.summaryGender}
                />
              )
            }) : null}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    summaryGender: state.summariesReducer.summaryGender
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSummariesClient: bindActionCreators(getSummariesClient, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaSeleccion);
