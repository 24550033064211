import React, { Component, Fragment } from 'react';
import './acompanamiento.styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Header from '../../header/Header';
import { getAllSelectionByState } from '../../../redux/actions/selectionActions';
import NotificacionAprendiz from '../notificaciones/notificaciones';


class AcompanamientoPracticante extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount = () => {
		var obj = {
			all_id: 3,
			idSubState: 5
		}
		this.props.getAllSelectionByState(obj);
	}

	componentDidUpdate = () => {
		// console.log('Entrevistas', this.props);
	}

	navigateTo = (route) => {
		this.props.history.push(route);
	}

	render() {
		return (
			<Fragment>
				<Header />
				<div className="customRow mb-5 text-left">
					<p>
						<strong className="titleSeguimiento text-left">Acompañamiento</strong>
					</p>
					<p className="subtitleSeguimiento">Listado de candidatos que fueron entrevistados</p>
				</div>

				<div className="col-12 bgResumenSeleccion">

					{/* <div className="col-12 mb-3">
                    <NotificacionAprendiz />
                </div> */}
					<div className="col-12 table-responsiveAcompanamiento containerResumenSeleccion row">
						{this.props.modulePath !== 'aprendiz' && <div className="col-4">
							<div className="itemCiudad" onClick={() => this.navigateTo(`advisory`)}>
								<h6>Asesorias</h6>
							</div>
						</div>}
						<div className="col-4">
							<div className="itemCiudad" onClick={() => this.navigateTo(`/${this.props.modulePath}/talleres`)}>
								<h6>Talleres</h6>
							</div>
						</div>
					</div>

				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoading: state.selectionReducer.isLoading,
		getSelectionByState: state.selectionReducer.getSelectionByState,
		modulePath: state.allyReducer.modulePath,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getAllSelectionByState: bindActionCreators(getAllSelectionByState, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AcompanamientoPracticante);