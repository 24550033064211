import React, { Component, Fragment } from "react";
import "./ciudades.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCities } from "../../redux/actions/citiesActions";
import { setAllyCities } from "../../redux/actions/allyActions";

import createReactClass from "create-react-class";

import Header from "../header/Header";
import { Link } from "react-router-dom";
import filterCities from "../../utils/departcities";

var FilteredList = createReactClass({
  filterList: function (event) {
    var updatedList = this.state.initialItems;
    updatedList = updatedList.filter(function (item) {
      return (
        item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ items: updatedList });
  },
  getInitialState: function () {
    return {
      initialItems: [
        "Apples",
        "Broccoli",
        "Chicken",
        "Duck",
        "Eggs",
        "Fish",
        "Granola",
        "Hash Browns",
      ],
      items: [],
    };
  },
  componentWillMount: function () {
    this.setState({ items: this.state.initialItems });
    if (this.props.items) {
      this.setState({ items: this.props.items });
      this.setState({ initialItems: this.props.items });
    }
  },
  navigateTo: function (route) {
    this.props.history.push(route);
  },
  render: function () {
    return (
      <div className="col" style={{ width: "100%" }}>
        <div className="row" style={{ width: "100%" }}>
          <div className="col">
            <form>
              <fieldset className="form-group">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Filtrar ciudades"
                  onChange={this.filterList}
                />
              </fieldset>
            </form>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          {this.state.items.map((item, index) => {
            return (
              <div
                className="col-4"
                data-category={item.name}
                key={index.toString()}
              >
                <div
                  className="itemCiudad"
                  style={{ minWidth: "150px", flexDirection: "column" }}
                  onClick={() =>
                    this.navigateTo(`${item.name}/emprendimientos`)
                  }
                >
                  <i className="fas fa-building"></i>
                  <h6 style={{ padding: "0 8px" }}>{item.name}</h6>
                  {/* <span>0</span> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
});

class CitiesDepart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
    };
  }

  componentDidMount = () => {
    let department = this.props.match.params.name;
    if (department) {
      let objCity = filterCities(department).map((d, i) => {
        return {
          id: i + 1,
          name: d,
        };
      });
      this.setState({
        cities: objCity,
      });
    }
  };

  navigateTo = (route) => {
    this.props.history.push(route);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundCiudades">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Ciudades de {this.props.match.params.name}
              </strong>
            </p>
            <p className="subtitleSeguimiento">Selecciona una opción</p>
          </div>

          <div className=" container-fluid containerCiudades">
            {this.state.cities.length !== 0 && (
              <FilteredList
                items={this.state.cities}
                history={this.props.history}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CitiesDepart);
