import React, { Component, Fragment } from "react";
import "./talleres.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../../header/Header";
import { get_all_Workshops } from "../../../../redux/actions/acompanamientoActions";
// import NotificacionAcompanamiento from '../notificaciones/notificaciones';

class TalleresPracticante extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.get_all_Workshops();
  };

  componentDidUpdate = () => {
    // console.log('Entrevistas', this.props);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12 containerTitleSeleccion mb-5 text-left">
            <p>
              <strong className="titleTaller text-left">Talleres</strong>
            </p>
            <p className="subtitleTaller">Listado de Talleres</p>
          </div>
          <div className="col-12 mb-3">
            {/* <NotificacionAcompanamiento /> */}
          </div>
          <div className="col-12 table-responsive containerResumenSeleccion">
            <button className="btn btn-info">
              <Link
                to={`/practicante/practicante/talleres/new`}
                style={{ textDecoration: "none" }}
              >
                <strong id="btnNew">Nuevo </strong>
              </Link>
            </button>
            <hr />
            <div className="table-responsiveTalleres">
              <table className="table tableResumenSeleccion">
                <thead>
                  <tr>
                    <th scope="col" className="titleTable">
                      Grupo
                    </th>
                    <th scope="col" className="titleTable">
                      Constancia
                    </th>
                    <th scope="col" className="titleTable">
                      Ciudad
                    </th>
                    <th scope="col" className="titleTable">
                      Día
                    </th>
                    <th scope="col" className="titleTable">
                      Lugar
                    </th>
                    <th scope="col" className="titleTable">
                      Opcion
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.setWorkshops.length !== 0
                    ? this.props.setWorkshops.results.map((item, index) => {
                        return (
                          <tr
                            style={{ backgroundColor: "white" }}
                            key={index.toString()}
                          >
                            <td>
                              <p className="titleItemRow">{item.group}</p>
                            </td>

                            <td>
                              <p className="titleItemRow">{item.constancy}</p>
                            </td>
                            <td className="titleItemRow"></td>
                            <td>
                              <p className="titleItemRow">{item.day}</p>
                            </td>

                            <td>
                              <p className="titleItemRow">{item.place}</p>
                            </td>

                            <td>
                              <div className="row">
                                <div className="col-3 mr-2">
                                  
                                    <i className="fas fa-pencil-alt text-info"></i>
                                  
                                </div>

                                <div className="col-3">
                                  <i
                                    className="fas fa-minus-circle text-danger"
                                    
                                  ></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </div>

          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.accompanimentReducer.workshops);
  return {
    isLoading: state.accompanimentReducer.isLoading,
    setWorkshops: state.accompanimentReducer.workshops,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_all_Workshops: bindActionCreators(get_all_Workshops, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TalleresPracticante);
