import { ISLOADING, GET_CITIES, GET_CITIES_BY_BUSINESS, SET_BUSINESS } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

import swal from 'sweetalert';

export const isLoading = bool => ({
  type: ISLOADING,
  isLoading: bool
});

export const setCitiesData = citiesData => ({
  type: GET_CITIES,
  citiesData
});

export const setCitiesBusiness = citiesBusiness => ({
  type: GET_CITIES_BY_BUSINESS,
  citiesBusiness
});

export const setBusiness = business => ({
  type: SET_BUSINESS,
  business
});

export const getAllCities = () => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCities = await axios.get(`${urls.url_base}/Y2l1ZGFk/`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCities.data;
      if (data.results.length !== 0) {
        dispatch(setCitiesData(data));
        return data.results;
      } else {
        dispatch(setCitiesData([]));
        swal('No hay información disponible');
        return []
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getCitiesByBusiness = (id) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCities = await axios.get(`${urls.url_base}/QnVzaW5lc3M/?manager=${id}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCities.data;
      if (data.results.length !== 0) {
        console.log('do dispatch')
        dispatch(setCitiesBusiness(data));
        return data.results;
      } else {
        dispatch(setCitiesBusiness({ results: [] }));
        swal('No hay información disponible');
        return []
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}