import React, { Component, Fragment } from "react";
import "./ciudades.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getCitiesByBusiness,
  setCitiesBusiness,
  setBusiness,
} from "../../../redux/actions/citiesActions";
import createReactClass from "create-react-class";

import Header from "../../header/Header";
import { Link } from "react-router-dom";

var FilteredList = createReactClass({
  filterList: function (event) {
    var updatedList = this.state.initialItems;
    updatedList = updatedList.filter(function (item) {
      return (
        item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ items: updatedList });
  },
  getInitialState: function () {
    return {
      initialItems: [
        "Apples",
        "Broccoli",
        "Chicken",
        "Duck",
        "Eggs",
        "Fish",
        "Granola",
        "Hash Browns",
      ],
      items: [],
    };
  },
  componentWillMount: function () {
    this.setState({ items: this.state.initialItems });
    if (this.props.items) {
      this.setState({ items: this.props.items });
      this.setState({ initialItems: this.props.items });
    }
  },
  sendToBussines: function (id) {
    this.props.onSelectBussines(id);
  },

  render: function () {
    return (
      <div className="row" style={{ width: "100%" }}>
        <div className="row" style={{ width: "100%" }}>
          <div className="col">
            <form>
              <fieldset className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filtrar ciudades"
                  onChange={this.filterList}
                />
              </fieldset>
            </form>
          </div>
        </div>
        <div className="row" style={{ width: "100%" }}>
          {this.state.items.map((item, index) => {
            return (
              <div
                className="col-4"
                data-category={item.name}
                key={index.toString()}
              >
                <div
                  className="itemCiudad"
                  style={{ minWidth: "150px" }}
                  onClick={() => this.sendToBussines(item.business)}
                >
                  <i className="fas fa-building"></i>
                  <h6>{item.name}</h6>
                </div>
              </div>
            );
            //<li className="list-group-item" data-category={item} key={item}>{item}</li>
          })}
        </div>
      </div>
    );
  },
});

class Ciudades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manageCities: [],
    };
  }

  componentDidMount = () => {
    if (this.props.allyData.length !== 0) {
      let id = this.props.allyData[0].managerData.id;
      this.props.getCitiesByBusiness(id).then((resp) => {
        let clientId = this.props.menuClient.id;
        if (
          resp.filter((el) => el.idClient.split("/")[5] === clientId.toString())
            .length !== 0
        ) {
          this.mapCities(
            resp.filter(
              (el) => el.idClient.split("/")[5] === clientId.toString()
            )
          );
        } else {
          this.mapCities(resp);
        }
      });
    }
  };

  goToBusiness = (data) => {
    this.props.setBusiness(data);
    this.props.history.push("cities/business");
  };

  mapCities = async (bussines) => {
    let finalMap = [];
    let tempData = bussines;
    let newData = await tempData.reduce((key, val) => {
      key[val.nameCity] = key[val.nameCity] || [];
      key[val.nameCity].push(val);
      return key;
    }, Object.create(null));

    let key = Object.keys(newData);
    key.forEach((item) => {
      finalMap.push({
        name: item,
        business: newData[item],
      });
    });

    if (this.state.manageCities.length == 0) {
      this.setState({
        manageCities: finalMap,
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Header />

        <div className="backgroundCiudades">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">Ciudades</strong>
            </p>
            <p className="subtitleSeguimiento">Selecciona una opción</p>
          </div>

          <div className=" container-fluid containerCiudades">
            {this.state.manageCities.length !== 0 ? (
              <FilteredList
                items={this.state.manageCities}
                history={this.props.history}
                onSelectBussines={this.goToBusiness}
              />
            ) : (
              ""
            )}

            {/* {this.state.manageCities.length !== 0
              ? this.state.manageCities.map((item, index) => {
                  return (
                    <div className="col-2" key={index.toString()}>
                      <div className="itemCiudad" onClick={() => {this.goToBusiness(item.business)}}>
                          <h6>{item.name}</h6>
                      </div>
                    </div>
                  );
                })
              : ""} */}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allyData: state.allyReducer.allyData,
    isLoading: state.candidateReducer.isLoading,
    citiesBusiness: state.citiesReducer.citiesBusiness,
    menuClient: state.clientsReducer.menuClient,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBusiness: bindActionCreators(setBusiness, dispatch),
    getCitiesByBusiness: bindActionCreators(getCitiesByBusiness, dispatch),
    setCitiesBusiness: bindActionCreators(setCitiesBusiness, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ciudades);
