import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add_TracingNuevo } from "../../../../redux/actions/acompanamientoActions";
import { Redirect } from "react-router-dom";
import Header from "../../../header/Header";
import "./addSeguimiento.css";
import swal from "sweetalert";
import urls from "../../../../apiConf";

class AddSeleccionAcomNuevo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      month: "",
      year: "",
      calidad: 0,
      proactividad: 0,
      responsabilidad: 0,
      disposicion: 0,
      puntualidad: 0,
      relaciones: 0,
      cumplimiento: 0,
      presentacion: 0,
      honestidad: 0,
      habilidad: 0,
      observations: "",
      origen: "",
      idCandidate: null,
    };
  }

  componentDidMount = () => {
    var id = this.props.match.params.idCan;
    console.log(id);
    let candidate = this.props.getSelectionByState;
    console.log(candidate);
    let findCandidate;
    if (candidate.length > 0) {
      findCandidate = candidate.results.find(
        (p) => p.candidate.id === parseInt(id)
      );
    }

    if (typeof findCandidate !== "undefined") {
      this.setState({
        fullname: findCandidate?.candidate.fullName,
        idCandidate: id,
      });
    }
  };

  handleChange = (e) => {
    console.log(e.target.value);
    const newState = this.state;
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  addTracingData = () => {
    if (this.state.month !== "") {
      let year = new Date().getFullYear();
      let obj = {
        month: this.state.month,
        year: year,
        calidad: parseInt(this.state.calidad),
        proactividad: parseInt(this.state.proactividad),
        responsabilidad: parseInt(this.state.responsabilidad),
        disposicion: parseInt(this.state.disposicion),
        puntualidad: parseInt(this.state.puntualidad),
        relaciones: parseInt(this.state.relaciones),
        cumplimiento: parseInt(this.state.cumplimiento),
        presentacion: parseInt(this.state.presentacion),
        honestidad: parseInt(this.state.honestidad),
        habilidad: parseInt(this.state.habilidad),
        observations: this.state.observations,
        origen: "Cliente",
        idCandidate:
          urls.url_base +
          "/Q2FuZGlkYXRvcw/" +
          this.props.match.params.idCan +
          "/",
      };

      swal({
        title: "Estas seguro?",
        text: "Una vez guardado el seguimiento no podrá ser editado.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willSave) => {
        if (willSave) {
          obj.total = Math.round(
            ((obj.calidad +
              obj.proactividad +
              obj.responsabilidad +
              obj.disposicion +
              obj.puntualidad +
              obj.relaciones +
              obj.cumplimiento +
              obj.presentacion +
              obj.honestidad +
              obj.habilidad) /
              40) *
              100
          );

          this.props.add_TracingNuevo(obj);
        }
      });
    } else {
      swal("Por favor selecciona el mes!");
    }
  };
  render() {
    console.log(this.props.saveTracingNuevo);
    return (
      <Fragment>
        <Header />
        <div className="backgroundRegistros">
          {this.props.saveTracing === true ? (
            <Redirect to={`/acompanamiento/acompanamiento/seguimiento`} />
          ) : null}
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Nuevo Seguimiento
              </strong>
            </p>
            <p className="subtitleSeguimiento">
              {`Agregar nuevo seguimiento para ${this.state.fullname}`}
            </p>
          </div>

          <div className="cardContent">
            <form className="contentForm">
              <div className="form-group row">
                <label className="col-sm-7 col-form-label">Mes</label>
                <div className="col-sm-5">
                  <select
                    className="form-control"
                    name="month"
                    value={this.state.month}
                    onChange={this.handleChange}
                  >
                    <option value="" defaultValue="" disabled>
                      Seleccionar mes
                    </option>
                    <option value="1">Enero</option>
                    <option value="2">Febrero</option>
                    <option value="3">Marzo</option>
                    <option value="4">Abril</option>
                    <option value="5">Mayo</option>
                    <option value="6">Junio</option>
                    <option value="7">Julio</option>
                    <option value="8">Agosto</option>
                    <option value="9">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  1. Calidad en el trabajo: Método y orden en la ejecución y
                  presentación de tareas.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="calidad"
                    type="range"
                    list="calidad"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.calidad}
                    onChange={this.handleChange}
                  />

                  <datalist id="calidad">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.calidad}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  2. Proactividad: Capacidad de trabajar a ritmo óptimo en
                  diferentes condiciones.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="proactividad"
                    type="range"
                    list="proactividad"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.proactividad}
                    onChange={this.handleChange}
                  />

                  <datalist id="proactividad">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.proactividad}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  3. Responsabilidad: Preocupación por los aspectos que competen
                  al cargo y a la Organización.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="responsabilidad"
                    type="range"
                    list="responsabilidad"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.responsabilidad}
                    onChange={this.handleChange}
                  />

                  <datalist id="responsabilidad">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.responsabilidad}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  4. Disposición a la tarea y colaboración: Compromiso para
                  desempeñar sus funciones, actitud de servicio.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="disposicion"
                    type="range"
                    list="disposicion"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.disposicion}
                    onChange={this.handleChange}
                  />

                  <datalist id="disposicion">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.disposicion}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  5. Puntualidad: Asistencia regular al trabajo y cumplimiento
                  de metas en el momento adecuado.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="puntualidad"
                    type="range"
                    list="puntualidad"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.puntualidad}
                    onChange={this.handleChange}
                  />

                  <datalist id="puntualidad">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.puntualidad}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  6. Relaciones interpersonales: Habilidad para entablar y
                  mantener relaciones armónicas con compañeros y superiores.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="relaciones"
                    type="range"
                    list="relaciones"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.relaciones}
                    onChange={this.handleChange}
                  />

                  <datalist id="relaciones">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.relaciones}</span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  7. Cumplimiento de normas y reglamento interno: Capacidad de
                  incorporar las reglas, normas y políticas de la organización
                  en su comportamiento.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="cumplimiento"
                    type="range"
                    list="cumplimiento"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.cumplimiento}
                    onChange={this.handleChange}
                  />

                  <datalist id="cumplimiento">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.cumplimiento}</span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  8. Presentación personal: Capacidad de incorporar el código de
                  vestuario de la organización con su estilo personal.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="presentacion"
                    type="range"
                    list="presentacion"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.presentacion}
                    onChange={this.handleChange}
                  />

                  <datalist id="presentacion">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.presentacion}</span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  9. Honestidad: Es el valor que nos permite vivir una vida
                  congruente, es decir, que lo que pensamos, sentimos y hacemos
                  está en sincronía.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="honestidad"
                    type="range"
                    list="honestidad"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.honestidad}
                    onChange={this.handleChange}
                  />

                  <datalist id="honestidad">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.honestidad}</span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-7 col-form-label">
                  10. Habilidad técnica: Conocimiento adquirido aplicado en la
                  práctica diaria.{" "}
                </label>
                <div className="col-sm-5 customRange">
                  <input
                    name="habilidad"
                    type="range"
                    list="habilidad"
                    min="1"
                    max="4"
                    step="1"
                    value={this.state.habilidad}
                    onChange={this.handleChange}
                  />

                  <datalist id="habilidad">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                  </datalist>

                  <span>{this.state.habilidad}</span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-7 col-form-label">Observaciones</label>
                <div className="col-sm-5">
                  <textarea
                    className="form-control"
                    name="observations"
                    rows="3"
                    value={this.state.observations}
                    onChange={this.handleChange}
                  ></textarea>
                </div>
              </div>
              <br />
              <div
                className="form-group row"
                style={{ justifyContent: "center" }}
              >
                <button
                  className="btn btn-primary"
                  type="button"
                  style={{ backgroundColor: "#204d6a" }}
                  onClick={this.addTracingData}
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allyData: state.allyReducer.allyData,
    getSelectionByState: state.selectionReducer.getSelectionByState,
    linkedList: state.clientsReducer.linkedList,
    isLoading: state.accompanimentReducer.isLoading,
    saveTracingNuevo: state.accompanimentReducer.saveTracingNuevo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    add_TracingNuevo: bindActionCreators(add_TracingNuevo, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSeleccionAcomNuevo);
