import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Spinner,
} from "reactstrap";
import "./header.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  searchCandidateDoc,
  setDataSearch,
  setCandidate,
} from "../../redux/actions/candidateActions";
import { withRouter } from "react-router-dom";
import Menu from "../menu/Menu";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  componentDidMount = () => {
    this.props.setDataSearch([]);
    this.props.setCandidate([]);
  };

  search = (e) => {
    e.preventDefault();
    this.toggleModal();
    this.props.setCandidate([]);
    this.props.searchCandidateDoc(e.target.doc.value);
  };

  viewCandidate = (idcan) => {
    this.props.history.push(`/${this.props.modulePath}/viewCandidate/${idcan}`);
  };

  closeClean = () => {
    this.toggleModal();
    this.props.setDataSearch([]);
    this.props.setCandidate([]);
  };

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  searchModal = () => {
    return (
      <Modal isOpen={this.state.openModal} toggle={this.closeClean} size={"lg"}>
        <ModalHeader toggle={this.closeClean}>Resultados búsqueda</ModalHeader>
        <ModalBody>
          {this.props.searchCandidate.length !== 0 && (
            <div>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Documento</th>
                    <th>Nombre completo</th>
                    <th>Negocio</th>
                    <th>Programa / Agencia</th>
                    <th>Tipo población</th>
                    <th>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.searchCandidate.results.map((item) => {
                    return (
                      <tr key={item.id.toString()}>
                        <th scope="row">{item.id}</th>
                        <th scope="row">{item.document}</th>
                        <td>{item.fullName}</td>
                        <td>{item.positions[0].nameBussiness}</td>
                        <td>{item.allyName}</td>
                        <td>{item.arnPoblation}</td>
                        <td>{item.positions[0].psubstateName}</td>
                        <td
                          onClick={() => {
                            this.viewCandidate(item.id);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fa fa-eye"
                            style={{ color: "#17a2b8" }}
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.closeClean}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    return (
      <div className="row backgroundHeader">
        <div className="col-1">
          <Button
            color="info"
            type="button"
            onClick={() => {
              window.history.back();
            }}
          >
            Volver
          </Button>
        </div>
        {this.searchModal()}
        <div className="col-11 containerSearch">
          <form
            onSubmit={this.search}
            style={{ width: "400px", display: "flex" }}
          >
            <input
              type="text"
              style={{ width: "100%" }}
              className="txtSearch"
              placeholder="Buscar por documento"
              id="doc"
            />
            <button
              type="submit"
              className="btn btn-info"
              style={{ marginLeft: 10 }}
            >
              Buscar
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchCandidate: state.candidateReducer.searchCandidate,
    modulePath: state.allyReducer.modulePath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchCandidateDoc: bindActionCreators(searchCandidateDoc, dispatch),
    setDataSearch: bindActionCreators(setDataSearch, dispatch),
    setCandidate: bindActionCreators(setCandidate, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
