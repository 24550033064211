import React, { Component } from "react";
import { Col, FormGroup, Input } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getEmprendimientosByAlly } from "../../../redux/actions/emprendActions";
import { updateStateCandidate } from "../../../redux/actions/candidateActions";
import "./tableEmprendimiento.styles.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Pagination from "react-js-pagination";

function EmprendimientoRow(props) {
  const emprendimiento = props.emprendimiento;
  const date = emprendimiento.timestamp.split('T')[0];

  return (
    <tr id="tr" key={emprendimiento.id.toString()}>
      <td className="titleItemRow">{emprendimiento.fullName}</td>
      <td className="titleItemRow">{emprendimiento.authorizingContact}</td>
      <td className="titleItemRow">{emprendimiento.cellphone}</td>
      <td className="titleItemRow">{emprendimiento.city}</td>
      <td className="titleItemRow">{emprendimiento.sector}</td>
      <td className="titleItemRow">{date}</td>
      <td className="titleItemRow">
        <div className="btn btn-warning text-black" style={{ minWidth: 210 }}>
          {emprendimiento.stateName}
        </div>
      </td>
    </tr>
  );
}

class TableEmprendimiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  componentDidMount = () => {
    if (this.props.allyData.length !== 0) {
      var obj = {
        idAlly: this.props.allyData ? this.props.allyData[0].advisorData.id : 0,
      };
      this.props.getEmprendimientosByAlly(obj.idAlly, this.state.activePage);
    }
  };

  formatDate(date) {
    var fecha = date.split("T");
    var resultado = fecha[0];
    return resultado;
  }

  sendToReintry = (candidate) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" className="customAlert">
            <h4>El candidato se encuentra en estado retirado</h4>
            <p>¿Deseas solicitar el reingreso?</p>
            <button
              className="customButtons"
              onClick={() => {
                this.updateStateCandidate(candidate);
                onClose();
              }}
            >
              Si
            </button>
            <button className="customButtons" onClick={onClose}>
              No
            </button>
          </div>
        );
      },
    });
  };

  updateStateCandidate = (candidate) => {
    var obj = {
      requestsReentry: true,
    };
    this.props.updateStateCandidate(
      candidate.id,
      obj,
      `Tu solicitud se ha enviado correctamente.`
    );
  };

  handlePageChange = (e) => {
    window.scroll(0, 0);
    var obj = {
      idAlly: this.props.allyData ? this.props.allyData[0].advisorData.id : 0,
    };
    this.props.getEmprendimientosByAlly(obj, e);

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if (this.props.countEmpred != 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.countEmpred / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  render() {
    return (
      <div className="backgroundViewCandidates">
        <div className="headerInicio">
          <div className="allyPicture">
            {this.props.allyData.length != 0 && <img src={this.props.allyData[0].advisorData.profilePicture} width="100%" />}
          </div>

          <a className="btnNotification" href="#">
            <img src={require('../../../assets/Icons/bell.png')} alt="Notificaciones" className="iconAlerts" />
          </a>
        </div>
        <div className="customRow mb-2 text-left">
          <p>
            <strong className="titleSeguimiento text-left">
              Emprendimientos Enviados
            </strong>
          </p>
          <p className="subtitleSeguimiento">
            Listado de los emprendimientos enviados
          </p>
        </div>

        <div className="bootRow">
          <div className="col-3">
            <label><strong>Desde</strong> </label>
            <input className="form-control" type="date" name="" id="" />
          </div>
          <div className="col-3">
            <label><strong>Hasta</strong> </label>
            <input className="form-control" type="date" name="" id="" />
          </div>
          <div className="col-3">
            <button className="btn btn-info mt-3">
              <i className="fas fa-search mr-3"></i>
              <strong className="ml-4">Filtrar</strong>
            </button>
          </div>
          <div className="col-3">
            <i className="fas fa-download fa-2x"></i>
          </div>
        </div>

        <div className="table-responsiveViewCandidates containerTable">
          <table className="table tableViewAliados">
            <thead>
              <tr>
                <th scope="col" className="titleTable">
                  Emprendimiento
                </th>
                <th scope="col" className="titleTable">
                  Nombre Autorizante
                </th>
                <th scope="col" className="titleTable">
                  Celular
                </th>
                <th scope="col" className="titleTable">
                  Ciudad
                </th>
                <th scope="col" className="titleTable">
                  Sector
                </th>
                <th scope="col" className="titleTable">
                  Fecha
                </th>
                <th scope="col" className="titleTable">
                  Estado
                </th>
                <th scope=""></th>
              </tr>
            </thead>
            <tbody>
              {this.props.emprendimientosAlly.results.map((emprendimiento, index) => (
                <EmprendimientoRow
                  emprendimiento={emprendimiento}
                  key={index.toString()}
                />
              ))}
            </tbody>
          </table>
        </div>

        <Col md={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={50}
            totalItemsCount={this.props.countEmpred}
            pageRangeDisplayed={5}
            onChange={(e) => this.handlePageChange(e)}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
            pageRangeDisplayed={5}
            pageRangeDisplayed={10}
          />
          {this.renderOptionsPage()}
        </Col>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    emprendimientosAlly: state.emprendimientoReducer.emprendimientosAlly,
    allyData: state.allyReducer.allyData,
    countEmpred: state.emprendimientoReducer.countEmpred
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmprendimientosByAlly: bindActionCreators(getEmprendimientosByAlly, dispatch),
    updateStateCandidate: bindActionCreators(updateStateCandidate, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableEmprendimiento);
