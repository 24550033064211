import React, { Component, Fragment } from "react";
import { Col, Row, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from 'reactstrap';
import "./talleres.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import { get_all_Workshops, setCount } from "../../../redux/actions/acompanamientoActions";
import { setCandidateGroup } from '../../../redux/actions/candidateActions';
import NotificacionAcompanamiento from "../notificaciones/notificaciones";
import ModalDeleteAcompanamiento from '../popUpDelete/popUpDelete';
import Pagination from "react-js-pagination";

function TallerRow(props) {
  const taller = props.list;
  const path = props.path;
  var medology = [];
  try {
    medology = JSON.parse(taller.medology);
  } catch {
    medology = [taller.medology];
  }

  return (
    <tr id="tr" key={taller.id.toString()}>
      <td className="titleItemRow">{taller.group}</td>
      <td className="titleItemRow">{`${taller.date} - ${taller.hour}`}</td>
      <td className="titleItemRow">{taller.thematic}</td>
      <td className="titleItemRow">{taller.place}</td>
      <td className="titleItemRow" onClick={() => { props.openModal(medology) }}>
        <p style={{ color: 'blue', cursor: 'pointer' }}>Ver</p>
      </td>
      <td>
        <Row style={{ width: '100%' }}>
        <Col xs="12" md="4">
        <a href={`https://alianza.multimedialab.dev/UHJvamVjdA/fkfk3344/?workshop=${taller.id}`} target="_blank" style={{ display: 'inline' }}>
            
              <i className="fa fa-download" style={{ color: '#17a2b8' }} aria-hidden="true"></i>
            </a>
          </Col>
          <Col xs="12" md="4">
            <Link to={`/${path}/talleres/edit/${taller.id}`}>
              <i className="fa fa-eye" style={{ color: '#17a2b8' }} aria-hidden="true"></i>
            </Link>
          </Col>
          <Col xs="12" md="4">
            <ModalDeleteAcompanamiento
              taller={taller.id}
              type={0}
            />
          </Col>
        </Row>
      </td>
    </tr>
  );
}

class TalleresAcompanamiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      openModal: false,
      searchText: "",
      arr: []
    };
  }

  componentDidMount = () => {
    this.props.setCount(0);
    this.props.get_all_Workshops();
    this.props.setCandidateGroup([]);
  };

  componentDidUpdate = () => { };

  handlePageChange = (e) => {
    window.scroll(0, 0);
    this.props.get_all_Workshops(e);

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if (this.props.pageCount !== 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  toggleModal = (item) => {
    console.log('array de metodologias', item);
    this.setState({
      openModal: !this.state.openModal,
      arr: item
    });
  }

  searchInfo = () => {
    if (this.state.searchText.length !== 0) {
      this.props.get_all_Workshops(this.activePage, this.state.searchText);
    } else {
      this.props.get_all_Workshops(1);
    }
  }

  modalMedology = () => {
    return (
      <Modal isOpen={this.state.openModal} toggle={() => { this.toggleModal([]) }} size={'md'}>
        <ModalHeader toggle={() => { this.toggleModal([]) }}>Metodologías del taller</ModalHeader>
        <ModalBody>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Id</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {this.state.arr.map((item, index) => {
                return (
                  <tr key={index.toString()}>
                    <th scope="row">{index + 1}</th>
                    <th scope="row" style={{ display: 'flex', justifyContent: 'center' }}>
                      <a href={item} target="_blank">Ver metodología</a>
                    </th>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => { this.toggleModal([]) }}>Cerrar</Button>
        </ModalFooter>
      </Modal >
    )
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          {this.modalMedology()}
          <div className="customRow mb-1 text-left" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <div>
              <p>
                <strong className="titleTaller text-left">Talleres</strong>
              </p>
              <p className="subtitleTaller">Listado de Talleres</p>
            </div>
            <button className="btn btn-info" style={{ marginLeft: 58 }}>
              <Link
                to={`/${this.props.modulePath}/talleres/new`}
                style={{ textDecoration: "none" }}
              >
                <strong id="btnNew">Nuevo </strong>
              </Link>
            </button>
            <a
           href={`https://alianza.multimedialab.dev/UHJvamVjdA/gf4349_3/`}
           target="_blank"
           style={{ display: "inline", marginLeft: 10 }}>
           <Button color="info">Descargar</Button>
          </a>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <FormGroup style={{ margin: 0, paddingLeft: 20 }}>
              <label>Buscar taller(es)</label>
              <Input
                type="text"
                name="search"
                placeholder="Buscar..."
                onChange={(e) => this.setState({
                  searchText: e.target.value
                })}
                style={{ width: 400 }}
              />
            </FormGroup>
            <button className="btn btn-info ml-2" onClick={() => this.searchInfo()}>
              <strong id="btnNew">Buscar </strong>
            </button>
          </div>

          <div className="col-12 table-responsiveSeguimiento containerResumenSeleccion">
            <hr />
            <div className="table-responsiveTalleres">
              <table className="table tableResumenSeleccion">
                <thead>
                  <tr>
                    <th scope="col" className="titleTable">
                      Grupo
                    </th>
                    <th scope="col" className="titleTable">
                      Fecha
                    </th>
                    <th scope="col" className="titleTable">
                      Temática
                    </th>
                    <th scope="col" className="titleTable">
                      Lugar
                    </th>
                    <th scope="col" className="titleTable">
                      Metodología
                    </th>
                    <th scope="col" className="titleTable"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.setWorkshops.length !== 0
                    ? this.props.setWorkshops.results.map((item, index) => {
                      return (
                        <TallerRow
                          key={index.toString()}
                          list={item}
                          path={this.props.modulePath}
                          openModal={(e) => { this.toggleModal(e) }}
                        />
                      );
                    })
                    : null}
                </tbody>
              </table>
            </div>
          </div>

         {/*  <Col md={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.pageCount}
              pageRangeDisplayed={5}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={5}
              pageRangeDisplayed={10}
            />
            {this.renderOptionsPage()}
          </Col> */}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.accompanimentReducer.isLoading,
    setWorkshops: state.accompanimentReducer.workshops,
    modulePath: state.allyReducer.modulePath,
    pageCount: state.accompanimentReducer.pageCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_all_Workshops: bindActionCreators(get_all_Workshops, dispatch),
    setCandidateGroup: bindActionCreators(setCandidateGroup, dispatch),
    setCount: bindActionCreators(setCount, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TalleresAcompanamiento);
