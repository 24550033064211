import React, { Component, Fragment } from "react";
import "./resumenDetallado.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import NotificacionAprendiz from "../notificaciones/notificaciones";
import { countAllStatesGender } from "../../../redux/actions/selectionActions";

class ResumenDetalladoPracticante extends Component {
  //constructor(props) {
  //super(props);
  //this.state = {};
  //}

  constructor(props) {
    super(props);
    this.state = {
      enRemitidos: 0,
      enCandidatos: 0,
      enAprobados: 0,
      enNoPasaronProceso: 0,
      enNoCitados: 0,
      enNoAsistieron: 0,
      enPendientes: 0,
    };
  }

  componentDidMount = async () => {
    await this.props.countAllStatesGender();
    this.setInitialData();
  };

  setInitialData = () => {
    try {
      var remitidos = this.props.getSummaryState.filter(
        (el) => el.estado == "Remitidos"
      )[0];
      var candidatos = this.props.getSummaryState.filter(
        (el) => el.estado == "Candidatos"
      )[0];
      var aprobados = this.props.getSummaryState.filter(
        (el) => el.estado == "Aprobados"
      )[0];
      var noPasaronProceso = this.props.getSummaryState.filter(
        (el) => el.estado == "No pasaron proceso"
      )[0];
      var noAsistieron = this.props.getSummaryState.filter(
        (el) => el.estado == "Asistieron"
      )[0];
      var pendientes = this.props.getSummaryState.filter(
        (el) => el.estado == "Pendientes"
      )[0];
      var noCitados = this.props.getSummaryState.filter(
        (el) => el.estado == "No citados"
      )[0];

      this.setState({
        enRemitidos: remitidos.total,
        enCandidatos: candidatos.total,
        enAprobados: aprobados.total,
        enNoPasaronProceso: noPasaronProceso.total,
        enNoCitados: noCitados.total,
        enNoAsistieron: noAsistieron.total,
        enPendientes: pendientes.total,
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  render() {
    const idAlly = this.props.match.params.ally;
    return (
      <Fragment>
        <Header />
        <div className="container-fluid bgResumenDetalladoPracticante">
          <div className="col-12 containerTitleSeleccion mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Resumen Modulo de Selección
              </strong>
            </p>
            <div className="row">
              <div className="col-12">
              <i className="fas fa-download fa-2x ml-5" style={{left: 0, position: 'relative'}}></i>

              </div>
            </div>

          </div>


          <div className="col-12 mb-3">
            <NotificacionAprendiz />
          </div>
          <div className="col-12 table-responsivePracticante containerResumenDetalladoPracticante">
            <table className="table tableResumenDetalladoSeleccion">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Estados
                  </th>
                  <th scope="col" className="titleTable">
                    Total
                  </th>
                  <th scope="col" className="titleTable">
                    Opcion
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr id="tr">
                  <td className="titleItemRow">Remitidos</td>
                  <td className="titleItemRow">{this.state.enRemitidos}</td>
                  <td>
                    <Link to={`/practicante/practicante/remitidos/${idAlly}/0`}>
                      <i className="fas fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Candidatos</td>
                  <td className="titleItemRow">{this.state.enCandidatos}</td>
                  <td>
                    <Link
                      to={`/practicante/practicante/candidatos/${idAlly}/1`}
                    >
                      <i className="fas fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">No citados</td>
                  <td className="titleItemRow">{this.state.enNoCitados}</td>
                  <td>
                    <Link
                      to={`/practicante/practicante/no-citados/${idAlly}/2`}
                    >
                      <i className="fas fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Asistieron</td>
                  <td className="titleItemRow">{this.state.enNoAsistieron}</td>
                  <td>
                    <Link
                      to={`/practicante/practicante/asistieron/${idAlly}/3`}
                    >
                      <i className="fas fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Aprobados</td>
                  <td className="titleItemRow">{this.state.enAprobados}</td>
                  <td>
                    <Link
                      to={`/practicante/practicante/aprobados/${idAlly}/11`}
                    >
                      <i className="fas fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Pendientes</td>
                  <td className="titleItemRow">{this.state.enPendientes}</td>
                  <td>
                    <Link
                      to={`/practicante/practicante/pendientes/${idAlly}/4`}
                    >
                      <i className="fas fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">No pasaron proceso</td>
                  <td className="titleItemRow">
                    {this.state.enNoPasaronProceso}
                  </td>
                  <td>
                    <Link
                      to={`/practicante/practicante/no-pasaron-proceso/${idAlly}/8`}
                    >
                      <i className="fas fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.selectionReducer.isLoading,
    getSummaryState: state.selectionReducer.getSummaryState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    countAllStatesGender: bindActionCreators(countAllStatesGender, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumenDetalladoPracticante);
