import React, { Component, Fragment } from "react";
import { Col, FormGroup, Input } from 'reactstrap';
import "./aliadosExternos.styles.css";
import { Link } from "react-router-dom";
import Header from "../../../header/Header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCalendarFollow } from "../../../../redux/actions/timelinesActions";
import Pagination from "react-js-pagination";

function CandidateRow(props) {
  const timelines = props.timelines;
  const date = props.date;
  console.log(date);
  return (
    <tr id="tr">
      <td className="titleItemRow">{timelines.program}</td>
      <td className="titleItemRow">{timelines.nameCity}</td>
      <td className="titleItemRow">{timelines.initDate}</td>
      <td className="titleItemRow">{timelines.hour}</td>
    </tr>
  );
}

class AliadosExternosSeguimientos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  componentDidMount = () => {
    this.props.getAllCalendarFollow(0);
  };

  handleChange = () => {
    sessionStorage.setItem("timelines", "0");
  };

  handlePageChange = (e) => {
    window.scroll(0,0);
    this.props.getAllCalendarFollow(0, e);

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if(this.props.pageCount !== 0){
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i+1);
      }
    }
    return(
      <FormGroup>
        <Input
          style={{marginLeft: 10}}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return(
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundRegistros">
          <div className="row containerTitleRegistro">
            <div className="customRow mb-5 text-left">
              <p>
                <strong className="titleSeguimiento text-left">
                  Seguimiento Aliados Externos
                </strong>
              </p>
            </div>

            <div className="col-2 containerDownload">
              <button className="btn btn-info">
                  <Link
                    className="btnInbox"
                    to={`/${this.props.modulePath}/calendar/aliados/add`}
                    onClick={this.handleChange} style={{textDecoration: 'none'}}>
                      <strong id="btnNew">Nueva Reunión</strong>
                    </Link>
              </button>
            </div>
          </div>

          <div className="table-responsiveAliadosExternos containerTable">
            <table className="table tableCronogramaAliados">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Aliado Externo
                  </th>
                  <th scope="col" className="titleTable">
                    Ciudad
                  </th>
                  <th scope="col" className="titleTable">
                    Fecha
                  </th>
                  <th scope="col" className="titleTable">
                    Hora
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.timelinesData4.length != 0
                  ? this.props.timelinesData4.results.map(
                      (timelines, index) => {
                        return (
                          <CandidateRow
                            timelines={timelines}
                            key={index.toString()}
                          />
                        );
                      }
                    )
                  : null}
              </tbody>
            </table>
          </div>

          <Col md={12} style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.pageCount}
              pageRangeDisplayed={5}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={5}
              pageRangeDisplayed={10}
            />
            {this.renderOptionsPage()}
          </Col>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    timelinesData4: state.timelinesReducer.timelinesData4,
    pageCount: state.timelinesReducer.pageCount,
    modulePath: state.allyReducer.modulePath
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCalendarFollow: bindActionCreators(getAllCalendarFollow, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AliadosExternosSeguimientos);
