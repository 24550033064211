import React from "react";
import { Component } from "react";
import Header from "../../header/Header";

class Asesoria extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="col-12 containerTitleSeleccion mb-5 text-left">
          <p>
            <strong className="titleSeguimiento text-left">Asesorias</strong>
          </p>
          
            <div>
                <button className="btn btn-info">
                    <i className="fas fa-plus"></i>
                    <strong className="ml-4">

                    Nueva
                    </strong>
                </button>
            </div>
            <br/>
          <div className="col-12 table-responsivePracticante containerResumenSeleccion">
            <table className="table tableResumenSeleccion">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Colaborador
                  </th>
                  <th scope="col" className="titleTable">
                    Cédula
                  </th>
                  <th scope="col" className="titleTable">
                    Tipo
                  </th>
                  <th scope="col" className="titleTable">
                    Fecha
                  </th>
                  <th scope="col" className="titleTable">
                    Tema
                  </th>
                  <th scope="col" className="titleTable">
                    Opción
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="titleItemRow">Juan</td>
                  <td className="titleItemRow">12345678</td>
                  <td className="titleItemRow">Programador</td>
                  <td className="titleItemRow"></td>
                  <td className="titleItemRow">Educación</td>
                  
                  <td>
                  <i className="fas fa-eye text-info"></i>
                  </td>
                </tr>
              </tbody>
            </table>

            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    );
  }
}
export default Asesoria;
