import { ISLOADING, GET_VACANTE, ADD_VACANTE, PAGE_COUNT } from '../constants/action-type';

const getInitialState = () => ({
    vacanteData2: [],
    isLoading: false,
    vacanteData3: [],
    pageCount: 0
});

const vacanteReducer = (state = getInitialState(), action) => {
    switch(action.type) {
        case ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case PAGE_COUNT:
            return { ...state, pageCount: action.pageCount };
        case GET_VACANTE:
            return { ...state, vacanteData2: action.vacanteData2, isLoading: false };
        case ADD_VACANTE:
            return { ...state, vacanteData3: action.vacanteData3, isLoading: false };
        default:
            return state;
     }
}

export default vacanteReducer;