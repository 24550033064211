import React, { Component, Fragment } from 'react';
import './registros.styles.css';
import Header from '../../../header/Header';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add_timelines, getAllProgram } from '../../../../redux/actions/timelinesActions';
import { Redirect } from 'react-router-dom';

class AddCalendar extends Component {
    constructor(props) {
        super(props);
        this.state={
            aliado: '',
            fechaInicio: '',
            fechaFin: ''
        }
    }

    componentDidMount = () => {
        this.props.getAllProgram();
    };

    componentDidUpdate = () => {
        console.log('PROPSSS', this.props);
    }

    handleChange = (e) => {
        const newState = this.state;
        newState[e.target.id] = e.target.value;
        this.setState(newState);
    }

    onSaveLog = () => {
        // e.preventDefault();
        var obj = {
            initDate: this.state.fechaInicio,
            endDate: this.state.fechaFin,
            idProgram: this.state.aliado
        }
        alert('saved')
        console.log('OBJETO', obj);
        this.props.add_timelines(obj);
    }

    render() {
        return(
            <Fragment>
            <Header />
            <div className="bachgroundAddCalendar">
                {this.props.isSave === true ? <Redirect to={`/${this.props.modulePath}/calendar/records`} /> : ''}

                <div className="customRow mb-5 text-left">
                    <p>
                        <strong className="titleSeguimiento text-left">Nuevo Cronograma de Registros</strong>
                    </p>
                    
                </div>

                

                <div className="containerFormularioRegistros">
                    <form className="formularioAddCalendar">
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Aliado Externo</label>
                            <div className="col-sm-9">
                                <select 
                                    className="form-control"  
                                    id="aliado"
                                    onChange={this.handleChange}
                                >
                                    <option value="" selected disabled>Selecciona el aliado externo</option>
                                    {this.props.programData.length != 0 ? this.props.programData.results.map((item, index) => { return <option value={item.url}>{item.fullName}</option>}) : null}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Fecha de apertura</label>
                            <div className="col-sm-9">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fechaInicio" 
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Fecha de cierre</label>
                            <div className="col-sm-9">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fechaFin" 
                                    onChange={this.handleChange}
                            />
                            </div>
                        </div>
                    </form>
                </div> 
                <div className="containerBtnEnviarRegistros">
                    <button className="btnEnviarRegistro btn-info" onClick={() => this.onSaveLog()}>Enviar</button>
                </div>
            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.candidateReducer.isLoading,
        timelinesData: state.timelinesReducer.timelinesData,
        programData: state.timelinesReducer.programData,
        isSave: state.timelinesReducer.isSave,
        modulePath: state.allyReducer.modulePath
    }
}

const mapDispatchToProps = dispatch => {
    return {
        add_timelines: bindActionCreators(add_timelines, dispatch),
        getAllProgram: bindActionCreators(getAllProgram, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCalendar);