import React, { Component, Fragment } from "react";
import "./aliados.styles.css";

import Header from "../header/Header";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllysByCity } from "../../redux/actions/allyActions";
import {
  setAllyCandidates,
  setCount,
} from "../../redux/actions/candidateActions";

class AliadosFiltro extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var id = this.props.match.params.city;
    console.log("city id", id);
    this.props.getAllysByCity(id);
    this.props.setAllyCandidates([]);
    this.props.setCount(0);
  };

  validateModuleNav = (id) => {
    let aptype = sessionStorage.getItem("aptype");
    if (this.props.modulePath == "relacionamiento") {
      this.props.history.push(
        `/${this.props.modulePath}/cities/aliados/emprendimientos/${id}`
      );
    } else if (
      this.props.modulePath === "aprendiz" ||
      this.props.modulePath === "seleccion"
    ) {
      if (parseInt(aptype) === 0) {
        this.props.history.push(
          `/${this.props.modulePath}/cities/aliados/remitted/${id}`
        );
      } else {
        this.props.history.push(
          `/${this.props.modulePath}/resumen-detallado/${id}`
        );
      }
    } else if (this.props.modulePath === "acompanamiento") {
      this.props.history.push(
        `/${this.props.modulePath}/cities/aliados/remitted/${id}`
      );
    } else {
      this.props.history.push(
        `/${this.props.modulePath}/resumen-detallado/${id}`
      );
    }
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundAgenciasFiltro">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Agencias de empleo y programas de gobierno
              </strong>
            </p>
            <p className="subtitleSeguimiento">Selecciona una opción</p>
          </div>

          <div className="containerAgenciasScroll">
            <div className="row containerAgeniasFiltro">
              {this.props.allyCities.length !== 0
                ? this.props.allyCities.results.map((item, index) => {
                    return (
                      <div className="col-4" key={index.toString()}>
                        <div
                          className="itemAgencias"
                          onClick={() => {
                            this.validateModuleNav(item.id);
                          }}
                        >
                          <i class="fas fa-handshake"></i>
                          <h6>{item.fullName}</h6>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allyCities: state.allyReducer.allyCities,
    modulePath: state.allyReducer.modulePath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllysByCity: bindActionCreators(getAllysByCity, dispatch),
    setAllyCandidates: bindActionCreators(setAllyCandidates, dispatch),
    setCount: bindActionCreators(setCount, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AliadosFiltro);
