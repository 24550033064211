import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Dashboard from './components/dashboard/Dashboard';
import Login from './components/login/Login';
import Menu from './components/menu/Menu';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


function App() {
  return (
    <Router>
        <Switch>
          <Route path="/aliados">
            <Dashboard module="aliados"/>
          </Route>
          <Route path="/relacionamiento">
            <Dashboard module="relacionamiento"/>
          </Route>
          <Route path="/seleccion">
            <Dashboard module="seleccion"/>
          </Route>
          <Route path="/aprendiz">
            <Dashboard module="aprendiz"/>
          </Route>
          <Route path="/practicante">
            <Dashboard module="practicante"/>
          </Route>
          <Route path="/acompanamiento">
            <Dashboard module="acompanamiento"/>
          </Route>
          <Route path="/clientes">
            <Dashboard module="clientes"/>
          </Route>
          <Route path="/">
            <Login/>
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
