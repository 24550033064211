import React, { Component, Fragment } from "react";
import "./generalData.styles.css";

import Header from "../header/Header";
import Graphics from "../graphics/Graphics";

import {
  ResponsiveContainer,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

//Componentes propios
import GraficosSeleccion from "../seleccion/consolidado/graficas/graficas";
import BarrasSeleccion from "../seleccion/consolidado/barras/barras";
import TablaSeleccion from "../seleccion/consolidado/tabla/tabla";
import TortaSeleccion from "../seleccion/consolidado/torta/torta";

class GeneralData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title ? this.props.title : "Sodexo",
    };
  }

  goToDetail = () => {
    this.props.history.push("customer/cities");
  };

  render() {
    const data = [
      {
        subject: "Candidatos",
        A: 120,
        B: 110,
        fullMark: 150,
      },
      {
        subject: "Entrevistas",
        A: 98,
        B: 130,
        fullMark: 150,
      },
      {
        subject: "Pendientes contratación	",
        A: 86,
        B: 130,
        fullMark: 150,
      },
      {
        subject: "No continuan proceso	",
        A: 99,
        B: 100,
        fullMark: 150,
      },
      {
        subject: "Vinculados",
        A: 85,
        B: 90,
        fullMark: 150,
      },
      {
        subject: "Desvinculados",
        A: 65,
        B: 85,
        fullMark: 150,
      },
    ];

    const data2 = [
      {
        name: "Abr",
        vacantes: 5,
      },
      {
        name: "May",
        vacantes: 2,
      },
      {
        name: "Jun",
        vacantes: 0,
      },
      {
        name: "Jul",
        vacantes: 0,
      },
      {
        name: "Ago",
        vacantes: 3,
      },
      {
        name: "Sep",
        vacantes: 2,
      },
    ];

    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion" style={{ height: "100vh" }}>
          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <strong className="titleCiudad text-left">
                  {`Resumen General ${this.state.title}`}
                </strong>
              </div>
              <div className="col-4"></div>
              <div className="col-4 text-right">
                <button
                  className="btn btn-primary alibtn"
                  onClick={this.goToDetail}
                  style={{ marginRight: 10 }}
                >
                  Ver Detalle
                </button>
                <a
                  href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?client=${
                    this.props.idBussines !== undefined
                      ? this.props.idBussines
                      : 5
                  }`}
                  target="_blank"
                  style={{ display: "inline" }}
                >
                  <button
                    className="btn btn-primary alibtn"
                    onClick={this.download}
                  >
                    <i className="fas fa-download"></i> Descargar
                  </button>
                </a>
                <div></div>
              </div>
            </div>

            <div className="row ">
              <div className="col-12">
                <TablaSeleccion
                  idBussines={
                    this.props.idBussines !== undefined
                      ? this.props.idBussines
                      : 5
                  }
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-6">
                <h5 className="card-title">Candidatos x Ciudad y Genero</h5>
                <GraficosSeleccion
                  idBussines={
                    this.props.idBussines !== undefined
                      ? this.props.idBussines
                      : 5
                  }
                />
              </div>

              <div className="col-6">
                <h5 className="card-title">
                  Candidatos Remitidos x Genero y mes
                </h5>

                <BarrasSeleccion />
              </div>
              {/* <div className="col-4">
                <h5 className="card-title">Candidatos x Programa</h5>  
                  
                  <TortaSeleccion 
                    idBussines={this.props.idBussines !== undefined ? this.props.idBussines : 5}
                  />
                
              </div> */}
            </div>
            <div style={{ height: "30px" }}></div>
            {/* <div class="row">
            <div className="col-4">
            <h5 class="card-title">Vacantes x Mes</h5>  
            <ResponsiveContainer width='100%' aspect={4.0/3.0}>
              <AreaChart
                
                data={data2}
                margin={{
                  top: 10, right: 30, left: 0, bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="vacantes" stroke="#8884d8" fill="#204d6a" />
              </AreaChart>  
                </ResponsiveContainer>
            </div>
            <div className="col-4">
            <h5 class="card-title">Candidatos x Estado</h5>  
            <ResponsiveContainer width='100%' aspect={4.0/3.0}>
                <RadarChart  data={data}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis />
                <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#204d6a" fillOpacity={0.6} />
              </RadarChart>
              </ResponsiveContainer>
                </div>
                <div className="col-4"></div>
                </div> */}

            
            <div style={{ height: "130px" }}></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GeneralData;
