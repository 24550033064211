import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCandidateByID, getAllCandidates, add_Candidate, get_all_agencies, get_all_programs } from '../../../../redux/actions/candidateActions';
import './viewHojaVida.css';
import { uploadSingleImage } from '../../../../services/uploadFile';
import { Redirect } from 'react-router-dom';
import { getAllCities } from '../../../../redux/actions/citiesActions';
import { getAllAllys } from '../../../../redux/actions/allyActions';
import { getAllClient } from '../../../../redux/actions/clientsActions';

import swal from 'sweetalert';

class ViewHojaVidaAcompanamiento extends Component {

    constructor(props){
        super(props);
        this.state = this.setInitialData();
    }

    componentDidMount = () => {   
        
        this.props.getAllCities();
        this.props.getAllAllys();
        this.props.getAllClient();
        this.props.get_all_agencies();
        this.props.get_all_programs();
        this.props.getAllCandidates();
        console.log("updates", this.props);  
    }

    componentDidUpdate = () => {
        // console.log("update", this.props);
    }

    setInitialData = () => {
        try{
            var candidateId = this.props.match.params.candidate;
            var findsCandidate = this.props.getCandidateByID(candidateId); // .results.filter(el => el.id == candidateId)[0];
            var findCandidate = this.props.setCandidate;
            var defaultId = '';
            var allyType = 0;

            return{
                admissionDate: "",
                birthDate: findCandidate.birthDate,
                age: findCandidate.age,
                fullName: findCandidate.fullName,
                document: findCandidate.document,
                militaryCard: findCandidate.militaryCard,
                phone: findCandidate.phone,
                email: findCandidate.email,
                gender: findCandidate.gender,
                cellphone: findCandidate.cellphone,
                cellphoneTwo: findCandidate.cellphoneTwo,
                scholarship: findCandidate.scholarship,
                city: findCandidate.idCity,
                direction: findCandidate.direction,
                neighborhood: findCandidate.neighborhood,
                commune: findCandidate.commune,
                program: findCandidate.program,
                profilePicture: findCandidate.profilePicture,
                candidateState: findCandidate.candidateState,
                candidateSubstate: findCandidate.candidateSubstate,
                personalPresentation: findCandidate.personalPresentation,
                emotionalManagement: findCandidate.emotionalManagement,
                findings: findCandidate.findings,
                txtFindings: findCandidate.txtFindings,
                documentationFiles: findCandidate.documentationFiles,
                idProgram: findCandidate.idProgram,
                slcPersonalPresentation: findCandidate.personalPresentation == true ? "cumple" : "no",
                slcEmotionalManagement: findCandidate.emotionalManagement == true ? "cumple" : "no",
                slcFindings: "no",
                disableFindings: true,
                disableMilitaryCard: false,
                positions: findCandidate.positions,
                documentArr: findCandidate.documentationFiles !== 'null' ? JSON.parse(findCandidate.documentationFiles) : [],
                imagePath: [],
                documents: '',
                whoIs: allyType
            }
        }catch{
            return{
                admissionDate: new Date(),
                birthDate: "",
                age: null,
                fullName: "",
                document: "",
                militaryCard: "",
                phone: "",
                email: "",
                gender: "",
                cellphone: "",
                cellphoneTwo: "",
                scholarship: "",
                city: "",
                direction: "",
                neighborhood: "",
                commune: "",
                program: null,
                profilePicture: {},
                candidateState: 0,
                candidateSubstate: 0,
                personalPresentation: false,
                emotionalManagement: false,
                findings: false,
                txtFindings: null,
                documentationFiles: null,
                idProgram: defaultId,
                slcPersonalPresentation: "",
                slcEmotionalManagement: "",
                slcFindings: "no",
                disableFindings: true,
                disableMilitaryCard: false,
                positions: [{
                    positionName: "",
                    companyApply:""
                }],
                documentArr: [{
                    docName: "",
                    path: []
                }],
                imagePath: [],
                documents: '',
                whoIs: allyType
            }
        }
    }

    handleSelect = (e) => {
        this.setState({slcPersonalPresentation: e.target.value});
    }

    handleSelect2 = (e) => {
        this.setState({slcEmotionalManagement: e.target.value});
    }

    handleSelect3 = (e) => {
        this.setState({slcFindings: e.target.value});
        if(this.state.disableFindings){
            this.setState({disableFindings: false});
        } else {
            this.setState({disableFindings: true});
        }
    }

    handleChange = (e) => {
        const newState = this.state;
        newState[e.target.id] = e.target.value;
        this.setState(newState);
        
        if(this.state.gender === "F"){
            this.setState({disableMilitaryCard: true, militaryCard: 0});
        } else {
            this.setState({disableMilitaryCard: false});
        }
    }

    handleChangePositionsObj = (e, key, i) => {
        const newState = this.state;    
        newState[e.target.id][i][key] = e.target.value;
        this.setState(newState);
    }

    handleChangeFile = e => {
        if (e.target.files[0]) {
            const imageFile = e.target.files[0];
            this.setState({
                imagePath: imageFile
            });
        }
    };

    handleChangeMultiFile = (e, key, i) => {
        if(e.target.files[0]) {
            const imageFile = e.target.files[0];
            this.state.documentArr[i][key] = imageFile
            // this.setState({
            //     documentArr: values
            // });
        }
    }

    addDocuments = () => { 
        var tempDocs = this.state.documentArr;
        tempDocs.push({
            docName: "",
            path: []
        });
        this.setState({
            documentArr: tempDocs
        });
    }

    rmDocuments = (item, index) => {
        var tempDocs = this.state.documentArr;
        var i = tempDocs.indexOf(item);
        if (i !== -1){
            tempDocs.splice(i, 1);
            this.setState({
                documentArr: tempDocs
            });
        }
    }

    addPositions = () => {
        // console.log(this.state.positions);
        var tempPosition = this.state.positions;
        tempPosition.push({
            positionName: "",
            companyApply:""
        });
        this.setState({
            positions: tempPosition
        });
    }

    rmPositions = (item, index) => {
        if(this.state.positions.length > 1) {
            let tempPositions = this.state.positions;
            let i = tempPositions.indexOf(item);
            if (i !== -1){
                tempPositions.splice(i, 1);
                this.setState({
                    Positions: tempPositions
                });
            }
        }
    }

    onSend = async() => {
        // Validacion del selector presentacion personal
        if(this.state.slcPersonalPresentation === "cumple") {
            this.setState({personalPresentation: true})
        }

        // Validacion del selector manejo emocional
        if(this.state.slcEmotionalManagement === "cumple") {
            this.setState({emotionalManagement: true})
        }

        if(this.state.documentArr.length !== 0 && this.state.documentArr[0].docName !== '') {
            var documents = [];
            this.state.documentArr.forEach(async(item, index) => {
                var data = await uploadSingleImage(item.path);
                documents.push({
                    docName: item.docName,
                    url: data.file
                })

                if(this.state.documentArr.length === documents.length){
                    this.setState({
                        documentationFiles: documents
                    });
                    this.saveDataCandidate();
                }
            });
        }else{
            this.saveDataCandidate();
        }
    };

    saveDataCandidate = () => {
        uploadSingleImage(this.state.imagePath).then((data) => {
            var objCandidate = {
                admissionDate: parseInt(new Date().getTime() / 1000),
                birthDate: this.state.birthDate,
                age: this.calculateAge(this.state.birthDate),
                fullName: this.state.fullName,
                document: this.state.document,
                militaryCard: this.state.militaryCard,
                phone: this.state.phone,
                email: this.state.email,
                gender: this.state.gender,
                cellphone: this.state.cellphone,
                cellphoneTwo: this.state.cellphoneTwo,
                scholarship: this.state.scholarship,
                idCity: this.state.city,
                direction: this.state.direction,
                neighborhood: this.state.neighborhood,
                commune: this.state.commune,
                program: this.state.program,
                profilePicture: 'data.file',
                candidateState: this.state.candidateState,
                candidateSubstate: this.state.candidateSubstate,
                personalPresentation: this.state.personalPresentation,
                emotionalManagement: this.state.emotionalManagement,
                findings: this.state.findings,
                txtFindings: this.state.txtFindings,
                documentationFiles: JSON.stringify(this.state.documentationFiles),
                idProgram: this.state.idProgram,
                positionsArr: this.state.positions
            }
            // console.log(objCandidate)
            this.props.add_Candidate(objCandidate);
        });
    }

    confirmSave = () => {
        swal('El candidato se guardo correctamente.');
        this.setInitialData();
    }

    calculateAge = (birth) => {
        var today = new Date();
        var birthDate = new Date(birth);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }


    render(){
        return(
            <div className="backgroundHojaVida">
                {/* { this.props.isSaveCandidate === true ? <Redirect to='/products' /> : ''} */}
                { this.props.isSaveCandidate === true ? this.confirmSave() : '' }
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    
                                </button>
                            </h5>
                        </div>

                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                            <div className="card-body">
                                
                                <form>

                                    <div className="row">

                                        
                                        <div className="col-6">
                                                <div className="form-group row containerFoto">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <label htmlFor="exampleFormControlFile1">Foto Perfil</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 containerFile">
                                                        <input 
                                                            type="file" 
                                                            accept="image/x-png,image/jpeg"
                                                            className="form-control-file" 
                                                            id="profilePicture" 
                                                            onChange={this.handleChangeFile} 
                                                        />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <img src={this.state.profilePicture} width="150px" height="150px" />
                                                    </div>
                                                </div>
                                                {/* <div className="form-group row">
                                                    <label className="col-sm-2 col-form-label">Fecha de registro</label>
                                                    <div className="col-sm-10">
                                                        <p>{this.state.admissionDate}</p>
                                                    </div>
                                                </div> */}
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Nombre Completo</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa tu nombre completo" 
                                                            id="fullName" 
                                                            value={this.state.fullName}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Genero</label>
                                                    <div className="col-sm-10">
                                                        <select 
                                                            className="form-control"  
                                                            id="gender"
                                                            value={this.state.gender}
                                                            disabled
                                                            onChange={this.handleChange}
                                                        >
                                                            <option value="" selected disabled>Seleccione el genero</option>
                                                            <option value="M">Masculino</option>
                                                            <option value="F">Femenino</option>
                                                            <option value="No">No binario</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Telefono Fijo</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa un telefono fijo" 
                                                            id="phone" 
                                                            value={this.state.phone}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Cédula</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa tu numero de identificación" 
                                                            id="document" 
                                                            value={this.state.document}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Libreta Militar</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa tu numero de libreta militar" 
                                                            id="militaryCard" 
                                                            value={this.state.militaryCard}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Celular</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa tu numero de celular" 
                                                            id="cellphone"
                                                            value={this.state.cellphone}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Celular 2</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa otro numero de celular" 
                                                            id="cellphoneTwo" 
                                                            value={this.state.cellphoneTwo}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Formación</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa tu nivel de formación" 
                                                            id="scholarship" 
                                                            value={this.state.scholarship}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Ciudad</label>
                                                    <div className="col-sm-10">
                                                        <select
                                                            className="form-control"
                                                            id="city"
                                                            value={this.state.city}
                                                            disabled
                                                            onChange={this.handleChange}
                                                        >
                                                            <option value="" selected disabled>Seleccione tu ciudad</option>
                                                        |   {this.props.citiesData.length != 0 ? this.props.citiesData.results.map((item, index) => { 
                                                                return <option key={index.toString()} value={item.url}>{item.name}</option>}) : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Dirección</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa tu dirección" 
                                                            id="direction" 
                                                            value={this.state.direction}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Barrio</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa tu barrio" 
                                                            id="neighborhood" 
                                                            value={this.state.neighborhood}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Comuna</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa tu comuna" 
                                                            id="commune" 
                                                            value={this.state.commune}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Correo</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Ingresa tu correo electronico" 
                                                            id="email" 
                                                            value={this.state.email}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Fecha Nacimiento</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="date" 
                                                            required pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                                                            className="form-control" 
                                                            placeholder="" 
                                                            id="birthDate" 
                                                            value={this.state.birthDate}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Edad</label>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="" 
                                                            id="age" 
                                                            value={this.state.age}
                                                            disabled
                                                            onChange={this.handleChange}/>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Agencia Empleo</label>
                                                    <div className="col-sm-10">
                                                        <select 
                                                            className="form-control" 
                                                            id={this.state.whoIs === 0 ? "idProgram" : "program" }
                                                            onChange={this.handleChange}
                                                            disabled
                                                            value={this.state.whoIs === 0 ? this.state.idProgram : this.state.program}
                                                        >
                                                            <option value="" selected disabled>Seleccione una agencia</option>
                                                            <option value={null}>No aplica</option>
                                                            {this.props.agencies.length != 0 ? this.props.agencies.results.map((item, index) => { return <option key={index.toString()} value={item.url}>{item.fullName}</option>}) : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Programa</label>
                                                    <div className="col-sm-10">
                                                        <select 
                                                            className="form-control" 
                                                            id={this.state.whoIs === 1 ? "idProgram" : "program" }
                                                            onChange={this.handleChange}
                                                            disabled
                                                            value={this.state.whoIs === 1 ? this.state.idProgram : this.state.program}
                                                        >
                                                            <option value="" selected disabled>Seleccione un programa</option>
                                                            <option value={null}>No pertenece</option>
                                                        |   {this.props.programs.length != 0 ? this.props.programs.results.map((item, index) => { 
                                                                return <option key={index.toString()} value={item.url}>{item.fullName}</option>}) : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row containerPlus"
                                                    onClick={() => this.addPositions()}
                                                >
                                                    <a className="btnPlus">
                                                        <img src={require('../../../../assets/Icons/plus.png')} alt="Agregar" className="iconPlus" />
                                                    </a>
                                                </div>
                                                {this.state.positions.map((item, index)=>{
                                                    return(
                                                        <div className="containerDinamic" key={index.toString()}>
                                                            <div className="row containerPlus"
                                                                onClick={() => this.rmPositions(item, index)}
                                                            >
                                                                <a className="btnPlus">
                                                                    <img src={require('../../../../assets/Icons/close.png')} alt="Eliminar" className="iconPlus" />
                                                                </a>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-12 col-form-label">Cargo a Postular</label>
                                                                <div className="col-sm-10">
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control" 
                                                                        placeholder="Ingresa el cargo a postularte"
                                                                        value={item.positionName}
                                                                        disabled
                                                                        onChange={(e) => this.handleChangePositionsObj(e, 'positionName', index)} 
                                                                        id="positions"/>
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-12 col-form-label">Empresa a Postular</label>
                                                                <div className="col-sm-10">
                                                                    <select 
                                                                        className="form-control" 
                                                                        value={item.companyApply}
                                                                        disabled
                                                                        onChange={(e) => this.handleChangePositionsObj(e, 'companyApply', index)}
                                                                        id="positions"
                                                                    >
                                                                        <option selected value="" disabled>Seleccione un cliente y/o negocio</option>
                                                                        {this.props.clientData.length != 0 ? this.props.clientData.results.map((item, index) => { return <option key={index.toString()} value={item.url}>{item.fullName}</option>}) : null}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Presentacion Personal</label>
                                                    <div className="col-sm-10">
                                                        <select 
                                                            className="form-control" 
                                                            id="slcPersonalPresentation" 
                                                            value={this.state.slcPersonalPresentation} 
                                                            disabled
                                                            onChange={this.handleSelect}
                                                        >
                                                            <option value="" selected disabled>Seleccione una opcion</option>
                                                            <option value="cumple">Cumple</option>
                                                            <option value="no">No Cumple</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Manejo Emocional</label>
                                                    <div className="col-sm-10">
                                                        <select 
                                                            className="form-control" 
                                                            id="slcEmotionalManagement"
                                                            disabled
                                                            value={this.state.slcEmotionalManagement} 
                                                            onChange={this.handleSelect2}
                                                        >
                                                            <option value="" selected disabled>Seleccione una opcion</option>
                                                            <option value="cumple">Cumple</option>
                                                            <option value="no">No Cumple</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label">Hallazgos Juridicos</label>
                                                    <div className="col-sm-10">
                                                        <select 
                                                            className="form-control" 
                                                            id="slcFindings" 
                                                            onChange={this.handleSelect3}
                                                            value={this.state.findings}
                                                            disabled
                                                        >
                                                            <option value="no">No</option>
                                                            <option value="si">Si</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* 
                                                <div className="form-group row">
                                                    <div className="col-sm-2"></div>
                                                    <div className="col-sm-10">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="" 
                                                            disabled={this.state.disableFindings} 
                                                            id="txtFindings"/>
                                                    </div>
                                                </div>
                                                */}
                                                <div className="form-group row">
                                                    <label className="col-12" htmlFor="exampleFormControlTextarea1">Conceptos</label>
                                                    <div className="col-sm-10">
                                                        <textarea 
                                                            className="form-control" 
                                                            id="exampleFormControlTextarea1" 
                                                            rows="3" 
                                                            id="timestamp">
                                                        </textarea>
                                                    </div>
                                                </div>
                                                

                                            </div>

                                        {/*------------------ DIVISION DEL FORM  ------------------------*/} 
                                        
                                        <div className="col-6">
                                    <div className="form-group row">
                                        <label className="col-12" htmlFor="exampleFormControlTextarea1">Hallazgos de alianza</label>
                                        <div className="col-sm-10">
                                        <select 
                                                className="form-control" 
                                                id="slcFindings">
                                                <option value="no">No</option>
                                                <option value="si">Si</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-12" htmlFor="exampleFormControlTextarea1">Causa Real</label>
                                        <div className="col-sm-10">
                                            <textarea 
                                                className="form-control" 
                                                id="exampleFormControlTextarea1" 
                                                rows="3" 
                                                id="timestamp">
                                            </textarea>
                                        </div>
                                    </div>

                                    <hr />                                    
                                    <div className="form-group row">
                                        <label className="col-12" htmlFor="exampleFormControlTextarea1">Hallazgos de alianza</label>
                                        <div className="col-sm-10">
                                        <select 
                                                className="form-control" 
                                                id="slcFindings">
                                                <option value="no">No</option>
                                                <option value="si">Si</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-12" htmlFor="exampleFormControlTextarea1">Causa Real</label>
                                        <div className="col-sm-10">
                                            <textarea 
                                                className="form-control" 
                                                id="exampleFormControlTextarea1" 
                                                rows="3" 
                                                id="timestamp">
                                            </textarea>
                                        </div>
                                    </div>

                                    <hr />  
                                    <div className="form-group row">
                                        <label className="col-12" htmlFor="exampleFormControlTextarea1">Contesto a entrevista con Alianza</label>
                                        <div className="col-sm-10">
                                        <input type="radio"
                                                className="form-control" 
                                                id="slcFindings" /> Si
                                        <input type="radio"
                                                className="form-control" 
                                                id="slcFindings" /> No
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-12" htmlFor="exampleFormControlTextarea1">Entrevistador</label>
                                        <div className="col-sm-10">
                                        <select 
                                                className="form-control" 
                                                id="slcFindings">
                                                <option value="no">No</option>
                                                <option value="si">Si</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-12" htmlFor="exampleFormControlTextarea1">Fecha de la entrevista</label>
                                        <div className="col-sm-10">
                                        <input type="date"
                                                className="form-control" 
                                                id="slcFindings" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-12" htmlFor="exampleFormControlTextarea1">Conceptos</label>
                                        <div className="col-sm-10">
                                            <textarea 
                                                className="form-control" 
                                                id="exampleFormControlTextarea1" 
                                                rows="3" 
                                                id="timestamp">
                                            </textarea>
                                        </div>
                                    </div>





                                    <div className="row containerPlus"
                                        onClick={() => this.addDocuments()}
                                    >
                                        <a className="btnPlus">
                                            <img src={require('../../../../assets/Icons/plus.png')} alt="Agregar" className="iconPlus" />
                                        </a>
                                    </div>
                                    {this.state.documentArr.map((item, index) => {
                                        return(
                                            <div className="form-group row containerArchivos" key={index.toString()}>
                                                <label className="col-12" htmlFor="exampleFormControlFile1">Subir Archivos</label>
                                                <div className="col-sm-10 containerFileDocuments">
                                                    <div 
                                                        className="contentCloseDocuments"
                                                        onClick={() => this.rmDocuments(item, index)}
                                                    >
                                                        <a className="btnPlus">
                                                            <img src={require('../../../../assets/Icons/close.png')} alt="Eliminar" className="iconPlus" />
                                                        </a>
                                                    </div>
                                                    <div className="row contentDocumentos">
                                                        <div className="col-sm-6">
                                                            <select 
                                                                className="form-control" 
                                                                id="documentArr"
                                                                value={item.docName}
                                                                disabled
                                                                onChange={(e) => this.handleChangePositionsObj(e, 'docName', index)}
                                                            >
                                                                <option value="" disabled selected>Selecciona archivo</option>
                                                                <option value="ncedula">Cédula</option>
                                                                <option value="hojaVida">Hoja de vida</option>
                                                                <option value="Wartegg">Wartegg</option>
                                                                <option value="psicometrica">Prueba Psicometrica</option>
                                                                <option value="prueba">Prueba de lectoescritura</option>
                                                                <option value="consentimiento">Consentimiento informado</option>
                                                                <option value="consentimiento">Datos personales</option>
                                                                <option value="policía">Policía</option>
                                                                <option value="Procuraduría">Procuraduría</option>
                                                                <option value="Contraloría">Contraloría</option>
                                                                <option value="Validación institución aliada">Validación institución aliada</option>
                                                                <option value="otro">Otro</option>
                                                            </select>
                                                        </div>
                                                    
                                                        <div className="col-sm-6">
                                                            <a href={item.url} target="blank" style={{color: '#000'}}>Ver documento</a>
                                                        </div>
                                                        {/* <div className="col">
                                                            <button className="btnUpload">Añadir</button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    

                                    </div>

                                    

                                    </div>






                                    <hr />  

                                    
                                    
                                    <div className="col-12 containerBtnEnviar">
                                        <button className="btn-success btnEnviar" onClick={() => this.update()}>
                                            ACTUALIZAR
                                        </button>
                                    </div> 
                                    
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    console.log(state.candidateReducer.candidates);
    return {
        isLoading: state.candidateReducer.isLoading,
        setCandidate: state.candidateReducer.candidate,
        setCandidates: state.candidateReducer.candidates,
        programs: state.candidateReducer.programs,
        agencies: state.candidateReducer.agencies,
        isSaveCandidate: state.candidateReducer.isSaveCandidate,
        citiesData: state.citiesReducer.citiesData,
        allAllys: state.allyReducer.allAllys,
        clientData: state.clientsReducer.clientData,
        allyData: state.allyReducer.allyData        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCandidateByID: bindActionCreators(getCandidateByID, dispatch),
        getAllCandidates: bindActionCreators(getAllCandidates, dispatch),
        add_Candidate: bindActionCreators(add_Candidate, dispatch),
        getAllCities: bindActionCreators(getAllCities, dispatch),
        getAllAllys: bindActionCreators(getAllAllys, dispatch),
        getAllClient: bindActionCreators(getAllClient, dispatch),
        get_all_agencies: bindActionCreators(get_all_agencies, dispatch),
        get_all_programs: bindActionCreators(get_all_programs, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewHojaVidaAcompanamiento);