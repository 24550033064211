import { ISLOADING, SET_TRACEABILITY } from '../constants/action-type';

const getInitialState = () =>({
  isLoading: false,
  isSave: false,
  traceability: []
});

const traceabilityReducer = (state = getInitialState(), action) => {
  switch(action.type) {
    case ISLOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_TRACEABILITY:
      return { ...state, traceability: action.traceability };
    default:
      return state;
  }
}

export default traceabilityReducer;