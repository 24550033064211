import React, { Component, Fragment } from "react";
import "./resumen.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import { countAllStatesGender } from '../../../redux/actions/selectionActions';


class ResumenSeleccion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enEntrevistas: 0,
      enVinculados: 0,
      enAprobados: 0,
      enNoContinuanProceso: 0,
      enNoCitados: 0,
      enDesvinculados: 0,
  };
}

componentDidMount = async() => {
  await this.props.countAllStatesGender();
  this.setInitialData();
};

setInitialData = () => {
  try{
      var entrevistas = this.props.getSummaryState.filter(el => el.estado == "Entrevistas")[0];
      var vinculados = this.props.getSummaryState.filter(el => el.estado == "Vinculados")[0];
      var aprobados = this.props.getSummaryState.filter(el => el.estado == "Aprobados")[0];
      var noContinuanProceso = this.props.getSummaryState.filter(el => el.estado == "No continuan proceso")[0];
      var desvinculados = this.props.getSummaryState.filter(el => el.estado == "Desvinculados")[0];
      var noCitados = this.props.getSummaryState.filter(el => el.estado == "No citados")[0];

      this.setState({
          enEntrevistas: entrevistas.total,
          enVinculados: vinculados.total,
          enAprobados: aprobados.total,
          enNoContinuanProceso: noContinuanProceso.total,
          enNoCitados: noCitados.total,
          enDesvinculados: desvinculados.total
      })
  }catch(err){
      console.log('error', err);
  }
}

  render() {
    const idAlly = this.props.match.params.ally;

    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Resumen Modulo de Seleccion
                </strong>
              </p>
            </div>  

          </div>
        </div>

        <div className="col-12 backgroundCiudadesResumenSeleccion">
          

          <div className="container-fluid bgResumenSeleccion">
            <div className="col-12 table-responsiveResumen containerResumenSeleccion">
              <table className="table tableResumenSeleccion">
                <thead>
                  <tr id="tr">
                    <th scope="col" className="titleTable">
                      Estados
                    </th>
                    <th scope="col" className="titleTable">
                      Genero
                    </th>
                    <th scope="col" className="titleTable">
                      Total
                    </th>
                    <th scope="col" className="titleTable">
                      Opcion
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="tr">
                    <td className="titleItemRow">Aprobados</td>
                    <td className="titleItemRow"></td>
                    <td className="titleItemRow">{this.state.enAprobados}</td>
                    <td className="titleItemRow">
                      <div className="text-center">

                      <Link to={`/seleccion/aprobados-detalle/${idAlly}/11`}>
                        <i className="far fa-eye text-info"></i>
                        
                      </Link>
                      </div>
                    </td>
                  </tr>
                  <tr id="tr">
                    <td className="titleItemRow">No contesto</td>
                    <td className="titleItemRow"></td>
                    <td className="titleItemRow">{this.state.enNoCitados}</td>
                    <td className="titleItemRow">
                      <Link to={`/seleccion/no-contestaron/${idAlly}/3`}>
                      <i className="far fa-eye text-info"></i>

                      </Link>
                    </td>
                  </tr>
                  <tr id="tr">
                    <td className="titleItemRow">Entrevista</td>
                    <td className="titleItemRow"></td>
                    <td className="titleItemRow">{this.state.enEntrevistas}</td>
                    <td className="titleItemRow">
                      <Link to={`/seleccion/entrevista/${idAlly}/5`}>
                      <i className="far fa-eye text-info"></i>

                      </Link>
                    </td>
                  </tr>
                  {/* Estos 3 modelos no estaán creados */}
                  <tr id="tr">
                    <td className="titleItemRow">No continua proceso</td>
                    <td className="titleItemRow"></td>
                    <td className="titleItemRow">{this.state.enNoContinuanProceso}</td>
                    <td className="titleItemRow">
                      <Link to={`/seleccion/no-continua-proceso/${idAlly}/8`}>
                      <i className="far fa-eye text-info"></i>

                      </Link>
                    </td>
                  </tr>
                  <tr id="tr">
                    <td className="titleItemRow">Vinculados</td>
                    <td className="titleItemRow"></td>
                    <td className="titleItemRow">{this.state.enVinculados}</td>
                    <td className="titleItemRow">
                      <Link to={`/seleccion/vinculados/${idAlly}/10`}>
                      <i className="far fa-eye text-info"></i>

                      </Link>
                    </td>
                  </tr>
                  <tr id="tr">
                    <td className="titleItemRow">Desvinculados</td>
                    <td className="titleItemRow"></td>
                    <td className="titleItemRow">{this.state.enDesvinculados}</td>
                    <td className="titleItemRow">
                      <Link to={`/seleccion/desvincular/${idAlly}/9`}>
                      <i className="far fa-eye text-info"></i>

                      </Link>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      isLoading: state.selectionReducer.isLoading,
      getSummaryState: state.selectionReducer.getSummaryState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      countAllStatesGender: bindActionCreators(countAllStatesGender, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumenSeleccion);
