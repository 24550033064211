import React, { Component, Fragment } from "react";
import "./entrevista.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import { getAllSelectionByState } from "../../../redux/actions/selectionActions";
import NotificacionAprendiz from "../notificaciones/notificaciones";

class EntrevistaPracticante extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var obj = {
      all_id: 3,
      idSubState: 5,
    };
    this.props.getAllSelectionByState(obj);
  };

  componentDidUpdate = () => {
    // console.log('Entrevistas', this.props);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 containerTitleSeleccion mb-5 text-left">
          <p>
            <strong className="titleSeguimiento text-left">Clientes</strong>
          </p>
          <p className="subtitleSeguimiento">Listado de clientes</p>
        </div>
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12 mb-3">
            {/*  <NotificacionAprendiz />*/}
            {/* <div className="alert alert-danger">
              Enviar a entrevista a Pedro Ruiz a las 8:00am de 22/Agosto
            </div> */}
          </div>

          <div>
            <button className="btnEnviarRelacionamientoAliados btn-info mr-3">
              <Link
                to={`/practicante/practicante/asignarEntrevista`}
                style={{ textDecoration: "none" }}
              >
                <strong>Asignar Entrevistas</strong>
              </Link>
            </button>
            <button className="btnEnviarRelacionamientoAliados btn-info">
              <strong>Actualizar Entrevistas</strong>
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.selectionReducer.isLoading,
    getSelectionByState: state.selectionReducer.getSelectionByState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllSelectionByState: bindActionCreators(
      getAllSelectionByState,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntrevistaPracticante);
