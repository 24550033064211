import React, { Component } from "react";
import {
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import Header from "../../header/Header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getEmprendConsolidado } from "../../../redux/actions/summaryActions";
import {
  searchEmprendimientos,
  setSearchEmpren,
} from "../../../redux/actions/emprendActions";
import { getEmprendiAlerts } from "../../../redux/actions/alertActions";
import "./graphicsEmprend.styles.css";

//Componentes Propios
import GraficosSeleccion from "../../seleccion/consolidado/graficas/graficas";
import BarrasSeleccion from "../../seleccion/consolidado/barras/barras";
import TablaSeleccion from "../../seleccion/consolidado/tabla/tabla";
import { Link } from "react-router-dom";
import CustomAlertEmprendimiento from "../../alerts/alertsEmpren";

class GraphicsEmprend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      openModal: false,
    };
  }

  componentDidMount = () => {
    this.props.getEmprendConsolidado();
    this.props.getEmprendiAlerts(new Date());
  };

  searchInfo = () => {
    this.toggleModal();
    this.props.searchEmprendimientos(this.state.searchText);
  };

  viewEmprendimiento = (idEmp) => {
    this.props.history.push(
      `/${this.props.modulePath}/detalleEmprendimiento/${idEmp}`
    );
  };

  closeClean = () => {
    this.toggleModal();
    this.props.setSearchEmpren([]);
  };

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  searchModal = () => {
    return (
      <Modal isOpen={this.state.openModal} toggle={this.closeClean} size={"lg"}>
        <ModalHeader toggle={this.closeClean}>Resultados búsqueda</ModalHeader>
        <ModalBody>
          {this.props.searchEmprend.length !== 0 && (
            <div>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nombre completo</th>
                    <th>Sector</th>
                    <th>Ciudad</th>
                    <th>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.searchEmprend.results.map((item) => {
                    return (
                      <tr key={item.id.toString()}>
                        <th scope="row">{item.id}</th>
                        <td>{item.fullName}</td>
                        <td>{item.sector}</td>
                        <td>{item.city}</td>
                        <td>{item.stateName}</td>
                        <td
                          onClick={() => {
                            this.viewEmprendimiento(item.id);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className="fa fa-eye"
                            style={{ color: "#17a2b8" }}
                            aria-hidden="true"
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.closeClean}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    return (
      <>
        <Header />
        {this.searchModal()}
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div
              className="alert alert-danger"
              role="alert"
              style={{ display: "none" }}
            >
              Victimas nacionales ha ingresado 5 nuevos candidatos remitidos
            </div>
          </div>
          <div>
            <div style={{ position: "absolute", zIndex: 99, right: 10 }}>
              {this.props.alertRelacionamiento.results.length !== 0 && (
                <CustomAlertEmprendimiento
                  color="info"
                  msg={`Se han recibido ${this.props.alertRelacionamiento.results.length} nuevos emprendimientos`}
                  data={this.props.alertRelacionamiento.results}
                  history={this.props.history}
                  modulePath={this.props.modulePath}
                />
              )}
            </div>
            <div className="customRow mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Resumen Modulo Emprendimientos
                </strong>
              </p>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <FormGroup style={{ margin: 0, paddingLeft: 20 }}>
                  <Input
                    className="txtSearch"
                    type="text"
                    name="search"
                    placeholder="Buscar Emprendimiento(s)..."
                    onChange={(e) =>
                      this.setState({
                        searchText: e.target.value,
                      })
                    }
                    style={{ width: 400 }}
                  />
                </FormGroup>
                <button
                  className="btn btn-info ml-2"
                  onClick={() => this.searchInfo()}
                >
                  <strong id="btnNew">Buscar </strong>
                </button>
              </div>
            </div>

            {/*   <div className="row">
              <div className="col-6">
                <GraficosSeleccion idBussines={3} />
              </div>

              <div className="col-6">
                <BarrasSeleccion />
              </div>
            </div>
 */}
            <div className="row">
              <div className="col-3">
                <p className="subtitleSeguimiento">Resumen: </p>
              </div>

              <div
                className="col-9"
                style={{ textAlign: "right", paddingRight: 26 }}
              >
                <button className="btn btn-info" style={{ marginRight: 26 }}>
                  <Link
                    to={`/${this.props.modulePath}/sectores`}
                    style={{ color: "white" }}
                  >
                    Ver x Producto
                  </Link>
                </button>
                <button className="btn btn-info">
                  <Link
                    to={`/${this.props.modulePath}/departments`}
                    style={{ color: "white" }}
                  >
                    Ver x Ciudades
                  </Link>
                </button>
              </div>
            </div>
            <div className="row  bgConsolidadoSeleccion">
              <div className="table-responsiveGraphics containerTable">
                <table className="table ">
                  <thead>
                    <tr id="tr">
                      <th>Estado</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  {this.props.emprendTotal.length !== 0 && (
                    <tbody>
                      <tr id="tr">
                        <td>No Activo</td>
                        <td>
                          {this.props.emprendTotal.consolidado.find(
                            (el) => el.emprendState == 0
                          ) !== undefined
                            ? this.props.emprendTotal.consolidado.find(
                                (el) => el.emprendState == 0
                              ).total
                            : 0}
                        </td>
                      </tr>

                      <tr id="tr">
                        <td>Activo</td>
                        <td>
                          {this.props.emprendTotal.consolidado.find(
                            (el) => el.emprendState == 1
                          ) !== undefined
                            ? this.props.emprendTotal.consolidado.find(
                                (el) => el.emprendState == 1
                              ).total
                            : 0}
                        </td>
                      </tr>

                      {/*    <tr id="tr">
                        <td>Aprobados por Alianza</td>
                        <td>{this.props.emprendTotal.consolidado.find(el => el.emprendState == 2) !== undefined ? this.props.emprendTotal.consolidado.find(el => el.emprendState == 2).total : 0}</td>
                      </tr>

                      <tr id="tr">
                        <td>Rechazados</td>
                        <td>{this.props.emprendTotal.consolidado.find(el => el.emprendState == 3) !== undefined ? this.props.emprendTotal.consolidado.find(el => el.emprendState == 3).total : 0}</td>
                      </tr> */}
                    </tbody>
                  )}
                </table>
              </div>

              <br />
              <br />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modulePath: state.allyReducer.modulePath,
    emprendTotal: state.summariesReducer.emprendTotal,
    alertRelacionamiento: state.alertsReducer.alertRelacionamiento,
    alertRemitidos: state.alertsReducer.alertRemitidos,
    searchEmprend: state.emprendimientoReducer.searchEmprend,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmprendConsolidado: bindActionCreators(getEmprendConsolidado, dispatch),
    getEmprendiAlerts: bindActionCreators(getEmprendiAlerts, dispatch),
    searchEmprendimientos: bindActionCreators(searchEmprendimientos, dispatch),
    setSearchEmpren: bindActionCreators(setSearchEmpren, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphicsEmprend);
