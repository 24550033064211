// MANAGERS
export const LOGIN_GESTORES="MANAGER AUTH";
export const GET_ALL_MANAGERS = "CONSULTA_LISTADO_GESTORES";

// EMPRENDIMIENTOS
export const GET_EMPRENDIMIENTOS="CONSULTAR_EMPRENDIMIENTOS";
export const ADD_EMPRENDIMIENTO="AGREGAR_EMPRENDIMIENTOS";
export const EDIT_EMPRENDIMIENTO="ACTUALIZAR_EMPRENDIMIENTOS";
export const GET_EMPRENDIMIENTOS_BY_ALLY="CONSULTAR_EMPRENDIMIENTO_POR_ALIADO";
export const GET_TOTAL_EMPRENDIMIENTO="CONSULTAR_CONSOLIDADO_EMPRENDIMIENTOS";
export const GET_COURSES = "CONSULTAR_CURSOSTALLERS";
export const ADD_COURSES = "AGREGAR_CURSOSTALLERES";
export const SEARCH_EMPRENDIMIENTO = "BUSCAR_EMPRENDIMIENTO";

// CANDIDATES
export const GET_CANDIDATES="CONSULTAR LISTA DE CANDIDATOS";
export const GET_CANDIDATE="CONSULTAR CANDIDATO POR ID";
export const ADD_CANDIDATE="AGREGAR CANDIDATO";
export const GET_VACANTE="CONSULTAR VACANTES";
export const GET_CLIENT="CONSULTAR LOS CLIENTES";
export const GET_ALL_PROGRAMS="CONSULTAR LISTADO DE PROGRAMAS REGISTRADOS EN ALIANZA";
export const GET_ALL_AGENCIES="CONSULTAR LISTADO DE AGENCIAS DE EMPLEO REGISTRADAS EN ALIANZA";
export const GET_ALL_CANDIDATES_BY_ALLY="CONSULTAR CANDIDATOS POR ID DEL ALIADO O PROGRAMA";
export const GET_CANDIDATES_REENTRY="CONSULTAR CANDIDATOS CON SOLICITUD REINGRESO";
export const UPDATE_STATUS_CANDIDATES= "ACTUALIZAR EL ESTADO DE UN CANDIDATO"; 
export const SET_VISIBLE_TABS = "PASAR_PERMISOS_DE_VER_INFORMACION_CANDIDATO";
export const GET_CANDIDATES_BY_BUSINESS = "CONSULTAR_CANDIDATOS_POR_NEGOCIOS";
export const GET_CANDIDATES_BY_GROUP = "CONSULTAR_CANDIDATOS_POR_GRUPO";
export const GET_CONTRACT_INFO = "CONSULTAR_INFORMACION_CONTRATO";

// CLIENTS
export const LOGIN_CLIENTES="CUSTOMER AUTH";
export const ADD_VACANTE="AGREGAR VACANTE";
export const GET_BUSINESS_BY_CLIENT="CONSULTAR TODOS LOS NEGOCIOS POR ID DEL CLIENTE";
export const GET_LINKED = "CONSULTAR_CANDIDATOS_VINCULADOS";
export const SAVETRACING = "SAVE_TRACING";
export const SAVETRACING_NUEVO = "SAVE_TRACING NUEVO";
export const GET_TRACING_CANDIDATE = "CONSULTAR_SEGUIMIENTOS_CLIENTE";
export const GET_TRACING_CANDIDATE_NUEVO = "CONSULTAR_SEGUIMIENTOS_CLIENTE_NUEVO";

// RELACIONAMIENTO
export const ADD_CALENDAR_REGISTROS="AGREGAR CALENDARIO DE REGISTROS";
export const ADD_CALENDAR_SEGUIMIENTOS="AGREGAR CALENDARIO DE SEGUIMIENTOS";
export const GET_PROGRAM="CONSULTAR LOS PROGRAMAS";
export const GET_CALENDAR_REGISTROS="CONSULTAR LOS CRONOGRAMAS DE REGISTROS";
export const GET_CALENDAR_FOLLOW="CONSULTAR LOS CRONOGRAMAS DE SEGUIMIENTOS";
export const GET_TIMELINE_BY_ALLY="CONSULTAR LOS CRONOGRAMAS DEL ALIADO PARA REGISTROS";
export const GET_CITIES_BY_BUSINESS = "CONSULTAR_CIUDADES_POR_NEGOCIO";
export const SET_BUSINESS = "PASAR_NEGOCIOS_DE_CIUDAD";

// EXTERNALS
export const LOGIN_ALIADO="LOGIN DE ALIADOS EXTERNOS";
export const LOGOUT_ALIADO="CERRAR SESION ALIADO EXTERNO";
export const GET_CALENDAR_EXTERNALS="CONSULTAR CALENDARIO DE REGISTRO PARA ALIADOS EXTERNOS";
export const GET_CANDIDATES_EXTERNALS="CONSULTAR LISTA DE CANDIDATOS PARA ALIADOS EXTERNOS";
export const PUT_ALLYDATA="ACTUALIZAR INFORMACION DEL ALIADO";
export const GET_CANDIDATES_BY_ALLY="CONSULTAR LOS CANDIDATOS POR ALIADOS";
export const GET_ALL_ALLYS="CONSULTAR TODOS LOS ALIADOS EXTERNOS";
export const GET_ALLYS_BY_CITY="CONSULTAR LOS ALIADOS Y PROGRAMAS POR CIUDADES";

// GENERAL
export const ISLOADING="ISLOADING";
export const PAGE_COUNT="NUMBER_OF_PAGES";
export const ISSAVE="ISSAVE";
export const SEARCH_CANDIDATE="CONSULTAR CANDIDATO BUSCADO";
export const GET_CITIES="CONSULTAR CIUDADES";
export const ADD_CONSULTANCIES="AGREGAR ASESORIA";
export const GET_CONSULTANCIES="CONSULTAR TODAS LAS ASESORIAS";
export const GET_CONSOLIDATED="CONSULTAR_CONSOLIDADO_ASESORIAS";
export const GET_RESUM_GENDER_BY_BUSINESS = "CONSULTAR_TOTALES_GENERO_NEGOCIO";
export const SET_MODULE_PATH = "CONFIGURAR_PATH_RUTAS";
export const GET_REMISSIONS = "CONSULTAR_REMISIONES";
export const ADD_REMISSIONS = "AGREGAR_REMISIONES";
export const SET_CLIENT_MENU = "GUARDAR_INFO_CLIENTE_MENU";

// BUSINESS
export const GET_BUSINESS = "CONSULTAR TODOS LOS NEGOCIOS";
export const GET_SELECTION_STATE="CONSULTAR TODOS LOS NEGOCIOS";

// SELECCIÓN
export const CREATE_INTERVIEW = "CREAR ENTREVISTAS";
export const GET_INTERVIEWS = "CONSULTAR TODAS LAS ENTREVISTAS";
export const GET_INTERVIEWS_ID = "CONSULTAR TODAS LAS ENTREVISTAS POR ID";
export const GET_SUMMARY_STATE = "LISTA DE RESUMEN";
export const GET_FINDING = "LISTAR HALLAZGOS";
export const CREATE_FINDING = "CREAR UN HALLAZGO";
export const UPDATE_FINDING = "ACTUALIZAR UN HALLAZGO";

// ACOMPAÑAMIENTO
export const CREATE_ACCOMPANIMENT = "CREAR ACOMPAÑAMIENTO";
export const GET_ACCOMPANIMENT = "CONSULTAR TODAS LAS ACOMPAÑAMIENTO";
export const GET_WORKSHOPS = "CONSULTAR TODOS LOS TALLERES";
export const GET_WORKSHOP = "CONSULTAR TALLER POR ID";
export const ADD_WORKSHOP = "AGREGAR TALLER";
export const DELETE_WORKSHOP = "AGREGAR TALLER";
export const UPDATE_WORKSHOP = "ACTUALIZAR TALLER";
export const ADD_TRACING = "ADICIONAR SEGUIMIENTO";
export const ADD_TRACING_NUEVO = "ADICIONAR SEGUIMIENTO NUEVO";
export const GET_WORKSHOPS_BY_COLLABORATOR = "CONSULTAR_TALLERES_COLABORADOR";
export const SAVE_ASSISTANCE = "GUARDAR_ASISTENCIA";
export const GET_WORKSHOPSFOCUS = "CONSULTAR TALLERES FOCO";
export const ADD_WORKSHOPFOCUS = "AGREGAR TALLERES FOCO";

// RESUMENES
export const GET_TOTAL_SUMMARY = "CONSULTAR_TOTALES_GLOBALES";

// Entrevistas
export const GET_INTERVIEWS_BY_TYPE = "CONSULTAR_ENTREVISTAS_SEGUN_TIPO";

//Trazabilidad
export const GET_SUMMARY_TRACING = "CONSULTAR_CONSOLIDADO_SEGUIMIENTOS";
export const GET_SUMMARY_TRACING_NUEVO = "CONSULTAR_CONSOLIDADO_SEGUIMIENTOS_NUEVO";
export  const SET_TRACEABILITY = "ALMACENAR_TRAZABILIDAD";

//Alertas
export const FIND_ALERT = "ENCONTRAR_ALERTAS";
export const FIND_REMITIDOS = "ENCONTRAR_REMITIDOS";