import React, { Component, Fragment } from 'react';
import './cronogramas.styles.css';
import Header from '../../header/Header';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllProgram,add_timelines_follow } from '../../../redux/actions/timelinesActions';
import { getAllCities } from '../../../redux/actions/citiesActions';
import { Redirect } from 'react-router-dom';

class AddSeguimientos extends Component {
    constructor(props) {
        super(props);
        this.state={
            city: '',
            initDate: '',
            hour: '',
            typeTimeline: 0,
            idProgram: '',
        }
    }

    componentDidMount = () => {
        this.props.getAllProgram();
        this.props.getAllCities();
        this.setState({typeTimeline: sessionStorage.getItem('timelines')});
    };

    handleChange = (e) => {
        const newState = this.state;
        newState[e.target.id] = e.target.value;
        this.setState(newState);
    }

    onSaveFollow = () => {
        var obj = {
            city: this.state.city,
            initDate: this.state.initDate,
            hour: this.state.hour,
            typeTimeline: this.state.typeTimeline,
            idProgram: this.state.idProgram
        }
        console.log('OBJETO', obj);
        this.props.add_timelines_follow(obj);
    }

    render() {
        return(
            <Fragment>
            <Header />
            <div className="bachgroundAddCalendar">
                {this.props.isSave === true ? <Redirect to={`/${this.props.modulePath}/calendar`} /> : ''}

                <div className="customRow mb-5 text-left">
                    <p>
                        <strong className="titleSeguimiento text-left">Nuevo Cronograma de Seguimiento</strong>
                    </p>
                </div>

               

                <div className="containerFormularioRegistros">
                    <form className="formularioAddCalendar">
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Aliado Externo</label>
                            <div className="col-sm-9">
                                <select 
                                    className="form-control"  
                                    id="idProgram"
                                    onChange={this.handleChange}
                                >
                                    <option value="" selected disabled>Selecciona el aliado externo</option>
                                    {this.props.programData.length != 0 ? this.props.programData.results.map((item, index) => { return <option value={item.url}>{item.fullName}</option>}) : null}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Ciudad</label>
                            <div className="col-sm-9">
                                <select 
                                    className="form-control"  
                                    id="city"
                                    onChange={this.handleChange}
                                >
                                    <option selected value="" disabled >Seleccione Ciudad</option>
                                    {this.props.citiesData.length != 0 ? this.props.citiesData.results.map((item, index) => { return <option value={item.url}>{item.name}</option>}) : null}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Fecha</label>
                            <div className="col-sm-9">
                                <input 
                                    type="date" 
                                    className="form-control"
                                    id="initDate"
                                    onChange={this.handleChange}    
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Hora</label>
                            <div className="col-sm-9">
                                <input 
                                    type="text" 
                                    className="form-control"
                                    id="hour"
                                    onChange={this.handleChange}  
                                    />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="containerBtnEnviarRegistros">
                    <button className="btnEnviarRegistro btn-info" onClick={() => this.onSaveFollow()}>Enviar</button>
                </div>
            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.candidateReducer.isLoading,
        programData: state.timelinesReducer.programData,
        citiesData: state.citiesReducer.citiesData,
        timelinesData2: state.timelinesReducer.timelinesData2,
        isSave: state.timelinesReducer.isSave,
        modulePath: state.allyReducer.modulePath
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllProgram: bindActionCreators(getAllProgram, dispatch),
        getAllCities: bindActionCreators(getAllCities, dispatch),
        add_timelines_follow: bindActionCreators(add_timelines_follow, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSeguimientos);