import { ISLOADING, ADD_CONSULTANCIES, GET_REMISSIONS, GET_CONSULTANCIES, GET_CONSOLIDATED, ADD_REMISSIONS, PAGE_COUNT } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

import swal from 'sweetalert';

export const isLoading = bool => ({
  type: ISLOADING,
  isLoading: bool
});

export const addConsultancies = consultanciesData => ({
  type: ADD_CONSULTANCIES,
  consultanciesData
});

export const getConsultancies = consultanciesData2 => ({
  type: GET_CONSULTANCIES,
  consultanciesData2
});

export const setConsolidated = consolidate => ({
  type: GET_CONSOLIDATED,
  consolidate
});

export const setRemissions = remissionsData => ({
  type: GET_REMISSIONS,
  remissionsData
});

export const setIsSave = bool => ({
  type: ADD_REMISSIONS,
  isSave: bool
});

export const setCount = pageCount => ({
  type: PAGE_COUNT,
  pageCount
});

export const add_consultancies = (obj) => {
    return async(dispatch) => {
      dispatch(isLoading(true));
      try{
        const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
          headers: {'Content-Type': 'application/json'}
        });
        const token = await reponseToken.data.token;
        const responseTimelines = await axios.post(`${urls.url_base}/QXNlc29yaWFz/`, obj, {headers: {'Authorization': `JWT ${token}`}});
        const data = await responseTimelines.data;
        if (data.length !== 0) {
          dispatch(addConsultancies([]));
          return data;
        } else {
          dispatch(addConsultancies([]));
          swal('Upss! correo y / o contraseña incorrectos!');
        }  
      }catch (err) {
        alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
    }
  }

export const getAllConsultancies = () => {
  return async(dispatch) => {
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/QXNlc29yaWFz/`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          if (data.results.length !== 0) {
            dispatch(getConsultancies(data));
          } else {
            dispatch(getConsultancies([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getRemissionSended = (page=1) => {
  return async(dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(`${urls.url_base}/c3BlY2lhbA/?page=${page}`, {headers: {'Authorization': `JWT ${token}`}});
      const data = await responseCandidates.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setRemissions(data));
        dispatch(setCount(count));
      } else {
        dispatch(setRemissions([]));
        swal('No hay información disponible');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const addRemission = (obj) => {
  return async(dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.post(`${urls.url_base}/c3BlY2lhbA/`, obj, {headers: {'Authorization': `JWT ${token}`}});
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(setIsSave(true));
        swal('La remisión fue enviada correctamente!');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const updateRemissionInfo = (id, obj) => {
  return async(dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.put(`${urls.url_base}/c3BlY2lhbA/${id}/`, obj, {headers: {'Authorization': `JWT ${token}`}});
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(setIsSave(true));
        swal('Información actualizada correctamente!');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getAllConsultanciesByCandidate = (id) => {
  return async(dispatch) => {
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/QXNlc29yaWFz/?candidate=${id}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          if (data.results.length !== 0) {
            dispatch(addConsultancies(data));
            dispatch(setIsSave(false))
            return data;
          } else {
            dispatch(addConsultancies([]));
            swal('Lo sentimos, no se encuentran asesorias para esta persona.');
            return []
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getManagerConsultancies = (id, page=1) => {
  return async(dispatch) => {
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/QXNlc29yaWFz/?gestor=${id}&page=${page}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          const count = data.count;
          if (data.results.length !== 0) {
            dispatch(getConsultancies(data));
            dispatch(setCount(count));
          } else {
            dispatch(getConsultancies([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getConsolidated = () => {
  return async(dispatch) => {
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/dG90YWxB/`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          if (data.length !== 0) {
            dispatch(setConsolidated(data));
          } else {
            dispatch(setConsolidated([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}