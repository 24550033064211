import React, { Component, Fragment } from "react";
import { FormGroup, Input, Row, Col} from 'reactstrap';
import "./remisiones.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateRemissionInfo } from "../../../../redux/actions/consultanciesAction";
import { getAllCandidates } from "../../../../redux/actions/candidateActions";
import { Redirect } from "react-router-dom";
import SelectSearch from 'react-select-search';

import Header from "../../../header/Header";
import swal from "sweetalert";

class EditRemisiones extends Component {
  constructor(props) {
    super(props);
    this.state = this.setInitialState();
  }

  componentDidMount = () => {
    this.props.getAllCandidates();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if(prevProps.candidates !== this.props.candidates){
      let data = this.props.candidates.results;
      let optionsSelect = data.map((item) => {
        return{
          name: item.fullName,
          value: item.url
        }
      });
      this.setState({colaboradores: optionsSelect});
    }
  }

  setInitialState = () => {
    let id = this.props.match.params.remi;
    console.log('remi', id)
    if(this.props.remissionsData.length !== 0){
      let infoRemission = this.props.remissionsData.results.find(item => item.id === parseInt(id));
      console.log('info', infoRemission)
      if(infoRemission !== undefined){
        return {
          reason: infoRemission.reason,
          findings: infoRemission.findings !== "" ? infoRemission.findings : "",
          actionPlan: infoRemission.actionPlan,
          conclusions: infoRemission.conclusions,
          profesionalName: infoRemission.profesionalName,
          profesionalRegister: infoRemission.profesionalRegister,
          candidate: infoRemission.candidate.url,
          manager: infoRemission.manager.url,
          colaboradores: [],
          sessions: infoRemission.findings !== "" ? JSON.parse(infoRemission.findings) : [{
            date: "",
            hour: "",
            findings: ""
          }],
          isEdit: false
        };
      }
    }
    return {
      reason: "",
      findings: "",
      actionPlan: "",
      conclusions: "",
      profesionalName: "",
      profesionalRegister: "",
      candidate: null,
      manager: null,
      colaboradores: [],
      sessions: [{
        date: "",
        hour: "",
        findings: ""
      }],
      isEdit: false
    };
  };

  handleChange = (e) => {
    const newState = this.state;
    newState[e.target.id] = e.target.value;
    this.setState(newState);
  };

  addSessions = () => { 
    var tempSessions = this.state.sessions;
    tempSessions.push({
      date: "",
      hour: "",
      findings: ""
    });
    this.setState({
      sessions: tempSessions
    });
  }

  rmSessions = (item, index) => {
    var tempSessions = this.state.sessions;
    var i = tempSessions.indexOf(item);
    if (i !== -1){
      tempSessions.splice(i, 1);
      this.setState({
        sessions: tempSessions
      });
    }
  }

  handleChangeSelect = (e) => {
    let data = this.props.candidates.results.find(item => item.url === e);
    this.setState({
      candidate: e,
      candidateObj: data
    });
  }

  handleChangeObj = (e, key, i) => {
    const newState = this.state;    
    newState[e.target.id][i][key] = e.target.value;
    this.setState(newState);
}

  onSaveConsultancies = () => {
    let idRemission = this.props.match.params.remi;
    if (this.state.isEdit == false) {
      var obj = {
        findings: JSON.stringify(this.state.sessions),
        actionPlan: this.state.actionPlan,
        conclusions: this.state.conclusions,
        profesionalName: this.state.profesionalName,
        profesionalRegister: this.state.profesionalRegister,
        candidate: this.state.candidate,
        manager: this.props.allyData[0].managerData.url,
      };
      this.props.updateRemissionInfo(idRemission, obj);
    }
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Detalle Remisión
                </strong>
              </p>
            </div>
          </div>
        </div>

        <div className="bachgroundAddCalendar">
          {this.props.isSave === true ? (
            <Redirect to={`/${this.props.modulePath}/advisory-special`} />
          ) : (
            ""
          )}

          <div className="containerFormularioAsesoriaJefe">
            <form className="formularioAddAsesoria" style={{height: '50vh'}}>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Colaborador</p> </label>
                    <div className="col-sm-9">
                      <SelectSearch
                        search
                        options={this.state.colaboradores}
                        placeholder="Selecciona un colaborador"
                        id="candidate"
                        onChange={this.handleChangeSelect}
                        value={this.state.candidate}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Motivo de la consulta</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="reason"
                        value={this.state.reason}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Profesional</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="profesionalName"
                        value={this.state.profesionalName}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Registro profesional</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="profesionalRegister"
                        value={this.state.profesionalRegister}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <hr></hr>

              <Row>
                <Col xs="12" md="12" style={{textAlign: 'right', marginBottom: 10}}>
                  <button
                    className="btn btn-info"
                    onClick={this.addSessions}
                    type="button"
                  >+ Agregar</button>
                </Col>
                {this.state.sessions.map((item, index) => {
                  return(
                    <Col xs="12" md="6" key={index.toString()} style={{margin: '10px 0px'}}>
                      <p>Sesión {index+1}</p>
                      <div className="form-group row">
                        <label className="col-sm-3"><p className="labelForm">Fecha</p> </label>
                        <div className="col-sm-9">
                          <Input
                            type="date"
                            id="sessions"
                            value={item.date}
                            onChange={(e) => {this.handleChangeObj(e, 'date', index)}}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-3"><p className="labelForm">Hora</p> </label>
                        <div className="col-sm-9">
                          <Input
                            type="time"
                            id="sessions"
                            value={item.hour}
                            onChange={(e) => {this.handleChangeObj(e, 'hour', index)}}
                          />
                        </div>
                      </div>

                      {this.props.modulePath === 'acompanamiento' && <div className="form-group row">
                        <label className="col-sm-3"><p className="labelForm">Hallazgos</p> </label>
                        <div className="col-sm-9">
                          <Input
                            type="textarea"
                            id="sessions"
                            value={item.findings}
                            onChange={(e) => {this.handleChangeObj(e, 'findings', index)}}
                            rows={4}
                          />
                        </div>
                      </div>}
                    </Col>
                  )
                })}
              </Row>

              <hr></hr>

              {this.props.modulePath === 'acompanamiento' && <Row>
                <Col xs="12" md="6">
                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Plan de acción</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="textarea"
                        id="actionPlan"
                        onChange={this.handleChange}
                        rows={4}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="6">
                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Conclusiones</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="textarea"
                        id="conclusions"
                        onChange={this.handleChange}
                        rows={4}
                      />
                    </div>
                  </div>
                </Col>
              </Row>}
              
            </form>
          </div>
          <div className="containerBtnEnviarAsesorias">
            <button
              className="btn btn-info"
              style={{width: 200, backgroundColor: '#204d6a'}}
              onClick={() => this.onSaveConsultancies()}
            >
              {this.state.isEdit === false ? "Enviar" : "Actualizar"}
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.consultanciesReducer.isLoading,
    consultanciesData: state.consultanciesReducer.consultanciesData,
    candidates: state.candidateReducer.candidates,
    isSave: state.consultanciesReducer.isSave,
    consultanciesData2: state.consultanciesReducer.consultanciesData2,
    modulePath: state.allyReducer.modulePath,
    allyData: state.allyReducer.allyData,
    remissionsData: state.consultanciesReducer.remissionsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRemissionInfo: bindActionCreators(updateRemissionInfo, dispatch),
    getAllCandidates: bindActionCreators(getAllCandidates, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRemisiones);
