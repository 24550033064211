import React, { Component, Fragment } from "react";
import { FormGroup, Input, Row, Col, Button } from 'reactstrap';
import "./remisiones.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addRemission } from "../../../../redux/actions/consultanciesAction";
import { getAllCandidates, searchCandidateDoc } from "../../../../redux/actions/candidateActions";
import { Redirect } from "react-router-dom";
import SelectSearch from 'react-select-search';

import Header from "../../../header/Header";
import swal from "sweetalert";

class RemisionesAcompanamiento extends Component {
  constructor(props) {
    super(props);
    this.state = this.setInitialState();
  }

  componentDidMount = () => {
    this.props.getAllCandidates();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.candidates !== this.props.candidates) {
      let data = this.props.candidates.results;
      let optionsSelect = data.map((item) => {
        return {
          name: item.fullName,
          value: item.url
        }
      });
      this.setState({ colaboradores: optionsSelect });
    }
  }

  setInitialState = () => {
    return {
      remissionDate: "",
      reason: "",
      city: "",
      department: "",
      operation: "",
      findings: "",
      actionPlan: "",
      conclusions: "",
      profesionalName: "",
      profesionalRegister: "",
      candidate: null,
      manager: null,
      candidateObj: {
        positions: [{
          nameClient: "",
          nameBussiness: ""
        }],
        cellphone: "",
        cellphoneTwo: "",
        fullName: "",
        allyName: ""
      },
      colaboradores: [],
      isEdit: false,
      searchInput: ''
    };
  };

  handleChange = (e) => {
    const newState = this.state;
    newState[e.target.id] = e.target.value;
    this.setState(newState);
  };

  handleChangeSelect = (e) => {
    let data = this.props.candidates.results.find(item => item.url === e);
    if(data !== undefined){
      this.setState({
        candidate: e,
        candidateObj: data
      });
    }else{
      data = this.props.searchCandidate.results.find(item => item.url === e);
      this.setState({
        candidate: e,
        candidateObj: data
      })
    }
  }

  onSaveConsultancies = () => {
    if (this.state.isEdit == false) {
      if (this.state.duration !== "") {
        var obj = {
          remissionDate: this.state.remissionDate,
          reason: this.state.reason,
          city: this.state.city,
          department: this.state.department,
          operation: this.state.candidateObj.positions[0].nameBussiness,
          findings: "",
          actionPlan: "",
          conclusions: "",
          profesionalName: "",
          profesionalRegister: "",
          idCandidate: this.state.candidateObj.url,
          idManager: this.props.allyData[0].managerData.url,
        };
        this.props.addRemission(obj);
      } else {
        swal('Por favor ingresa todos los campos');
      }
    } else {
      console.log("Actualizacion info");
    }
  };

  searchCandidate = () => {
    if (this.state.searchInput.length !== 0) {
      this.props.searchCandidateDoc(this.state.searchInput).then(resp => {
        if (resp.length !== 0) {
          let optionsSelect = resp.map((item) => {
            return {
              name: item.fullName,
              value: item.url
            }
          });
          this.setState({ 
            colaboradores: optionsSelect,
            candidate: '',
          });
        }
      });
    } else {
      swal('Oops!', 'Por favor ingresa un nombre o documento para buscar.', 'warning')
    }
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Remitir colaborador
                </strong>
              </p>
            </div>
          </div>
        </div>

        <div className="bachgroundAddCalendar">
          {this.props.isSave === true ? (
            <Redirect to={`/${this.props.modulePath}/advisory-special`} />
          ) : (
              ""
            )}

          <div className="containerFormularioAsesoriaJefe">
            <form className="formularioAddAsesoria" style={{ height: '50vh' }}>
              <Row>
                <Col xs="12" md="6">
                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Colaborador</p> </label>
                    <div className="col-sm-9">
                      <div style={{flexDirection: 'row', alignItems: 'center', display: 'flex', marginBottom: 8, }}>
                        <Input
                          className="form-control"
                          id="searchInput"
                          value={this.state.searchInput}
                          onChange={this.handleChange}
                          placeholder="Buscar por documento o nombre"
                          style={{marginRight: 5}}
                        />
                        <Button
                          color="info"
                          type="button"
                          onClick={() => { this.searchCandidate() }}
                        >Buscar</Button>
                      </div>
                      <SelectSearch
                        search
                        options={this.state.colaboradores}
                        placeholder="Selecciona un colaborador"
                        id="candidate"
                        onChange={this.handleChangeSelect}
                        value={this.state.candidate}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Fecha de remisión</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="date"
                        id="remissionDate"
                        value={this.state.remissionDate}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Nombre completo</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="fullname"
                        value={this.state.candidateObj.fullName}
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Teléfonos</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="fullname"
                        value={`${this.state.candidateObj.cellphone} - ${this.state.candidateObj.cellphoneTwo}`}
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Motivo de la consulta</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="reason"
                        value={this.state.reason}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </Col>

                <Col xs="12" md="6">
                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Ciudad</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="city"
                        value={this.state.city}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Departamento</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="department"
                        value={this.state.department}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Programa</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="department"
                        value={this.state.candidateObj.allyName}
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Cliente</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="client"
                        value={this.state.candidateObj.positions[0].nameClient}
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3"><p className="labelForm">Operación</p> </label>
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        id="operation"
                        value={this.state.candidateObj.positions[0].nameBussiness}
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>
                  </div>
                </Col>
              </Row>

            </form>
          </div>
          <div className="containerBtnEnviarAsesorias">
            <button
              className="btn btn-info"
              style={{ width: 200, backgroundColor: '#204d6a' }}
              onClick={() => this.onSaveConsultancies()}
            >
              {this.state.isEdit === false ? "Enviar" : "Actualizar"}
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.consultanciesReducer.isLoading,
    consultanciesData: state.consultanciesReducer.consultanciesData,
    candidates: state.candidateReducer.candidates,
    isSave: state.consultanciesReducer.isSave,
    consultanciesData2: state.consultanciesReducer.consultanciesData2,
    modulePath: state.allyReducer.modulePath,
    allyData: state.allyReducer.allyData,
    searchCandidate: state.candidateReducer.searchCandidate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRemission: bindActionCreators(addRemission, dispatch),
    getAllCandidates: bindActionCreators(getAllCandidates, dispatch),
    searchCandidateDoc: bindActionCreators(searchCandidateDoc, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemisionesAcompanamiento);
