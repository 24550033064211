import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { createInterviewState } from '../../../redux/actions/selectionActions';
import { updatePositionsSubstates } from '../../../redux/actions/candidateActions';
import { bindActionCreators } from "redux";
import urls from '../../../apiConf';
import "./popUpOk.steles.css";


class ModalUpOk extends Component {
	constructor(props) {
        super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
			show: false,
        };
    }
    
	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        var obj = {
            idCandidate: urls.url_base+'/Q2FuZGlkYXRvcw/'+event.target.candidato.value+'/',
            dateInterview: event.target.fecha.value,
            hourInterview: event.target.hora.value,
            inCharge: event.target.destino.value
        }
        var traceObj = {
            action: `Citado a entrevista con la Alianza por ${this.validateIdentity().name}`,
            candidate: urls.url_base+'/Q2FuZGlkYXRvcw/'+event.target.candidato.value+'/',
            manager: this.validateIdentity().url
        }
        if(event.target.destino.value === '2'){
            obj.manager = this.props.allyData[0].managerData.url
        }
        this.props.createInterviewState(obj);
        this.props.updatePositionsSubstates({
            position: event.target.position.value,
            proccessSubstate: 2,
            idCandidate: urls.url_base+'/Q2FuZGlkYXRvcw/'+event.target.candidato.value+'/'
        }, false, traceObj);
        this.setState({ show: false });
    }

    validateIdentity = () => {
        if(this.props.allyData.length !== 0){
          let obj = {};
          let data = this.props.allyData[0];
          if(data.businessData){
            obj = {
              name: data.businessData.name,
              url: data.businessData.url
            }
          }else if(data.advisorData){
            obj = {
              name: data.advisorData.fullName,
              url: data.advisorData.url
            }
          }else if(data.managerData) {
            obj = {
              name: data.managerData.fullName,
              url: data.managerData.url
            }
          }
          return obj;
        }
      }

    formatDate(date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        return [year, month, day].join("-");
      }

	render() {
        const idCap = this.props.idCap;        
		return (
			<>
                <i className="fas fa-plus-circle text-success" onClick={this.handleShow}></i>
				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
					    <Modal.Title>
                            <div className="contentTitle">
                                <p style={{margin: 0}}>
                                    <b>Seleccionar fecha y hora para la entrevista.</b>
                                </p>
                            </div>
                        </Modal.Title>
					</Modal.Header>
                    <form onSubmit={this.handleSubmit}>
                        <Modal.Body>
                            <div className="row">
                                <input type="hidden" name="candidato" value={idCap}/>
                                <input type="hidden" name="position" value={this.props.position} />
                                <input type="hidden" name="oferta" value="0" />
                                <div className="col-6"> 
                                    <label>
                                        Fecha  
                                        <input type="date" className="form-control" name="fecha" min={this.formatDate(new Date())} />
                                    </label>                                    
                                </div>
                                <div className="col-6"> 
                                    <label>
                                        Hora
                                        <input type="time" className="form-control" name="hora" />
                                    </label>                                    
                                </div>
                                <div className="col-12">Seleccionar quien es el encargado de citar al candidato:  </div>
                                <br/>   
                                <hr/>
                                <div className="col-4">
                                    <input type="radio" value="0" name="destino" id="aprendiz" /> {" "}
                                    <label htmlFor="aprendiz"> Aprendiz</label>
                                </div>
                                <div className="col-4">
                                    <input type="radio" value="1" name="destino" id="practicante" /> {" "}
                                    <label htmlFor="practicante"> Practicante</label>
                                </div>
                                <div className="col-4">
                                    <input type="radio" value="2" name="destino" id="gestor" /> {" "}
                                    <label htmlFor="gestor"> Gestor</label>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Cerrar
                            </Button>
                            <Button type="submit" variant="primary">
                                Citar
                            </Button>
                        </Modal.Footer>
                    </form>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
    return {
        isLoading: state.selectionReducer.isLoading,
        createInterview: state.selectionReducer.addInterview,
        allyData: state.allyReducer.allyData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createInterviewState: bindActionCreators(createInterviewState, dispatch),
        updatePositionsSubstates: bindActionCreators(updatePositionsSubstates, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpOk);