import React, { Component, Fragment } from 'react';
import './notificaciones.style.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

export default class NotificacionAcompanamiento extends Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render() {
        return(   
            null 
            // <div className="alert alert-danger" role="alert">
            //     Tienes 5 notificaciones nuevas
            // </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(NotificacionAcompanamiento);