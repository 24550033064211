import React, { Component, Fragment } from "react";
import { Table, Row, Col } from 'reactstrap';
import "./candidatos.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import { getCandidatesByBusiness, setCandidate } from '../../../redux/actions/candidateActions';
import ModalUpOk from "../popUpOk/popUpOk";

function CandidateRow(props) {
  const candidate = props.candidate;
  const date = props.date;
  const module = props.module;

  return (
    <tr id="tr" key="">
      <td scope="row">{candidate.document}</td>
      <td>{candidate.fullName}</td>
      <td>{candidate.positions[0].nameVacancy}</td>
      <td>{candidate.positions[0].nameClient}</td>
      <td>{candidate.gender}</td>
      <td>{candidate.allyName}</td>
      <td>
        <Row style={{width: '100%'}}>
          <Col xs="6">
            <Link
              to={`/${module}/viewCandidate/${candidate.id}`}
              className="f-left"
            >
              <i className="fas fa-pencil-alt text-info"></i>
            </Link>
          </Col>
          {module !== 'relacionamiento' && <Col xs="6">
            <ModalUpOk
              className="f-left"
              idCap={candidate.id}
              position={candidate.positions[0].id}
            />
          </Col>}
        </Row>
      </td>
    </tr>
  )
}

class Candidatos extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  componentDidMount = () => {
    let idb = this.props.match.params.ally;
    let complement = `?ally=${idb}&substate=1`;
    this.props.getCandidatesByBusiness(complement);
    this.props.setCandidate([]);
  }

  goToDetail = () => {

  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">Candidatos</strong>
            </p>
            <p className="subtitleSeguimiento">
              Listado de candidatos
            </p>
          </div>
          
          <div className="table-responsiveAprobados containerResumenSeleccion">
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Cédula</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Cargo</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Genero</th>
                  <th scope="col">Programa / Agencia</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.candidatesBusiness.length !== 0 ? 
                  this.props.candidatesBusiness.results.map((item, index) => {
                    return (
                      <CandidateRow
                        key={index.toString()}
                        module={this.props.modulePath}
                        candidate={item}
                        date={this.formatDate(
                          parseInt(item.admissionDate) * 1000
                        )}
                        onEdit={() => this.goToDetail()}
                      />
                    )
                  })
                  : ''
                }
              </tbody>
            </Table>
          </div>
          
          <div className="row" style={{width: '100%'}}>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    candidatesBusiness: state.candidateReducer.candidatesBusiness,
    isLoading: state.candidateReducer.isLoading,
    modulePath: state.allyReducer.modulePath
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCandidatesByBusiness: bindActionCreators(getCandidatesByBusiness, dispatch),
    setCandidate: bindActionCreators(setCandidate, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Candidatos);
