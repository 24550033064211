import { GET_INTERVIEWS_BY_TYPE, ISLOADING, PAGE_COUNT } from '../constants/action-type';

const getInitialState = () => ({
  interviewList: [],
  isLoading: false,
  pageCount: 0
});

const interviewReducer = (state = getInitialState(), action) => {
  switch(action.type) {
    case ISLOADING:
      return { ...state, isLoading: action.isLoading };
    case PAGE_COUNT:
      return { ...state, pageCount: action.pageCount };
    case GET_INTERVIEWS_BY_TYPE:
      return { ...state, interviewList: action.interviewList, isLoading: false };
    default:
      return state;
  }
}

export default interviewReducer;