import React, { Component, Fragment } from "react";
import "./ciudades.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCities } from "../../../../redux/actions/citiesActions";

import Header from "../../../header/Header";
import { Link } from "react-router-dom";

class CiudadesResumenPracticante extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.getAllCities();
  };

  componentDidUpdate = () => {
    console.log("Ciudades", this.props);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12">
          <div className="backgroundCiudadesResumenPracticante">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleSeguimiento text-left">Ciudades</strong>
              </p>
              <p className="subtitleSeguimiento">Selecciona una opción</p>
            </div>

            <div className="row containerCiudadesResumenPracticante">
              {this.props.citiesData.length !== 0
                ? this.props.citiesData.results.map((item, index) => {
                    return (
                      <div className="col-2" key={index.toString()}>
                        <div className="itemCiudad">
                          <Link
                            to={`/practicante/practicante/filter/summary/cities/aliados/${item.id}`}
                          >
                            <h6>{item.name}</h6>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    citiesData: state.citiesReducer.citiesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCities: bindActionCreators(getAllCities, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CiudadesResumenPracticante);
