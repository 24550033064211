import { ISLOADING, GET_SELECTION_STATE, CREATE_INTERVIEW, GET_INTERVIEWS, GET_SUMMARY_STATE, GET_INTERVIEWS_ID, CREATE_FINDING, UPDATE_FINDING, GET_FINDING, PAGE_COUNT } from '../constants/action-type';

const getInitialState = () => ({
    allyData: [],
    timeAlly: [],
    getSummaryState: [],
    createInterview: [],
    isLoading: false,
    getSelectionByState: [],
    interviewByState: [],
    interviewByID: [],
    findings: [],
    allAllys: [],
    pageCount: 0
});

const selectionReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case PAGE_COUNT:
            return { ...state, pageCount: action.pageCount };
        case GET_SELECTION_STATE:
            return { ...state, getSelectionByState: action.getSelectionByState, isLoading: false };
        case CREATE_INTERVIEW:
            return { ...state, createInterview: action.createInterview, isLoading: false };
        case GET_INTERVIEWS:
            return { ...state, interviewByState: action.interviewByState, isLoading: false };
        case GET_INTERVIEWS_ID:
            return { ...state, interviewByID: action.interviewByID, isLoading: false };
        case GET_FINDING:    
            return { ...state, findings: action.findings, isLoading: false };
        case CREATE_FINDING:
            return { ...state, findings: action.findings, isLoading: false };
        case UPDATE_FINDING:
            return { ...state, findings: action.findings, isLoading: false };
        case GET_SUMMARY_STATE:
            return { ...state, getSummaryState: action.getSummaryState, isLoading: false };
        default:
            return state;
    }
}
export default selectionReducer;