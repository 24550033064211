import React, { Component, Fragment } from "react";
import { Button } from "reactstrap";
import "./resumenDetallado.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import NotificacionSeleccion from "./notificaciones/notificaciones";
import { setCandidateBus } from "../../../redux/actions/candidateActions";
import { getSummariesGenderProgram } from "../../../redux/actions/summaryActions";
import swal from "sweetalert";

function RowSeleccion(props) {
 const data = props.data;
 const modulePath = props.modulePath;
 const detail = props.detail;
 const idAlly = props.idAlly;
 const pstate = props.pstate;
 const consolidatos = props.consolidado;
 let link = "";

 if (detail == "entrevista") {
  link = `/${modulePath}/${detail}`;
 } else {
  link = `/${modulePath}/${detail}/${idAlly}/${pstate}`;
 }

 var itemAmount = {
  total: 0,
  hombres: 0,
  mujeres: 0,
  arn: 0,
  victimas: 0,
 };

 itemAmount = consolidatos.consolidado.find((el) => el.subState == data.sub);

 return (
  <tr key={data.sub.toString()}>
   <td>{data.name}</td>
   <td>
    {itemAmount !== undefined
     ? `${itemAmount.hombres} / ${itemAmount.mujeres}`
     : `0 / 0`}
   </td>
   <td>
    {itemAmount !== undefined
     ? `${itemAmount.programas} / ${itemAmount.agencias}`
     : `0 / 0`}
   </td>
   {/* <td>{itemAmount !== undefined ? `${itemAmount.arn} / ${itemAmount.victimas}` : `0 / 0`}</td> */}
   <td>{itemAmount !== undefined ? itemAmount.total : 0}</td>
   <td>
    <Link to={link}>
     <i className="far fa-eye text-info"></i>
    </Link>
   </td>
  </tr>
 );
}

class ResumenDetalladoSeleccion extends Component {
 constructor(props) {
  super(props);
  this.state = {
   consolidadosPrograma: [
    {
     name: "Remitidos",
     state: 1,
     sub: 1,
     detail: "remitidos",
    },
    {
     name: "Entrevista",
     state: 1,
     sub: 2,
     detail: "entrevista",
    },
    {
     name: "No citados",
     state: 1,
     sub: 3,
     detail: "no-citados",
    },
    {
     name: "No asistieron",
     state: 1,
     sub: 4,
     detail: "no-asistieron",
    },
    {
     name: "No continuan proceso",
     state: 1,
     sub: 5,
     detail: "no-continua-proceso",
    },
    {
     name: "No pasaron proceso",
     state: 1,
     sub: 6,
     detail: "no-pasaron-proceso",
    },
    {
     name: "Pendientes",
     state: 1,
     sub: 7,
     detail: "pendientes",
    },
    {
     name: "Aprobados por la Alianza",
     state: 1,
     sub: 8,
     detail: "aprobados",
    },
   ],
   enRemitidos: {
    total: 0,
    totalF: 0,
    totalM: 0,
   },
   enCandidatos: {
    total: 0,
    totalF: 0,
    totalM: 0,
   },
   enAprobados: {
    total: 0,
    totalF: 0,
    totalM: 0,
   },
   enNoPasaronProceso: {
    total: 0,
    totalF: 0,
    totalM: 0,
   },
   enNoCitados: {
    total: 0,
    totalF: 0,
    totalM: 0,
   },
   enNoAsistieron: {
    total: 0,
    totalF: 0,
    totalM: 0,
   },
   enPendientes: {
    total: 0,
    totalF: 0,
    totalM: 0,
   },
  };
 }

 componentDidMount = async () => {
  let id = this.props.match.params.ally;
  await this.props.getSummariesGenderProgram(id);
  this.setInitialData();
  this.props.setCandidateBus([]);
 };

 setInitialData = () => {
  try {
   var remitidos = this.props.summaryGender.find(
    (el) => el.estado == "Remitidos"
   );
   var candidatos = this.props.summaryGender.find(
    (el) => el.estado == "Candidatos"
   );
   var aprobados = this.props.summaryGender.find(
    (el) => el.estado == "Aprobados"
   );
   var noPasaronProceso = this.props.summaryGender.find(
    (el) => el.estado == "No pasaron proceso"
   );
   var noAsistieron = this.props.summaryGender.find(
    (el) => el.estado == "Asistieron"
   );
   var pendientes = this.props.summaryGender.find(
    (el) => el.estado == "Pendientes"
   );
   var noCitados = this.props.summaryGender.find(
    (el) => el.estado == "No citados"
   );

   this.setState({
    enRemitidos: remitidos,
    enCandidatos: candidatos,
    enAprobados: aprobados,
    enNoPasaronProceso: noPasaronProceso,
    enNoCitados: noCitados,
    enNoAsistieron: noAsistieron,
    enPendientes: pendientes,
   });
  } catch (err) {
   console.log("error", err);
  }
 };

 render() {
  const idAlly = this.props.match.params.ally;
  return (
   <Fragment>
    <Header />
    <div className="container-fluid bgResumenDetalladoSeleccion">
     <div className="customRow mb-5 text-left">
      <p>
       <strong className="titleSeguimiento text-left">Resumen Selección</strong>
      </p>
     </div>
     <div className="col-12">
      <NotificacionSeleccion />
     </div>
     <div style={{ textAlign: "right", marginBottom: 10 }}>
      <a
       href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?partner=${this.props.match.params.ally}`}
       target="_blank"
       style={{ display: "inline" }}>
       <Button color="info">Descargar</Button>
      </a>
     </div>
     <div className="col-12 table-responsiveAprobados containerResumenDetalladoSeleccion">
      <table className="table tableResumenDetalladoSeleccion">
       <thead>
        <tr>
         <th
          scope="col"
          className="titleTable">
          Estados
         </th>
         <th
          scope="col"
          className="titleTable">
          Genero (M/F)
         </th>
         <th
          scope="col"
          className="titleTable">
          Programa / Agencias
         </th>
         {/* <th scope="col" className="titleTable">ARN / Victimas</th> */}
         <th
          scope="col"
          className="titleTable">
          Total
         </th>
         <th
          scope="col"
          className="titleTable">
          Opcion
         </th>
        </tr>
       </thead>
       <tbody>
        {this.props.summaryGender.length !== 0
         ? this.state.consolidadosPrograma.map((item, index) => {
            return (
             <RowSeleccion
              key={index.toString()}
              data={item}
              modulePath={this.props.modulePath}
              detail={item.detail}
              idAlly={idAlly}
              pstate={"0"}
              consolidado={this.props.summaryGender}
             />
            );
           })
         : null}
        {/* <tr id="tr">
										<td className="titleItemRow">Remitidos</td>
										<td className="titleItemRow">
										<p>{`${this.state.enRemitidos.totalM} / ${this.state.enRemitidos.totalF}`}</p>
										</td>
										<td className="titleItemRow">
										<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enRemitidos.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/remitidos/${idAlly}/0`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr>

								<tr id="tr">
										<td className="titleItemRow">Aprobados por la Alianza</td>
										<td className="titleItemRow">
												<p>{`${this.state.enAprobados.totalM} / ${this.state.enAprobados.totalF}`}</p>
										</td>
										<td className="titleItemRow">
												<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enAprobados.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/aprobados/${idAlly}/11`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr>

								<tr id="tr">
										<td className="titleItemRow">No asistieron</td>
										<td className="titleItemRow">
												<p>{`${this.state.enNoAsistieron.totalM} / ${this.state.enNoAsistieron.totalF}`}</p>
										</td>
										<td className="titleItemRow">
												<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enNoAsistieron.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/asistieron/${idAlly}/3`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr>

								<tr id="tr">
										<td className="titleItemRow">No citados</td>
										<td className="titleItemRow">
												<p>{`${this.state.enNoCitados.totalM} / ${this.state.enNoCitados.totalF}`}</p>
										</td>
										<td className="titleItemRow">
												<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enNoCitados.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/no-citados/${idAlly}/2`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr>

								<tr id="tr">
										<td className="titleItemRow">No continuan proceso</td>
										<td className="titleItemRow">
												<p>{`${this.state.enNoPasaronProceso.totalM} / ${this.state.enNoPasaronProceso.totalF}`}</p>
										</td>
										<td className="titleItemRow">
												<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enNoPasaronProceso.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/no-pasaron-proceso/${idAlly}/7`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr>

								<tr id="tr">
										<td className="titleItemRow">No pasaron proceso</td>
										<td className="titleItemRow">
												<p>{`${this.state.enNoPasaronProceso.totalM} / ${this.state.enNoPasaronProceso.totalF}`}</p>
										</td>
										<td className="titleItemRow">
												<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enNoPasaronProceso.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/no-pasaron-proceso/${idAlly}/7`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr>
								
								<tr id="tr">
										<td className="titleItemRow">Pendientes</td>
										<td className="titleItemRow">
												<p>{`${this.state.enPendientes.totalM} / ${this.state.enPendientes.totalF}`}</p>
										</td>
										<td className="titleItemRow">
												<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enPendientes.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/pendientes/${idAlly}/4`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr>


								<tr id="tr">
										<td className="titleItemRow">Candidatos</td>
										<td className="titleItemRow">
										<p>{`${this.state.enCandidatos.totalM} / ${this.state.enCandidatos.totalF}`}</p>
										</td>
										<td className="titleItemRow">
										<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enCandidatos.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/candidatos/${idAlly}/1`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr>

								<tr id="tr">
										<td className="titleItemRow">Asistieron</td>
										<td className="titleItemRow">
												<p>{`${this.state.enNoAsistieron.totalM} / ${this.state.enNoAsistieron.totalF}`}</p>
										</td>
										<td className="titleItemRow">
												<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enNoAsistieron.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/asistieron/${idAlly}/3`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr>

								<tr id="tr">
										<td className="titleItemRow">Entrevista alianza</td>
										<td className="titleItemRow">
												<p>{`${this.state.enNoPasaronProceso.totalM} / ${this.state.enNoPasaronProceso.totalF}`}</p>
										</td>
										<td className="titleItemRow">
												<p>0/0</p>
										</td>
										<td className="titleItemRow">
												{this.state.enNoPasaronProceso.total}
										</td>
										<td>
												<Link to={`/${this.props.modulePath}/no-pasaron-proceso/${idAlly}/7`}>
												<i className="far fa-eye text-info"></i> 
												</Link>
										</td>
								</tr> */}
       </tbody>
      </table>
     </div>
    </div>
   </Fragment>
  );
 }
}

const mapStateToProps = (state) => {
 return {
  isLoading: state.selectionReducer.isLoading,
  summaryGender: state.summariesReducer.summaryGender,
  modulePath: state.allyReducer.modulePath,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  getSummariesGenderProgram: bindActionCreators(
   getSummariesGenderProgram,
   dispatch
  ),
  setCandidateBus: bindActionCreators(setCandidateBus, dispatch),
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(ResumenDetalladoSeleccion);
