import React, { Component, Fragment } from 'react';
import './aliadosExternos.styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCandidatesByAlly } from '../../../redux/actions/candidateActions';
import { Link } from 'react-router-dom';
import Header from '../../header/Header';

import swal from 'sweetalert';

function CandidateRow(props) {
    const candidate = props.candidate;
    const date = props.date;
    const state = props.candidateState;
    console.log(candidate)
    return(
        <tr key={candidate.id.toString()}>
            <td>
                <input type="checkbox" onChange={(e) => props.onChangeCheck(e)}/>
            </td>
            <td>{candidate.document}</td>
            <td>{candidate.fullName}</td>
            <td>{candidate.positions.length != 0 ? candidate.positions[0].positionName : "N/A"}</td>
            <td>{candidate.positions.length != 0 ? candidate.positions[0].nameClient : "N/A"}</td>
            <td>N/A</td>
            {/* <td>{candidate.program}</td> */}
            <td>{date}</td>
            <td>
                <Link to={`/relacionamiento/viewCandidate/${candidate.id}`}>
                    <img src={require('../../../assets/IconAliadosExternos/hv.png')} alt="Hoja de vida" className="iconHojaVida" ></img>
                </Link>
            </td>
        </tr>
    )
}

class AliadosExternosSeleccion extends Component {
    constructor(props) {
        super(props);
        this.state={
            fullName: '',
            states: [
                'En proceso',
                'Retirados',
                'Vinculados',
                'Aprobados'
            ],
            substates: [
                'Remitidos',
                'Candidatos',
                'No citados',
                'Asistieron',
                'Pendientes',
                'Entrevistas',
                'Pendientes de contratacion',
                'No pasaron proceso',
                'No continuan proceso',
                'Desvinculados',
                'Vinculados',
                'Aprobados'
            ],
            program: [
                'Agencia',
                'Programa'
            ]
        };
    }

    componentDidMount = () => {
        var idAlly = this.props.match.params.ally;
        this.props.getCandidatesByAlly(idAlly);
    }

    changeValidateChange = (e, check) => {
        this.setState({
            fullName: e.fullName
        });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    sendToSelection = () => {
        alert(`El candidato ${this.state.fullName} fue enviado a selección correctamente.`);
    }


    render() {
        return(
            <Fragment>
            <Header />
            <div className="backgroundAliadosExternosSeleccion">
                <div className="row containerHeader">
                    <div className="col-2 containerInbox">
                        <Link className="btnInbox" to={`/relacionamiento/cities/aliados/remitted/:ally/removed`}>
                            <img src={require('../../../assets/IconAliadosExternos/inbox.png')} alt="Inbox" className="iconInbox" />
                        </Link>
                    </div>
                    <div className="col-10 containerTitle">
                        <h6>LISTADO DE LOS CANDIDATOS REMITIDOS</h6>
                    </div>
                </div>

                <div className="table-responsive containerTable">
                    <table className="table tableRelacionamientoAliados">
                        <thead>
                            <tr>
                            <th scope="col">Todos<input type="checkbox" /></th>
                            <th scope="col">Cédula</th>
                            <th scope="col">Nombre Completo</th>
                            <th scope="col">Cargo</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Negocio</th>
                            {/* <th scope="col">Programa</th> */}
                            <th scope="col">Fecha Ingreso</th>
                            <th scope="col">Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {this.props.candidatesAlly.length != 0 ? this.props.candidatesAlly.results.map((candidate, index) => {
                                return(
                                    <CandidateRow
                                        candidate={candidate}
                                        key={index.toString()}
                                        date={this.formatDate(parseInt(candidate.admissionDate)*1000)}
                                        candidateState={this.state.program[candidate.candidateState]}
                                        onChangeCheck={(check) => this.changeValidateChange(candidate, check)}
                                    />
                                )
                            }) : null }
                        </tbody>
                    </table>
                </div>

                {/* <div className="containerBoton">
                    <button className="btnEnviarRelacionamientoAliados" onClick={() => this.sendToSelection()}>
                        ENVIAR A PROCESO DE SELECCION
                    </button>
                </div> */}

            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        candidatesAlly: state.candidateReducer.candidatesAlly,
        isLoading: state.candidateReducer.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCandidatesByAlly: bindActionCreators(getCandidatesByAlly, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AliadosExternosSeleccion);
