import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { deleteWorkshop, deleteWorkshopFocus } from '../../../redux/actions/acompanamientoActions';
import { bindActionCreators } from "redux";
import urls from '../../../apiConf';


class ModalDeleteAcompanamiento extends Component {
	constructor(props) {
		super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
			show: false,
		};
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	handleSubmit = (event) => {
		event.preventDefault();
		let id = event.target.taller.value;
		if (this.props.type === 0) {
			this.props.deleteWorkshop(id);
		} else if (this.props.type === 2) {
			this.props.deleteWorkshopFocus(id);
		} else {
			console.log('delete courses');
		}
		this.setState({ show: false });
	}

	render() {
		return (
			<>
				<i className="fas fa-minus-circle text-danger" style={{ bottom: -2 }} onClick={this.handleShow}></i>

				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Eliminar Taller</Modal.Title>
					</Modal.Header>
					<form onSubmit={this.handleSubmit}>
						<Modal.Body className="text-center">
							<input type="hidden" name="taller" value={this.props.taller} />
                            Esta seguro de eliminar este taller?
                            <br />
							<br />
							<Button variant="danger" className="mr-3" onClick={this.handleClose}>
								No
							</Button>
							<Button type="submit" variant="danger">
								Si
							</Button>
						</Modal.Body>
					</form>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoading: state.selectionReducer.isLoading,
		setCandidateStatus: state.selectionReducer.candidatesStatus
	}
}

const mapDispatchToProps = dispatch => {
	return {
		deleteWorkshop: bindActionCreators(deleteWorkshop, dispatch),
		deleteWorkshopFocus: bindActionCreators(deleteWorkshopFocus, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteAcompanamiento);