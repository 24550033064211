import { LOGIN_ALIADO, LOGOUT_ALIADO, ISLOADING, SET_MODULE_PATH, LOGIN_GESTORES, LOGIN_CLIENTES, GET_CANDIDATES_BY_ALLY, GET_TIMELINE_BY_ALLY, GET_ALL_ALLYS, GET_ALLYS_BY_CITY, PAGE_COUNT } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';
import swal from 'sweetalert';

export const isLoading = bool => ({
    type: ISLOADING,
    isLoading: bool
});

export const setAllyData = allyData => ({
    type: LOGIN_ALIADO,
    allyData
});

export const logOutAlly = allyData => ({
  type: LOGOUT_ALIADO,
  allyData
});

export const setManageData = allyData => ({
  type: LOGIN_GESTORES,
  allyData
});

export const setCustomerData = allyData => ({
  type: LOGIN_CLIENTES,
  allyData
});

export const getCandidatesByAlly = candidatesByAlly => ({
  type: GET_CANDIDATES_BY_ALLY,
  candidatesByAlly
});

export const setTimelineByAlly = timeAlly => ({
  type: GET_TIMELINE_BY_ALLY,
  timeAlly
});

export const getAllAllysData = allAllys => ({
  type: GET_ALL_ALLYS,
  allAllys
});

export const setAllyCities = allyCities => ({
  type: GET_ALLYS_BY_CITY,
  allyCities
});

export const setModule = modulePath => ({
  type: SET_MODULE_PATH,
  modulePath
});

export const setCount = pageCount => ({
  type: PAGE_COUNT,
  pageCount
});

export const loginAlly = (obj) => {
    return async(dispatch) => {
        // let data = [];
        dispatch(isLoading(true))
        try {
            const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
              headers: {'Content-Type': 'application/json'}
            });
            const token = await reponseToken.data.token;
            const responseCandidates = await axios.get(`${urls.url_base}/YWNjZXNz/?email=${obj.email}&password=${obj.password}`, {headers: {'Authorization': `JWT ${token}`}});
            const data = await responseCandidates.data;
            if (data.results.length !== 0) {
              if(data.results[0].state){
                dispatch(setAllyData(data.results));
              }else{
                swal('Lo sentimos, tu usuario se encuentra inactivo, por favor comunicate con nosotros!');
              }
            } else {
              dispatch(setAllyData([]));
              swal('Upss! correo y / o contraseña incorrectos!');
            }
        } catch (err) {
            dispatch(isLoading(false));
            alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
        }
    }
}

export const loginManage = (obj) => {
  return async(dispatch) => {
      // let data = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/R2VzdG9yZXM/?email=${obj.email}&password=${obj.password}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          if (data.length !== 0) {
            dispatch(setManageData(data));
          } else {
            dispatch(setManageData([]));
            swal('Upss! correo y / o contraseña incorrectos!');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const loginCustomer = (obj) => {
  return async(dispatch) => {
      // let data = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/Y2xpZW50ZXM/?email=${obj.email}&password=${obj.password}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          if (data.length !== 0) {
            dispatch(setCustomerData(data));
          } else {
            dispatch(setCustomerData([]));
            swal('Upss! correo y / o contraseña incorrectos!');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const updateData = (dataObj, id) => {
  return async(dispatch) => {
    dispatch(isLoading(true));
    try{
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.put(`${urls.url_base}/QWxpYWRvcw/${id}/`, dataObj, {headers: {'Authorization': `JWT ${token}`}});
      const data = await responseAllyData.data;
      if (data.length !== 0) {
        dispatch(setAllyData(data));
      } else {
        dispatch(setAllyData([]));
        swal('Upss! correo y / o contraseña incorrectos!');
      }  
    }catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getAllCandidatesByAlly = (obj, page) => {
  return async(dispatch) => {
      // let candidate = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/Q2FuZGlkYXRvcw/?ally=${obj.idAlly}&page=${page}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          const count = data.count;
          console.log('count', count);
          if (data.results.length !== 0) {
            dispatch(getCandidatesByAlly(data));
            dispatch(setCount(count));
          } else {
            dispatch(getCandidatesByAlly([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getTimelinesRegister = (obj) => {
  return async(dispatch) => {
      // let candidate = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseTimeline = await axios.get(`${urls.url_base}/dGltZXBvcw/?ally=${obj.idAlly}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseTimeline.data;
          if (data.length !== 0) {
            dispatch(setTimelineByAlly(data));
          } else {
            dispatch(setTimelineByAlly([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getAllAllys = () => {
  return async(dispatch) => {
      // let candidate = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCandidates = await axios.get(`${urls.url_base}/QWxpYWRvcw/`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          if (data.length !== 0) {
            dispatch(getAllAllysData(data));
          } else {
            dispatch(getAllAllysData([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getAllysByCity = (id) => {
  return async(dispatch) => {
      // let candidate = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          //const responseCandidates = await axios.get(`${urls.url_base}/QWxpYWRvcw/?city=${id}`, {headers: {'Authorization': `JWT ${token}`}});
          const responseCandidates = await axios.get(`${urls.url_base}/QWxpYWRvcw/`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCandidates.data;
          if (data.results.length !== 0) {
            dispatch(setAllyCities(data));
          } else {
            dispatch(setAllyCities([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const logOut = () => {
  return (dispatch) => {
    dispatch(logOutAlly([]));
  }
}



