import React, { Component, Fragment } from 'react';
import './torta.styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import {
  ResponsiveContainer, PieChart, Pie, Legend, Tooltip,
} from 'recharts';

  

class TortaSeleccion extends Component {
    constructor(props) {
        super(props);
        this.state={
          data01: []
        };
        fetch(`https://alianza.multimedialab.dev/UHJvamVjdA/stats2/?client=${this.props.idBussines}`)
        .then(response => response.json())
        .then((jsonData) => {
          // jsonData is parsed json object received from url
          
          this.setState({
            data01 : [
              { name: 'ARN', value: jsonData[1].arn },
              { name: 'Victimas', value: jsonData[1].victimas }
              
            ]
          })
          console.log(this.state.data01);
          
        })
        .catch((error) => {
          // handle your errors here
          console.error(error)
        })
    }

    render () {
      
    
    
        return (
            <ResponsiveContainer width='100%' aspect={4.0/3.0}>
                <PieChart >
        <Pie dataKey="value" isAnimationActive={true} data={this.state.data01}   fill="#204d6a" label />
        
        <Tooltip />
      </PieChart>
        </ResponsiveContainer>
        )
    }
    
}

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TortaSeleccion);