import React, { Component, Fragment } from "react";
import { Row, Col, Input, FormGroup, Form } from 'reactstrap';
import Header from "../../../header/Header";
import { connect } from "react-redux";
import { getEmprendimientoBySector } from '../../../../redux/actions/emprendActions';
import { saveCourseAssistance } from '../../../../redux/actions/courseActions';
import { bindActionCreators } from "redux";
import './addCursosTalleres.css';
import swal from "sweetalert";
import SelectSearch from 'react-select-search';

function TallerRow(props) {
  const candidate = props.list;
  const assistance = [{
    name: 'Asistio',
    type: 'group',
    items: [{
      value: "Asistio",
      name: 'Asistio'
    }]
  }, {
    name: 'No Asistio',
    type: 'group',
    items: [{
      value: 'Nuevo',
      name: 'Nuevo'
    }, {
      value: 'Incapacidad',
      name: 'Incapacidad'
    }, {
      value: 'Permiso',
      name: 'Permiso'
    }, {
      value: 'Vacaciones',
      name: 'Vacaciones'
    }, {
      value: 'Sin Justa Causa',
      name: 'Sin Justa Causa'
    }]
  }];

  return (
    <tr id="tr" key={candidate.id.toString()}>
      <td className="titleItemRow">{candidate.fullName}</td>
      <td className="titleItemRow">{candidate.authorizingDocument}</td>
      <td className="titleItemRow">{candidate.authorizingContact}</td>
      <td className="titleItemRow">
        <FormGroup row style={{ width: '90%', margin: '0 auto' }}>
          <SelectSearch
            options={assistance}
            placeholder="Tomar asistencia"
            id="assistance"
            onChange={props.handleChangeSelect}
          />
        </FormGroup>
      </td>
      <td>
        <FormGroup row style={{ width: '90%', margin: '0 auto' }}>
          <Input
            type="textarea"
            id="observations"
            onChange={props.handleChange}
          />
        </FormGroup>
      </td>
      <td className="titleItemRow">
        <div style={{ paddingTop: 10 }}>
          <i className="fa fa-check-circle text-success" aria-hidden="true" onClick={props.saveAsistencia}></i>
        </div>
      </td>
    </tr>
  );
}

class EditCursosTalleres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupWorkshop: {},
      assistance: "",
      observations: "",
      emprendList: []
    };
  }

  componentDidMount = () => {
    let group = {};
    if (this.props.courses.length !== 0) {
      let id = this.props.match.params.id;
      group = this.props.courses.results.find(item => item.id == id);
      if (group) {
        this.setState({
          groupWorkshop: group
        });
        this.props.getEmprendimientoBySector(group.sector).then(resp => {
          if (resp.results.length !== 0) {
            this.setState({
              emprendList: resp.results
            });
          }
        });
      }
    }
  };

  updateAsistencia = (item) => {
    console.log(item)
    if (this.state.assistance !== "" && this.state.observations !== "") {
      let obj = {
        reason: this.state.assistance,
        notes: this.state.observations,
        course: `https://alianza.multimedialab.dev/UHJvamVjdA/Y291cnNl/${this.props.match.params.id}/`,
        entrepreneurship: item.url
      }
      this.props.saveCourseAssistance(obj);
    } else {
      swal('Por favor ingresa la informacion de asistencia y Observaciones')
    }
  }

  handleChangeSelect = (e) => {
    this.setState({
      assistance: e
    });
  }

  handleChange = (e) => {
    this.setState({
      observations: e.target.value
    })
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleTaller text-left">Control Asistencia</strong>
            </p>
            <p className="subtitleTaller">{`Listado de emprendimientos pertenecientes al sector ${this.state.groupWorkshop.sector}`}</p>
          </div>

          <div className="col-12 table-responsiveSeguimiento containerResumenSeleccion">
            <div className="table-responsiveTalleres">
              <table className="table tableResumenSeleccion">
                <thead>
                  <tr>
                    <th scope="col" className="titleTable">
                      Emprendimiento
                    </th>
                    <th scope="col" className="titleTable">
                      Documento contacto autorizante
                    </th>
                    <th scope="col" className="titleTable">
                      Nombre completo
                    </th>
                    <th scope="col" className="titleTable">
                      Control asistencia
                    </th>
                    <th scope="col" className="titleTable">
                      Observaciones
                    </th>
                    <th scope="col" className="titleTable"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.emprendList.map((item, index) => {
                    return (
                      <TallerRow
                        key={index.toString()}
                        list={item}
                        saveAsistencia={() => this.updateAsistencia(item)}
                        handleChangeSelect={this.handleChangeSelect}
                        assistance={this.state.assistance}
                        handleChange={this.handleChange}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.accompanimentReducer.isLoading,
    courses: state.coursesReducer.courses,
    candidatesGroup: state.candidateReducer.candidatesGroup
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmprendimientoBySector: bindActionCreators(getEmprendimientoBySector, dispatch),
    saveCourseAssistance: bindActionCreators(saveCourseAssistance, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCursosTalleres);
