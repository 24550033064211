import React, { Component, Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormText,
  Spinner,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import "./vacantes.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAllVacantes,
  add_vacante,
} from "../../../redux/actions/vacanteActions";
import { getAllAllys } from "../../../redux/actions/allyActions";
import { getAllClient } from "../../../redux/actions/clientsActions";
import { getAllCities } from "../../../redux/actions/citiesActions";
import "react-confirm-alert/src/react-confirm-alert.css";
import Pagination from "react-js-pagination";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import Header from "../../header/Header";

import swal from "sweetalert";

const products = [];
const columns = [
  {
    dataField: "id",
    text: "ID Vacante",
  },
  {
    dataField: "nameCity",
    text: "Ciudad",
  },
  {
    dataField: "name",
    text: "Vacante",
    filter: textFilter(),
  },
  {
    dataField: "nameClient",
    text: "Cliente",
  },
  {
    dataField: "nameBusiness",
    text: "Negocio",
  },
  {
    dataField: "endDate",
    text: "Fecha Cierre",
  },
];

class VacantesRS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planTutor: "No",
      client: "",
      nameVacant: "",
      business: "",
      city: "",
      endDate: "",
      partner: [],
      businessData: [],
      businessCity: [],
      cities: [],
      openModal: false,
      activePage: 1,
      errors: {
        nameErr: {},
        clientErr: {},
        businessErr: {},
        partnersErr: {},
        endDateErr: {},
      },
    };
  }

  formatDate(date) {
    var fecha = date.split("T");
    var resultado = fecha[0];
    return resultado;
  }

  componentDidMount = () => {
    this.props.getAllVacantes();
    this.props.getAllAllys();
    this.props.getAllClient();
    this.props.getAllCities();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isLoading == false && this.props.isLoading == true) {
      this.setState({
        client: "",
        planTutor: "No",
        city: "",
        nameVacant: "",
        business: "",
        partner: [],
        businessData: [],
        businessCity: [],
        cities: [],
        endDate: "",
        errors: {
          nameErr: {},
          clientErr: {},
          businessErr: {},
          partnersErr: {},
          endDateErr: {},
        },
      });
    }
  };

  handleChange = (e) => {
    let newState = this.state;

    if (e.target.id === "client") {
      let bussinesDataFilter = [];
      for (let index = 0; index < this.state.businessCity.length; index++) {
        for (
          let index3 = 0;
          index3 < this.state.businessCity[index].business.length;
          index3++
        ) {
          if (
            this.state.businessCity[index].business[index3].nameCity ===
            this.state.city
          ) {
            bussinesDataFilter.push(
              this.state.businessCity[index].business[index3]
            );
          }
        }
      }

      this.setState({
        businessData: bussinesDataFilter,
        client: e.target.value,
      });
    } else if (e.target.id === "city") {
      let clientFilter = [];
      this.setState({
        businessCity: [],
        city: [],
        businessData: [],
        client: [],
      });
      for (
        let index = 0;
        index < this.props.clientData.results.length;
        index++
      ) {
        for (
          let index2 = 0;
          index2 < this.props.clientData.results[index].business.length;
          index2++
        ) {
          if (
            this.props.clientData.results[index].business[index2].nameCity ===
            e.target.value
          ) {
            clientFilter.push(this.props.clientData.results[index]);
            console.log(clientFilter)
          }
        }
      }
      
      clientFilter = clientFilter.filter(
        (item, index) => clientFilter.indexOf(item) === index
      );

      this.setState({
        businessCity: clientFilter,
        city: e.target.value,
      });
    } else if (e.target.id === "partner") {
      let temp = this.state.partner;
      let i = temp.indexOf(parseInt(e.target.value));
      if (i === -1) {
        temp.push(parseInt(e.target.value));
        this.setState({
          partner: temp,
        });
      } else {
        temp.splice(i, 1);
        this.setState({
          partner: temp,
        });
      }
    } else {
      newState[e.target.id] = e.target.value;
      this.setState(newState);
    }
  };

  sendVacancy = (e) => {
    e.preventDefault();
    const isValid = this.formValidation();
    if (isValid) {
      let obj = {
        planTutor: this.state.planTutor !== "No" ? true : false,
        client: this.state.client,
        name: this.state.nameVacant,
        business: this.state.business,
        partner: this.state.partner,
        endDate: this.state.endDate,
        state: false,
      };
      this.props.add_vacante(obj);
    }
  };

  formValidation = () => {
    const nameErr = {};
    const clientErr = {};
    const businessErr = {};
    const partnersErr = {};
    const endDateErr = {};
    let isValid = true;

    if (this.state.nameVacant.trim().length === 0) {
      nameErr.required = "Por favor ingresa el nombre de la vacante";
      isValid = false;
    }
    if (this.state.client.length === 0) {
      clientErr.required = "Por favor selecciona un cliente";
      isValid = false;
    }
    if (this.state.business.length === 0) {
      businessErr.required = "Por favor selecciona un negocio";
      isValid = false;
    }
    if (this.state.partner.length === 0) {
      partnersErr.required =
        "Por favor selecciona al menos una agencia o programa";
      isValid = false;
    }
    if (this.state.endDate.length === 0) {
      endDateErr.required = "Por favor indica la fecha de cierre";
    }

    this.setState({
      errors: {
        nameErr,
        clientErr,
        businessErr,
        partnersErr,
        endDateErr,
      },
    });

    return isValid;
  };

  closeClean = () => {
    this.toggleModal();
    this.setState({
      planTutor: "No",
      client: "",
      city: "",
      nameVacant: "",
      business: "",
      partner: [],
      endDate: "",
      businessData: [],
      businessCity: [],
      cities: [],
      errors: {
        nameErr: {},
        clientErr: {},
        businessErr: {},
        partnersErr: {},
        endDateErr: {},
      },
    });
  };

  toggleModal = () => {
    this.setState({
      openModal: !this.state.openModal,
    });
  };

  addModal = () => {
    return (
      <Modal isOpen={this.state.openModal} toggle={this.closeClean} size={"lg"}>
        <ModalHeader toggle={this.closeClean}>Agregar vacante</ModalHeader>
        <ModalBody>
          <div className="containerFormulario">
            <form onSubmit={(e) => this.sendVacancy(e)}>
              <div className="row" style={{ width: "100%" }}>
               {/*  <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Plan Tutor?
                    </label>
                    <div className="col-sm-12">
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.planTutor}
                        id="planTutor"
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                <div className="col-sm-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">Ciudad</label>
                    <div className="col-sm-12">
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.city}
                        id="city"
                      >
                        <option defaultValue="" value="" disabled>
                          Seleccione una ciudad
                        </option>
                        {this.props.citiesData.length !== 0
                          ? this.props.citiesData.results.map((item, index) => {
                              return (
                                <option
                                  key={index.toString()}
                                  value={item.name}
                                >
                                  {item.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Nombre Vacante
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ingresa nombre de la vacante"
                        id="nameVacant"
                        value={this.state.nameVacant}
                        onChange={this.handleChange}
                      />
                      {Object.keys(this.state.errors.nameErr).map(
                        (keyObj, index) => (
                          <FormText
                            className="errorFormText"
                            key={index.toString()}
                          >
                            {this.state.errors.nameErr[keyObj]}
                          </FormText>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">Cliente</label>
                    <div className="col-sm-12">
                      <select
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.client}
                        id="client"
                      >
                        <option defaultValue="" value="" disabled>
                          Seleccione un cliente
                        </option>
                        {this.state.businessCity.length !== 0
                          ? this.state.businessCity.map((item, index) => {
                              return (
                                <option key={index.toString()} value={item.url}>
                                  {item.fullName}
                                </option>
                              );
                            })
                          : null}
                      </select>
                      {Object.keys(this.state.errors.clientErr).map(
                        (keyObj, index) => (
                          <FormText
                            className="errorFormText"
                            key={index.toString()}
                          >
                            {this.state.errors.clientErr[keyObj]}
                          </FormText>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ width: "100%" }}>
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Fecha de cierre
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="date"
                        className="form-control"
                        id="endDate"
                        value={this.state.endDate}
                        onChange={this.handleChange}
                      />
                      {Object.keys(this.state.errors.endDateErr).map(
                        (keyObj, index) => (
                          <FormText
                            className="errorFormText"
                            key={index.toString()}
                          >
                            {this.state.errors.endDateErr[keyObj]}
                          </FormText>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">Negocio</label>
                    <div className="col-sm-12">
                      <select
                        className="col-sm-12 form-control"
                        onChange={this.handleChange}
                        id="business"
                        value={this.state.business}
                      >
                        <option defaultValue="" value="" disabled>
                          Seleccione un negocio
                        </option>
                        {this.state.businessData.length !== 0
                          ? this.state.businessData.map((item, index) => {
                              return (
                                <option key={index.toString()} value={item.url}>
                                  {item.name}
                                </option>
                              );
                            })
                          : null}
                      </select>
                      {Object.keys(this.state.errors.businessErr).map(
                        (keyObj, index) => (
                          <FormText
                            className="errorFormText"
                            key={index.toString()}
                          >
                            {this.state.errors.businessErr[keyObj]}
                          </FormText>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ width: "100%" }}>
                <div className="col-sm-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Programa
                    </label>
                    <div className="col-sm-12">
                      <select
                        className="form-control"
                        id="partner"
                        onChange={this.handleChange}
                        value={this.state.partner}
                        style={{ position: "absolute" }}
                        multiple
                      >
                        <option value="" defaultValue="" disabled>
                          Seleccione el programa
                        </option>
                        <option value={null}>No pertenece</option>
                        {this.props.allAllys.length !== 0
                          ? this.props.allAllys.results.map((item, index) => {
                              return (
                                <option key={index.toString()} value={item.id}>
                                  {item.fullName}
                                </option>
                              );
                            })
                          : null}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              {Object.keys(this.state.errors.partnersErr).map(
                (keyObj, index) => (
                  <FormText className="errorFormText" key={index.toString()}>
                    {this.state.errors.partnersErr[keyObj]}
                  </FormText>
                )
              )}
              <br />
              <div
                className="row"
                style={{ textAlign: "center", width: "100%" }}
              >
                <div className="col-sm-12">
                  <button
                    className="btn btn-info"
                    type="submit"
                    // onClick={this.sendVacancy}
                  >
                    Solicitar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.closeClean}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  handlePageChange = (e) => {
    window.scroll(0, 0);
    this.props.getAllVacantes(e);

    this.setState({
      activePage: e,
    });
  };

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value),
    });
  };

  renderOptionsPage = () => {
    let options = [];
    if (this.props.pageCount !== 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>
                {i}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  render() {
    return (
      <Fragment>
        {this.addModal()}
        <Header />
        <div className="backgroundReingreso">
          <div className="customRow containerHeader">
            <div className="col-12 containerTitleSeleccion mb-3 text-left">
              <p>
                <strong className="titleSeguimiento text-left">Vacantes</strong>
              </p>
              <p className="subtitleSeguimiento">
                Listado de vacantes solicitadas
              </p>
            </div>
          </div>

          <div className="contentBtnVacante">
            <Button
              className="btn btn-info"
              onClick={() => {
                this.toggleModal();
              }}
            >
              Nueva vacante
            </Button>
          </div>

          <div className="containerTable">
            <br></br>
            {this.props.vacanteData2.length !== 0 && (
              <BootstrapTable
                keyField="id"
                data={this.props.vacanteData2.results}
                columns={columns}
                pagination={paginationFactory()}
                filter={filterFactory()}
              />
            )}
            {/* <table className="table tableViewAliados">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Ciudad
                  </th>
                  <th scope="col" className="titleTable">
                    Vacante
                  </th>
                  <th scope="col" className="titleTable">
                    Cliente
                  </th>
                  <th scope="col" className="titleTable">
                    Negocio
                  </th>
                  <th scope="col" className="titleTable">
                    Fecha apertura
                  </th>
                  <th scope="col" className="titleTable">
                    Fecha cierre
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.vacanteData2.length != 0
                  ? this.props.vacanteData2.results.map((vacante, index) => {
                      return (
                        <CandidateRow
                          vacante={vacante}
                          key={index.toString()}
                          date={this.formatDate(vacante.timestamp)}
                        />
                      );
                    })
                  : null}
              </tbody>
            </table> */}
          </div>

          {/*  <Col
            md={12}
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.pageCount}
              pageRangeDisplayed={5}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={5}
              pageRangeDisplayed={10}
            />
            {this.renderOptionsPage()}
          </Col> */}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vacanteData2: state.vacanteReducer.vacanteData2,
    allyData: state.allyReducer.allyData,
    allAllys: state.allyReducer.allAllys,
    clientData: state.clientsReducer.clientData,
    isLoading: state.vacanteReducer.isLoading,
    citiesData: state.citiesReducer.citiesData,
    pageCount: state.vacanteReducer.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllVacantes: bindActionCreators(getAllVacantes, dispatch),
    getAllAllys: bindActionCreators(getAllAllys, dispatch),
    getAllClient: bindActionCreators(getAllClient, dispatch),
    add_vacante: bindActionCreators(add_vacante, dispatch),
    getAllCities: bindActionCreators(getAllCities, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VacantesRS);
