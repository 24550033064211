import React, { Component, Fragment } from "react";
import "./consolidado.style.css";
import { Row, Spinner } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import Header from "../../header/Header";
import { Modal, Button } from "react-bootstrap";

import GeneralData from '../../generalData/GeneralData';


//Componentes propios
import GraficosSeleccion from "./graficas/graficas";
import BarrasSeleccion from "./barras/barras";
import TablaSeleccion from "./tabla/tabla";

class ConsolidadoSeleccion extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      client: {
        id: 3,
        name: ''
      },
      loading: true
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 700)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevProps.menuClient.name !== this.props.menuClient.name){
      console.log('prev', prevProps.menuClient)
      console.log('new', this.props.menuClient)
      this.setState({
        client: this.props.menuClient,
        loading: true
      });

      setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 700)
    }
  }

  render() {
    if(this.state.loading){
      return(
        <Row style={{width: '100%', alignItems: 'center', marginTop: '20%'}}>
          <Spinner type="grow" size="sm" color="info" />{' '}
          <Spinner type="grow" color="info" />{' '}
          <Spinner type="grow" size="sm" color="info" />{' '}
        </Row>
      )
    }else{
      return (
        <GeneralData history={this.props.history} title={this.props.menuClient.name} idBussines={this.props.menuClient.id} />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    menuClient: state.clientsReducer.menuClient
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsolidadoSeleccion);
