import React, { Component } from 'react';
import './graphics.styles.css';

class Graphics extends Component {
    constructor(props){
        super(props);
        this.state={};
    }
    render() {
        return (
            <div>
                <div className="row backgroundGraphics">
                    <div className="col-7 containerGraphics">
                        <p>Candidatos por Ciudades</p>
                        <div className="graphic1">

                        </div>
                    </div>
                    <div className="col-5 containerGraphics">
                        <p>Candidatos por programas de gobierno</p>
                        <div className="graphic1">
                            
                        </div>
                    </div>
                </div>
                <div className="row bgTables">
                    <div className="col-10">
                        <div className="table-responsiveGraphics containerTableHome">
                            <table className="table tableResumenHome">
                                <thead>
                                    <tr id="tr">
                                    <th scope="col" className="titleTable">Estados</th>
                                    <th scope="col" className="titleTable">Masculino</th>
                                    <th scope="col" className="titleTable">Femenino</th>
                                    <th scope="col" className="titleTable">No Binario</th>
                                    <th scope="col" className="titleTable">ARN</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr id="tr" className="customStripper">
                                        <td className="titleItemRow">En proceso</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                    </tr>

                                    <tr id="tr" className="customStripper">
                                        <td className="titleItemRow">En proceso</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                    </tr>

                                    <tr id="tr" className="customStripper">
                                        <td className="titleItemRow">En proceso</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                    </tr>

                                    <tr id="tr" className="customStripper">
                                        <td className="titleItemRow">En proceso</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                    </tr>


                                    <tr id="tr" className="customStripper">
                                        <td className="titleItemRow">En proceso</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                    </tr>


                                    <tr id="tr" className="customStripper">
                                        <td className="titleItemRow">En proceso</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                    </tr>


                                    <tr id="tr" className="customStripper">
                                        <td className="titleItemRow">En proceso</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                    </tr>


                                    <tr id="tr" className="customStripper">
                                        <td className="titleItemRow">En proceso</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                        <td className="titleItemRow">0</td>
                                    </tr>



                                    

                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-2 containerIconDownload">
                        <a className="btnDownloadHome">
                            <img src={require("../../assets/Icons/download.png")} className="iconDownloadGraphics"/>
                            <p>Descargar</p>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Graphics;