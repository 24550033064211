import React, { Component } from "react";
import { Col, FormGroup, Input } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCandidatesByAlly } from "../../../redux/actions/allyActions";
import { updateStateCandidate } from "../../../redux/actions/candidateActions";
import "./viewCandidates.styles.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Pagination from "react-js-pagination";

function CandidateRow(props) {
  const candidate = props.candidate;
  const date = props.date;
  const substate = candidate.positions[0] ? candidate.positions[0].psubstateName : "N/A";
  const substateNumber = candidate.positions[0] ? candidate.positions[0].proccessSubstate : "N/A";
  const allowShow = [0, 5, 6, 7, 8, 11, 12, 13, 14];
  const allowReentry = [5, 14]

  return (
    <tr id="tr" key={candidate.id.toString()}>
      <td className="titleItemRow">{candidate.document}</td>
      <td className="titleItemRow">{candidate.fullName}</td>
      <td className="titleItemRow">{candidate.cellphone}</td>
      <td className="titleItemRow">{candidate.positions[0] ? candidate.positions[0].nameVacancy : ""}</td>
      <td className="titleItemRow">{candidate.positions[0] ? candidate.positions[0].nameBussiness : ""}</td>
      <td className="titleItemRow">{date}</td>
      <td className="titleItemRow">
        <div className="btn btn-warning text-black" style={{minWidth: 210}}>
          <strong>{allowShow.includes(substateNumber) ? substate : 'Aprobados por la alianza'}</strong>
        </div>
      </td>
      <td className="titleItemRow">
        {allowReentry.includes(substateNumber) && (
          <div className="btnHV" onClick={props.reEntryFunction}>
            <img
              src={require("../../../assets/IconAliadosExternos/refresh.png")}
              alt="Hoja de vida"
              className="iconHojaVida"
            ></img>
          </div>
        )}
      </td>
    </tr>
  );
}

class ViewCandidates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1
    };
  }

  componentDidMount = () => {
    if(this.props.allyData.length !== 0) {
      var obj = {
        idAlly: this.props.allyData ? this.props.allyData[0].advisorData.id : 0,
      };
      this.props.getAllCandidatesByAlly(obj, this.state.activePage);
    }
  };

  formatDate(date) {
    var fecha = date.split("T");
    var resultado = fecha[0];
    return resultado;
  }

  sendToReintry = (candidate) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui" className="customAlert">
            <h4>El candidato se encuentra en estado retirado</h4>
            <p>¿Deseas solicitar el reingreso?</p>
            <button
              className="customButtons"
              onClick={() => {
                this.updateStateCandidate(candidate);
                onClose();
              }}
            >
              Si
            </button>
            <button className="customButtons" onClick={onClose}>
              No
            </button>
          </div>
        );
      },
    });
  };

  updateStateCandidate = (candidate) => {
    var obj = {
      requestsReentry: true,
    };
    this.props.updateStateCandidate(
      candidate.id,
      obj,
      `Tu solicitud se ha enviado correctamente.`
    );
  };

  handlePageChange = (e) => {
    window.scroll(0,0);    
    var obj = {
      idAlly: this.props.allyData ? this.props.allyData[0].advisorData.id : 0,
    };
    this.props.getAllCandidatesByAlly(obj, e);

    this.setState({
      activePage: e
    });
  }

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value)
    });
  }

  renderOptionsPage = () => {
    let options = [];
    if(this.props.pageCount != 0){
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i+1);
      }
    }
    return(
      <FormGroup>
        <Input
          style={{marginLeft: 10}}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return(
              <option key={i.toString()} value={i}>{i}</option>
            )
          })}
        </Input>
      </FormGroup>
    )
  }

  render() {
    return (
      <div className="backgroundViewCandidates">
         <div className="headerInicio">
            <div className="allyPicture">
                {this.props.allyData.length != 0 && <img src={this.props.allyData[0].advisorData.profilePicture} width="100%" />}
            </div>

            <a className="btnNotification" href="#">
                <img src={require('../../../assets/Icons/bell.png')} alt="Notificaciones" className="iconAlerts" />
            </a>
        </div>
        <div className="customRow mb-2 text-left">
          <p>
            <strong className="titleSeguimiento text-left">
              Candidatos Enviados
            </strong>
          </p>
          <p className="subtitleSeguimiento">
            Listado de los candidatos enviados
          </p>
        </div>

        <div className="bootRow">
          <div className="col-3">
            <label><strong>Desde</strong> </label>
            <input className="form-control" type="date" name="" id="" />
          </div>
          <div className="col-3">
            <label><strong>Hasta</strong> </label>
            <input className="form-control" type="date" name="" id="" />
          </div>
          <div className="col-3">
            <button className="btn btn-info mt-3">
              <i className="fas fa-search mr-3"></i>
              <strong className="ml-4">Filtrar</strong>
            </button>
          </div>
          <div className="col-3">
            <i className="fas fa-download fa-2x"></i>
          </div>
        </div>

        <div className="table-responsiveViewCandidates containerTable">
          <table className="table tableViewAliados">
            <thead>
              <tr>
                <th scope="col" className="titleTable">
                  Cédula
                </th>
                <th scope="col" className="titleTable">
                  Nombre
                </th>
                <th scope="col" className="titleTable">
                  Telefono
                </th>
                <th scope="col" className="titleTable">
                  Cargo
                </th>
                <th scope="col" className="titleTable">
                  Empresa
                </th>
                <th scope="col" className="titleTable">
                  Fecha
                </th>
                <th scope="col" className="titleTable">
                  Estado
                </th>
                <th scope=""></th>
              </tr>
            </thead>
            <tbody>
              {this.props.candidatesByAlly.length != 0
                ? this.props.candidatesByAlly.results.map(
                    (candidate, index) => {
                      return (
                        <CandidateRow
                          candidate={candidate}
                          key={index.toString()}
                          date={this.formatDate(candidate.actualizado)}
                          reEntryFunction={() => this.sendToReintry(candidate)}
                        />
                      );
                    }
                  )
                : null}
            </tbody>
          </table>
        </div>

        <Col md={12} style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={50}
            totalItemsCount={this.props.pageCount}
            pageRangeDisplayed={5}
            onChange={(e) => this.handlePageChange(e)}
            itemClass="page-item"
            linkClass="page-link"
            hideDisabled
            pageRangeDisplayed={5}
            pageRangeDisplayed={10}
          />
          {this.renderOptionsPage()}
        </Col>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    candidatesByAlly: state.allyReducer.candidatesByAlly,
    allyData: state.allyReducer.allyData,
    pageCount: state.allyReducer.pageCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCandidatesByAlly: bindActionCreators(getAllCandidatesByAlly, dispatch),
    updateStateCandidate: bindActionCreators(updateStateCandidate, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCandidates);
