import React, { Component, Fragment } from 'react';
import './cronogramas.styles.css';
import Header from '../../header/Header';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSave } from '../../../redux/actions/timelinesActions';


class Cronogramas extends Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    componentDidMount = () => {
        this.props.setSave(false);
    }

    render() {
        return(
            <Fragment>
            <Header />
            {console.log(this.props.isSave)}
            <div className="backgroundCronogramas">

                <div className="row" style={{width: '100%'}}>
                    <div className="col-10">
                        {/* <div className="alert alert-danger">
                            Reunion el 26 de Agosto con programas de gobierno - candidatos remitidos
                        </div>       */}
                    </div>
                </div>

                <div className="customRow mb-5 text-left">
                    <p>
                        <strong className="titleSeguimiento text-left">Cronogramas</strong>
                    </p>
                    <p>
                        <strong className="subtitleSeguimiento text-left">Eliga la opcion sobre la cual deseas crear un nuevo cronograma</strong>
                    </p>
                </div>
                
                <div className="row containerCronogramas">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 containerBtnCronograma">
                        <Link to={`/${this.props.modulePath}/calendar/records`} style={{textDecoration: 'none'}}>
                            <button className="btnCronograma">
                                <p className="nameCard">

                                    Fechas postulacion de candidatos
                                </p>
                                </button>
                        </Link>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 containerBtnCronograma">
                        <Link to={`/${this.props.modulePath}/calendar/aliados`} style={{textDecoration: 'none'}}>
                            <button className="btnCronograma">
                                <p className="nameCard">

                                Seguimientos aliados externos
                                </p>
                                </button>
                        </Link>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 containerBtnCronograma">
                        <Link to={`/${this.props.modulePath}/calendar/vinculados`} style={{textDecoration: 'none'}}>
                            <button className="btnCronograma">
                                <p className="nameCard">

                                Seguimientos a vinculados
                                </p>
                                </button>
                        </Link>
                    </div>
                </div>
            </div>
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        isSave: state.timelinesReducer.isSave,
        modulePath: state.allyReducer.modulePath
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSave: bindActionCreators(setSave, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cronogramas);