import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { createInterviewState } from '../../../redux/actions/selectionActions';
import { bindActionCreators } from "redux";
import urls from '../../../apiConf';


class ModalUpOkAprendiz extends Component {
	constructor(props) {
        super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
			show: false,
        };
    }
    
	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        var obj = {
            idCandidate: urls.url_base+'/Q2FuZGlkYXRvcw/'+event.target.candidato.value+'/',
            dateInterview: event.target.fecha.value,
            hourInterview: event.target.hora.value,
            inCharge: 0
        }
        this.props.createInterviewState(obj);
        this.setState({ show: false });
    }

	render() {

        const idCap = this.props.idCap;
        
		return (
			<>
                                <i className="fas fa-plus-circle text-success" onClick={this.handleShow}></i>

				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Seleccionar fecha y hora para la entrevista</Modal.Title>
					</Modal.Header>
                    <form onSubmit={this.handleSubmit}>
                        <Modal.Body>
                                <div className="row">
                                    <input type="hidden" name="candidato" value={idCap}/>
                                    <input type="hidden" name="oferta" value="0" />
                                    <div className="col-6"> 
                                        <label>
                                            Fecha  
                                            <input type="date" className="form-control" name="fecha" />
                                        </label>                                    
                                    </div>
                                    <div className="col-6"> 
                                        <label>
                                            Hora
                                            <input type="time" className="form-control" name="hora" />
                                        </label>                                    
                                    </div>
                                </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Cerrar
                            </Button>
                            <Button type="submit" variant="primary">
                                citar
                            </Button>
                        </Modal.Footer>
                    </form>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
    return {
        isLoading: state.selectionReducer.isLoading,
        createInterview: state.selectionReducer.addInterview
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createInterviewState: bindActionCreators(createInterviewState, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpOkAprendiz);