import React, { Component, Fragment } from "react";
import "./resumenDetallado.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import NotificacionAprendiz from "../notificaciones/notificaciones";
import { countAllStatesGender } from "../../../redux/actions/selectionActions";

class ResumenDetalladoPracticante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enRemitidos: 0,
      enCandidatos: 0,
      enAprobados: 0,
      enNoPasaronProceso: 0,
      enNoCitados: 0,
      enNoAsistieron: 0,
      enPendientes: 0,
    };
  }

  componentDidMount = async () => {
    await this.props.countAllStatesGender();
    this.setInitialData();
  };

  setInitialData = () => {
    try {
      var remitidos = this.props.getSummaryState.filter(
        (el) => el.estado == "Remitidos"
      )[0];
      var candidatos = this.props.getSummaryState.filter(
        (el) => el.estado == "Candidatos"
      )[0];
      var aprobados = this.props.getSummaryState.filter(
        (el) => el.estado == "Aprobados"
      )[0];
      var noPasaronProceso = this.props.getSummaryState.filter(
        (el) => el.estado == "No pasaron proceso"
      )[0];
      var noAsistieron = this.props.getSummaryState.filter(
        (el) => el.estado == "Asistieron"
      )[0];
      var pendientes = this.props.getSummaryState.filter(
        (el) => el.estado == "Pendientes"
      )[0];
      var noCitados = this.props.getSummaryState.filter(
        (el) => el.estado == "No citados"
      )[0];

      this.setState({
        enRemitidos: remitidos.total,
        enCandidatos: candidatos.total,
        enAprobados: aprobados.total,
        enNoPasaronProceso: noPasaronProceso.total,
        enNoCitados: noCitados.total,
        enNoAsistieron: noAsistieron.total,
        enPendientes: pendientes.total,
      });
    } catch (err) {
      console.log("error", err);
    }
  };

  render() {
    const idAlly = this.props.match.params.ally;

    return (
      <Fragment>
        <Header />
        <div className="col-12 backgroundAgenciasFiltro">
          <div className="col-12 containerTitleSeleccion mb-5 text-left">
            <p>
              <strong className="titleCiudad text-left">
                Resumen Modulo de Selección
              </strong>
            </p>
          </div>

          <div className="container-fluid bgResumenDetalladoPracticante">
            <div className="col-12 mb-3">
              <NotificacionAprendiz />
            </div>
            <div className="col-12 table-responsiveResumenAcompanamiento containerResumenDetalladoPracticante">
              <table className="table tableResumenDetalladoPracticante">
                <thead>
                  <tr>
                    <th scope="col" className="titleTable">
                      Estados
                    </th>
                    <th scope="col" className="titleTable">
                      Genero
                    </th>
                    <th scope="col" className="titleTable">
                      Total
                    </th>
                    <th scope="col" className="titleTable">
                      Opcion
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="tr">
                    <td className="titleItemRow">Aprobados</td>
                    <td className="titleItemRow"></td>
                    <td className="titleItemRow">{this.state.enAprobados}</td>
                    <td>
                      <Link
                        to={`/acompanamiento/status/aprobados/${idAlly}/11`}
                      >
                        <i className="fas fa-eye text-info"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr id="tr">
                    <td className="titleItemRow">No contesto</td>
                    <td className="titleItemRow"></td>

                    <td className="titleItemRow">{this.state.enNoCitados}</td>
                    <td>
                      <Link
                        to={`/acompanamiento/status/no-contestaron/${idAlly}/2`}
                      >
                        <i className="fas fa-eye text-info"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr id="tr">
                    <td className="titleItemRow">Entrevistas</td>
                    <td className="titleItemRow"></td>

                    <td className="titleItemRow">
                      {this.state.enNoAsistieron}
                    </td>
                    <td>
                      <Link
                        to={`/acompanamiento/status/entrevista/${idAlly}/5`}
                      >
                        <i className="fas fa-eye text-info"></i>
                      </Link>
                    </td>
                  </tr>

                  <tr id="tr">
                    <td className="titleItemRow">No continuan proceso</td>
                    <td className="titleItemRow"></td>

                    <td className="titleItemRow">{this.state.enPendientes}</td>
                    <td>
                      <Link
                        to={`/acompanamiento/status/no-pasaron-proceso/${idAlly}/8`}
                      >
                        <i className="fas fa-eye text-info"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr id="tr">
                    <td className="titleItemRow">Vinculados</td>
                    <td className="titleItemRow"></td>

                    <td className="titleItemRow">
                      {this.state.enNoPasaronProceso}
                    </td>
                    <td>
                      <Link
                        to={`/acompanamiento/status/vinculados/${idAlly}/10`}
                      >
                        <i className="fas fa-eye text-info"></i>
                      </Link>
                    </td>
                  </tr>
                  <tr id="tr">
                    <td className="titleItemRow">Desvinculados</td>
                    <td className="titleItemRow"></td>

                    <td className="titleItemRow">
                      {this.state.enNoPasaronProceso}
                    </td>
                    <td>
                      <Link
                        to={`/acompanamiento/status/desvinculados/${idAlly}/9`}
                      >
                        <i className="fas fa-eye text-info"></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.selectionReducer.isLoading,
    getSummaryState: state.selectionReducer.getSummaryState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    countAllStatesGender: bindActionCreators(countAllStatesGender, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumenDetalladoPracticante);
