import React, { Component, Fragment } from "react";
import "./aliados.styles.css";

import Header from "../../../header/Header";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllysByCity } from "../../../../redux/actions/allyActions";

class AliadosSeleccionResumenFiltro extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var id = this.props.match.params.city;
    console.log("city id", id);
    this.props.getAllysByCity(id);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12">
          <div className="col-12 backgroundCiudadesResumenSeleccion">
            <div className="containerTitle">
              <p>
                <strong className="titleSeguimiento text-left">Ciudades</strong>
              </p>
            </div>

        <div className="col-12 backgroundAgenciasFiltro">
          <div className="col-12 containerTitleSeleccion mb-5 text-left">
            <p>
              <strong className="titleCiudad text-left">
                Agencias de Empleo y Programas de Gobierno
              </strong>
            </p>
            <p className="subtitleCiudad">Selecciona una agencia</p>
          </div>
          <div className="col-12 backgroundAgenciasFiltro">
            <div className="containerAgenciasScroll">
              <div className="row containerAgeniasFiltro">
                {this.props.allyCities.length !== 0
                  ? this.props.allyCities.results.map((item, index) => {
                      return (
                        <div className="col-2" key={item.id.toString()}>
                          <div className="itemAgencias">
                            <Link
                              to={`/seleccion/resumen-detallado/${item.id}`}
                            >
                              <h6>{item.fullName}</h6>
                            </Link>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>


          </div>
        </div>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allyCities: state.allyReducer.allyCities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllysByCity: bindActionCreators(getAllysByCity, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AliadosSeleccionResumenFiltro);
