import { ISLOADING, SET_TRACEABILITY } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';
import swal from 'sweetalert';

export const isLoading = bool => ({
  type: ISLOADING,
  isLoading: bool
});

export const setTraceability = traceability => ({
  type: SET_TRACEABILITY,
  traceability
});

export const getCandidateTrace = (id) => {
  return async(dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
        const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
          headers: {'Content-Type': 'application/json'}
        });
        const token = await reponseToken.data.token;
        const responseTrace = await axios.get(`${urls.url_base}/dHJhemFi/?candidate=${id}`, {headers: {'Authorization': `JWT ${token}`}});
        const data = await responseTrace.data;
        if (data.results.length !== 0) {
          dispatch(setTraceability(data));
        } else {
          dispatch(setTraceability([]));
          // swal('No hay información disponible');
        }
    } catch (err) {
        dispatch(isLoading(false))
        alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const addTraceability = (traceObj) => {
  return async(dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    console.log('traza', traceObj)
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      const responseTrace = await axios.post(`${urls.url_base}/dHJhemFi/`, traceObj, {headers: {'Authorization': `JWT ${token}`}});
      const data = await responseTrace.data;
      console.log('se guardo traza');          
    } catch (err) {
      dispatch(isLoading(false))
      console.log(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}