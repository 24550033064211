import React, { Component } from 'react';
import './home.styles.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add_vacante } from '../../../redux/actions/vacanteActions';
import { getBusinessClient } from '../../../redux/actions/clientsActions';

class HomeClientes extends Component {
    constructor(props) {
        super(props);
        this.state={
            nameVacant: '',
            vacantState: false,
            idClient: '',
            idBusiness: ''
        };
    }

    handleChange = (e) => {
        const newState = this.state;
        newState[e.target.id] = e.target.value;
        this.setState(newState);
    }

    onRequest = () => {
        var obj = {
            nameVacant: this.state.nameVacant,
            vacantState: this.state.vacantState,
            idClient: this.props.allyData.results[0].url,
            idBusiness: this.state.idBusiness
        }
        this.props.add_vacante(obj);
    }

    render() {
        return(
            <div className="backgroundInicio">
                <div className="headerInicio">
                    <div className="clientPicture">
                        {this.props.allyData.length != 0 && <img src={this.props.allyData[0].clientData.profilePicture} width="100%" />}
                    </div>
                    <a className="btnNotification" href="#">
                        <img src={require('../../../assets/Icons/bell.png')} alt="Notificaciones" className="iconAlerts" />
                    </a>
                </div>
                <div className="dateInicio">
                    <div className="containerDate">
                        <h6>SOLICITAR VACANTE</h6>

                        <div className="containerFormulario">
                            <form>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Nombre Vacante</label>
                                    <div className="col-sm-9">
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Ingresa nombre de la vacante"
                                            id="nameVacant" 
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                {/* <div className="form-group row">
                                    <label className="col-sm-3 col-form-label">Negocio</label>
                                    <div className="col-sm-9">
                                        <select
                                            className="form-control" 
                                            id="idBusiness"
                                            onChange={this.handleChange}
                                        >
                                            <option value="" defaultValue="" disabled>Selecciona el negocio</option>
                                            {this.props.businessClientData.length != 0 ? this.props.businessClientData.results.map((item, index) => { return <option value={item.url}>{item.nameClient}</option>}) : null}
                                        </select>
                                    </div>
                                </div> */}
                            </form>
                        </div>
                        <button 
                            className="btnEnviarSolicitud"
                            onClick={this.onRequest}
                        >
                            Enviar
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.candidateReducer.isLoading,
        vacanteData3: state.vacanteReducer.vacanteData3,
        allyData: state.allyReducer.allyData,
        businessClientData: state.clientsReducer.businessClientData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        add_vacante: bindActionCreators(add_vacante, dispatch),
        getBusinessClient: bindActionCreators(getBusinessClient, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeClientes);