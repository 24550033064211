import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { setAllCandidatesStatus } from '../../../redux/actions/candidateActions';
import { updatePositionsSubstates } from '../../../redux/actions/candidateActions';
import { bindActionCreators } from "redux";
import urls from '../../../apiConf';


class ModalPendienteOk extends Component {
	constructor(props) {
        super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
			show: false,
        };
    }
    
	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
    }
    
    handleSubmit = (event) => {
        event.preventDefault();
        var traceObj = {
            action: `Cambio estado a Vinculados por ${this.validateIdentity().name}`,
            candidate: urls.url_base+'/Q2FuZGlkYXRvcw/'+event.target.candidato.value+'/',
            manager: this.validateIdentity().url
        }
        this.props.updatePositionsSubstates({
            position: event.target.position.value,
            proccessState: 2,
            proccessSubstate: 11,
            idCandidate: urls.url_base+'/Q2FuZGlkYXRvcw/'+event.target.candidato.value+'/'
        }, true, traceObj);
        this.setState({ show: false });
    }

    validateIdentity = () => {
        if(this.props.allyData.length !== 0){
          let obj = {};
          let data = this.props.allyData[0];
          if(data.businessData){
            obj = {
              name: data.businessData.name,
              url: data.businessData.url
            }
          }else if(data.advisorData){
            obj = {
              name: data.advisorData.fullName,
              url: data.advisorData.url
            }
          }else if(data.managerData) {
            obj = {
              name: data.managerData.fullName,
              url: data.managerData.url
            }
          }
          return obj;
        }
    }

	render() {

        const idCap = this.props.idCap;
        const idStatus = this.props.idStatus;
        const idSubStatus = this.props.subStatus;
        
		return (
			<>
                <i className="fas fa-check-circle text-success" onClick={this.handleShow}></i>

				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>El candidato pasa hacer vinculado con el cliente?</Modal.Title>
					</Modal.Header>
                    <form onSubmit={this.handleSubmit}>
                        <Modal.Body className="text-center">
                                <div className="row">
                                    <input type="hidden" name="candidato" value={idCap}/>
                                    <input type="hidden" name="estado" value={idStatus} />
                                    <input type="hidden" name="SubEstado" value={idSubStatus} />
                                    <input type="hidden" name="position" value={this.props.position} />
                                </div>
                                <Button variant="danger" className="mr-3" onClick={this.handleClose}>
                                    No
                                </Button>
                                <Button type="submit" variant="success">
                                    Si
                                </Button>
                        </Modal.Body>
                    </form>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
    return {
        isLoading: state.selectionReducer.isLoading,
        setCandidateStatus: state.selectionReducer.candidatesStatus,
        allyData: state.allyReducer.allyData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAllCandidatesStatus: bindActionCreators(setAllCandidatesStatus, dispatch),
        updatePositionsSubstates: bindActionCreators(updatePositionsSubstates, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPendienteOk);