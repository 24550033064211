import React, { Component, Fragment } from "react";
import "./ciudades.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCities } from "../../redux/actions/citiesActions";
import { setAllyCities } from "../../redux/actions/allyActions";

import createReactClass from "create-react-class";

import Header from "../header/Header";
import { Link } from "react-router-dom";

var FilteredList = createReactClass({
  filterList: function (event) {
    var updatedList = this.state.initialItems;
    updatedList = updatedList.filter(function (item) {
      return (
        item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ items: updatedList });
  },
  getInitialState: function () {
    return {
      initialItems: [
        "Apples",
        "Broccoli",
        "Chicken",
        "Duck",
        "Eggs",
        "Fish",
        "Granola",
        "Hash Browns",
      ],
      items: [],
    };
  },
  componentWillMount: function () {
    this.setState({ items: this.state.initialItems });
    if (this.props.items) {
      this.setState({ items: this.props.items });
      this.setState({ initialItems: this.props.items });
    }
  },
  navigateTo: function (route) {
    this.props.history.push(route);
  },
  render: function () {
    return (
      <div className="row">
        <div className="col-12">
          <form>
            <fieldset className="form-group">
              <input
                type="text"
                className="form-control "
                placeholder="Filtrar ciudades"
                onChange={this.filterList}
              />
            </fieldset>
          </form>
        </div>

        <div className="row">
          {this.state.items.map((item, index) => {
            return (
              <div
                className="col-2"
                data-category={item.name}
                key={index.toString()}
              >
                <div
                  className="itemCiudad"
                  style={{ minWidth: "150px" }}
                  onClick={() => this.navigateTo(`cities/aliados/${item.id}`)}
                >
                  <i className="fas fa-building"></i>
                  <h6>{item.name}</h6>
                </div>
              </div>
            );
            //<li className="list-group-item" data-category={item} key={item}>{item}</li>
          })}
        </div>
      </div>
    );
  },
});

class Ciudades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
    };
  }

  componentDidMount = () => {
    this.props.getAllCities().then((resp) => {
      this.setState({
        cities: resp,
      });
    });
    this.props.setAllyCities([]);
  };

  navigateTo = (route) => {
    this.props.history.push(route);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundCiudades container-fluid">
          <div className="customRow text-left">
            <p>
              <strong className="titleSeguimiento text-left">Ciudades </strong>
            </p>
            <p className="subtitleSeguimiento">Selecciona una opción</p>
          </div>

          <div className="containerCiudades container-fluid">
            {this.state.cities.length !== 0 ? (
              <FilteredList
                items={this.state.cities}
                history={this.props.history}
              />
            ) : null}
            {/* {this.props.citiesData.length !== 0
              ? this.props.citiesData.results.map((item, index) => {
                  return (
                    <div className="col-2" key={index.toString()}>
                      <div className="itemCiudad" onClick={() => this.navigateTo(`cities/aliados/${item.id}`)}>
                        <h6>{item.name}</h6>
                      </div>
                    </div>
                  );
                })
              : ""} */}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    citiesData: state.citiesReducer.citiesData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCities: bindActionCreators(getAllCities, dispatch),
    setAllyCities: bindActionCreators(setAllyCities, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ciudades);
