import { GET_ALL_MANAGERS, ISLOADING } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

import swal from 'sweetalert';

export const isLoading = bool => ({
  type: ISLOADING,
  isLoading: bool
});

export const setManager = managersList => ({
  type: GET_ALL_MANAGERS,
  managersList
});

export const getManagersList = () => {
  return async(dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      const responseManager = await axios.get(`${urls.url_base}/R2VzdG9yZXM/`, {headers: {'Authorization': `JWT ${token}`}});
      const data = await responseManager.data;
      if (data.results.length !== 0) {
        dispatch(setManager(data));
      } else { 
        dispatch(setManager([]));
        swal('Lo sentimos, no hay información disponible.');
      }
    } catch (err) {
      dispatch(isLoading(false))
      swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}