import React, { Component, Fragment } from "react";
import { Col, FormGroup, Input } from "reactstrap";
import "./EmprendimientosT.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setTabs,
  updatePositionsSubstates,
} from "../../redux/actions/candidateActions";
import {
  getEmprendimientoBySector,
  setEmpredDataAlly,
} from "../../redux/actions/emprendActions";
import { Link } from "react-router-dom";
import Header from "../header/Header";
import Pagination from "react-js-pagination";

import Swal from "sweetalert2";
import swal from "sweetalert";

function EmprendimientoRow(props) {
  const emprendimiento = props.emprendimiento;
  const date = emprendimiento.timestamp.split("T")[0];

  return (
    <tr id="tr" key={emprendimiento.id.toString()}>
      <td className="titleItemRow">{emprendimiento.fullName}</td>
      <td className="titleItemRow">{emprendimiento.authorizingContact}</td>
      <td className="titleItemRow">{emprendimiento.cellphone}</td>
      <td className="titleItemRow">{emprendimiento.city}</td>
      <td className="titleItemRow">{emprendimiento.sector}</td>
      <td className="titleItemRow">{date}</td>
      <td className="titleItemRow">
        <div className="btn btn-warning text-black" style={{ minWidth: 210 }}>
          {emprendimiento.stateName === "Remitidos" ? "Activo" : "Inactivo"}
        </div>
      </td>
      <td>
        <Link to={`/${props.path}/detalleEmprendimiento/${emprendimiento.id}`}>
          <i className="fas fa-pencil-alt text-info"></i>
        </Link>
      </td>
    </tr>
  );
}

class SectoresEmprendimientosT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      multipleCandidate: [],
      program: ["Agencia", "Programa"],
      checkAll: false,
      activePage: 1,
    };
  }

  componentDidMount = () => {
    var sector = this.props.match.params.name;
    this.props.getEmprendimientoBySector(sector);
    console.log(this.props.emprendimientosAlly);
    //this.props.setEmpredDataAlly({ results: [] }, 0);
  };

  changeValidateChange = (e, c) => {
    let tempCandidate = this.state.multipleCandidate;
    let i = tempCandidate.indexOf(c);
    if (i !== -1) {
      tempCandidate.splice(i, 1);
      this.setState({
        multipleCandidate: tempCandidate,
      });
    } else {
      tempCandidate.push(c);
      this.setState({
        multipleCandidate: tempCandidate,
      });
    }
  };

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  onClickSendToRevision = () => {
    Swal.fire({
      icon: "success",
      title: "Candidatos Enviados A Revision con Alianza",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  onClickSendAllToRevision = () => {
    Swal.fire({
      icon: "success",
      title: "Todos Los Candidatos Fueron Enviados A Revision con Alianza",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  sendAll = (e) => {
    if (e) {
      this.setState({
        multipleCandidate: this.props.candidatesAlly.results,
        checkAll: true,
      });
    } else {
      this.setState({
        multipleCandidate: [],
        checkAll: false,
      });
    }
  };

  handlePageChange = (e) => {
    window.scroll(0, 0);
    var city = this.props.match.params.name;
    this.props.getEmprendimientosByCity(city, e);

    this.setState({
      activePage: e,
    });
  };

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value),
    });
  };

  renderOptionsPage = () => {
    let options = [];
    if (this.props.countEmpred !== 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.countEmpred / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>
                {i}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundAliadosExternos">
          <div className="row containerHeader">
            <div className="customRow mb-5 text-left">
              <p>
                <strong className="titleSeguimiento text-left">
                  Emprendimientos Remitidos
                </strong>
              </p>
              <p className="subtitleSeguimiento">
                Listado de emprendimientos remitidos de{" "}
                {this.props.match.params.name}
              </p>
            </div>
          </div>

          <div className="table-responsiveAliados containerTable">
            <table className="table tableRelacionamientoAliados">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Emprendimiento
                  </th>
                  <th scope="col" className="titleTable">
                    Nombre Autorizante
                  </th>
                  <th scope="col" className="titleTable">
                    Celular
                  </th>
                  <th scope="col" className="titleTable">
                    Ciudad
                  </th>
                  <th scope="col" className="titleTable">
                    Sector
                  </th>
                  <th scope="col" className="titleTable">
                    Fecha
                  </th>
                  <th scope="col" className="titleTable">
                    Estado
                  </th>
                  <th scope=""></th>
                </tr>
              </thead>
              <tbody>
                {this.props.emprendimientosAlly.results.map(
                  (emprendimiento, index) => (
                    <EmprendimientoRow
                      emprendimiento={emprendimiento}
                      key={index.toString()}
                      path={this.props.modulePath}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>

          <Col
            md={12}
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.countEmpred}
              pageRangeDisplayed={5}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={10}
            />
            {this.renderOptionsPage()}
          </Col>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emprendimientosAlly: state.emprendimientoReducer.emprendimientosAlly,
    countEmpred: state.emprendimientoReducer.countEmpred,
    modulePath: state.allyReducer.modulePath,
    allyData: state.allyReducer.allyData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmprendimientoBySector: bindActionCreators(
      getEmprendimientoBySector,
      dispatch
    ),
    setTabs: bindActionCreators(setTabs, dispatch),
    setEmpredDataAlly: bindActionCreators(setEmpredDataAlly, dispatch),
    updatePositionsSubstates: bindActionCreators(
      updatePositionsSubstates,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectoresEmprendimientosT);
