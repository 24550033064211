import React, { Component, Fragment } from "react";
import "./entrevista.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import { getAllInterviewByState } from "../../../redux/actions/selectionActions";
import { setInterviews } from '../../../redux/actions/interviewActions';
import NotificacionAprendiz from "../notificaciones/notificaciones";

class EntrevistaAprendiz extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var obj = {
      all_id: 3,
      idSubState: 5,
    };
    this.props.getAllInterviewByState(1);

    this.props.setInterviews([]);
  };

  componentDidUpdate = () => {
    
  };

  navigateComponent = (route) => {
    this.props.history.push(route);
  }

  render() {
    return (
      <Fragment>
        <Header />

        <div className="col-12 bgResumenSeleccion">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">Entrevistas</strong>
            </p>

            <p className="subtitleSeguimiento">
              Opciones para manipulación entrevistas.
            </p>
          </div>

          <div className="col-12 mb-5">
            <NotificacionAprendiz />
          </div>

          <div className="row" style={{width: '98%', margin:'0 auto'}}>
            <div className="col-4">
              <button className="btnEnviarRegistro btn-info" onClick={() => {this.navigateComponent(`aprendiz/asignarEntrevista`)}}>
                <strong>Entrevistas Selección</strong>
              </button>
            </div>

            <div className="col-4">
              <button className="btnEnviarRegistro btn-info" onClick={() => {this.navigateComponent(`aprendiz/actualizarEntrevista`)}}>
                <strong>Entrevistas Clientes</strong>
              </button>
            </div>

            <div className="col-4">
              <button className="btnEnviarRegistro btn-info" onClick={() => {this.navigateComponent(`aprendiz/pendientes`)}}>
                <strong>Actualizar Pendientes de Contratación</strong>
              </button>
            </div>
          </div>
          {/* <div className="table-responsiveAprendiz containerResumenSeleccion">
            <table className="table tableResumenSeleccion">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Cédula
                  </th>
                  <th scope="col" className="titleTable">
                    Nombre
                  </th>
                  <th scope="col" className="titleTable">
                    Fecha
                  </th>
                  <th scope="col" className="titleTable">
                    Hora
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.getInterviewByState.length !== 0
                  ? this.props.getInterviewByState.results.map(
                      (item, index) => {
                        return (
                          <tr
                            style={{ backgroundColor: "white" }}
                            key={index.toString()}
                          >
                            <td>
                              <p className="titleItemRow">
                                {item.candidate.document}
                              </p>
                            </td>
                            <td>
                              <p className="titleItemRow">
                                {item.candidate.fullName}
                              </p>
                            </td>
                            <td>
                              <p className="titleItemRow">
                                {item.dateInterview}
                              </p>
                            </td>
                            <td>
                              <p className="titleItemRow">
                                {item.hourInterview}
                              </p>
                            </td>
                          </tr>
                        );
                      }
                    )
                  : ""}
              </tbody>
            </table>

            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div> */}
        </div>
        <div></div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.selectionReducer.isLoading,
    getInterviewByState: state.selectionReducer.interviewByState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllInterviewByState: bindActionCreators(getAllInterviewByState, dispatch),
    setInterviews: bindActionCreators(setInterviews, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EntrevistaAprendiz);
