import React, { Component, Fragment } from "react";
import "./noPasaronProceso.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../../header/Header";
import { getAllSelectionByState } from "../../../../redux/actions/selectionActions";
import ModalUpOk from "../../../seleccion/popUpOk/popUpOk";

class NoPasaronProcesoAcompanamiento extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    var obj = {
      all_id: this.props.match.params.ally,
      idSubState: this.props.match.params.substatus,
    };
    this.props.getAllSelectionByState(obj);
  };

  componentDidUpdate = () => {
    console.log("No pasaron proceso", this.props);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12 containerTitleSeleccion mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                No Pasaron Proceso
              </strong>
            </p>
            <p className="subtitleSeguimiento">
              Listado de candidatos que no pasaron proceso
            </p>
          </div>

          <div className="table-responsiveNoPasaronProceso containerResumenSeleccion">
            <table className="table tableResumenSeleccion">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Cédula
                  </th>
                  <th scope="col" className="titleTable">
                    Nombre
                  </th>
                  <th scope="col" className="titleTable">
                    Cargo
                  </th>
                  <th scope="col" className="titleTable">
                    Fecha de entrevista
                  </th>
                  <th scope="col" className="titleTable">
                    Motivo
                  </th>
                  <th scope="col" className="titleTable">
                    Opción
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.getSelectionByState.length !== 0
                  ? this.props.getSelectionByState.results.map(
                      (item, index) => {
                        return (
                          <tr key={index.toString()}>
                            <td>{item.document}</td>
                            <td>{item.fullName}</td>
                            <td>{item.positions[0].positionName}</td>
                            <td>{item.timestamp}</td>
                            <td>{item.requestsReentry}</td>
                            <td>
                              <Link
                                to={`/acompanamiento/acompanamiento/viewCandidate/${item.id}`}
                              >
                                <img
                                  src={require("../../../../assets/IconAliadosExternos/hv.png")}
                                  alt="Hoja de vida"
                                  className="iconHojaVida"
                                ></img>
                              </Link>
                            </td>
                          </tr>
                        );
                      }
                    )
                  : ""}

                <tr id="tr">
                  <td className="titleItemRow">1019865465</td>
                  <td className="titleItemRow">Carlos Perez</td>
                  <td className="titleItemRow">Analista</td>
                  <td className="titleItemRow">Marzo 15</td>
                  <td className="titleItemRow">Aspecto Fisico</td>
                  <td className="titleItemRow">ARN</td>

                  <td>
                    <div className="row">
                      <div className="col-3 mr-2">
                        <Link
                          to={`/seleccion/seleccion/viewCandidate/26`}
                          className="f-left"
                        >
                          <i className="fas fa-pencil-alt text-info"></i>
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.selectionReducer.isLoading,
    getSelectionByState: state.selectionReducer.getSelectionByState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllSelectionByState: bindActionCreators(
      getAllSelectionByState,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoPasaronProcesoAcompanamiento);
