import React, { Component, Fragment } from 'react';
import './barras.styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  } from 'recharts';

  

class BarrasSeleccion extends Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render () {
        const data = [
          {
            name: 'Ago', hombres: 15, mujeres: 40,
          },
          {
            name: 'Sep', hombres: 15, mujeres: 40,
          },
          {
            name: 'Oct', hombres: 22, mujeres: 54,
          },
          {
            name: 'Nov', hombres: 12, mujeres: 41,
          },
          {
            name: 'Dic', hombres: 15, mujeres: 40,
          },
          {
            name: 'Ene', hombres: 53, mujeres: 25,
          },
            
          ];
    
        return (
            <ResponsiveContainer width='100%' aspect={4.0/3.0}>
                <LineChart
            height={300}
            data={data}
            margin={{
            top: 5, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="hombres" stroke="#204d6a" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="mujeres" stroke="#82ca9d" />
        </LineChart>
        </ResponsiveContainer>
        )
    }
    
}

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BarrasSeleccion);