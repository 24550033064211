import React, { Component } from "react";
import { FormGroup, Input, Col, Row, CustomInput } from 'reactstrap';
import Header from "../../../header/Header";
import { connect } from "react-redux";
import { addCourse } from '../../../../redux/actions/courseActions';
import { bindActionCreators } from "redux";
import { uploadSingleImage } from '../../../../services/uploadFile';
import './addCursosTalleres.css';
import swal from "sweetalert";

class AddCursosTalleres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePath: []
    }
  }

  handleChangeFile = e => {
    if (e.target.files[0]) {
      const imageFile = e.target.files[0];
      this.setState({
        imagePath: imageFile
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    var obj = {
      sector: event.target.groupSelect.value,
      date: event.target.fecha.value,
      hour: event.target.hora.value,
      responsable: event.target.responsable.value,
      place: event.target.lugar.value,
      thematic: event.target.tematica.value
    }
    if (this.state.imagePath.length !== 0) {
      uploadSingleImage(this.state.imagePath).then((data) => {
        obj.medology = data.file;
        this.props.addCourse(obj).then(resp => {
          if (resp) {
            swal('Genial!', 'El taller/curso se ha creado correctamente.', 'success').then(() => {
              this.props.history.goBack();
            });
          }
        });
      });
    } else {
      swal('Por favor adjunta la metodología');
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  render() {
    return (
      <>
        <Header />
        <div className="col-12  mb-5 text-left">
          <strong className="titleTaller text-center">
            Nuevo Taller/Curso
          </strong>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 bgResumenSeleccion">
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 containerTitleSeleccion mb-5">
            <br />

            <div className="row">
              <div className="col">
                <div className="card cardNewTaller col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card-body">

                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group">
                            <label>Sector Económico</label>
                            <Input type="select" className="form-control" name="groupSelect">
                              <option>Artesanías</option>
                              <option>Confección</option>
                              <option>Turismo</option>
                              <option>Industria</option>
                            </Input>
                          </div>

                          <FormGroup>
                            <label>Temática</label>
                            <Input
                              type="text"
                              name="tematica"
                              required
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Fecha</label>
                            <Input
                              min={this.formatDate(new Date())}
                              type="date"
                              name="fecha"
                              required
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Hora</label>
                            <Input
                              type="time"
                              name="hora"
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Responsable</label>
                            <Input
                              type="select"
                              name="responsable"
                            >
                              <option>Alianza</option>
                              <option>Pendientes por definir</option>
                            </Input>
                          </FormGroup>

                          <FormGroup>
                            <label>Metodología</label>
                            <Input
                              type="file"
                              name="metodologia"
                              onChange={this.handleChangeFile}
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Lugar</label>
                            <Input type="text" name="lugar" />
                          </FormGroup>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mr-5">
                          <button type="submit" className="btn btn-info ">
                            <strong>Guardar</strong>
                          </button>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 ml-12">
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => this.props.history.goBack()}
                          >
                            <strong>Cancelar</strong>
                          </button>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    isLoading: state.accompanimentReducer.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addCourse: bindActionCreators(addCourse, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCursosTalleres);
