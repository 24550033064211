import { ISLOADING, GET_BUSINESS } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';
import swal from 'sweetalert';

export const isLoading = bool => ({
    type: ISLOADING,
    isLoading: bool
});

export const getBusiness = businessData => ({
    type: GET_BUSINESS,
    businessData
});

export const getAllBusiness = () => {
    return async(dispatch) => {
        // let candidate = [];
        dispatch(isLoading(true))
        try {
            const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
              headers: {'Content-Type': 'application/json'}
            });
            const token = await reponseToken.data.token;
            const responseCities = await axios.get(`${urls.url_base}/QnVzaW5lc3M/`, {headers: {'Authorization': `JWT ${token}`}});
            const data = await responseCities.data;
            if (data.results.length !== 0) {
              dispatch(getBusiness(data));
            } else {
              dispatch(getBusiness([]));
              swal('No hay información disponible');
            }
        } catch (err) {
            dispatch(isLoading(false))
            alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
        }
    }
}