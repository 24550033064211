import React, { Component } from "react";
import Header from "../../../../header/Header";
import { connect } from "react-redux";
import { add_Workshop } from '../../../../../redux/actions/acompanamientoActions';
import { bindActionCreators } from "redux";
import './addTalleres.css';

class AddTalleresPracticante extends Component {
  constructor(props) {
    super(props);
    this.state = {}
}

  handleSubmit = (event) => {
    event.preventDefault();
    var obj = {
        group: event.target.grupo.value,
        constancy: event.target.constancia.value,
        day: event.target.dia.value,
        place: event.target.lugar.value
    }
    this.props.add_Workshop(obj);
  }


  render() {
    return (
      <>
        <Header />
        <div className="col-12  mb-5 text-left">
          <strong className="titleTaller text-center">
            Nuevo Taller
          </strong>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 bgResumenSeleccion">
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 containerTitleSeleccion mb-5">
            <br />

            <div className="row">
              <div className="col">
                <div className="card cardNewTaller col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card-body">

                    <form onSubmit={this.handleSubmit}>
                      <div className="row text-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group">
                            <label>
                              <h6>Grupo</h6>
                            </label>
                            <select className="form-control" name="grupo">
                              <option value="grupo A">Grupo A</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <label>
                            <h6 className="text-left">Constancia</h6>
                          </label>
                          <br />
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                              <label className="mr-2">Diaria</label>
                              <input type="radio" name="constancia" id="unique1" value="1" />
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                              <label className="mr-2">Semanal</label>
                              <input type="radio" name="constancia" id="unique2" value="2" />
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                              <label className="mr-2">Mensual</label>
                              <input type="radio" name="constancia" id="unique3" value="3" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group">
                            <label>
                              <h6 className="text-left">Día</h6>
                            </label>
                            <select className="form-control" name="dia">
                              <option value="Lunes">Lunes</option>
                              <option value="Martes">Martes</option>
                              <option value="Miercoles">Miercoles</option>
                              <option value="Jueves">Jueves</option>
                              <option value="Viernes">Viernes</option>
                              <option value="Sábado">Sábado</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group">
                            <label>
                              <h6 className="text-left">Lugar</h6>
                            </label>
                            <input className="form-control" type="text" name="lugar" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mr-5">
                          <button type="submit" className="btn btn-info ">
                            <strong>Guardar</strong>
                          </button>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 ml-12">
                          <button className="btn btn-secondary">
                            <strong>Cancelar</strong>
                          </button>
                        </div>
                      </div>
                    </form>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
      isLoading: state.accompanimentReducer.isLoading,
      addWorkshop: state.accompanimentReducer.workshop
  }
}

const mapDispatchToProps = dispatch => {
  return {
    add_Workshop: bindActionCreators(add_Workshop, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTalleresPracticante);
