import React, { Component, Fragment } from "react";
import "./ciudades.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCities } from "../../redux/actions/citiesActions";
import { setAllyCities } from "../../redux/actions/allyActions";

import createReactClass from "create-react-class";

import Header from "../header/Header";
import { Link } from "react-router-dom";

var FilteredList = createReactClass({
  filterList: function (event) {
    var updatedList = this.state.initialItems;
    updatedList = updatedList.filter(function (item) {
      return (
        item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ items: updatedList });
  },
  getInitialState: function () {
    return {
      initialItems: [
        "Apples",
        "Broccoli",
        "Chicken",
        "Duck",
        "Eggs",
        "Fish",
        "Granola",
        "Hash Browns",
      ],
      items: [],
    };
  },
  componentWillMount: function () {
    this.setState({ items: this.state.initialItems });
    if (this.props.items) {
      this.setState({ items: this.props.items });
      this.setState({ initialItems: this.props.items });
    }
  },
  navigateTo: function (route) {
    this.props.history.push(route);
  },
  render: function () {
    return (
      <div className="row" style={{ width: "100%" }}>
        <div className="row" style={{ width: "100%" }}>
          <form>
            <fieldset className="form-group">
              <input
                type="text"
                className="form-control "
                placeholder="Filtrar departamentos"
                onChange={this.filterList}
              />
            </fieldset>
          </form>
        </div>
        <div className="row" style={{ width: "100%" }}>
          {this.state.items.map((item, index) => {
            return (
              <div
                className="col-4"
                data-category={item.name}
                key={index.toString()}
              >
                <div
                  className="itemCiudad"
                  style={{ minWidth: "150px", flexDirection: "column" }}
                  onClick={() =>
                    this.navigateTo(`departments/city/${item.name}`)
                  }
                >
                  <i className="fas fa-building"></i>
                  <h6 style={{ padding: "0 8px" }}>{item.name}</h6>
                  {/* <span>0</span> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
});

class Departments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departments: [
        { id: 1, name: "Amazonas" },
        { id: 2, name: "Antioquia" },
        { id: 3, name: "Arauca" },
        { id: 4, name: "Atlántico" },
        { id: 5, name: "Bogotá" },
        { id: 6, name: "Bolívar" },
        { id: 7, name: "Boyacá" },
        { id: 8, name: "Caldas" },
        { id: 9, name: "Caquetá" },
        { id: 10, name: "Casanare" },
        { id: 11, name: "Cauca" },
        { id: 12, name: "Cesar" },
        { id: 13, name: "Chocó" },
        { id: 14, name: "Córdoba" },
        { id: 15, name: "Cundinamarca" },
        { id: 16, name: "Güainia" },
        { id: 17, name: "Guaviare" },
        { id: 18, name: "Huila" },
        { id: 19, name: "La Guajira" },
        { id: 20, name: "Magdalena" },
        { id: 21, name: "Meta" },
        { id: 22, name: "Nariño" },
        { id: 23, name: "Norte de Santander" },
        { id: 24, name: "Putumayo" },
        { id: 25, name: "Quindío" },
        { id: 26, name: "Risaralda" },
        { id: 27, name: "San Andrés y Providencia" },
        { id: 28, name: "Santander" },
        { id: 29, name: "Sucre" },
        { id: 30, name: "Tolima" },
        { id: 31, name: "Valle del Cauca" },
        { id: 32, name: "Vaupés" },
        { id: 33, name: "Vichada" },
      ],
    };
  }

  navigateTo = (route) => {
    this.props.history.push(route);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundCiudades">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Departamentos
              </strong>
            </p>
            <p className="subtitleSeguimiento">Selecciona una opción</p>
          </div>

          <div className=" container-fluid containerCiudades">
            {
              <FilteredList
                items={this.state.departments}
                history={this.props.history}
              />
            }
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
