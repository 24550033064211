import React, { Component } from "react";
import {
 Card,
 CardBody,
 CardHeader,
 Col,
 Row,
 FormGroup,
 Form,
 Input,
 Label,
 CardFooter,
 Button,
 FormFeedback,
 FormText,
 TabContent,
 TabPane,
 Nav,
 NavItem,
 NavLink,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
 getAllCandidates,
 add_Candidate,
 get_all_agencies,
 get_all_programs,
 existCandidateDoc,
} from "../../../redux/actions/candidateActions";
import "./hojaVida.styles.css";
import { uploadSingleImage } from "../../../services/uploadFile";
import { Redirect } from "react-router-dom";
import { getAllCities } from "../../../redux/actions/citiesActions";
import { getAllAllys } from "../../../redux/actions/allyActions";
import { getAllClient } from "../../../redux/actions/clientsActions";
import { getRequestVacantes } from "../../../redux/actions/vacanteActions";
import { addTraceability } from "../../../redux/actions/traceabilityActions";
import { formatDateYYMMDD } from "../../../utils/formatDate";
import classnames from "classnames";
import swal from "sweetalert";
import Swal from "sweetalert2";

import { formatDate } from "../../../utils/timestampToDate";

class HojaVida extends Component {
 constructor(props) {
  super(props);
  this.state = this.setInitialData();
 }

 handleLoadVacantes = (e) => {
  let item = JSON.parse(e.target.value);
  console.log(item);
  let today = formatDateYYMMDD(new Date());
  let id = item.id;
  let city = item.idCity.split("/")[5];
  this.props.getRequestVacantes(id, 1, city, today);
 };

 componentDidMount = () => {
  this.props.getAllCities();
  this.props.getAllAllys();
  this.props.getAllClient();
  this.props.get_all_agencies();
  this.props.get_all_programs();
  if (this.props.allyData.length !== 0) {
   let today = formatDateYYMMDD(new Date());
   //let id = this.props.allyData[0].advisorData.id;
   //let city = this.props.allyData[0].advisorData.idCity.split("/")[5];
   //this.props.getRequestVacantes(id, 1, city, today);
  }
 };

 componentDidUpdate = () => {};

 setInitialData = () => {
  var defaultId = "";
  var allyType = 0;
  var programType = 0;
  /*  if (this.props.allyData.length !== 0) {
      programType = this.props.allyData[0].advisorData.programType;
      if (this.props.allyData[0].advisorData.allyType == 0) {
        defaultId = this.props.allyData[0].advisorData.url;
        allyType = 0;
      } else {
        defaultId = this.props.allyData[0].advisorData.url;
        allyType = 1;
      }
    } */

  return {
   programType,
   activeTab: "1",
   visibleTabs: this.props.visibleTabs,
   admissionDate: new Date(),
   birthDate: "",
   age: null,
   fullName: "",
   document: "",
   militaryCard: "",
   phone: "",
   email: "",
   gender: "",
   cellphone: "",
   cellphoneTwo: "",
   scholarship: "N/A",
   city: "",
   direction: "",
   neighborhood: "",
   commune: "",
   program: null,
   profilePicture: {},
   candidateState: 0,
   observationsConcept: "",
   candidateSubstate: 0,
   personalPresentation: false,
   emotionalManagement: false,
   findings: false,
   txtFindings: null,
   documentationFiles: null,
   idProgram: defaultId,
   slcPersonalPresentation: "",
   slcEmotionalManagement: "",
   slcFindings: "no",
   disableFindings: true,
   disableMilitaryCard: false,
   positions: [
    {
     positionName: "",
     companyApply: "",
    },
   ],
   documentArr: [
    {
     docName: "",
     path: [],
    },
   ],
   imagePath: [],
   documents: "",
   whoIs: allyType,
   companyName: "",
   arnStatus: "N/A",
   arnPoblation: "N/A",
   career: "",
   civilStatus: "S/I",
   currentlyStudying: "false",
   whatStudies: "",
   dontSave: false,
   errors: {
    photoErr: {},
    fullNameErr: {},
    genderErr: {},
    documentErr: {},
    cellphoneErr: {},
    addressErr: {},
    neighborhoodErr: {},
    sectorErr: {},
    emailErr: {},
    birthDateErr: {},
    positionsErr: {},
   },
  };
 };

 handleSelect = (e) => {
  this.setState({ slcPersonalPresentation: e.target.value });
 };

 handleSelect2 = (e) => {
  this.setState({ slcEmotionalManagement: e.target.value });
 };

 handleSelect3 = (e) => {
  this.setState({ slcFindings: e.target.value });
  if (this.state.disableFindings) {
   this.setState({ disableFindings: false });
  } else {
   this.setState({ disableFindings: true });
  }
 };

 handleChange = (e) => {
  const newState = this.state;
  newState[e.target.id] = e.target.value;
  this.setState(newState);

  if (this.state.gender === "F") {
   this.setState({ disableMilitaryCard: true, militaryCard: 0 });
  } else {
   this.setState({ disableMilitaryCard: false });
  }
 };

 handleChangePositionsObj = (e, key, i) => {
  const newState = this.state;
  newState[e.target.id][i][key] = e.target.value;
  if (key == "positionName") {
   let name = this.props.vacanteData2.results.find(
    (item) => item.url === e.target.value
   ).nameClient;
   let url = this.props.vacanteData2.results.find(
    (item) => item.url === e.target.value
   ).idClient;
   newState["positions"][i]["companyApply"] = url;
   newState["companyName"] = name;
  }
  this.setState(newState);
 };

 handleChangeFile = (e) => {
  if (e.target.files[0]) {
   const imageFile = e.target.files[0];
   this.setState({
    imagePath: imageFile,
   });
  }
 };

 handleChangeMultiFile = (e, key, i) => {
  if (e.target.files[0]) {
   const imageFile = e.target.files[0];
   this.state.documentArr[i][key] = imageFile;
  }
 };

 addDocuments = () => {
  var tempDocs = this.state.documentArr;
  tempDocs.push({
   docName: "",
   path: [],
  });
  this.setState({
   documentArr: tempDocs,
  });
 };

 rmDocuments = (item, index) => {
  var tempDocs = this.state.documentArr;
  var i = tempDocs.indexOf(item);
  if (i !== -1) {
   tempDocs.splice(i, 1);
   this.setState({
    documentArr: tempDocs,
   });
  }
 };

 addPositions = () => {
  var tempPosition = this.state.positions;
  tempPosition.push({
   positionName: "",
   companyApply: "",
  });
  this.setState({
   positions: tempPosition,
  });
 };

 rmPositions = (item, index) => {
  if (this.state.positions.length > 1) {
   let tempPositions = this.state.positions;
   let i = tempPositions.indexOf(item);
   if (i !== -1) {
    tempPositions.splice(i, 1);
    this.setState({
     Positions: tempPositions,
    });
   }
  }
 };

 onSend = async () => {
  const isValid = this.validateForm();
  if (isValid) {
   this.checkInfoToSave();
  } else {
   swal(
    "Lo sentimos!",
    "Por favor verifica la información de los campos obligatorios antes de continuar.",
    "warning"
   );
   this.setState({
    activeTab: "1",
   });
  }
 };

 checkInfoToSave = () => {
  // Validacion del selector presentacion personal
  if (this.state.slcPersonalPresentation === "cumple") {
   this.setState({ personalPresentation: true });
  }

  // Validacion del selector manejo emocional
  if (this.state.slcEmotionalManagement === "cumple") {
   this.setState({ emotionalManagement: true });
  }

  if (
   this.state.documentArr.length !== 0 &&
   this.state.documentArr[0].docName !== ""
  ) {
   var documents = [];
   this.state.documentArr.forEach(async (item, index) => {
    var data = await uploadSingleImage(item.path);
    documents.push({
     docName: item.docName,
     url: data.file,
    });

    if (this.state.documentArr.length === documents.length) {
     this.setState({
      documentationFiles: documents,
     });
     this.saveDataCandidate();
    }
   });
  } else {
   this.saveDataCandidate();
  }
 };

 saveDataCandidate = () => {
  var objCandidate = {
   admissionDate: formatDate(new Date()),
   birthDate: this.state.birthDate,
   age: this.calculateAge(this.state.birthDate),
   fullName: this.state.fullName,
   document: this.state.document,
   militaryCard: this.state.militaryCard,
   phone: this.state.phone,
   email: this.state.email,
   gender: this.state.gender !== "" ? this.state.gender : "M",
   cellphone: this.state.cellphone,
   cellphoneTwo: this.state.cellphoneTwo,
   scholarship: this.state.scholarship,
   idCity: this.state.city,
   direction: this.state.direction,
   neighborhood: this.state.neighborhood,
   commune: this.state.commune,
   program: this.state.program,
   profilePicture: null,
   candidateState: this.state.candidateState,
   candidateSubstate: this.state.candidateSubstate,
   personalPresentation: this.state.personalPresentation,
   emotionalManagement: this.state.emotionalManagement,
   findings: this.state.findings,
   txtFindings: this.state.txtFindings,
   documentationFiles: JSON.stringify(this.state.documentationFiles),
   idProgram: this.state.idProgram,
   positionsArr: this.state.positions,
   arnStatus: this.state.arnStatus,
   arnPoblation: this.state.arnPoblation,
   career: this.state.career,
   civilStatus: this.state.civilStatus,
   currentlyStudying: this.state.currentlyStudying === "false" ? false : true,
   whatStudies: this.state.whatStudies,
   txtFindingsContent: this.state.observationsConcept,
   findingsContent: this.state.slcFindings === "no" ? false : true,
  };
  var traceObj = {
   action: `Ingresado por Gestor Empleabilidad`,
   candidate: "",
   manager: null,
  };
  if (typeof this.state.imagePath === "object" && this.state.imagePath.name) {
   uploadSingleImage(this.state.imagePath).then((data) => {
    objCandidate.profilePicture = data.file;
    this.props.add_Candidate(objCandidate, traceObj);
   });
  } else {
   this.props.add_Candidate(objCandidate, traceObj);
  }
 };

 confirmSave = () => {
  swal("El candidato se guardo correctamente.");
  this.setInitialData();
 };

 calculateAge = (birth) => {
  var today = new Date();
  var birthDate = new Date(birth);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
   age--;
  }
  return age;
 };

 toggleTab = (index) => {
  this.setState({
   activeTab: index,
  });
 };

 onNextTab = () => {
  let i = parseInt(this.state.activeTab);
  i = i + 1;
  this.setState({
   activeTab: i.toString(),
  });
 };

 validateExistDocument = (e) => {
  let value = e.target.value;
  if (value !== "") {
   this.props.existCandidateDoc(value).then((resp) => {
    if (resp) {
     swal(
      "Oops!",
      "El candidato que intentas registrar ya fue ingresado por otro de nuestros aliados externos",
      "warning"
     ).then(() => {
      this.setState({
       dontSave: true,
      });
     });
    } else {
     this.setState({
      dontSave: false,
     });
    }
   });
  }
 };

 validateForm = () => {
  const validEmail = RegExp(
   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  const photoErr = {};
  const fullNameErr = {};
  const genderErr = {};
  const documentErr = {};
  const cellphoneErr = {};
  const addressErr = {};
  const neighborhoodErr = {};
  const sectorErr = {};
  const emailErr = {};
  const birthDateErr = {};
  const positionsErr = {};
  let isValid = true;

  // if (typeof (this.state.imagePath) === 'object' && this.state.imagePath.name) {
  // 	isValid = true;
  // } else {
  // 	photoErr.required = "Por favor ingresa la foto de perfil del candidato.";
  // 	isValid = false;
  // }
  if (this.state.fullName.trim().length === 0) {
   fullNameErr.required = "Por favor ingresa el nombre completo.";
   isValid = false;
  }
  if (this.state.gender.length === 0) {
   genderErr.required = "Por favor selecciona el género.";
   isValid = false;
  }

  if (this.state.document.toString().length < 3) {
   documentErr.required = "Por favor ingresa un número de documento valido.";
   isValid = false;
  }
  if (this.state.cellphone.toString().length !== 10) {
   cellphoneErr.required = "Número de celular no valido.";
   isValid = false;
  }
  if (this.state.direction.length === 0) {
   addressErr.required = "Por favor ingresa la dirección.";
   isValid = false;
  }
  if (this.state.neighborhood.length === 0) {
   neighborhoodErr.required = "Por favor ingresa el barrio.";
   isValid = false;
  }
  if (this.state.commune.length === 0) {
   sectorErr.required = "Por favor ingresa la información de sector.";
   isValid = false;
  }
  if (!validEmail.test(this.state.email)) {
   emailErr.valid = "Por favor ingresa un correo valido.";
   isValid = false;
  }
  if (this.state.email.length < 15) {
   emailErr.minLength = "El correo es muy corto";
   isValid = false;
  }
  if (this.state.birthDate.length === 0) {
   birthDateErr.required = "Por favor ingresa la fecha de nacimiento.";
   isValid = false;
  }
  if (this.state.positions[0].positionName.length === 0) {
   positionsErr.required = "Por favor selecciona la vacante a postular.";
   isValid = false;
  }

  this.setState({
   errors: {
    photoErr,
    fullNameErr,
    genderErr,
    documentErr,
    cellphoneErr,
    addressErr,
    neighborhoodErr,
    sectorErr,
    emailErr,
    birthDateErr,
    positionsErr,
   },
  });

  return isValid;
 };

 render() {
  return (
   <div className="backgroundHojaVida">
    {this.props.isSaveCandidate === true ? <Redirect to="seleccion" /> : ""}
    <Row style={{ width: "100%", justifyContent: "center" }}>
     <Col
      xs="12"
      md="12">
      <Card>
       <CardHeader>
        <strong>Candidato</strong>
       </CardHeader>
       <CardBody style={{ overflowY: "scroll" }}>
        <Nav tabs>
         {this.state.visibleTabs.includes("1") && (
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "1",
            })}
            onClick={() => {
             this.toggleTab("1");
            }}>
            Información general
           </NavLink>
          </NavItem>
         )}
         {this.state.visibleTabs.includes("2") && (
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "2",
            })}
            onClick={() => {
             this.toggleTab("2");
            }}>
            Cargos a postular
           </NavLink>
          </NavItem>
         )}
         {this.state.visibleTabs.includes("3") && (
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "3",
            })}
            onClick={() => {
             this.toggleTab("3");
            }}>
            Características
           </NavLink>
          </NavItem>
         )}

         {this.state.visibleTabs.includes("4") && (
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "4",
            })}
            onClick={() => {
             this.toggleTab("4");
            }}>
            Documentos
           </NavLink>
          </NavItem>
         )}
         {this.state.visibleTabs.includes("5") && (
          <NavItem>
           <NavLink
            className={classnames({
             active: this.state.activeTab === "5",
            })}
            onClick={() => {
             this.toggleTab("5");
            }}>
            Pruebas psicométricas
           </NavLink>
          </NavItem>
         )}
        </Nav>
        <Form className="form-horizontal">
         <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
           <br />
           <Row>
            <Col
             xs="12"
             md="6">
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Cédula *</label>
              <div className="col-sm-10">
               <input
                type="number"
                min="0"
                className="form-control"
                placeholder="Ingresa número de identificación"
                id="document"
                onChange={this.handleChange}
                onBlur={this.validateExistDocument}
               />
              </div>
              {Object.keys(this.state.errors.documentErr).map(
               (keyObj, index) => (
                <div className="paddingTxtError">
                 <FormText
                  className="errorFormText"
                  key={index.toString()}>
                  {this.state.errors.documentErr[keyObj]}
                 </FormText>
                </div>
               )
              )}
             </div>
             <div className="form-group row containerFoto">
              <label
               className="col-sm-12"
               htmlFor="exampleFormControlFile1">
               Foto Perfil *
              </label>
              <div className="col-sm-10 containerFile">
               <input
                type="file"
                accept="image/x-png,image/jpeg"
                className="form-control-file"
                id="profilePicture"
                onChange={this.handleChangeFile}
               />
              </div>
              {Object.keys(this.state.errors.photoErr).map((keyObj, index) => (
               <div className="paddingTxtError">
                <FormText
                 className="errorFormText"
                 key={index.toString()}>
                 {this.state.errors.photoErr[keyObj]}
                </FormText>
               </div>
              ))}
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">
               Nombre Completo *
              </label>
              <div className="col-sm-10">
               <input
                type="text"
                className="form-control"
                placeholder="Ingresa tu nombre completo"
                id="fullName"
                onChange={this.handleChange}
               />
              </div>
              {Object.keys(this.state.errors.fullNameErr).map(
               (keyObj, index) => (
                <div className="paddingTxtError">
                 <FormText
                  className="errorFormText"
                  key={index.toString()}>
                  {this.state.errors.fullNameErr[keyObj]}
                 </FormText>
                </div>
               )
              )}
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Genero *</label>
              <div className="col-sm-10">
               <select
                className="form-control"
                id="gender"
                onChange={this.handleChange}
                value={this.state.gender}>
                <option
                 value=""
                 disabled>
                 Seleccione el genero
                </option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
                <option value="No">No binario</option>
               </select>
              </div>
              {Object.keys(this.state.errors.genderErr).map((keyObj, index) => (
               <div className="paddingTxtError">
                <FormText
                 className="errorFormText"
                 key={index.toString()}>
                 {this.state.errors.genderErr[keyObj]}
                </FormText>
               </div>
              ))}
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Telefono Fijo</label>
              <div className="col-sm-10">
               <input
                type="number"
                className="form-control"
                placeholder="Ingresa un telefono fijo"
                id="phone"
                min="0"
                onChange={this.handleChange}
               />
              </div>
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">
               Libreta Militar
              </label>
              <div className="col-sm-10">
               <input
                type="number"
                min="0"
                className="form-control"
                placeholder="Ingresa tu numero de libreta militar"
                id="militaryCard"
                disabled={this.state.disableMilitaryCard}
                onChange={this.handleChange}
               />
              </div>
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Celular *</label>
              <div className="col-sm-10">
               <input
                type="number"
                min="0"
                className="form-control"
                placeholder="Ingresa tu numero de celular"
                id="cellphone"
                onChange={this.handleChange}
               />
              </div>
              {Object.keys(this.state.errors.cellphoneErr).map(
               (keyObj, index) => (
                <div className="paddingTxtError">
                 <FormText
                  className="errorFormText"
                  key={index.toString()}>
                  {this.state.errors.cellphoneErr[keyObj]}
                 </FormText>
                </div>
               )
              )}
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Celular 2 *</label>
              <div className="col-sm-10">
               <input
                type="number"
                min="0"
                className="form-control"
                placeholder="Ingresa otro numero de celular"
                id="cellphoneTwo"
                onChange={this.handleChange}
               />
              </div>
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Estado Civil *</label>
              <div className="col-sm-10">
               <Input
                type="select"
                className="form-control"
                placeholder="Selecciona el estado civil"
                id="civilStatus"
                onChange={this.handleChange}>
                <option>S/I</option>
                <option>Soltero</option>
                <option>Casado</option>
                <option>Unión libre</option>
                <option>Viudo</option>
                <option>Separado</option>
                <option>Divorciado</option>
               </Input>
              </div>
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Formación *</label>
              <div className="col-sm-10">
               <Input
                type="select"
                className="form-control"
                placeholder="Ingresa tu nivel de formación"
                id="scholarship"
                onChange={this.handleChange}>
                <option>N/A</option>
                <option>Tercero</option>
                <option>Cuarto</option>
                <option>Quinto</option>
                <option>Sexto</option>
                <option>Séptimo</option>
                <option>Octavo</option>
                <option>Noveno</option>
                <option>Décimo</option>
                {/* <option>Once</option> */}
                <option>Bachiller</option>
                <option>Técnico</option>
                <option>Tecnólogo</option>
                <option>Profesional</option>
                <option>Especialización</option>
                <option>Maestria</option>
                <option>Doctorado</option>
               </Input>
              </div>
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">
               Formación en ? *
              </label>
              <div className="col-sm-10">
               <input
                type="text"
                className="form-control"
                placeholder="Ingresa el nombre de la técnica, tecnologia o carrera profesional"
                id="career"
                onChange={this.handleChange}
               />
              </div>
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">
               Estudia actualmente? *
              </label>
              <div className="col-sm-10">
               <Input
                type="select"
                className="form-control"
                placeholder="Ingresa tu nivel de formación"
                id="currentlyStudying"
                onChange={this.handleChange}>
                <option value="false">No</option>
                <option value="true">Si</option>
               </Input>
              </div>
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Que estudia? *</label>
              <div className="col-sm-10">
               <Input
                type="text"
                className="form-control"
                placeholder="Nombre de lo que estudia"
                id="whatStudies"
                disabled={
                 this.state.currentlyStudying === "false" ? true : false
                }
                onChange={this.handleChange}
               />
              </div>
             </div>
            </Col>
            <Col
             xs="12"
             md="6">
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Ciudad *</label>
              <div className="col-sm-10">
               <select
                className="form-control"
                id="city"
                value={this.state.city}
                onChange={this.handleChange}>
                <option
                 value=""
                 disabled>
                 Seleccione tu ciudad
                </option>
                {this.props.citiesData.length != 0
                 ? this.props.citiesData.results.map((item, index) => {
                    return (
                     <option
                      key={index.toString()}
                      value={item.url}>
                      {item.name}
                     </option>
                    );
                   })
                 : null}
               </select>
              </div>
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Dirección *</label>
              <div className="col-sm-10">
               <input
                type="text"
                className="form-control"
                placeholder="Ingresa tu dirección"
                id="direction"
                onChange={this.handleChange}
               />
              </div>
              {Object.keys(this.state.errors.addressErr).map(
               (keyObj, index) => (
                <div className="paddingTxtError">
                 <FormText
                  className="errorFormText"
                  key={index.toString()}>
                  {this.state.errors.addressErr[keyObj]}
                 </FormText>
                </div>
               )
              )}
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Barrio *</label>
              <div className="col-sm-10">
               <input
                type="text"
                className="form-control"
                placeholder="Ingresa tu barrio"
                id="neighborhood"
                onChange={this.handleChange}
               />
              </div>
              {Object.keys(this.state.errors.neighborhoodErr).map(
               (keyObj, index) => (
                <div className="paddingTxtError">
                 <FormText
                  className="errorFormText"
                  key={index.toString()}>
                  {this.state.errors.neighborhoodErr[keyObj]}
                 </FormText>
                </div>
               )
              )}
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Sector *</label>
              <div className="col-sm-10">
               <input
                type="text"
                className="form-control"
                placeholder="Sector / Comuna / Localidad"
                id="commune"
                onChange={this.handleChange}
               />
              </div>
              {Object.keys(this.state.errors.sectorErr).map((keyObj, index) => (
               <div className="paddingTxtError">
                <FormText
                 className="errorFormText"
                 key={index.toString()}>
                 {this.state.errors.sectorErr[keyObj]}
                </FormText>
               </div>
              ))}
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">
               Correo electrónico *
              </label>
              <div className="col-sm-10">
               <input
                type="text"
                className="form-control"
                placeholder="Ingresa tu correo electronico"
                id="email"
                onChange={this.handleChange}
               />
              </div>
              {Object.keys(this.state.errors.emailErr).map((keyObj, index) => (
               <div className="paddingTxtError">
                <FormText
                 className="errorFormText"
                 key={index.toString()}>
                 {this.state.errors.emailErr[keyObj]}
                </FormText>
               </div>
              ))}
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">
               Fecha Nacimiento *
              </label>
              <div className="col-sm-10">
               <input
                type="date"
                required
                pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                className="form-control"
                placeholder=""
                id="birthDate"
                onChange={this.handleChange}
               />
              </div>
              {Object.keys(this.state.errors.birthDateErr).map(
               (keyObj, index) => (
                <div className="paddingTxtError">
                 <FormText
                  className="errorFormText"
                  key={index.toString()}>
                  {this.state.errors.birthDateErr[keyObj]}
                 </FormText>
                </div>
               )
              )}
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Programa *</label>
              <div className="col-sm-10">
               <select
                className="form-control"
                id={this.state.whoIs === 0 ? "idProgram" : "program"}
                onChange={this.handleChange}
                value={
                 this.state.whoIs === 0
                  ? this.state.idProgram
                  : this.state.program
                }>
                <option
                 value=""
                 selected
                 disabled>
                 Seleccione un programa
                </option>
                <option value={null}>No aplica</option>
                {this.props.agencies.length != 0
                 ? this.props.agencies.results.map((item, index) => {
                    return (
                     <option
                      key={index.toString()}
                      value={item.url}>
                      {item.fullName}
                     </option>
                    );
                   })
                 : null}
               </select>
              </div>
             </div>
             {/*  <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                              Agencia *
                            </label>
                            <div className="col-sm-10">
                              <select
                                className="form-control"
                                id={"idProgram"}
                                onChange={this.handleChange}
                                value={this.state.idProgram}
                              >
                                <option value="" selected disabled>
                                  Seleccione un programa
                                </option>
                                <option value={null}>No pertenece</option>
                                {this.props.programs.length != 0
                                  ? this.props.programs.results.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            key={index.toString()}
                                            value={item.url}
                                          >
                                            {item.fullName}
                                          </option>
                                        );
                                      }
                                    )
                                  : null}
                              </select>
                            </div>
                          </div> */}
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">
               Tipo de población ARN
              </label>
              <div className="col-sm-10">
               <Input
                type="select"
                className="form-contol"
                id="arnPoblation"
                onChange={this.handleChange}
                value={this.state.arnPoblation}
                disabled={this.state.programType === 2 ? true : false}>
                <option>S/I</option>
                <option>Victima</option>
                <option>Familiar</option>
                <option>Justicia y Paz</option>
                <option>Reincorporación</option>
                <option>Reintegración</option>
                <option>Pospenados</option>
                <option>Migrantes</option>
               </Input>
              </div>
             </div>
             <div className="form-group row">
              <label className="col-sm-12 col-form-label">Estado ARN</label>
              <div className="col-sm-10">
               <Input
                type="select"
                className="form-contol"
                id="arnStatus"
                onChange={this.handleChange}
                value={this.state.arnStatus}
                disabled={this.state.programType === 2 ? true : false}>
                <option>S/I</option>
                <option>Activo</option>
                <option>Culminado</option>
                {/* <option>Graduado</option> */}
                {/* <option>PDT</option> */}
                {/* <option>PPR</option> */}
                {/* <option>PPR E</option> */}
                {/* <option>PR</option> */}
                {/* <option>PRR</option> */}
               </Input>
              </div>
             </div>
            </Col>
           </Row>
          </TabPane>
          <TabPane tabId="2">
           <br />
           <div
            className="row containerPlus"
            onClick={() => this.addPositions()}>
            <a className="btnPlus">
             <img
              src={require("../../../assets/Icons/plus.png")}
              alt="Agregar"
              className="iconPlus"
             />
            </a>
           </div>
           {this.state.positions.map((item, index) => {
            return (
             <div
              className="containerDinamic"
              key={index.toString()}>
              <div
               className="row containerPlus"
               onClick={() => this.rmPositions(item, index)}>
               <a className="btnPlus">
                <img
                 src={require("../../../assets/Icons/close.png")}
                 alt="Eliminar"
                 className="iconPlus"
                />
               </a>
              </div>
              <div
               className="row"
               style={{ width: "100%" }}>
               <div className="col-sm-12">
                <div className="form-group row">
                 <label className="col-sm-12 col-form-label">Programa</label>
                 <div className="col-sm-12">
                  <select
                   className="form-control"
                   id="partner"
                   onChange={this.handleLoadVacantes}
                   value={this.state.partner}
                   style={{ position: "absolute" }}>
                   <option
                    value=""
                    defaultValue=""
                    disabled>
                    Seleccione el programa o agencia
                   </option>
                   <option value={null}>No pertenece</option>
                   {this.props.allAllys.length !== 0
                    ? this.props.allAllys.results.map((item, index) => {
                       return (
                        <option
                         key={index.toString()}
                         value={JSON.stringify(item)}>
                         {item.fullName}
                        </option>
                       );
                      })
                    : null}
                  </select>
                 </div>
                </div>
               </div>
              </div>
              <br />
              <div className="form-group row">
               <label className="col-sm-2 col-form-label">
                Vacante a postular
               </label>
               <div className="col-sm-6">
                <select
                 className="form-control"
                 onChange={(e) =>
                  this.handleChangePositionsObj(e, "positionName", index)
                 }
                 id="positions">
                 <option
                  selected
                  value=""
                  disabled>
                  Seleccione la vacante
                 </option>
                 {this.props.vacanteData2.length != 0
                  ? this.props.vacanteData2.results.map((item, index) => {
                     return (
                      <option value={item.url}>{item.vacancyDisplay}</option>
                     );
                    })
                  : null}
                </select>
               </div>
               {Object.keys(this.state.errors.positionsErr).map(
                (keyObj, index) => (
                 <div className="paddingTxtError">
                  <FormText
                   className="errorFormText"
                   key={index.toString()}>
                   {this.state.errors.positionsErr[keyObj]}
                  </FormText>
                 </div>
                )
               )}
              </div>
              <div className="form-group row">
               <label className="col-sm-2 col-form-label">
                Empresa a postular
               </label>
               <div className="col-sm-6">
                <span>{this.state.companyName}</span>
                {/* <select 
																				className="form-control" 
																				onChange={(e) => this.handleChangePositionsObj(e, 'companyApply', index)}
																				id="positions"
																		>
																				<option selected value="" disabled>Seleccione un cliente y/o negocio</option>
																				{this.props.clientData.length != 0 ? this.props.clientData.results.map((item, index) => { return <option value={item.url}>{item.fullName}</option>}) : null}
																		</select> */}
               </div>
              </div>
             </div>
            );
           })}
          </TabPane>
          <TabPane tabId="3">
           <br />
           {/* <div className="form-group row">
												<label className="col-sm-2 col-form-label">Presentacion Personal</label>
												<div className="col-sm-6">
													<select
														className="form-control"
														id="slcPersonalPresentation"
														value={this.state.slcPersonalPresentation}
														onChange={this.handleSelect}
													>
														<option value="" selected disabled>Seleccione una opcion</option>
														<option value="cumple">Cumple</option>
														<option value="no">No Cumple</option>
													</select>
												</div>
											</div>
											<div className="form-group row">
												<label className="col-sm-2 col-form-label">Manejo Emocional</label>
												<div className="col-sm-6">
													<select
														className="form-control"
														id="slcEmotionalManagement"
														value={this.state.slcEmotionalManagement}
														onChange={this.handleSelect2}
													>
														<option value="" selected disabled>Seleccione una opcion</option>
														<option value="cumple">Cumple</option>
														<option value="no">No Cumple</option>
													</select>
												</div>
											</div> */}
           <div className="form-group row">
            <label className="col-sm-2 col-form-label">
             Hallazgos Juridicos
            </label>
            <div className="col-sm-6">
             <select
              className="form-control"
              id="slcFindings"
              onChange={this.handleChange}>
              <option value="no">No</option>
              <option value="si">Si</option>
             </select>
            </div>
           </div>
           {/* <div className="form-group row">
												<div className="col-sm-2"></div>
												<div className="col-sm-6">
													<input
														type="text"
														className="form-control"
														placeholder=""
														disabled={this.state.disableFindings}
														id="txtFindings" />
												</div>
											</div> */}
           <div className="form-group row">
            <label
             className="col-sm-2"
             htmlFor="observationsConcept">
             Conceptos
            </label>
            <div className="col-sm-6">
             <textarea
              className="form-control"
              id="observationsConcept"
              onChange={this.handleChange}
              placeholder="Ingrese breve descripción, hallazgos o información relevante."
              rows="3"
              id="observationsConcept"></textarea>
            </div>
           </div>
          </TabPane>
          <TabPane tabId="4">
           <br />
           <div
            className="row containerPlus"
            onClick={() => this.addDocuments()}>
            <a className="btnPlus">
             <img
              src={require("../../../assets/Icons/plus.png")}
              alt="Agregar"
              className="iconPlus"
             />
            </a>
           </div>
           {this.state.documentArr.map((item, index) => {
            return (
             <div
              className="form-group row containerArchivos"
              key={index.toString()}>
              <label
               className="col-sm-2"
               htmlFor="exampleFormControlFile1">
               Subir Archivos
              </label>
              <div className="col-sm-10 containerFileDocuments">
               <div
                className="contentCloseDocuments"
                onClick={() => this.rmDocuments(item, index)}>
                <a className="btnPlus">
                 <img
                  src={require("../../../assets/Icons/close.png")}
                  alt="Eliminar"
                  className="iconPlus"
                 />
                </a>
               </div>
               <div className="row contentDocumentos">
                <div className="col-sm-6">
                 <select
                  className="form-control"
                  id="documentArr"
                  onChange={(e) =>
                   this.handleChangePositionsObj(e, "docName", index)
                  }>
                  <option
                   value=""
                   disabled
                   selected>
                   Selecciona archivo
                  </option>
                  <option value="ncedula">Cédula</option>
                  <option value="hojaVida">Hoja de vida</option>
                  <option value="Wartegg">Wartegg</option>
                  <option value="psicometrica">Prueba Psicometrica</option>
                  <option value="prueba">Prueba de lectoescritura</option>
                  <option value="consentimiento">
                   Consentimiento informado
                  </option>
                  <option value="consentimiento">Datos personales</option>
                  <option value="policía">Policía</option>
                  <option value="Procuraduría">Procuraduría</option>
                  <option value="Contraloría">Contraloría</option>
                  <option value="Validación institución aliada">
                   Validación institución aliada
                  </option>
                  <option value="otro">Otro</option>
                 </select>
                </div>

                <div className="col-sm-6">
                 <input
                  type="file"
                  className="form-control-file"
                  id="exampleFormControlFile1"
                  onChange={(e) => this.handleChangeMultiFile(e, "path", index)}
                 />
                </div>
               </div>
              </div>
             </div>
            );
           })}
          </TabPane>
          <TabPane tabId="5">
           <br />

           <div className="form-group row">
            <label className="col-sm-2 col-form-label">Estado</label>
            <div className="col-sm-6">
             <select
              className="form-control"
              id="pruebas_estado"
              onChange={this.handleChange}>
              <option value="revision">revisión</option>
              <option value="aprobado">aprobado</option>
              <option value="noaprobado">no aprobado</option>
             </select>
            </div>
           </div>

           <div className="form-group row">
            <label
             className="col-sm-2"
             htmlFor="observationsConcept">
             Sinopsis
            </label>
            <div className="col-sm-6">
             <textarea
              className="form-control"
              id="pruebas_sinopsis"
              onChange={this.handleChange}
              placeholder="Ingrese breve descripción, hallazgos o información relevante."
              rows="3"></textarea>
            </div>
           </div>
          </TabPane>
         </TabContent>
        </Form>
       </CardBody>
       <CardFooter>
        {this.state.activeTab == "4" && (
         <div className="col-12 containerBtnEnviar">
          <button
           className="btn-success btnEnviar"
           onClick={() => this.onSend()}
           disabled={this.state.dontSave}>
           ENVIAR
          </button>
         </div>
        )}
        {this.state.activeTab != "4" && (
         <div className="col-12 containerBtnEnviar">
          <button
           className="btn-success btnEnviar"
           onClick={() => this.onNextTab()}>
           SIGUIENTE
          </button>
         </div>
        )}
       </CardFooter>
      </Card>
     </Col>
    </Row>
   </div>
  );
 }
}

const mapStateToProps = (state) => {
 return {
  candidates: state.candidateReducer.candidates,
  isLoading: state.candidateReducer.isLoading,
  programs: state.candidateReducer.programs,
  agencies: state.candidateReducer.agencies,
  isSaveCandidate: state.candidateReducer.isSaveCandidate,
  citiesData: state.citiesReducer.citiesData,
  allAllys: state.allyReducer.allAllys,
  clientData: state.clientsReducer.clientData,
  allyData: state.allyReducer.allyData,
  vacanteData2: state.vacanteReducer.vacanteData2,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  getAllCandidates: bindActionCreators(getAllCandidates, dispatch),
  add_Candidate: bindActionCreators(add_Candidate, dispatch),
  getAllCities: bindActionCreators(getAllCities, dispatch),
  getAllAllys: bindActionCreators(getAllAllys, dispatch),
  getAllClient: bindActionCreators(getAllClient, dispatch),
  get_all_agencies: bindActionCreators(get_all_agencies, dispatch),
  get_all_programs: bindActionCreators(get_all_programs, dispatch),
  getRequestVacantes: bindActionCreators(getRequestVacantes, dispatch),
  addTraceability: bindActionCreators(addTraceability, dispatch),
  existCandidateDoc: bindActionCreators(existCandidateDoc, dispatch),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(HojaVida);
