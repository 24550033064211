import React, { Component, Fragment } from "react";
import { Table, Row, Col, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getCandidatesByBusiness,
  setCandidate,
  setCount,
} from "../../../../redux/actions/candidateActions";
import Header from "../../../header/Header";
import ModalEntrevistaCliente from "../../../seleccion/popUpEntrevistaCliente/popUpEntrevistaCliente";
import Pagination from "react-js-pagination";
import { convertTimestampToDate } from "../../../../utils/timestampToDate";

function CandidateRow(props) {
  const candidate = props.candidate;
  const module = props.module;
  const posdate = convertTimestampToDate(candidate.admissionDate);

  return (
    <tr id="tr" key="">
      <td scope="row">{candidate.document}</td>
      <td>{candidate.fullName}</td>
      <td>{candidate.positions[0].nameVacancy}</td>
      <td>{candidate.gender}</td>
      <td>{candidate.allyName}</td>
      <td>{posdate}</td>
      <td>
        <button className="btn btn-success">Listo</button>
      </td>
      <td>
        <Row style={{ width: "100%" }}>
          <Col xs="6">
            <Link
              to={`/${module}/viewCandidate/${candidate.id}`}
              className="f-left"
            >
              <i className="fas fa-pencil-alt text-info"></i>
            </Link>
          </Col>
          <Col xs="6">
            <ModalEntrevistaCliente
              className="f-left"
              idCap={candidate.id}
              position={candidate.positions[0].id}
            />
          </Col>
        </Row>
      </td>
    </tr>
  );
}

class AprobadosRelacionamiento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  componentDidMount = () => {
    this.props.setCount(0);
    let idb = this.props.match.params.id;
    let complement = `?business=${idb}&state=1&substate=8`;
    this.props.getCandidatesByBusiness(complement);
    this.props.setCandidate([]);
  };

  goToDetail = () => {};

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  handlePageChange = (e) => {
    window.scroll(0, 0);
    let idb = this.props.match.params.id;
    let complement = `?business=${idb}&state=1&substate=8&page=${e}`;
    this.props.getCandidatesByBusiness(complement);

    this.setState({
      activePage: e,
    });
  };

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value),
    });
  };

  renderOptionsPage = () => {
    let options = [];
    if (this.props.pageCount !== 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>
                {i}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">Candidatos</strong>
            </p>
            <p className="subtitleSeguimiento">
              Stock de candidatos que fueron aprobados
            </p>
          </div>

          <div className="table-responsiveAprobados containerResumenSeleccion">
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Cédula</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Cargo</th>
                  <th scope="col">Genero (M / F)</th>
                  <th scope="col">Programa / Agencia</th>
                  <th scope="col">Fecha postulación</th>
                  <th scope="col">Estado</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.candidatesBusiness.length !== 0
                  ? this.props.candidatesBusiness.results.map((item, index) => {
                      return (
                        <CandidateRow
                          key={index.toString()}
                          module={this.props.modulePath}
                          candidate={item}
                          onEdit={() => this.goToDetail()}
                        />
                      );
                    })
                  : null}
              </tbody>
            </Table>
          </div>

          <Col
            md={12}
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.pageCount}
              pageRangeDisplayed={5}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={5}
              pageRangeDisplayed={10}
            />
            {this.renderOptionsPage()}
          </Col>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    candidatesBusiness: state.candidateReducer.candidatesBusiness,
    isLoading: state.candidateReducer.isLoading,
    modulePath: state.allyReducer.modulePath,
    pageCount: state.candidateReducer.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCandidatesByBusiness: bindActionCreators(
      getCandidatesByBusiness,
      dispatch
    ),
    setCandidate: bindActionCreators(setCandidate, dispatch),
    setCount: bindActionCreators(setCount, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AprobadosRelacionamiento);
