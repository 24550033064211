import { ISLOADING, ISSAVE, ADD_CALENDAR_REGISTROS, GET_PROGRAM, ADD_CALENDAR_SEGUIMIENTOS, GET_CALENDAR_REGISTROS, GET_CALENDAR_FOLLOW, PAGE_COUNT } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

import swal from 'sweetalert';

export const isLoading = bool => ({
    type: ISLOADING,
    isLoading: bool
});

export const setSave = bool => ({
  type: ISSAVE,
  isSave: bool
});

export const addTimelines = timelinesData => ({
    type: ADD_CALENDAR_REGISTROS,
    timelinesData
});

export const addTimelinesFollow = timelinesData2 => ({
  type: ADD_CALENDAR_SEGUIMIENTOS,
  timelinesData2
});

export const getProgram = programData => ({
  type: GET_PROGRAM,
  programData
});

export const getCalendarRegister = timelinesData3 => ({
  type: GET_CALENDAR_REGISTROS,
  timelinesData3
});

export const getCalendarFollow = timelinesData4 => ({
  type: GET_CALENDAR_FOLLOW,
  timelinesData4
});

export const setCount = pageCount => ({
  type: PAGE_COUNT,
  pageCount
});

export const add_timelines = (obj) => {
  return async(dispatch) => {
    dispatch(isLoading(true));
    try{
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      const responseTimelines = await axios.post(`${urls.url_base}/dGltZXBvcw/`, obj, {headers: {'Authorization': `JWT ${token}`}});
      const data = await responseTimelines.data;
      if (data.length !== 0) {
        dispatch(addTimelines([]));
      } else {
        dispatch(addTimelines([]));
        swal('Upss! correo y / o contraseña incorrectos!');
      }  
    }catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const add_timelines_follow = (obj) => {
  return async(dispatch) => {
    dispatch(isLoading(true));
    try{
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: {'Content-Type': 'application/json'}
      });
      const token = await reponseToken.data.token;
      const responseTimelines = await axios.post(`${urls.url_base}/dGltZQ/`, obj, {headers: {'Authorization': `JWT ${token}`}});
      const data = await responseTimelines.data;
      if (data.length !== 0) {
        dispatch(addTimelinesFollow([]));
      } else {
        dispatch(addTimelinesFollow([]));
        swal('Upss! correo y / o contraseña incorrectos!');
      }  
    }catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getAllProgram = () => {
  return async(dispatch) => {
      // let candidate = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseProgram = await axios.get(`${urls.url_base}/QWxpYWRvcw/`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseProgram.data;
          if (data.results.length !== 0) {
            console.log('DATA', data);
            dispatch(getProgram(data));
          } else {
            dispatch(getProgram([]));
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getAllCalendarRegisters = (page=1) => {
  return async(dispatch) => {
      // let candidate = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCalendar = await axios.get(`${urls.url_base}/dGltZXBvcw/?page=${page}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCalendar.data;
          const count = data.count;
          if (data.results.length !== 0) {
            dispatch(getCalendarRegister(data));
            dispatch(setSave(false));
            dispatch(setCount(count));
          } else {
            dispatch(getCalendarRegister([]));
            dispatch(setSave(false))
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}

export const getAllCalendarFollow = (type, page=1) => {
  return async(dispatch) => {
      // let candidate = [];
      dispatch(isLoading(true))
      try {
          const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
            headers: {'Content-Type': 'application/json'}
          });
          const token = await reponseToken.data.token;
          const responseCalendar = await axios.get(`${urls.url_base}/dGltZQ/?type=${type}&page=${page}`, {headers: {'Authorization': `JWT ${token}`}});
          const data = await responseCalendar.data;
          const count = data.count;
          if (data.results.length !== 0) {
            dispatch(getCalendarFollow(data));
            dispatch(setSave(false));
            dispatch(setCount(count));
          } else {
            dispatch(getCalendarFollow([]));
            dispatch(setSave(false))
            swal('No hay información disponible');
          }
      } catch (err) {
          dispatch(isLoading(false))
          alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      }
  }
}
