import React from "react";
import { Component } from "react";
import Header from "../../../header/Header";

class AsignarEntrevistaPracticante extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="col-12 containerTitleSeleccion mb-5 text-left">
          <p>
            <strong className="titleSeguimiento text-left">
              Candidatos A Citar
            </strong>
          </p>
          <p className="subtitleSeguimiento"></p>
        </div>
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12 mb-3">
            {/*  <NotificacionAprendiz />*/}
            {/* <div className="alert alert-danger">
              Enviar a entrevista a Pedro Ruiz a las 8:00am de 22/Agosto
            </div> */}
          </div>
          <div className="col-12 table-responsivePracticante containerResumenSeleccion">
            <table className="table tableResumenSeleccion">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Cédula
                  </th>
                  <th scope="col" className="titleTable">
                    Nombre
                  </th>
                  <th scope="col" className="titleTable">
                    Cargo
                  </th>
                  <th scope="col" className="titleTable">
                    Fecha Asignada
                  </th>
                  <th scope="col" className="titleTable">
                    Cliente
                  </th>
                  <th scope="col" className="titleTable">
                    Contesto?
                  </th>
                  <th scope="col" className="titleTable">
                    Opción
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="titleItemRow">12345678</td>
                  <td className="titleItemRow">Juan</td>
                  <td className="titleItemRow">Programador</td>
                  <td className="titleItemRow"></td>
                  <td className="titleItemRow">Sodexo</td>
                  <td className="titleItemRow">
                      <label htmlFor="">Si</label>
                      <input type="checkbox" name="" id=""/>
                      <label htmlFor="">No</label>
                      <input type="checkbox" name="" id=""/>
                  </td>
                  <td>
                  <i className="fas fa-pencil-alt text-info"></i>
                  </td>
                </tr>
              </tbody>
            </table>

            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    );
  }
}
export default AsignarEntrevistaPracticante;
