import React, { Component, Fragment } from "react";
import { Row, Spinner, Button } from 'reactstrap';
import "./resumen.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSummariesGender } from '../../../redux/actions/summaryActions';
import { setCandidateBus, setCandidate } from '../../../redux/actions/candidateActions';
import { Link } from "react-router-dom";
import Header from "../../header/Header";

class ResumenCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: ''
    };
  }

  componentDidMount = () => {
    let id = this.props.match.params.id;
    this.setState({
      id
    });
    this.props.getSummariesGender(id);
    this.props.setCandidateBus([]);
    this.props.setCandidate([]);
  }

  findObjectandReturn = (sub) => {
    let itemAmount = this.props.summaryGender.consolidado.find(el => el.subState === sub);
    if(itemAmount !== undefined){
      return itemAmount;
    }else{
      return {
        "total": 0,
        "hombres": 0,
        "mujeres": 0,
        "agencias": 0,
        "programas": 0,
        "arn": 0,
        "victimas": 0
      }
    }
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundAliadosExternos">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Resumen
              </strong>
            </p>
            <p className="subtitleSeguimiento">
              Listado de estados de los candidatos.
            </p>
          </div>

          <div style={{textAlign: 'right', marginRight: 10}}>
            <a href={`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?business=${this.props.match.params.id}`} target="_blank" style={{display: 'inline'}}>
              <Button color="info">Descargar</Button>
            </a>
          </div>

          <div className="table-responsiveProcesoVinculacion containerTable">
            <table className="table tableRelacionamientoAliados">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Estado
                  </th>
                  <th scope="col" className="titleTable">
                    Genero (M / F)
                  </th>
                  <th scope="col" className="titleTable">
                    Programas / Agencias
                  </th>
                 {/*  <th scope="col" className="titleTable">
                    ARN / Victimas
                  </th> */}
                  <th scope="col" className="titleTable">
                    Total
                  </th>
                  <th scope="col" className="titleTable"></th>
                </tr>
              </thead>
              {this.props.summaryGender.length !== 0 && <tbody>
                <tr id="tr">
                  <td className="titleItemRow">Candidatos</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(8).hombres} / ${this.findObjectandReturn(8).mujeres}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(8).programas} / ${this.findObjectandReturn(8).agencias}`}</td>
                  {/* <td className="titleItemRow">{`${this.findObjectandReturn(8).arn} / ${this.findObjectandReturn(8).victimas}`}</td> */}
                  <td className="titleItemRow">{this.findObjectandReturn(8).total}</td>
                  <td className="titleItemRow">
                    <Link to={`${this.state.id}/aprobados`}>
                    <i className="far fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Entrevistas</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(9).hombres} / ${this.findObjectandReturn(9).mujeres}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(9).programas} / ${this.findObjectandReturn(9).agencias}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(9).arn} / ${this.findObjectandReturn(9).victimas}`}</td>
                  <td className="titleItemRow">{this.findObjectandReturn(9).total}</td>
                  <td>
                    <Link to={`/${this.props.modulePath}/entrevista`}>
                    <i className="far fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Pendientes de contratación</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(10).hombres} / ${this.findObjectandReturn(10).mujeres}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(10).programas} / ${this.findObjectandReturn(10).agencias}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(10).arn} / ${this.findObjectandReturn(10).victimas}`}</td>
                  <td className="titleItemRow">{this.findObjectandReturn(10).total}</td>
                  <td>
                  <Link to={`${this.state.id}/pendientes`}>
                    <i className="far fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">No continuan proceso</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(5).hombres} / ${this.findObjectandReturn(5).mujeres}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(5).programas} / ${this.findObjectandReturn(5).agencias}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(5).arn} / ${this.findObjectandReturn(5).victimas}`}</td>
                  <td className="titleItemRow">{this.findObjectandReturn(5).total}</td>
                  <td>
                    <Link to={`${this.state.id}/no-continuan-proceso`}>
                    <i className="far fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Vinculados</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(11).hombres} / ${this.findObjectandReturn(11).mujeres}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(11).programas} / ${this.findObjectandReturn(11).agencias}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(11).arn} / ${this.findObjectandReturn(11).victimas}`}</td>
                  <td className="titleItemRow">{this.findObjectandReturn(11).total}</td>
                  <td>
                    <Link to={`${this.state.id}/vinculados/11`}>
                    <i className="far fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Vinculados aprendiz</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(12).hombres} / ${this.findObjectandReturn(12).mujeres}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(12).programas} / ${this.findObjectandReturn(12).agencias}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(12).arn} / ${this.findObjectandReturn(12).victimas}`}</td>
                  <td className="titleItemRow">{this.findObjectandReturn(12).total}</td>
                  <td>
                    <Link to={`${this.state.id}/vinculados/12`}>
                    <i className="far fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Vinculados reintegro</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(13).hombres} / ${this.findObjectandReturn(13).mujeres}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(13).programas} / ${this.findObjectandReturn(13).agencias}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(13).arn} / ${this.findObjectandReturn(13).victimas}`}</td>
                  <td className="titleItemRow">{this.findObjectandReturn(13).total}</td>
                  <td>
                    <Link to={`${this.state.id}/vinculados/13`}>
                    <i className="far fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">Desvinculados</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(14).hombres} / ${this.findObjectandReturn(14).mujeres}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(14).programas} / ${this.findObjectandReturn(14).agencias}`}</td>
                  <td className="titleItemRow">{`${this.findObjectandReturn(14).arn} / ${this.findObjectandReturn(14).victimas}`}</td>
                  <td className="titleItemRow">{this.findObjectandReturn(14).total}</td>
                  <td>
                    <Link to={`${this.state.id}/desvinculados`}>
                    <i className="far fa-eye text-info"></i>
                    </Link>
                  </td>
                </tr>
              </tbody>}
            </table>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    summaryGender: state.summariesReducer.summaryGender,
    isLoading: state.summariesReducer.isLoading,
    modulePath: state.allyReducer.modulePath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSummariesGender: bindActionCreators(getSummariesGender, dispatch),
    setCandidateBus: bindActionCreators(setCandidateBus, dispatch),
    setCandidate: bindActionCreators(setCandidate, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumenCliente);
