import React, { Component } from "react";
import { FormGroup, Input, Col, Row, Spinner } from 'reactstrap';
import Header from "../../../header/Header";
import { connect } from "react-redux";
import { add_Workshop } from '../../../../redux/actions/acompanamientoActions';
import { bindActionCreators } from "redux";
import { uploadSingleImage, uploadMultipleDocs } from '../../../../services/uploadFile';
import './addTalleres.css';
import swal from "sweetalert";

class AddTalleres extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePath: [],
      loading: false,
      selGroups: []
    }
  }

  handleChangeFile = e => {
    console.log(e.target.files)
    if (e.target.files.length !== 0) {
      const imageFile = e.target.files;
      this.setState({
        imagePath: imageFile
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true
    })

    if (this.state.selGroups.length !== 0) {
      var count = 0;
      var obj = {
        date: event.target.fecha.value,
        hour: event.target.hora.value,
        responsable: event.target.responsable.value,
        place: event.target.lugar.value,
        thematic: event.target.tematica.value
      }

      if (this.state.imagePath.length !== 0) {
        uploadMultipleDocs(this.state.imagePath).then((data) => {
          obj.medology = JSON.stringify(data);

          this.state.selGroups.forEach(group => {
            obj.group = group;
            this.props.add_Workshop({
              date: obj.date,
              hour: obj.hour,
              responsable: obj.responsable,
              place: obj.place,
              thematic: obj.thematic,
              group: group,
              medology: obj.medology
            }).then(resp => {
              if (resp) {
                count += 1;
                if (count === this.state.selGroups.length) {
                  this.props.history.goBack();
                  this.setState({
                    loading: false
                  });
                }
              }
            });
          })
        });
      } else {
        this.setState({
          loading: false
        })
        swal('Por favor adjunta al menos una metodología');
      }
    } else {
      this.setState({
        loading: false
      })
      swal('Por favor selecciona al menos un grupo');
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  handleChangeSelect = (e) => {
    const selected = [];
    let selectedOption = (e.target.selectedOptions);
    for (let i = 0; i < selectedOption.length; i++) {
      selected.push(selectedOption.item(i).value)
    }

    this.setState({ selGroups: selected });
  }

  render() {
    return (
      <>
        <Header />
        <div className="col-12  mb-5 text-left">
          <strong className="titleTaller text-center">
            Nuevo Taller
          </strong>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 bgResumenSeleccion">
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 containerTitleSeleccion mb-5">
            <br />

            <div className="row">
              <div className="col">
                <div className="card cardNewTaller col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card-body">

                    <form onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group">
                            <label>Grupo</label>
                            <Input
                              type="select"
                              className="form-control"
                              name="groupSelect"
                              multiple
                              onChange={this.handleChangeSelect}
                            >
                              <option value="N/A">No Aplica</option>
                              <option value="Basico">Básico</option>
                              <option value="Basico A">Básico A</option>
                              <option value="Basico B">Básico B</option>
                              <option value="Basico C">Básico C</option>
                              <option value="Avanzado">Avanzado</option>
                              <option value="Graduados">Graduados</option>
                              <option value="Grupo A">Grupo A</option>
                              <option value="Grupo B">Grupo B</option>
                              <option value="otrasciudades">Otras Ciudades</option>

                            </Input>
                          </div>

                          <FormGroup>
                            <label>Temática</label>
                            <Input
                              type="text"
                              name="tematica"
                              required
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Fecha</label>
                            <Input
                              min={this.formatDate(new Date())}
                              type="date"
                              name="fecha"
                              required
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Hora</label>
                            <Input
                              type="time"
                              name="hora"
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Responsable</label>
                            <Input
                              type="select"
                              name="responsable"
                            >
                              <option>Alianza</option>
                              <option>Psicóloga Coach</option>
                              <option>ARN</option>
                              <option>Otros</option>
                            </Input>
                          </FormGroup>

                          <FormGroup>
                            <label>Metodología</label>
                            <Input
                              type="file"
                              name="metodologia"
                              accept="image/png,.doc,.docx,.pptx,.pdf,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={this.handleChangeFile}
                              multiple
                            />
                            <span style={{ fontSize: 12, fontWeight: '200', color: '#204d6a' }}>Por favor cargar la metodología en formato PDF</span>
                          </FormGroup>

                          <FormGroup>
                            <label>Lugar</label>
                            <Input type="text" name="lugar" />
                          </FormGroup>
                        </div>
                      </div>

                      {!this.state.loading && <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mr-5">
                          <button type="submit" className="btn btn-info ">
                            <strong>Guardar</strong>
                          </button>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12 ml-12">
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => this.props.history.goBack()}
                          >
                            <strong>Cancelar</strong>
                          </button>
                        </div>
                      </div>}

                      <br />

                      {this.state.loading && <div className="row" style={{ width: '100%', justifyContent: 'center', flexDirection: 'column' }}>
                        <Spinner type="grow" color="info" size="md" />
                        <span>Cargando...</span>
                      </div>}

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    isLoading: state.accompanimentReducer.isLoading,
    addWorkshop: state.accompanimentReducer.workshop
  }
}

const mapDispatchToProps = dispatch => {
  return {
    add_Workshop: bindActionCreators(add_Workshop, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTalleres);
