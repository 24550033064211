import { ISLOADING, GET_CONTRACT_INFO, GET_CANDIDATES_BY_GROUP, SET_VISIBLE_TABS, GET_CANDIDATES, GET_CANDIDATE, ADD_CANDIDATE, GET_ALL_PROGRAMS, GET_ALL_AGENCIES, GET_ALL_CANDIDATES_BY_ALLY, GET_CANDIDATES_REENTRY, UPDATE_STATUS_CANDIDATES, GET_CANDIDATES_BY_BUSINESS, SEARCH_CANDIDATE, PAGE_COUNT } from '../constants/action-type';

const getInitialState = () => ({
    candidates: [],
    candidate: [],
    programs: [],
    candidatesAlly: [],
    requestReentry: [],
    agencies: [],
    candidatesStatus: [],
    isLoading: false,
    isSaveCandidate: false,
    visibleTabs: [],
    candidatesBusiness: [],
    isUpdateStates: false,
    candidatesGroup: [],
    contratData: [],
    searchCandidate: [],
    pageCount: 0
});

const candidateReducer = (state = getInitialState(), action) => {
    switch(action.type) {
        case ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case PAGE_COUNT:
            return { ...state, pageCount: action.pageCount };
        case GET_CANDIDATES:
            return { ...state, candidates: action.candidates, isLoading: false, isSaveCandidate: false };
        case GET_CANDIDATE:
            return { ...state, candidate: action.candidate, isLoading: false, isSaveCandidate: false };
        case ADD_CANDIDATE:
            return { ...state, candidates: action.candidates, isLoading: false, isSaveCandidate: true};
        case GET_ALL_PROGRAMS:
            return { ...state, programs: action.programs, isLoading: false, isSaveCandidate: false };
        case GET_ALL_AGENCIES:
            return { ...state, agencies: action.agencies, isLoading: false, isSaveCandidate: false };
        case GET_ALL_CANDIDATES_BY_ALLY:
            return { ...state, candidatesAlly: action.candidatesAlly, isLoading: false, isSaveCandidate: false };
        case GET_CANDIDATES_REENTRY:
            return { ...state, requestReentry: action.requestReentry, isLoading: false, isSaveCandidate: false };
        case UPDATE_STATUS_CANDIDATES:
            return { ...state, candidatesStatus: action.candidatesStatus, isLoading: false, isSaveCandidate: false };
        case SET_VISIBLE_TABS:
            return { ...state, visibleTabs: action.visibleTabs };
        case GET_CANDIDATES_BY_BUSINESS:
            return { ...state, candidatesBusiness: action.candidatesBusiness, isLoading: false };
        case GET_CANDIDATES_BY_GROUP:
            return { ...state, candidatesGroup: action.candidatesGroup, isLoading: false };
        case GET_CONTRACT_INFO:
            return { ...state, contratData: action.contratData, isLoading: false };
        case SEARCH_CANDIDATE:
            return { ...state, searchCandidate: action.searchCandidate, isLoading: false };
        default:
            return state;
    }
}

export default candidateReducer;