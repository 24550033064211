import React, { Component, Fragment } from "react";
import "./negocios.styles.css";
import { setSummaries } from '../../../redux/actions/summaryActions';
import { bindActionCreators } from 'redux';
import Header from "../../header/Header";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import createReactClass from "create-react-class";


var FilteredList = createReactClass({
  filterList: function(event){
    var updatedList = this.state.initialItems;
    updatedList = updatedList.filter(function(item){
      return item.name.toLowerCase().search(
        event.target.value.toLowerCase()) !== -1;
    });
    this.setState({items: updatedList});
  },
  getInitialState: function(){
     return {
       initialItems: [
         "Apples",
         "Broccoli",
         "Chicken",
         "Duck",
         "Eggs",
         "Fish",
         "Granola",
         "Hash Browns"
       ],
       items: []
     }
  },
  componentWillMount: function(){
    this.setState({items: this.state.initialItems});
    if(this.props.items){
      this.setState({items: this.props.items})
      this.setState({initialItems: this.props.items})
    }
  },
  goToResumeState: function(id){
    this.props.history.push(`business/summary/${id}`);
  },
  render: function(){
    return (
      <div className="row" style={{width: '100%'}}>
        <div className="row" style={{width: '100%'}}>
          <form>
          <fieldset className="form-group">
          <input type="text" className="form-control" placeholder="Filtrar negocios" onChange={this.filterList}/>
          </fieldset>
          </form>
        </div>
        <div className="row" style={{width: '100%'}}>
        {
          this.state.items.map((item, index) => {
            return <div className="col-3" data-category={item.name} key={index.toString()}>
                        <div className="itemAgencias" style={{minWidth: '100px'}} onClick={() => {this.goToResumeState(item.id)}}>
                          <h6>{item.name}</h6>
                        </div>
                      </div>
            //<li className="list-group-item" data-category={item} key={item}>{item}</li>
          })
        }
        </div>
      </div>
    );
  }
});


class Negocios extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.setSummaries([]);
  }

  goToResumeState = (id) => {
    this.props.history.push(`business/summary/${id}`);
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundAgenciasFiltro">
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">Negocios</strong>
            </p>
            <p className="subtitleSeguimiento">Listado de negocios</p>
          </div>

          <div className="containerAgenciasScroll" >
            <div className="row containerAgeniasFiltro">

            <FilteredList items={this.props.business}  history={this.props.history}/>
              {/* {this.props.business.map((item, index) => {
                return (
                  <div className="col-2" key={index.toString()}>
                    <div className="itemAgencias"
                      onClick={() => {this.goToResumeState(item.id)}}
                    >
                      <h6>{item.name}</h6>
                    </div>
                  </div>
                )
              })} */}
              
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return{
    business: state.citiesReducer.business
  }
}

const mapDispatchToProps = dispatch => {
  return{
    setSummaries: bindActionCreators(setSummaries, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Negocios);
