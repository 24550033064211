import { GET_ALL_MANAGERS, ISLOADING } from '../constants/action-type';

const getInitialState = () => ({
  managersList: [],
  isLoading: false,
});

const managersReducer = (state = getInitialState(), action) => {
  switch(action.type) {
    case ISLOADING:
      return { ...state, isLoading: action.isLoading };
    case GET_ALL_MANAGERS:
      return { ...state, managersList: action.managersList, isLoading: false };
    default:
      return state;
  }
}

export default managersReducer;