import React from "react";
import { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "./informes.css";
import Header from "../../header/Header";
import { getAllClient } from '../../../redux/actions/clientsActions';
import { getAllBusiness } from '../../../redux/actions/businessActions';
import { getAllAllys } from '../../../redux/actions/allyActions';

class Informes extends Component {
  constructor(props){
    super(props);
    this.state = {
      type: "0",
      client: "",
      bussines: "",
      ally: ""
    }
  }

  componentDidMount = () => {
    this.props.getAllAllys();
    this.props.getAllClient();
    this.props.getAllBusiness();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  downloadReport = () => {
    switch(this.state.type){
      case '0':
        window.open('https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/', 'blank');
        break;
      case '1':
        window.open(`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?partner=${this.state.ally}`, 'blank');
        break;
      case '2':
        window.open(`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?client=${this.state.client}`, 'blank');
        break;
      case '3':
        window.open(`https://alianza.multimedialab.dev/UHJvamVjdA/5s48d4s2/?business=${this.state.bussines}`, 'blank');
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="customRow mb-5 text-left">
          <p>
            <strong className="titleSeguimiento text-left">Informes</strong>
          </p>
          <p className="subtitleSeguimiento">
            Escoja las caracteristicas del informe
          </p>
        </div>
        <div>
          <div className="card col-10" style={{margin: '0 auto'}}>
            <div className="card-body">
                <div className="row">
                    <div className="col-3">Tipo de informe</div>
                    <div className="col-3">
                        <select
                          className="form-control"
                          name="type"
                          id="type"
                          onChange={(e) => this.handleChange(e)}
                        >
                            <option value="0">Consolidado general</option>
                            <option value="1">Consolidado por aliados externos</option>
                            <option value="2">Consolidado por clientes</option>
                            <option value="3">Consolidado por negocio</option>
                        </select>
                    </div>
                </div>

                <br />
                {this.state.type === "1" && <div className="row mt-1">
                    <div className="col-3">Aliados Externos</div>
                    <div className="col-3">
                      {this.props.allAllys.length !== 0 && <select className="form-control" name="ally" id="ally" onChange={(e) => this.handleChange(e)}>
                          {this.props.allAllys.results.map((item, index) => (
                            <option value={item.id} key={index.toString()}>{item.fullName}</option>
                          ))};
                      </select>}
                    </div>
                </div>}
                
                {this.state.type === "2" && <div className="row mt-1">
                    <div className="col-3">Clientes</div>
                    <div className="col-3">
                      {this.props.clientData.length !== 0 && <select className="form-control" name="client" id="client" onChange={(e) => this.handleChange(e)}>
                        {this.props.clientData.results.map((item, index) => (
                          <option value={item.id} key={index.toString()}>{item.fullName}</option>
                        ))};
                      </select>}
                    </div>
                </div>}
                
                {this.state.type === "3" && <div className="row mt-1">
                    <div className="col-3">Negocios</div>
                    <div className="col-3">
                      {this.props.businessData.length !== 0 && <select className="form-control" name="bussines" id="bussines" onChange={(e) => this.handleChange(e)}>
                        {this.props.businessData.results.map((item, index) => (
                          <option value={item.id} key={index.toString()}>{item.name} - {item.nameCity}</option>
                        ))};
                      </select>}
                    </div>
                </div>}

                <div className="mt-5">
                    <button className="btn btn-info"
                      onClick={() => this.downloadReport()}
                    >Descargar</button>
                </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = state => {
  return{
    allAllys: state.allyReducer.allAllys,
    clientData: state.clientsReducer.clientData,
    businessData: state.businessReducer.businessData
  }
}

const mapDispatchToProps = dispatch => {
  return{
    getAllAllys: bindActionCreators(getAllAllys, dispatch),
    getAllClient: bindActionCreators(getAllClient, dispatch),
    getAllBusiness: bindActionCreators(getAllBusiness, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Informes);
