import { GET_EMPRENDIMIENTOS, ADD_EMPRENDIMIENTO, EDIT_EMPRENDIMIENTO, GET_EMPRENDIMIENTOS_BY_ALLY, ISLOADING, SEARCH_EMPRENDIMIENTO } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

import swal from 'sweetalert';

export const isLoading = bool => ({
  type: ISLOADING,
  isLoading: bool
});

export const setEmpredData = (emprendimientos, countEmpred) => ({
  type: GET_EMPRENDIMIENTOS,
  emprendimientos,
  countEmpred
});

export const setEmpredDataAlly = (emprendimientosAlly, countEmpred) => ({
  type: GET_EMPRENDIMIENTOS_BY_ALLY,
  emprendimientosAlly,
  countEmpred
});

export const setSave = bool => ({
  type: ADD_EMPRENDIMIENTO,
  isSaveEmprend: bool
});

export const setSearchEmpren = searchEmprend => ({
  type: SEARCH_EMPRENDIMIENTO,
  searchEmprend
});

export const searchEmprendimientos = (param) => {
  return async (dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(`${urls.url_base}/ZW1wcmU/?search=${param}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCandidates.data;
      if (data.results.length !== 0) {
        dispatch(setSearchEmpren(data));
        return data.results;
      } else {
        dispatch(setSearchEmpren([]));
        swal('Lo sentimos, no hay información disponible.');
        return []
      }
    } catch (err) {
      dispatch(isLoading(false))
      swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getEmprendimientos = () => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseEmpre = await axios.get(`${urls.url_base}/ZW1wcmU/`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseEmpre.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setEmpredData(data, count));
        return data.results;
      } else {
        dispatch(setEmpredData({ results: [] }, 0));
        swal('No hay información disponible');
        return []
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getEmprendimientosByAlly = (id, page) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseEmpre = await axios.get(`${urls.url_base}/ZW1wcmU/?page=${page}&ally=${id}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseEmpre.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setEmpredDataAlly(data, count));
        return data.results;
      } else {
        dispatch(setEmpredDataAlly({ results: [] }, 0));
        swal('No hay información disponible');
        return [];
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getEmprendimientosByCity = (city, page) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseEmpre = await axios.get(`${urls.url_base}/ZW1wcmU/?page=${page}&city=${city}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseEmpre.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setEmpredDataAlly(data, count));
        return data.results;
      } else {
        dispatch(setEmpredDataAlly({ results: [] }, 0));
        swal('No hay información disponible');
        return [];
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}



export const getEmprendimientoById = (id) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseEmpre = await axios.get(`${urls.url_base}/ZW1wcmU/${id}/`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseEmpre.data;
      const count = data.count;
      if (data) {
        dispatch(isLoading(false));
        return data;
      } else {
        swal('No hay información disponible');
        return [];
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const addEmprendimiento = (obj) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseEmpre = await axios.post(`${urls.url_base}/ZW1wcmU/`, obj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseEmpre.data;
      if (data) {
        dispatch(setSave(true));
        return true;
      } else {
        return false;
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const editEmprendimiento = (obj, id) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseEmpre = await axios.put(`${urls.url_base}/ZW1wcmU/${id}/`, obj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseEmpre.data;
      if (data) {
        dispatch(isLoading(false))
        return true;
      } else {
        return false;
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getEmprendimientoBySector = (sector) => {
  return async (dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseEmpre = await axios.get(`${urls.url_base}/ZW1wcmU/?sector=${sector}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseEmpre.data;
      console.log(data)
      if (data) {
        dispatch(setEmpredDataAlly(data))
        dispatch(isLoading(false));
        return data;
      } else {
        swal('No hay información disponible');
        return [];
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}