import { ISLOADING, GET_CITIES, GET_CITIES_BY_BUSINESS, SET_BUSINESS } from '../constants/action-type';

const getInitialState = () => ({
    citiesData: [],
    citiesBusiness: [],
    isLoading: false,
    business: []
});

const citiesReducer = (state = getInitialState(), action) => {
    switch(action.type) {
        case ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case GET_CITIES:
            return { ...state, citiesData: action.citiesData, isLoading: false };
        case GET_CITIES_BY_BUSINESS:
            return { ...state, citiesBusiness: action.citiesBusiness, isLoading: false };
        case SET_BUSINESS: 
            return { ...state, business: action.business, isLoading: false};
        default:
            return state;
     }
}

export default citiesReducer;