import { FIND_ALERT, FIND_REMITIDOS } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

export const setRelAlert = alertRelacionamiento => ({
  type: FIND_ALERT,
  alertRelacionamiento
});

export const setRemiAlert = alertRemitidos => ({
  type: FIND_REMITIDOS,
  alertRemitidos
});

export const getRelAlerts = (date, substate) => {
  return async (dispatch) => {
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(`${urls.url_base}/Q2FuZGlkYXRvcw/?date=${formatday(date)}&substate=${substate}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCandidates.data;
      switch (substate) {
        case 0:
          dispatch(setRelAlert(data));
          break;
        case 1:
          dispatch(setRemiAlert(data));
          break;
      }
    } catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getEmprendiAlerts = (date) => {
  return async (dispatch) => {
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(`${urls.url_base}/ZW1wcmU/?date=${formatday(date)}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCandidates.data;
      dispatch(setRelAlert(data));
    } catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

const formatday = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}