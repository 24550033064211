import React, { Component, Fragment } from "react";
import "./ciudades.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllCities } from "../../redux/actions/citiesActions";
import { setAllyCities } from "../../redux/actions/allyActions";

import createReactClass from "create-react-class";

import Header from "../header/Header";
import { Link } from "react-router-dom";

var FilteredList = createReactClass({
 filterList: function (event) {
  var updatedList = this.state.initialItems;
  updatedList = updatedList.filter(function (item) {
   return (
    item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1
   );
  });
  this.setState({ items: updatedList });
 },
 getInitialState: function () {
  return {
   initialItems: [
    "Apples",
    "Broccoli",
    "Chicken",
    "Duck",
    "Eggs",
    "Fish",
    "Granola",
    "Hash Browns",
   ],
   items: [],
  };
 },
 componentWillMount: function () {
  this.setState({ items: this.state.initialItems });
  if (this.props.items) {
   this.setState({ items: this.props.items });
   this.setState({ initialItems: this.props.items });
  }
 },
 navigateTo: function (route) {
  this.props.history.push(route);
 },
 render: function () {
  return (
   <div
    className="row"
    style={{ width: "100%" }}>
    <div
     className="row"
     style={{ width: "100%" }}>
     {this.state.items.map((item, index) => {
      return (
       <div
        className="col-4"
        data-category={item.name}
        key={index.toString()}>
        <div
         className="itemCiudad"
         style={{ minWidth: "150px", flexDirection: "column" }}
         onClick={() => this.navigateTo(`sectores/detalle/${item.name}`)}>
         <i className="fas fa-building"></i>
         <h6 style={{ padding: "0 8px" }}>{item.name}</h6>
         {/* <span>0</span> */}
        </div>
       </div>
      );
     })}
    </div>
   </div>
  );
 },
});

class Sectores extends Component {
 constructor(props) {
  super(props);
  this.state = {
   departments: [
    { name: "Estampaciones", id: "Estampaciones" },
    { name: "Cerveza", id: "Cerveza" },
    { name: "Miel", id: "Miel" },
    { name: "Artesanias en mostacilla", id: "Artesanias en mostacilla" },
    { name: "Confecciones", id: "Confecciones" },
    { name: "Café", id: "Café" },
    {
     name: "Publicidad, confecciones y estampación",
     id: "Publicidad, confecciones y estampación",
    },
    { name: "Gelatinas de pata de res", id: "Gelatinas de pata de res" },
    { name: "Huevos", id: "Huevos" },
    { name: "Miel polen, propoleo y Café", id: "Miel polen, propoleo y Café" },
    { name: "Velas decorativas", id: "Velas decorativas" },
    { name: "Chocolates", id: "Chocolates" },
    { name: "Ropa femenina", id: "Ropa femenina" },
    { name: "Nueces de Sachainchi", id: "Nueces de Sachainchi" },
    { name: "Miel y frutas deshidratadas", id: "Miel y frutas deshidratadas" },
    { name: "Achiras", id: "Achiras" },
    {
     name: "Impresiones 3D (Prromocionales, Suculentas,bisuteria)",
     id: "Impresiones 3D (Prromocionales, Suculentas,bisuteria)",
    },
    { name: "Jabones artesanales", id: "Jabones artesanales" },
    { name: "Productos en madera", id: "Productos en madera" },
    { name: "Orellanas", id: "Orellanas" },
    { name: "Canastas/Bolsos", id: "Canastas/Bolsos" },
    { name: "Publicidad", id: "Publicidad" },
    { name: "Artesanias de emberas", id: "Artesanias de emberas" },
    {
     name: "Productos y servicios de limpieza",
     id: "Productos y servicios de limpieza",
    },
    { name: "S/I", id: "S/I" },
    { name: "Café, Miel y Aji", id: "Café, Miel y Aji" },
    { name: "Estampados y sublimaciones", id: "Estampados y sublimaciones" },
   ],
  };
 }

 navigateTo = (route) => {
  this.props.history.push(route);
 };

 render() {
  return (
   <Fragment>
    <Header />
    <div className="backgroundCiudades">
     <div className="customRow mb-5 text-left">
      <p>
       <strong className="titleSeguimiento text-left">Sectores</strong>
      </p>
      <p className="subtitleSeguimiento">Selecciona una opción</p>
     </div>

     <div className=" container-fluid containerCiudades">
      {
       <FilteredList
        items={this.state.departments}
        history={this.props.history}
       />
      }
     </div>
    </div>
   </Fragment>
  );
 }
}

const mapStateToProps = (state) => {
 return {};
};

const mapDispatchToProps = (dispatch) => {
 return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sectores);
