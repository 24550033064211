import React, { Component, Fragment } from "react";
import "./eliminados.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";

class Eliminados extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="backgroundAliadosExternos">
          <div className="row containerHeader">
            <div className="customRow mb-5 text-left">
              <p>
                <strong className="titleSeguimiento text-left">
                  Candidatos Descartados
                </strong>
              </p>
              <p className="subtitleSeguimiento">
                Listado de candidatos que fueron descartados
              </p>
            </div>
          </div>

          <div className="table-responsiveEliminados containerTable">
            <table className="table tableRelacionamientoAliados">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Cédula
                  </th>
                  <th scope="col" className="titleTable">
                    Nombre
                  </th>
                  <th scope="col" className="titleTable">
                    Motivo
                  </th>
                  <th scope="col" className="titleTable">
                    Causa Real
                  </th>
                  <th scope="col" className="titleTable">
                    Opciones
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr id="tr">
                  <td className="titleItemRow">0001</td>
                  <td className="titleItemRow">Guest</td>
                  <td className="titleItemRow">Example</td>
                  <td className="titleItemRow">Example</td>
                  <td>
                    <Link to={`/seleccion/seleccion/viewCandidate/26`}>
                      <i className="fas fa-pencil-alt text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">0001</td>
                  <td className="titleItemRow">Guest</td>
                  <td className="titleItemRow">Example</td>
                  <td className="titleItemRow">Example</td>
                  <td>
                    <Link to={`/seleccion/seleccion/viewCandidate/26`}>
                      <i className="fas fa-pencil-alt text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">0001</td>
                  <td className="titleItemRow">Guest</td>
                  <td className="titleItemRow">Example</td>
                  <td className="titleItemRow">Example</td>
                  <td>
                    <Link to={`/seleccion/seleccion/viewCandidate/26`}>
                      <i className="fas fa-pencil-alt text-info"></i>
                    </Link>
                  </td>
                </tr>
                <tr id="tr">
                  <td className="titleItemRow">0001</td>
                  <td className="titleItemRow">Guest</td>
                  <td className="titleItemRow">Example</td>
                  <td className="titleItemRow">Example</td>
                  <td>
                    <Link to={`/seleccion/seleccion/viewCandidate/26`}>
                      <i className="fas fa-pencil-alt text-info"></i>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Eliminados);
