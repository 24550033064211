import React from "react";
import { Component } from "react";

//Componentes propios
import GraficosSeleccion from "../../seleccion/consolidado/graficas/graficas";
import BarrasSeleccion from "../../seleccion/consolidado/barras/barras";
import TablaSeleccion from "../../seleccion/consolidado/tabla/tabla";
import Header from "../../header/Header";

import GeneralData from '../../generalData/GeneralData';

class GraficaAcompanamiento extends Component {
  render() {
    return (
      <GeneralData history={this.props.history} title={'Sura'} idBussines={12} />
        // <Header />
        // <div className="col-12 bgResumenSeleccion">
        //   <div className="col-12">
        //     <div style={{ marginLeft: "70%" }}>
        //       <i className="fas fa-download fa-2x"></i>
        //     </div>
        //     <div className="col-12 containerTitleSeleccion mb-5 text-left">
        //       <p>
        //         <strong className="titleCiudad text-left">
        //           Datos Generales
        //         </strong>
        //       </p>
        //     </div>

        //     <div
        //       className="row  bgConsolidadoSeleccion"
              
        //     >
        //       <div className="row containerTitleSeleccion"></div>
        //       <div className="col-4">
        //         <GraficosSeleccion />
        //       </div>

        //       <div className="col-4">
        //         <BarrasSeleccion />
        //       </div>
        //       <br />
        //       <br />
        //       <div className="col-12">
        //         <TablaSeleccion />
        //       </div>
        //     </div>
        //   </div>
        // </div>
    );
  }
}
export default GraficaAcompanamiento;
