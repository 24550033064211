import { ISLOADING, ADD_WORKSHOP, GET_WORKSHOPS, ADD_TRACING, ADD_TRACING_NUEVO,  SAVETRACING, SAVETRACING_NUEVO, GET_TRACING_CANDIDATE, GET_TRACING_CANDIDATE_NUEVO, GET_WORKSHOPS_BY_COLLABORATOR, PAGE_COUNT, SAVE_ASSISTANCE, GET_WORKSHOPSFOCUS } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';

import swal from 'sweetalert';

export const isLoading = bool => ({
  type: ISLOADING,
  isLoading: bool
});

export const addWorkshop = workshop => ({
  type: ADD_WORKSHOP,
  workshop,
});

export const setWorkshops = workshops => ({
  type: GET_WORKSHOPS,
  workshops
});

export const addTracing = tracing => ({
  type: ADD_TRACING,
  tracing,
});

export const addTracingNuevo = tracing => ({
  type: ADD_TRACING_NUEVO,
  tracing,
});

export const isSaveTracing = bool => ({
  type: SAVETRACING,
  saveTracing: bool
});

export const isSaveTracingNuevo = bool => ({
  type: SAVETRACING_NUEVO,
  saveTracingNuevo: bool
});

export const setTracingC = candidateTracing => ({
  type: GET_TRACING_CANDIDATE,
  candidateTracing
});

export const setTracingCNuevo = candidateTracingNuevo => ({
  type: GET_TRACING_CANDIDATE_NUEVO,
  candidateTracingNuevo
});

export const setWorkshop = workshopsUser => ({
  type: GET_WORKSHOPS_BY_COLLABORATOR,
  workshopsUser
});

export const setCount = pageCount => ({
  type: PAGE_COUNT,
  pageCount
});

export const setWorkshopsFocus = (workshopsFocus, workshopsFocusCount) => ({
  type: GET_WORKSHOPSFOCUS,
  workshopsFocus,
  workshopsFocusCount
})

export const getWorkshopByUser = (group) => {
  return async (dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(`${urls.url_base}/V29ya3Nob3Bz/?group=${group}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCandidates.data;
      if (data.results.length !== 0) {
        dispatch(setWorkshop(data));
      } else {
        dispatch(setWorkshop([]));
        swal('No hay información de talleres disponible.');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const add_Workshop = (dataObj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.post(`${urls.url_base}/V29ya3Nob3Bz/`, dataObj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAllyData.data;
      if (data.length !== 0) {
        dispatch(addWorkshop(data));
        swal('Se ha creado el taller correctamente!');
        return true;
      } else {
        dispatch(addWorkshop([]));
        swal('Upss! correo y / o contraseña incorrectos!');
      }
    } catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const addWorkshopFocus = (dataObj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.post(`${urls.url_base}/Zm9jdXM/`, dataObj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAllyData.data;
      if (data.length !== 0) {
        swal('Se ha creado el taller correctamente!');
        return true;
      }
    } catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getAllWorkshopsFocus = (page = 1, search = "") => {
  return async (dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(`${urls.url_base}/Zm9jdXM/?search=${search}&page=${page}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCandidates.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setWorkshopsFocus(data, count));
      } else {
        dispatch(setWorkshopsFocus([], 0));
        swal('No hay información disponible');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const get_all_Workshops = (page = 1, search = "") => {
  return async (dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(`${urls.url_base}/V29ya3Nob3Bz/?search=${search}&page=${page}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCandidates.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setWorkshops(data));
        dispatch(setCount(count));
      } else {
        dispatch(setWorkshops([]));
        swal('No hay información disponible');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const add_Tracing = (dataObj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.post(`${urls.url_base}/VHJhY2luZw/`, dataObj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAllyData.data;
      if (data.length !== 0) {
        dispatch(isSaveTracing(true));
        dispatch(addTracing(data));
        swal('Se ha creado el seguimiento correctamente!');
      } else {
        dispatch(addTracing([]));
        swal('Upss! No se ha creado el seguimiento!');
      }
    } catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const add_TracingNuevo = (dataObj) => {
  console.log("ADD")
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.post(`${urls.url_base}/VHJhY2luZw2/`, dataObj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAllyData.data;
      if (data.length !== 0) {
        dispatch(isSaveTracingNuevo(true));
        //dispatch(add_TracingNuevo(data));
        swal('Se ha creado el seguimiento correctamente!');
      } else {
        dispatch(add_TracingNuevo([]));
        swal('Upss! No se ha creado el seguimiento!');
      }
    } catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getTracingCandidate = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.get(`${urls.url_base}/VHJhY2luZw/?candidate=${id}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAllyData.data;
      if (data.length !== 0) {
        dispatch(setTracingC(data));
      } else {
        dispatch(addTracing([]));
        swal('Upss! No se han realizado seguimientos a este candidato!');
      }
    } catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getTracingCandidateNuevo = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.get(`${urls.url_base}/VHJhY2luZw2/?candidate=${id}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAllyData.data;
      if (data.length !== 0) {
        dispatch(setTracingCNuevo(data));
      } else {
        dispatch(addTracingNuevo([]));
        swal('Upss! No se han realizado seguimientos a este candidato!');
      }
    } catch (err) {
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const deleteWorkshop = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.delete(`${urls.url_base}/V29ya3Nob3Bz/${id}/`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAllyData.data;
      dispatch(get_all_Workshops());
      swal('El taller fue eliminado correctamente!');
    } catch (err) {
      swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const deleteWorkshopFocus = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAllyData = await axios.delete(`${urls.url_base}/Zm9jdXM/${id}/`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAllyData.data;
      dispatch(getAllWorkshopsFocus());
      swal('El taller fue eliminado correctamente!');
    } catch (err) {
      swal(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const saveAssistance = (obj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAssistance = await axios.post(`${urls.url_base}/YXNzaXM/`, obj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAssistance.data;
      swal('La asistencia fue almacenada correctamente');
    } catch (err) {
      swal('Lo sentimos, no pudimos almacenar la información, intenta nuevamente');
    }
  }
}

export const saveAssistanceFocus = (obj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAssistance = await axios.post(`${urls.url_base}/YXNzaXNm/`, obj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAssistance.data;
      swal('La asistencia fue almacenada correctamente');
    } catch (err) {
      swal('Lo sentimos, no pudimos almacenar la información, intenta nuevamente');
    }
  }
}