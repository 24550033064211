import React, { Component } from "react";
import "./menu.styles.css";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logOut } from "../../redux/actions/allyActions";
import { setInfoClient } from "../../redux/actions/clientsActions";

import {
 BrowserRouter as Router,
 Switch,
 Route,
 Link,
 useParams,
 useRouteMatch,
 withRouter,
} from "react-router-dom";

class Menu extends Component {
 constructor(props) {
  super(props);
  this.state = {
   position: "",
   nameLogin: "",
   showSE: false,
   showSG: false,
   showFE: false,
   showFF: false,
   showRE: false,
   showREM: false,
   showAE: false,
   showAEP: false,

   showSC: true,
   showPI: true,
   showGP: true,
   showCL: false,
   showAEB: false,
  };
 }

 componentDidMount = () => {
  if (this.props.allyData.length !== 0) {
   let name = "";
   let data = this.props.allyData[0];
   if (data.businessData) {
    name = data.businessData.name;
   } else if (data.advisorData) {
    name = data.advisorData.fullName;
   } else if (data.managerData) {
    name = data.managerData.fullName;
   }
   this.setState({
    nameLogin: name,
   });
  }
  this.setState({
   position: sessionStorage.getItem("position"),
  });
 };

 onLogout = () => {
  // console.log('MenuProps', this.props);
  sessionStorage.setItem("auth", "false");
  this.props.logOut();
  // this.props.history.push('/');
 };

 navigateTo = (route) => {
  if (route === "/aprendiz/graphics") {
   sessionStorage.setItem("aptype", 0);
  } else {
   sessionStorage.setItem("aptype", 1);
  }
  this.props.history.push(route);
 };

 navigateGeneralTo = (route) => {
  if (route === "/seleccion/graphics") {
   sessionStorage.setItem("aptype", 0);
  } else {
   sessionStorage.setItem("aptype", 1);
  }
  this.props.history.push(route);
 };

 navigateGeneral = (rute, client) => {
  switch (client) {
   case "Nutresa":
    this.props.setInfoClient({ id: 3, name: "Nutresa", clientId: 3 });
    this.props.history.push(rute);
    break;
   case "Essilor":
    this.props.setInfoClient({ id: 9, name: "Essilor", clientId: 9 });
    this.props.history.push(rute);
    break;
   case "Comfama":
    this.props.setInfoClient({ id: 7, name: "Comfama" });
    this.props.history.push(rute);
    break;
   case "Sodexo":
    this.props.setInfoClient({ id: 5, name: "Sodexo" });
    this.props.history.push(rute);
    break;
   case "Sura":
    this.props.setInfoClient({ id: 12, name: "Sura" });
    this.props.history.push(rute);
    break;
   case "Bancolombia":
    this.props.setInfoClient({ id: 8, name: "Bancolombia" });
    this.props.history.push(rute);
    break;
   case "UPB":
    this.props.setInfoClient({ id: 13, name: "UPB" });
    this.props.history.push(rute);
    break;
   case "Biotech":
    this.props.setInfoClient({ id: 14, name: "Biotech" });
    this.props.history.push(rute);
    break;
   case "Arus":
    this.props.setInfoClient({ id: 15, name: "Arus" });
    this.props.history.push(rute);
    break;
   case "FundacionPaz":
    this.props.setInfoClient({ id: 16, name: "FundacionPaz" });
    this.props.history.push(rute);
    break;
   default:
    break;
  }
 };

 renderNewName = (path) => {
  switch (path) {
   case "relacionamiento":
    return "Emprendimiento";
   case "seleccion":
    return "Empleabilidad";
   case "acompanamiento":
    return "Formación para el talento";
   default:
    return path;
  }
 };

 render() {
  return (
   <div className="col backgroundMenu">
    {this.props.allyData.length === 0 ? <Redirect to="/" /> : ""}
    <div className="containerLogo">
     <img
      src={require("../../assets/imgs/logo.png")}
      width="90%"
     />
    </div>

    <div className="containerInformation">
     <div className="col containerGestor">
      {/* AQUI VA NOMBRE DEL USUARIO QUE INGRESO */}
      <p className="txtName">{this.state.nameLogin}</p>

      {/*AQUI VA CARGO DEL USUARIO QUE INGRESO*/}
      {/*  <p className="txtCargo">
              {this.renderNewName(this.props.modulePath)}
            </p> */}
     </div>
     <div className="containerSettings">
      <a className="dropbtn">
       <i className="fas fa-cog"></i>
      </a>
      <div className="dropdown-content">
       <a onClick={this.onLogout}>
        <strong
         id="logOut"
         className="text-center">
         Cerrar Sesión
        </strong>
       </a>
      </div>
     </div>
    </div>

    <div className="containerItems">
     {/* MENU QUE VA EN RELACIONAMIENTO*/}
     {this.props.module === "relacionamiento" &&
      this.props.allyData.length !== 0 &&
      this.props.allyData[0].managerData.permissions === "relacionamiento" && (
       <div>
        <div
         className="row item"
         style={{ backgroundColor: "#204d6a", marginTop: 5 }}
         onClick={() => {
          this.setState({ showRE: !this.state.showRE });
         }}>
         <Link
          to={`#`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <p
            className="itemMenu"
            style={{ color: "#fff" }}>
            Emprendimientos
           </p>
          </div>
         </Link>
        </div>

        <div style={{ display: this.state.showRE ? "block" : "none" }}>
         <div className="row item">
          <Link
           to={`/relacionamiento/graphicsEmprend`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fa fa-building-o"></i>
            <p className="itemMenu">Emprendimientos</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i class="fa fa-money-bill"></i>
            <p className="itemMenu">Reportes</p>
           </div>
          </Link>
         </div>
         {/* <div className="row item">
          <Link
           to={`/relacionamiento/cursos`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-cubes "></i>
            <p className="itemMenu">Talleres / Cursos</p>
           </div>
          </Link>
         </div> */}
         {/*   <div className="row item">
                    <Link to={`#`} style={{ textDecoration: "none" }}>
                      <div id="boxShadow">
                        <i className="fas fa-headset "></i>
                        <p className="itemMenu">Asesorías</p>
                      </div>
                    </Link>
                  </div>
                  <div className="row item">
                    <Link to={`#`} style={{ textDecoration: "none" }}>
                      <div id="boxShadow">
                        <i className="fas fa-tasks "></i>
                        <p className="itemMenu">Seguimientos</p>
                      </div>
                    </Link>
                  </div> */}
        </div>

      {/*   <div
         className="row item"
         style={{ backgroundColor: "#204d6a", marginTop: 20 }}
         onClick={() => {
          this.setState({ showREM: !this.state.showREM });
         }}>
         <Link
          to={`#`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <p
            className="itemMenu"
            style={{ color: "#fff" }}>
            Empleabilidad
           </p>
          </div>
         </Link>
        </div>

        <div style={{ display: this.state.showREM ? "block" : "none" }}>
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/relacionamiento/resumen-general`, "Sodexo")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Sodexo</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`/relacionamiento/advisory`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-headset "></i>
            <p className="itemMenu">Asesorias</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`/relacionamiento/advisory-special`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-headset "></i>

            <p className="itemMenu">Asesoria Especializada</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`/relacionamiento/resumen-seguimientos`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-tasks "></i>
            <p className="itemMenu">Seguimiento</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`listadoUsuarios`}
           style={{ textDecoration: "none" }}>
           <div
            id="boxShadow"
            className="">
            <i className="fas fa-users"></i>
            <p className="itemMenu">Lista Candidatos</p>
           </div>
          </Link>
         </div>
       
        </div> */}
       </div>
      )}

     {/* MENU QUE VA EN SELECCION*/}
     {this.props.module === "seleccion" &&
      this.props.allyData.length !== 0 &&
      this.props.allyData[0].managerData.permissions === "seleccion" && (
       <div>
        <div
         className="row item"
         style={{ backgroundColor: "#204d6a", marginTop: 10 }}
         onClick={() => {
          this.setState({ showSC: !this.state.showSC });
         }}>
         <Link
          to={`#`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <p
            className="itemMenu"
            style={{ color: "#fff", marginLeft: 8 }}>
            Selección y Contratación
           </p>
          </div>
         </Link>
        </div>

        <div style={{ display: this.state.showSC ? "block" : "none" }}>
         <div
          className="row item"
          onClick={() => this.navigateGeneralTo("/seleccion/seleccion")}>
          <Link
           to={`#`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user-check"></i>
            <p className="itemMenu">Resumen Seleccion</p>
           </div>
          </Link>
         </div>

         <div className="row item">
          <Link
           to={`vacantesRS`}
           style={{ textDecoration: "none" }}>
           <div
            id="boxShadow"
            className="">
            <i className="fas fa-street-view"></i>
            <p className="itemMenu">Vacantes</p>
           </div>
          </Link>
         </div>

         <div className="row item">
          <Link
           to={`/seleccion/addCandidate`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user-plus "></i>
            <p className="itemMenu">Postulaciones</p>
           </div>
          </Link>
         </div>
         {/* <div
          className="row item"
          onClick={() => this.navigateGeneralTo("/seleccion/graphics")}>
          <Link
           to={`#`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user-shield "></i>
            <p className="itemMenu">Remitidos</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`/seleccion/entrevista`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-users "></i>
            <p className="itemMenu">Entrevistas</p>
           </div>
          </Link>
         </div> */}
        </div>

        <div
         className="row item"
         style={{ backgroundColor: "#204d6a", marginTop: 10 }}
         onClick={() => {
          this.setState({ showCL: !this.state.showCL });
         }}>
         <Link
          to={`#`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <p
            className="itemMenu"
            style={{ color: "#fff", marginLeft: 8 }}>
            Clientes
           </p>
          </div>
         </Link>
        </div>

        <div style={{ display: this.state.showCL ? "block" : "none" }}>
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "Nutresa")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Nutresa</p>
           </div>
          </Link>
         </div>
       {/*   <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "Essilor")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Essilor</p>
           </div>
          </Link>
         </div> */}
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "Comfama")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Comfama</p>
           </div>
          </Link>
         </div>
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "Sodexo")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Sodexo</p>
           </div>
          </Link>
         </div>
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "Sura")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Sura</p>
           </div>
          </Link>
         </div>
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "Bancolombia")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Bancolombia</p>
           </div>
          </Link>
         </div>
        {/*  <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "UPB")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">UPB</p>
           </div>
          </Link>
         </div> */}
        {/*  <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "Biotech")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Biotech</p>
           </div>
          </Link>
         </div> */}
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "Arus")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Arus</p>
           </div>
          </Link>
         </div>
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "FundacionPaz")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">FIP</p>
           </div>
          </Link>
         </div>
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/seleccion/resumen-general`, "FundacionPaz")
          }>
          <Link
           // to={`/seleccion/resumen-general`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user"></i>
            <p className="itemMenu">Luxotica</p>
           </div>
          </Link>
         </div>
        </div>

        <div
         className="row item"
         style={{ backgroundColor: "#204d6a", marginTop: 10 }}
         onClick={() => {
          this.setState({ showPI: !this.state.showPI });
         }}>
         <Link
          to={`#`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <p
            className="itemMenu"
            style={{ color: "#fff", marginLeft: 8 }}>
            Plan Integral de acompañamiento
           </p>
          </div>
         </Link>
        </div>

        <div style={{ display: this.state.showPI ? "block" : "none" }}>
         <div className="row item">
          <Link
           to={`/seleccion/advisory`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-headset "></i>
            <p className="itemMenu">Asesorias</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`/seleccion/advisory-special`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-headset "></i>

            <p className="itemMenu">Asesoria Especializada</p>
           </div>
          </Link>
         </div>

         <div className="row item">
          <Link
           to={`/seleccion/talleres`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-cubes "></i>
            <p className="itemMenu">Talleres</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`/seleccion/resumen-seguimientos`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-tasks "></i>
            <p className="itemMenu">Seguimiento Historial</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`/seleccion/resumen-seguimientos-nuevo`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-tasks "></i>
            <p className="itemMenu">Seguimiento</p>
           </div>
          </Link>
         </div>
        </div>

        <div
         className="row item"
         style={{ backgroundColor: "#204d6a", marginTop: 10 }}
         onClick={() => {
          this.setState({ showGP: !this.state.showGP });
         }}>
         <Link
          to={`#`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <p
            className="itemMenu"
            style={{ color: "#fff", marginLeft: 8 }}>
            Gestión del personal
           </p>
          </div>
         </Link>
        </div>

        <div style={{ display: this.state.showGP ? "block" : "none" }}>
         <div className="row item">
          <Link
           to={`listadoUsuarios`}
           style={{ textDecoration: "none" }}>
           <div
            id="boxShadow"
            className="">
            <i className="fas fa-users"></i>
            <p className="itemMenu">Personal </p>
           </div>
          </Link>
         </div>
         {/*  <div className="row item">
          <Link
           to={`/seleccion/reentry`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-sync-alt "></i>
            <p className="itemMenu">Reingresos</p>
           </div>
          </Link>
         </div> */}
        </div>

      

        {/*  <div
         className="row item"
         style={{ backgroundColor: "#204d6a", marginTop: 10 }}
         onClick={() => {
          this.setState({ showAEB: !this.state.showAEB });
         }}>
         <Link
          to={`#`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <p
            className="itemMenu"
            style={{ color: "#fff", marginLeft: 8 }}>
            Aliados Externos
           </p>
          </div>
         </Link>
        </div>

        <div style={{ display: this.state.showAEB ? "block" : "none" }}>
         <div className="row item">
          <Link
           to={`/seleccion/calendar`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-calendar-alt "></i>
            <p className="itemMenu">Cronograma</p>
           </div>
          </Link>
         </div>
        </div> */}
       </div>
      )}

     {/* MENU QUE VA EN LOS APRENDICES*/}
     {this.props.module === "aprendiz" &&
      this.props.allyData.length !== 0 &&
      this.props.allyData[0].managerData.permissions === "aprendiz" && (
       <div>
        <div className="row item">
         <Link
          to={`/aprendiz/aprendiz`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-pen-alt "></i>
           <p className="itemMenu">Entrevista</p>
          </div>
         </Link>
        </div>
        <div
         className="row item"
         onClick={() => this.navigateTo(`/aprendiz/graphics`)}>
         <Link
          // to={`/aprendiz/graphics`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-globe-americas "></i>
           <p className="itemMenu">Relacionamiento</p>
          </div>
         </Link>
        </div>

        <div
         className="row item"
         onClick={() => this.navigateTo(`/aprendiz/seleccion`)}>
         <Link
          // to={`/aprendiz/seleccion`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-universal-access "></i>
           <p className="itemMenu">Seleccion</p>
          </div>
         </Link>
        </div>

        <div className="row item">
         <Link
          to={`/aprendiz/acompanamiento`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-people-arrows "></i>
           <p className="itemMenu">Acompañamiento</p>
          </div>
         </Link>
        </div>
       </div>
      )}

     {/* MENU QUE VA EN LOS PRACTICANTES*/}
     {this.props.module === "practicante" &&
      this.props.allyData.length !== 0 &&
      this.props.allyData[0].managerData.permissions === "practicante" && (
       <div>
        <div className="row item">
         <Link
          to={`/practicante/entrevista`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-users "></i>
           <p className="itemMenu">Clientes</p>
          </div>
         </Link>
        </div>
        <div className="row item">
         <Link
          to={`/practicante/seleccion`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-universal-access "></i>
           <p className="itemMenu">Seleccion</p>
          </div>
         </Link>
        </div>

        <div className="row item">
         <Link
          to={`/practicante/acompanamiento`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-people-arrows "></i>
           <p className="itemMenu">Acompañamiento</p>
          </div>
         </Link>
        </div>

        <div className="row item">
         <Link
          to={`/practicante/advisory`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-headset "></i>
           <p className="itemMenu">Asesorias</p>
          </div>
         </Link>
        </div>

        <div className="row item">
         <Link
          to={`/practicante/practicante/informes`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-clipboard "></i>
           <p className="itemMenu">Informes</p>
          </div>
         </Link>
        </div>
       </div>
      )}

     {/* MENU QUE VA EN ACOMPAÑAMIENTO*/}
     {this.props.module === "acompanamiento" &&
      this.props.allyData.length !== 0 &&
      this.props.allyData[0].managerData.permissions === "acompanamiento" && (
       <div>
        <div
         className="row item"
         style={{ backgroundColor: "#204d6a", marginTop: 10 }}
         onClick={() => {
          this.setState({ showFE: !this.state.showFE });
         }}>
         <Link
          to={`#`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <p
            className="itemMenu"
            style={{ color: "#fff" }}>
            Empleabilidad
           </p>
          </div>
         </Link>
        </div>

        <div style={{ display: this.state.showFE ? "block" : "none" }}>
         <div
          className="row item"
          onClick={() => this.navigateGeneralTo("/acompanamiento/graphics")}>
          <Link
           to={`#`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-user-shield "></i>
            <p className="itemMenu">Aliados Externos</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`vacantesRS`}
           style={{ textDecoration: "none" }}>
           <div
            id="boxShadow"
            className="">
            <i className="fas fa-street-view"></i>
            <p className="itemMenu">Vacantes</p>
           </div>
          </Link>
         </div>
         <div className="row item">
          <Link
           to={`listadoUsuarios`}
           style={{ textDecoration: "none" }}>
           <div
            id="boxShadow"
            className="">
            <i className="fas fa-users"></i>
            <p className="itemMenu">Lista Candidatos</p>
           </div>
          </Link>
         </div>
        </div>

        <div
         className="row item"
         style={{ backgroundColor: "#204d6a", marginTop: 10 }}
         onClick={() => {
          this.setState({ showFF: !this.state.showFF });
         }}>
         <Link
          to={`#`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <p
            className="itemMenu"
            style={{ color: "#fff" }}>
            Formación Talento
           </p>
          </div>
         </Link>
        </div>

        <div style={{ display: this.state.showFF ? "block" : "none" }}>
         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/acompanamiento/resumen-general`, "Sura")
          }>
          <Link
           // to={`/acompanamiento/grafica`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-users "></i>
            <p className="itemMenu">Sura</p>
           </div>
          </Link>
         </div>

         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(
            `/acompanamiento/resumen-general`,
            "Bancolombia"
           )
          }>
          <Link
           // to={`/acompanamiento/grafica`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-users "></i>
            <p className="itemMenu">Bancolombia</p>
           </div>
          </Link>
         </div>

         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/acompanamiento/resumen-general`, "UPB")
          }>
          <Link
           // to={`/acompanamiento/grafica`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-users "></i>
            <p className="itemMenu">UPB</p>
           </div>
          </Link>
         </div>

         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/acompanamiento/resumen-general`, "Sodexo")
          }>
          <Link
           // to={`/acompanamiento/grafica`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-users "></i>
            <p className="itemMenu">Sodexo</p>
           </div>
          </Link>
         </div>

         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/acompanamiento/resumen-general`, "Biotech")
          }>
          <Link
           // to={`/acompanamiento/grafica`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-users "></i>
            <p className="itemMenu">Biotech</p>
           </div>
          </Link>
         </div>

         <div
          className="row item"
          onClick={() =>
           this.navigateGeneral(`/acompanamiento/resumen-general`, "Arus")
          }>
          <Link
           // to={`/acompanamiento/grafica`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-users "></i>
            <p className="itemMenu">Arus</p>
           </div>
          </Link>
         </div>

         <div className="row item">
          <Link
           to={`/acompanamiento/talleres`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-cubes "></i>
            <p className="itemMenu">Talleres</p>
           </div>
          </Link>
         </div>

         <div className="row item">
          <Link
           to={`/acompanamiento/talleresFoco`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-cubes "></i>
            <p className="itemMenu">Talleres Foco</p>
           </div>
          </Link>
         </div>

         <div className="row item">
          <Link
           to={`/acompanamiento/advisory`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-headset "></i>

            <p className="itemMenu">Asesorias</p>
           </div>
          </Link>
         </div>

         <div className="row item">
          <Link
           to={`/acompanamiento/advisory-special`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-headset "></i>

            <p className="itemMenu">Asesoria Especializada</p>
           </div>
          </Link>
         </div>

         <div className="row item">
          <Link
           to={`/acompanamiento/resumen-seguimientos`}
           style={{ textDecoration: "none" }}>
           <div id="boxShadow">
            <i className="fas fa-tasks "></i>
            <p className="itemMenu">Seguimientos</p>
           </div>
          </Link>
         </div>
        </div>
       </div>
      )}

     {/* MENU QUE VA EN LOS ALIADOS EXTERNOS*/}
     {this.props.module === "aliados" && (
      <div>
       <div
        className="row item"
        style={{ backgroundColor: "#204d6a", marginTop: 10 }}
        onClick={() => {
         this.setState({ showAE: !this.state.showAE });
        }}>
        <Link
         to={`#`}
         style={{ textDecoration: "none" }}>
         <div id="boxShadow">
          <p
           className="itemMenu"
           style={{ color: "#fff" }}>
           Empleabilidad
          </p>
         </div>
        </Link>
       </div>

       <div style={{ display: this.state.showAE ? "block" : "none" }}>
        <div className="row item">
         <Link
          to={`/aliados/aliados`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <img
            src={require("../../assets/Icons/home.png")}
            alt="Inicio"
            className="iconHome"
           />
           <p className="itemMenu">Inicio</p>
          </div>
         </Link>
        </div>
        <div className="row item">
         <Link
          to={`/aliados/viewVacante`}
          style={{ textDecoration: "none" }}>
          <div
           id="boxShadow"
           className="">
           <i className="fas fa-street-view"></i>
           <p className="itemMenu">Vacantes</p>
          </div>
         </Link>
        </div>
        <div className="row item">
         <Link
          to={`/aliados/addCandidate`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fas fa-user-plus "></i>
           <p className="itemMenu">Ingresar Candidato</p>
          </div>
         </Link>
        </div>
        <div className="row item">
         <Link
          to={`/aliados/viewCandidate`}
          style={{ textDecoration: "none" }}>
          <div
           id="boxShadow"
           className="">
           <i className="fas fa-users"></i>
           <p className="itemMenu">Mostrar Candidatos</p>
          </div>
         </Link>
        </div>
       </div>

       <div
        className="row item"
        style={{ backgroundColor: "#204d6a", marginTop: 10 }}
        onClick={() => {
         this.setState({ showAEP: !this.state.showAEP });
        }}>
        <Link
         to={`#`}
         style={{ textDecoration: "none" }}>
         <div id="boxShadow">
          <p
           className="itemMenu"
           style={{ color: "#fff" }}>
           Emprendimientos
          </p>
         </div>
        </Link>
       </div>

       <div style={{ display: this.state.showAEP ? "block" : "none" }}>
        <div className="row item">
         <Link
          to={`/aliados/addEmprendimiento`}
          style={{ textDecoration: "none" }}>
          <div id="boxShadow">
           <i className="fa fa-building-o"></i>
           <p className="itemMenu">Ingresar Emprendimiento</p>
          </div>
         </Link>
        </div>
        <div className="row item">
         <Link
          to={`/aliados/viewEmprendimiento`}
          style={{ textDecoration: "none" }}>
          <div
           id="boxShadow"
           className="">
           <i className="fa fa-folder-open-o"></i>
           <p className="itemMenu">Mostrar Emprendimientos</p>
          </div>
         </Link>
        </div>
       </div>
      </div>
     )}

     {/* MENU QUE VA EN LOS CLIENTES*/}
     {this.props.module === "clientes" && (
      <div>
       {/*<div className="row item">
                <Link
                  to={`/clientes/clientes`}
                  style={{ textDecoration: "none" }}
                >
                  <div id="boxShadow">
                    <img
                      src={require("../../assets/Icons/home.png")}
                      alt="Inicio"
                      className="iconHome"
                    />
                    <p className="itemMenu">Inicio</p>
                  </div>
                </Link> 
              </div>*/}
       <div className="row item">
        <Link
         to={`/clientes/vacantes`}
         style={{ textDecoration: "none" }}>
         <div id="boxShadow">
          <img
           src={require("../../assets/Icons/vacante.png")}
           alt="Inicio"
           className="iconHome"
          />
          <p className="itemMenu">Vacantes</p>
         </div>
        </Link>
       </div>
       <div className="row item">
        <Link
         to={`/clientes/seguimientos-nuevo`}
         style={{ textDecoration: "none" }}>
         <div id="boxShadow">
          <img
           src={require("../../assets/Icons/list.png")}
           alt="Cronogramas"
           className="iconHome"
          />
          <p className="itemMenu">Seguimientos</p>
         </div>
        </Link>
       </div>
       <div className="row item">
        <Link
         to={`/clientes/vinculados`}
         style={{ textDecoration: "none" }}>
         <div id="boxShadow">
          <img
           src={require("../../assets/Icons/two.png")}
           alt="Cronogramas"
           className="iconHome"
          />
          <p className="itemMenu">Vinculados</p>
         </div>
        </Link>
       </div>
      </div>
     )}
    </div>
   </div>
  );
 }
}

const mapStateToProps = (state) => {
 return {
  allyData: state.allyReducer.allyData,
  modulePath: state.allyReducer.modulePath,
  isLoading: state.allyReducer.isLoading,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  logOut: bindActionCreators(logOut, dispatch),
  setInfoClient: bindActionCreators(setInfoClient, dispatch),
 };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
