import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { add_Tracing } from "../../../../redux/actions/acompanamientoActions";
import { Redirect } from 'react-router-dom';
import Header from "../../../header/Header";
import "./addSeguimiento.css";
import swal from 'sweetalert';

class AddSeleccionAcom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      month: '',
      year: '',
      adaptationCohesion: 0,
      regulationRules: 0,
      relationships: 0,
      personalPresentation: 0,
      communication: 0,
      dispositionTask: 0,
      observations: "",
      origen: '',
      idCandidate: null
    };
  }

  componentDidMount = () => {
    var id = this.props.match.params.idCan;
    let candidate = this.props.getSelectionByState;
    let findCandidate = candidate.results.find(p => p.id === parseInt(id));
    if (typeof findCandidate !== 'undefined') {
      this.setState({
        fullname: findCandidate.fullName,
        idCandidate: findCandidate.url
      });
    }
  }

  handleChange = (e) => {
    console.log(e.target.value);
    const newState = this.state;
    newState[e.target.name] = e.target.value;
    this.setState(newState)
  }

  addTracingData = () => {
    if(this.state.month !== ''){
      let year = new Date().getFullYear();
      let obj = {
        month: this.state.month,
        year: year,
        adaptationCohesion: parseInt(this.state.adaptationCohesion),
        regulationRules: parseInt(this.state.regulationRules),
        relationships: parseInt(this.state.relationships),
        personalPresentation: parseInt(this.state.personalPresentation),
        communication: parseInt(this.state.communication),
        dispositionTask: parseInt(this.state.dispositionTask),
        observations: this.state.observations,
        origen: 'Acompañamiento',
        idCandidate: this.state.idCandidate,
      }
      swal({
        title: 'Estas seguro?',
        text: 'Una vez guardado el seguimiento no podrá ser editado.',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      }).then((willSave) => {
        if(willSave){
          obj.total = obj.adaptationCohesion + obj.regulationRules + obj.relationships + obj.personalPresentation + obj.communication + obj.dispositionTask;
          this.props.add_Tracing(obj);
        }
      });
    }else{
      swal('Por favor selecciona el mes!');
    }
  }

  render() {
    console.log(this.props.saveTracing)
    return (
      <Fragment>
        <Header />
        <div className="backgroundRegistros">
        {this.props.saveTracing === true ? <Redirect to={`/acompanamiento/acompanamiento/seguimiento`} /> : null}
        <div className="customRow mb-5 text-left">
          <p>
            <strong className="titleSeguimiento text-left">Nuevo Seguimiento</strong>
          </p>
          <p className="subtitleSeguimiento">
            {`Agregar nuevo seguimiento para ${this.state.fullname}`}
          </p>
        </div>

        <div className="cardContent" >
          <form className="contentForm">
            <div className="form-group row">
              <label className="col-sm-7 col-form-label">Mes</label>
              <div className="col-sm-5">
                <select 
                  className="form-control"
                  name="month"
                  value={this.state.month}
                  onChange={this.handleChange}
                >
                  <option value="" defaultValue="" disabled>Seleccionar mes</option>
                  <option value="1">Enero</option>
                  <option value="2">Febrero</option>
                  <option value="3">Marzo</option>
                  <option value="4">Abril</option>
                  <option value="5">Mayo</option>
                  <option value="6">Junio</option>
                  <option value="7">Julio</option>
                  <option value="8">Agosto</option>
                  <option value="9">Septiembre</option>
                  <option value="10">Octubre</option>
                  <option value="11">Noviembre</option>
                  <option value="12">Diciembre</option>
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-7 col-form-label">Adaptación y cohesión al equipo de trabajo</label>
              <div className="col-sm-5 customRange">
                <input
                  name="adaptationCohesion"
                  type="range"
                  list="adaptacion"
                  min="0"
                  max="3"
                  step="1"
                  value={this.state.adaptationCohesion}
                  onChange={this.handleChange}
                />

                <datalist id="adaptacion">
                  <option value="0"/>
                  <option value="1"/>
                  <option value="2"/>
                  <option value="3"/>
                </datalist>

                <span>{this.state.adaptationCohesion}</span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-7 col-form-label">Cumplimiento de normas y reglamentos</label>
              <div className="col-sm-5 customRange">
                <input
                  name="regulationRules"
                  type="range"
                  list="cumplimiento"
                  min="0"
                  max="3"
                  step="1"
                  value={this.state.regulationRules}
                  onChange={this.handleChange}
                />

                <datalist id="cumplimiento">
                  <option value="0"/>
                  <option value="1"/>
                  <option value="2"/>
                  <option value="3"/>
                </datalist>

                <span>{this.state.regulationRules}</span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-7 col-form-label">Relaciones interpersonales</label>
              <div className="col-sm-5 customRange">
                <input
                  name="relationships"
                  type="range"
                  list="relaciones"
                  min="0"
                  max="3"
                  step="1"
                  value={this.state.relationships}
                  onChange={this.handleChange}
                />

                <datalist id="relaciones">
                  <option value="0"/>
                  <option value="1"/>
                  <option value="2"/>
                  <option value="3"/>
                </datalist>

                <span>{this.state.relationships}</span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-7 col-form-label">Presentación personal</label>
              <div className="col-sm-5 customRange">
                <input
                  name="personalPresentation"
                  type="range"
                  list="personal"
                  min="0"
                  max="3"
                  step="1"
                  value={this.state.personalPresentation}
                  onChange={this.handleChange}
                />

                <datalist id="personal">
                  <option value="0"/>
                  <option value="1"/>
                  <option value="2"/>
                  <option value="3"/>
                </datalist>

                <span>{this.state.personalPresentation}</span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-7 col-form-label">Comunicación</label>
              <div className="col-sm-5 customRange">
                <input
                  name="communication"
                  type="range"
                  list="comunicacion"
                  min="0"
                  max="3"
                  step="1"
                  value={this.state.communication}
                  onChange={this.handleChange}
                />

                <datalist id="comunicacion">
                  <option value="0"/>
                  <option value="1"/>
                  <option value="2"/>
                  <option value="3"/>
                </datalist>

                <span>{this.state.communication}</span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-7 col-form-label">Disposición a la tarea</label>
              <div className="col-sm-5 customRange">
                <input
                  name="dispositionTask"
                  type="range"
                  list="tarea"
                  min="0"
                  max="3"
                  step="1"
                  value={this.state.dispositionTask}
                  onChange={this.handleChange}
                />

                <datalist id="tarea">
                  <option value="0"/>
                  <option value="1"/>
                  <option value="2"/>
                  <option value="3"/>
                </datalist>

                <span>{this.state.dispositionTask}</span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-7 col-form-label">Observaciones</label>
              <div className="col-sm-5">
                <textarea
                  className="form-control"
                  name="observations"
                  rows="3"
                  value={this.state.observations}
                  onChange={this.handleChange}
                >
                </textarea>
              </div>
            </div>
            <br />
            <div className="form-group row" style={{justifyContent: 'center'}}>
              <button
                className="btn btn-primary"
                type="button"
                style={{backgroundColor: '#204d6a'}}
                onClick={this.addTracingData}
              >
                Guardar
              </button>

            </div>
          </form>
        </div>


      </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    allyData: state.allyReducer.allyData,
    getSelectionByState: state.selectionReducer.getSelectionByState,
    isLoading: state.accompanimentReducer.isLoading,
    saveTracing: state.accompanimentReducer.saveTracing
  }
}

const mapDispatchToProps = dispatch => {
  return{
    add_Tracing: bindActionCreators(add_Tracing, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSeleccionAcom);
