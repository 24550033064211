import React, { Component, Fragment } from "react";
import "./consolidados.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getConsolidated, getConsultancies } from "../../../../redux/actions/consultanciesAction";

import Header from "../../../header/Header";
import { Link } from "react-router-dom";


import {
  ResponsiveContainer, Legend, Bar, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
} from 'recharts';

import {
  AreaChart, BarChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';



function CandidateRow(props) {
  const consultancies = props.consultancies;
  const path = props.path;
  var route = `consolidado/gestor/${consultancies.managerId}/${consultancies.name}`;

  return (
    <tr id="tr">
      <td className="titleItemRow">{consultancies.name}</td>
      <td className="titleItemRow">{consultancies.totalC}</td>
      <td className="titleItemRow">{consultancies.totalJ}</td>
      <td className="titleItemRow">{`${parseInt(consultancies.time).toFixed(0)} min`}</td>
      <td className="titleItemRow">
        <Link className="btnHV" to={`${route}`}>
          <i className="fas fa-eye text-info"></i>
        </Link>
      </td>
    </tr>
  );
}

class Consolidados extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.getConsultancies([]);
    this.props.getConsolidated();
  };

  render() {
    const data2 = [
      {
        name: '4 - Juan pablo Álzate', seguimientos: 12
      },
      {
        name: '2 - Jeannete Arboleda', seguimientos: 8
      },
      {
        name: '3 - Ana María Lenis', seguimientos: 20
      },
      {
        name: '5 - Juan Alberto Cardona', seguimientos: 5
      },
      {
        name: '6 - José Fernando Acevedo', seguimientos: 6
      }
    ];
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div className="col-12 containerTitleSeleccion mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Consolidado Asesorias Gestores
                </strong>
              </p>
            </div>

            <div className="col-12 backgroundAsesorias">
            <div className="row">
              <div className="col-12">
                <h5 className="card-title">Consolidado x Gestor</h5>  
                <ResponsiveContainer width='100%' height="200px" aspect={25.0/4.0}>
                  <BarChart
                    
                    height={30}
                    data={this.props.consolidate.filter(x => x.managerId != 4)}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    
                 <Bar dataKey="totalC" fill="#82ca9d" />
                 <Bar dataKey="totalJ" fill="#82c84d" />
                 <Bar dataKey="time" fill="#34c84d" />
                  </BarChart>
                </ResponsiveContainer>
                <br />
                <br />
                <br />
              </div>
           </div>

              <div className="table-responsiveAsesoria containerTable">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="titleTable">
                        Gestor
                      </th>
                      <th scope="col" className="titleTable">
                        Asesorias Colaboradores
                      </th>
                      <th scope="col" className="titleTable">
                        Asesorias Jefes inmediatos
                      </th>
                      <th scope="col" className="titleTable">
                        Tiempo Promedio
                      </th>
                      <th scope="col" className="titleTable"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.consolidate.length != 0
                      ? this.props.consolidate.filter(x => x.managerId != 4).map(
                          (consultancies, index) => {
                            return (
                              <CandidateRow
                                consultancies={consultancies}
                                key={index.toString()}
                                path={this.props.modulePath}
                              />
                            );
                          }
                        )
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.candidateReducer.isLoading,
    consolidate: state.consultanciesReducer.consolidate,
    modulePath: state.allyReducer.modulePath,
    allyData: state.allyReducer.allyData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConsolidated: bindActionCreators(getConsolidated, dispatch),
    getConsultancies: bindActionCreators(getConsultancies, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Consolidados);
