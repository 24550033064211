import axios from 'axios';
import urls from '../apiConf';

import swal from 'sweetalert';

export const uploadSingleImage = async (path) => {
  const formattedPath = new FormData();
  formattedPath.append('file', path);

  try {
    const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
      headers: { 'Content-Type': 'application/json' }
    });
    const token = await reponseToken.data.token;
    const responseFile = await axios.post(`${urls.url_base}/c3ViaXI/`, formattedPath, { headers: { 'Authorization': `JWT ${token}` } });
    const data = await responseFile.data;
    if (data.length != 0) {
      return (data);
    }
  } catch (err) {
    alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
  }
}

export const uploadMultipleDocs = (arr) => {
  var urls = [];
  var count = 0;

  return new Promise(resolve => {
    for (let i = 0; i < arr.length; i++) {
      uploadSingleImage(arr[i]).then((data) => {
        if (data.length != 0) {
          urls.push(data.file);
          count += 1;
        } else {
          count += 1;
        }

        if (arr.length == count) {
          resolve(urls);
        }
      });
    }
  });

}