import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { updatePositionsSubstates } from '../../../redux/actions/candidateActions';
import { bindActionCreators } from "redux";

class ModalDeleteNoPasa extends Component {
	constructor(props) {
		super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.state = {
			show: false,
			causa: ""
		};
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	handleSubmit = (event) => {
		event.preventDefault();
		var obj = {
			position: event.target.position.value,
			proccessSubstate: event.target.substate.value,
			idCandidate: event.target.candidato.value,
			reentry: event.target.causa.value,
			reentryObs: event.target.causReal.value
		}
		console.log(obj);
		this.props.updatePositionsSubstates(obj, true);
		this.setState({ show: false });
	}

	render() {
		const idCan = this.props.idCan;
		const idStatus = this.props.idStatus;
		const idSubStatus = this.props.idSubStatus;
		return (
			<>
				<i className="fas fa-minus-circle text-danger" onClick={this.handleShow}></i>


				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>{this.props.titleModal}</Modal.Title>
					</Modal.Header>
					<form onSubmit={this.handleSubmit}>
						<Modal.Body className="text-center">
							<input type="hidden" name="candidato" value={idCan} />
							<input type="hidden" name="estado" value={idStatus} />
							<input type="hidden" name="substate" value={idSubStatus} />
							<input type="hidden" name="position" value={this.props.position} />

							<div className="row">
								<div className="col-12 mb-2">
									<select
										name="causa"
										className="form-control"
									>
										<option defaultValue="" disabled>Seleccionar Motivo</option>
										<option value="No le interesa">No le interesa</option>
										<option value="No cumple con el perfil">No cumple con el perfil</option>
										<option value="Actitud">Actitud</option>
										<option value="Inasistencia">Inasistencia</option>
										<option value="No citado">No citado</option>
										<option value="Muerte">Muerte</option>
										<option value="No contactado">No contactado</option>
										<option value="Número errado">Número errado</option>
									</select>
								</div>
								<div className="col-12 mb-2">
									<textarea className="form-control" name="causReal" placeholder="Descripción motivo ..."></textarea>
								</div>
							</div>
							<Button variant="danger" className="mr-3" onClick={this.handleClose}>
								Cerrar
							</Button>
							<Button type="submit" variant="danger">
								Eliminar
							</Button>
						</Modal.Body>
					</form>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoading: state.selectionReducer.isLoading,
		setCandidateStatus: state.selectionReducer.candidatesStatus
	}
}

const mapDispatchToProps = dispatch => {
	return {
		updatePositionsSubstates: bindActionCreators(updatePositionsSubstates, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteNoPasa);