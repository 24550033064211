import { GET_COURSES, ADD_COURSES, ISLOADING } from '../constants/action-type';

const getInitialState = () => ({
  courses: { results: [] },
  countCourse: 0,
  isSaveCourse: false,
  isLoading: false
});

const coursesReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case ISLOADING:
      return { ...state, isLoading: action.isLoading };
    case GET_COURSES:
      return { ...state, courses: action.courses, isSaveCourse: false, countCourse: action.countCourse };
    default:
      return state;
  }
}

export default coursesReducer;