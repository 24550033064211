import React, { Component } from 'react';
import './inicio.styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTimelinesRegister, getCandidatesByAlly } from '../../../redux/actions/allyActions';

//Alert
import CustomAlert from '../../alerts/alerts';

class Inicio extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initDate: '',
			endDate: '',
			showAlert: false
		};
	}

	componentDidMount = async () => {
		this.props.getCandidatesByAlly([]);
		if (this.props.allyData.length !== 0) {
			var obj = {
				idAlly: this.props.allyData[0].advisorData.id
			}
			await this.props.getTimelinesRegister(obj);
			var timeAlly = this.props.timeAlly;
			this.validateTimeToRegister(timeAlly);
		}
	}

	validateTimeToRegister = (timeAlly) => {
		let findInterval = [];
		if (timeAlly.length !== 0) {
			var timelines = timeAlly.results;
			var today = new Date().getTime();
			timelines.forEach((item, index) => {
				var init_date = new Date(item.initDate).getTime();
				var end_date = new Date(item.endDate).getTime();
				if (init_date <= today && end_date >= today) {
					findInterval.push(item);
				}

				if (timelines.length === (index + 1)) {
					if (findInterval.length !== 0) {
						sessionStorage.setItem('canAdd', true);
						this.calculateDifferenceDays(findInterval[0].endDate);
						this.setState({
							initDate: findInterval[0].initDate,
							endDate: findInterval[0].endDate
						});
					} else {
						sessionStorage.setItem('canAdd', false);
					}
				} else {
					sessionStorage.setItem('canAdd', false);
				}
			});
		}
	}

	calculateDifferenceDays = (end) => {
		var endDay = new Date(end);
		var today = new Date();
		var differenceTime = endDay.getTime() - today.getTime();
		var numberDays = Math.round(differenceTime / (1000 * 3600 * 24))
		if (numberDays <= 1) {
			this.setState({
				showAlert: true
			});
		}
	}

	render() {
		return (
			<div className="backgroundInicio">
				<div className="headerInicio">
					<div className="allyPicture">
						{this.props.allyData.length != 0 && <img src={this.props.allyData[0].advisorData.profilePicture} width="100%" />}
					</div>

					<a className="btnNotification" href="#">
						<img src={require('../../../assets/Icons/bell.png')} alt="Notificaciones" className="iconAlerts" />
					</a>
				</div>

				<div className="dateInicio">
					<div className="containerDate">
						<h6>RANGO ACTUAL</h6>
						<form>
							<div className="form-group row">
								<label className="col-sm-3 col-form-label">Fecha de Apertura</label>
								<div className="col-sm-9">
									<input
										type="date"
										value={this.state.initDate}
										className="form-control"
										placeholder="1/06/2021"
										disabled
									/>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-sm-3 col-form-label">Fecha de Cierre</label>
								<div className="col-sm-9">
									<input
										type="date"
										value={this.state.endDate}
										className="form-control"
										placeholder="30/06/2021"
										disabled
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
				{this.state.showAlert && <div className="row" style={{ width: '100%', justifyContent: 'center' }}>
					<CustomAlert
						color="warning"
						msg="Recuerda, la fecha de cierre de convocatoria de candidatos es mañana."
						data={[]}
						history={this.props.history}
						modulePath={this.props.modulePath}
					/>
				</div>}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoading: state.candidateReducer.isLoading,
		timeAlly: state.allyReducer.timeAlly,
		allyData: state.allyReducer.allyData
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getTimelinesRegister: bindActionCreators(getTimelinesRegister, dispatch),
		getCandidatesByAlly: bindActionCreators(getCandidatesByAlly, dispatch)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Inicio);