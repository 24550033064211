import React from "react";
import { Component } from "react";
import Header from "../../header/Header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { countAllStatesGender } from "../../../redux/actions/selectionActions";
import { getRelAlerts } from "../../../redux/actions/alertActions";
import "./graphics.css";

//Componentes Propios
import GraficosSeleccion from "../../seleccion/consolidado/graficas/graficas";
import BarrasSeleccion from "../../seleccion/consolidado/barras/barras";
import TablaSeleccion from "../../seleccion/consolidado/tabla/tabla";
import { Link } from "react-router-dom";
import CustomAlert from "../../alerts/alerts";

class Graphics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.countAllStatesGender();
    this.props.getRelAlerts(new Date(), 0);
    this.props.getRelAlerts(new Date(), 1);
  };

  render() {
    return (
      <>
        <Header />

        <div className="col-12 bgResumenSeleccion">
          <div className="col-12">
            <div
              className="alert alert-danger"
              role="alert"
              style={{ display: "none" }}
            >
              Victimas nacionales ha ingresado 5 nuevos candidatos remitidos
            </div>
          </div>
          <div>
            <div style={{ position: "absolute", zIndex: 99, right: 10 }}>
              {this.props.alertRelacionamiento.results.length !== 0 && (
                <CustomAlert
                  color="info"
                  msg={`Se han recibido ${this.props.alertRelacionamiento.results.length} nuevos candidatos`}
                  data={this.props.alertRelacionamiento.results}
                  history={this.props.history}
                  modulePath={this.props.modulePath}
                />
              )}
              {this.props.alertRemitidos.results.length !== 0 && (
                <CustomAlert
                  color="info"
                  msg={`Se han remitido ${this.props.alertRemitidos.results.length} candidatos a proceso de selección`}
                  data={this.props.alertRemitidos.results}
                  history={this.props.history}
                  modulePath={this.props.modulePath}
                />
              )}
            </div>
            <div className="customRow mb-5 text-left">
              <p>
                <strong className="titleCiudad text-left">
                  Candidatos Remitidos
                </strong>
              </p>
            </div>

            {/* <div className="row">
              <div className="col-4">
                <GraficosSeleccion idBussines={3} />
              </div>

              <div className="col-4">
                <BarrasSeleccion />
              </div>
            </div> */}

            {/* <div className="row">
              <div className="col-3">
                <p className="subtitleSeguimiento">Resumen: </p>
              </div>

              <div className="col-9">
                <button className="btn btn-info"></button>
              </div>
            </div> */}
            <div
              className="row  bgConsolidadoSeleccion"
              style={{ height: "500px" }}
            >
              <div className="table-responsiveGraphics containerTable">
                <table className="table ">
                  <thead>
                    <tr id="tr">
                      <th>Estado</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>
                  {this.props.getSummaryState.length !== 0 && (
                    <tbody>
                      <tr id="tr">
                        <td>Nuevos</td>
                        <td>
                          {this.props.getSummaryState.consolidado.find(
                            (el) => el.subState == 0
                          ) !== undefined
                            ? this.props.getSummaryState.consolidado.find(
                                (el) => el.subState == 0
                              ).total
                            : 0}
                        </td>
                        <td>
                          <Link
                            className="linkDetalle"
                            to={`/${this.props.modulePath}/cities`}
                          >
                            Ver Detalle
                          </Link>
                        </td>
                      </tr>

                      <tr id="tr">
                        <td>Remitidos</td>
                        <td>
                          {this.props.getSummaryState.consolidado.find(
                            (el) => el.subState == 1
                          ) !== undefined
                            ? this.props.getSummaryState.consolidado.find(
                                (el) => el.subState == 1
                              ).total
                            : 0}
                        </td>
                        <td>
                          <Link
                            className="linkDetalle"
                            to={`/${this.props.modulePath}/cities`}
                          >
                            Ver Detalle
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>

              <br />
              <br />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modulePath: state.allyReducer.modulePath,
    getSummaryState: state.selectionReducer.getSummaryState,
    alertRelacionamiento: state.alertsReducer.alertRelacionamiento,
    alertRemitidos: state.alertsReducer.alertRemitidos,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    countAllStatesGender: bindActionCreators(countAllStatesGender, dispatch),
    getRelAlerts: bindActionCreators(getRelAlerts, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Graphics);
