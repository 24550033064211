import { ISLOADING, GET_BUSINESS } from '../constants/action-type';

const getInitialState = () => ({
    businessData: [],
    isLoading: false,
});

const businessReducer = (state = getInitialState(), action) => {
    switch(action.type) {
        case ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case GET_BUSINESS:
            return { ...state, businessData: action.businessData, isLoading: false };
        default:
            return state;
     }
}

export default businessReducer;