import {
  ISLOADING,
  GET_SELECTION_STATE,
  CREATE_INTERVIEW,
  GET_INTERVIEWS,
  GET_SUMMARY_STATE,
  GET_INTERVIEWS_ID,
  CREATE_FINDING,
  UPDATE_FINDING,
  GET_FINDING,
  PAGE_COUNT,
} from "../constants/action-type";
import urls from "../../apiConf";
import axios from "axios";

import swal from "sweetalert";

export const isLoading = (bool) => ({
  type: ISLOADING,
  isLoading: bool,
});

export const getSelectionByState = (getSelectionByState) => ({
  type: GET_SELECTION_STATE,
  getSelectionByState,
});

export const addInterview = (createInterview) => ({
  type: CREATE_INTERVIEW,
  createInterview,
});

export const getInterviewByState = (interviewByState) => ({
  type: GET_INTERVIEWS,
  interviewByState,
});

export const getInterviewByID = (interviewByID) => ({
  type: GET_INTERVIEWS_ID,
  interviewByID,
});

export const getFindings = (findings) => ({
  type: GET_FINDING,
  findings,
});

export const addFindings = (findings) => ({
  type: CREATE_FINDING,
  findings,
});

export const updateFindings = (findings) => ({
  type: UPDATE_FINDING,
  findings,
});

export const addInterviewByID = (interviewByID) => ({
  type: GET_INTERVIEWS_ID,
  interviewByID,
});

export const getSummaryState = (getSummaryState) => ({
  type: GET_SUMMARY_STATE,
  getSummaryState,
});

export const setCount = (pageCount) => ({
  type: PAGE_COUNT,
  pageCount,
});

export const getAllSelectionByState = (obj) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?ally=${obj.all_id}&substate=${obj.idSubState}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;

      if (data.length !== 0) {
        dispatch(getSelectionByState(data));
      } else {
        dispatch(getSelectionByState([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getAllSelectionByStateNotAlly = (idSubState) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/Q2FuZGlkYXRvcw/?substate=${idSubState}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;

      if (data.length !== 0) {
        dispatch(getSelectionByState(data));
      } else {
        dispatch(getSelectionByState([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getTracingByRange = (min, max, month, year, page = 1) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      if (month.charAt(0) === "0") month = month.replace("0", "");
      const responseCandidates = await axios.get(
        `${urls.url_base}/VHJhY2luZw/?min=${min}&max=${max}&month=${month}&year=${year}&page=${page}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;

      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(getSelectionByState(data));
        dispatch(setCount(count));
      } else {
        dispatch(getSelectionByState([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getTracingByRangeNuevo = (min, max, month, year, page = 1) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      if (month.charAt(0) === "0") month = month.replace("0", "");

      const responseCandidates = await axios.get(
        `${urls.url_base}/VHJhY2luZw2/?min=${min}&max=${max}&month=${month}&year=${year}&page=${page}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;

      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(getSelectionByState(data));
        dispatch(setCount(count));
      } else {
        dispatch(getSelectionByState([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getAllInterviewByState = (type) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/SW50ZXJ2aWV3/?type=${type}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;

      if (data.length !== 0) {
        dispatch(getInterviewByState(data));
      } else {
        dispatch(getInterviewByState([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const getAllInterviewByID = (idCan, type) => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/SW50ZXJ2aWV3/?candidate=${idCan}&type=${type}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;

      if (data.length !== 0) {
        dispatch(getInterviewByID(data));
      } else {
        dispatch(getInterviewByID([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const countAllStatesGender = () => {
  return async (dispatch) => {
    // let candidate = [];
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(`${urls.url_base}/dds98798/`, {
        headers: { Authorization: `JWT ${token}` },
      });
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(getSummaryState(data));
      } else {
        dispatch(getSummaryState([]));
        swal("No hay información disponible");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const createInterviewState = (obj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.post(
        `${urls.url_base}/SW50ZXJ2aWV3/`,
        obj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(addInterview(data));
        swal("La entrevista fue creada correctamente.");
      } else {
        dispatch(addInterview([]));
        swal("Upss! a ocurrido un error, por favor intenta más tarde!");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

// Hallazgos

export const getFindingsCandidate = (idCan) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.get(
        `${urls.url_base}/RmluZGluZ3M/?candidate=${idCan}`,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.results.length !== 0) {
        dispatch(getFindings(data));
      } else {
        dispatch(getFindings([]));
        // swal('No hay información de hallazgos disponible');
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const createFinding = (obj, id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.post(
        `${urls.url_base}/RmluZGluZ3M/`,
        obj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        swal("El hallazgo / motivo fue almacenado correctamente!");
        dispatch(getFindingsCandidate(id));
      } else {
        dispatch(addFindings([]));
        swal("Upss! a ocurrido un error, por favor intenta más tarde!");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};

export const updateFinding = (obj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(
        `${urls.url_base}/dG9rZW4/`,
        urls.body,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const token = await reponseToken.data.token;
      const responseCandidates = await axios.post(
        `${urls.url_base}/RmluZGluZ3M/`,
        obj,
        { headers: { Authorization: `JWT ${token}` } }
      );
      const data = await responseCandidates.data;
      if (data.length !== 0) {
        dispatch(updateFindings([]));
      } else {
        dispatch(updateFindings([]));
        swal("Upss! a ocurrido un error, por favor intenta más tarde!");
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(
        `Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`
      );
    }
  };
};
