import { ISLOADING, ADD_CALENDAR_REGISTROS, GET_PROGRAM, ADD_CALENDAR_SEGUIMIENTOS, GET_CALENDAR_REGISTROS, GET_CALENDAR_FOLLOW, ISSAVE, PAGE_COUNT } from '../constants/action-type';

const getInitialState = () => ({
    timelinesData: [],
    isLoading: false,
    isSave: false,
    programData: [],
    timelinesData2: [],
    timelinesData3: [],
    timelinesData4: [],
    pageCount: 0
});

const timelinesReducer = (state = getInitialState(), action) => {
    switch(action.type) {
        case ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case PAGE_COUNT:
            return { ...state, pageCount: action.pageCount };
        case ADD_CALENDAR_REGISTROS:
            return { ...state, timelinesData: action.timelinesData, isLoading: false, isSave: true };
        case GET_PROGRAM:
            return { ...state, programData: action.programData, isLoading: false };
        case ADD_CALENDAR_SEGUIMIENTOS:
            return { ...state, timelinesData2: action.timelinesData2, isLoading: false, isSave: true };
        case GET_CALENDAR_REGISTROS:
            return { ...state, timelinesData3: action.timelinesData3, isLoading: false, isSave: true };
        case GET_CALENDAR_FOLLOW:
            return { ...state, timelinesData4: action.timelinesData4, isLoading: false, isSave: true };
        case ISSAVE:
            return { ...state, isSave: action.isSave }
        default:
            return state;
     }
}

export default timelinesReducer;