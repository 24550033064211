import React, { Component, Fragment } from "react";
import { FormGroup, Input, Button } from "reactstrap";
import "./colaborador.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add_consultancies } from "../../../../redux/actions/consultanciesAction";
import {
 getAllCandidates,
 searchCandidateDoc,
} from "../../../../redux/actions/candidateActions";
import { Redirect } from "react-router-dom";
import SelectSearch from "react-select-search";

import Header from "../../../header/Header";
import swal from "sweetalert";

class ColaboradorAcompanamiento extends Component {
 constructor(props) {
  super(props);
  this.state = this.setInitialState();
 }

 componentDidMount = async () => {
  let name = sessionStorage.getItem("name");
  if (name) {
   this.setState({
    searchInput: name,
   });
   setTimeout(() => {
    this.searchCandidate();
   }, 500);
  } else {
   this.props.getAllCandidates();
  }
 };

 componentDidUpdate = (prevProps, prevState) => {
  if (prevProps.candidates !== this.props.candidates) {
   let data = this.props.candidates.results;
   let optionsSelect = data.map((item) => {
    return {
     name: item.fullName,
     value: item.url,
    };
   });
   this.setState({ colaboradores: optionsSelect });
  }
 };

 setInitialState = () => {
  var id = this.props.match.params.advi;
  if (id !== undefined) {
   var findAdvisory = this.props.consultanciesData2.results.filter(
    (el) => el.id == id
   )[0];
   return {
    consultanciesType: findAdvisory.consultanciesType,
    mediaType: findAdvisory.mediaType,
    theme: findAdvisory.theme,
    advisoryDate: findAdvisory.advisoryDate,
    duration: findAdvisory.duration,
    summary: findAdvisory.summary,
    immediateBoss: findAdvisory.immediateBoss,
    city: findAdvisory.city,
    deal: findAdvisory.deal,
    idCandidate: findAdvisory.idCandidate,
    idProgram: findAdvisory.idProgram,
    isEdit: true,
    colaboradores: [],
    otherTheme: findAdvisory.otherTheme,
    candidateName: findAdvisory.candidate.fullName,
   };
  } else {
   return {
    consultanciesType: 0,
    mediaType: "",
    theme: "",
    advisoryDate: "",
    duration: "1",
    summary: "",
    immediateBoss: null,
    city: null,
    deal: null,
    idCandidate: "",
    idProgram: null,
    isEdit: false,
    colaboradores: [],
    otherTheme: "",
    candidateName: "",
    searchInput: "",
   };
  }
 };

 handleChange = (e) => {
  const newState = this.state;
  newState[e.target.id] = e.target.value;
  this.setState(newState);
 };

 handleChangeSelect = (e) => {
  this.setState({
   idCandidate: e,
  });
 };

 onSaveConsultancies = () => {
  if (this.state.isEdit == false) {
   if (this.state.duration !== "") {
    var obj = {
     mediaType: this.state.mediaType,
     theme: this.state.theme,
     advisoryDate: this.state.advisoryDate,
     duration: this.state.duration,
     summary: this.state.summary,
     immediateBoss: this.state.immediateBoss,
     city: this.state.city,
     deal: this.state.deal,
     idCandidate: this.state.idCandidate,
     idProgram: this.state.idProgram,
     manager: this.props.allyData[0].managerData.url,
     otherTheme: this.state.otherTheme,
    };
    this.props.add_consultancies(obj).then(() => {
     swal("Asesoria creada correctamente!").then(() => {
      sessionStorage.removeItem("name");
      this.props.history.push(`/${this.props.modulePath}/advisory`);
     });
    });
   } else {
    swal("Por favor ingresa todos los campos");
   }
  } else {
   console.log("Actualizacion info");
  }
 };

 searchCandidate = () => {
  if (this.state.searchInput.length !== 0) {
   this.props.searchCandidateDoc(this.state.searchInput).then((resp) => {
    if (resp.length !== 0) {
     let optionsSelect = resp.map((item) => {
      return {
       name: item.fullName,
       value: item.url,
      };
     });
     this.setState({ colaboradores: optionsSelect, idCandidate: "" });
    }
   });
  } else {
   swal(
    "Oops!",
    "Por favor ingresa un nombre o documento para buscar.",
    "warning"
   );
  }
 };

 render() {
  return (
   <Fragment>
    <Header />
    <div className="col-12 bgResumenSeleccion">
     <div className="col-12">
      <div className="col-12 containerTitleSeleccion mb-5 text-left">
       <p>
        <strong className="titleCiudad text-left">
         Nueva asesoria para el colaborador
        </strong>
       </p>
      </div>
     </div>
    </div>

    <div className="bachgroundAddCalendar">
     {/* {this.props.isSave === true ? (
            <Redirect to={`/${this.props.modulePath}/advisory`} />
          ) : (
              ""
            )} */}

     <div className="containerFormularioAsesoriaJefe">
      <form
       className="formularioAddAsesoria"
       style={{ height: "50vh" }}>
       <div
        className="form-group row"
        style={{ margin: 0 }}>
        <label className="col-sm-4">
         <p className="labelForm">Colaborador</p>{" "}
        </label>
        <div className="col-sm-6">
         <Input
          className="form-control"
          id="searchInput"
          value={this.state.searchInput}
          onChange={this.handleChange}
          placeholder="Buscar por documento o nombre"
          disabled={this.state.isEdit}
         />
        </div>
        <div
         className="col-sm-2"
         style={{ textAlign: "right" }}>
         <Button
          color="info"
          type="button"
          onClick={() => {
           this.searchCandidate();
          }}
          disabled={this.state.isEdit}>
          Buscar
         </Button>
        </div>
       </div>
       <div className="form-group row">
        <div className="col-sm-3"></div>
        <div className="col-sm-9">
         {!this.state.isEdit ? (
          <SelectSearch
           search
           options={this.state.colaboradores}
           placeholder="Selecciona un colaborador"
           id="idCandidate"
           onChange={this.handleChangeSelect}
           value={this.state.idCandidate}
          />
         ) : (
          <Input
           disabled
           value={this.state.candidateName}
          />
         )}
        </div>
       </div>

       <div className="form-group row">
        <label className="col-sm-3 col-form-label">
         <p className="labelForm">Medio de Comunicacion</p>
        </label>
        <div className="col-sm-9">
         <select
          className="form-control"
          id="mediaType"
          value={this.state.mediaType}
          onChange={this.handleChange}>
          <option
           value=""
           defaultValue=""
           disabled>
           Selecciona el medio de comunicacion
          </option>
          <option value="0">Whatsapp</option>
          <option value="1">Video llamada</option>
          <option value="2">Personal</option>
          <option value="4">Telefónico</option>
         </select>
        </div>
       </div>
       <div className="form-group row">
        <label className="col-sm-3 col-form-label">
         <p className="labelForm">Tema</p>
        </label>
        <div className="col-sm-9">
         <select
          className="form-control"
          id="theme"
          value={this.state.theme}
          onChange={this.handleChange}>
          <option
           value=""
           disabled
           defaultValue="">
           Selecciona el tema
          </option>
          <option value="Adaptación Laboral">Adaptación Laboral</option>
          <option value="Asesoría Especializada">Asesoría Especializada</option>
          <option value="Caso de Seguridad">Caso de Seguridad</option>
          <option value="Educación">Educación</option>
          <option value="Familia">Familia</option>
          <option value="Reglamento Interno">Reglamento Interno</option>
          <option value="Salud">Salud</option>
          <option value="Intervención en Crisis">Intervención en Crisis</option>
          <option value="Acompañamiento Individual">
           Acompañamiento Individual
          </option>
          <option value="Otros">Otros</option>
         </select>
        </div>
       </div>
       {this.state.theme === "Otros" && (
        <div className="form-group row">
         <label className="col-sm-3 col-form-label">
          <p className="labelForm">Nombre del tema</p>
         </label>
         <div className="col-sm-9">
          <input
           type="text"
           className="form-control"
           id="otherTheme"
           value={this.state.otherTheme}
           onChange={this.handleChange}></input>
         </div>
        </div>
       )}
       <div className="form-group row">
        <label className="col-sm-3 col-form-label">
         <p className="labelForm">Fecha</p>
        </label>
        <div className="col-sm-9">
         <input
          type="date"
          className="form-control"
          id="advisoryDate"
          value={this.state.advisoryDate}
          onChange={this.handleChange}></input>
        </div>
       </div>
       <div className="form-group row">
        <label className="col-sm-3 col-form-label">
         <p className="labelForm">Duracion</p>{" "}
        </label>
        <div className="col-sm-9">
         <div className="range range-info">
          <select
           className="form-control"
           id="duration"
           value={this.state.duration}
           onChange={this.handleChange}>
           <option
            value=""
            disabled
            defaultValue="">
            Selecciona la duracion
           </option>
           <option value="0.5">30 min</option>
           <option value="1">1 hora</option>
           <option value="1.5">1:30 horas</option>
           <option value="2">2 horas</option>
           <option value="2.5">2:30 horas</option>
           <option value="3">3 horas</option>
           <option value="3.5">3:30 horas</option>
           <option value="4">4 horass</option>
           <option value="4.5">4:30 horas</option>
           <option value="5">5 horas</option>
          </select>
         </div>
        </div>
       </div>
       <div className="form-group row">
        <label className="col-sm-3 col-form-label">
         <p className="labelForm">Resumen</p>{" "}
        </label>
        <div className="col-sm-9">
         <Input
          className="form-control"
          type="textarea"
          id="summary"
          value={this.state.summary}
          onChange={this.handleChange}
         />
        </div>
       </div>
      </form>
     </div>
     {!this.state.isEdit && (
      <div className="containerBtnEnviarAsesorias">
       <button
        className="btn btn-info"
        style={{ width: 200, backgroundColor: "#204d6a" }}
        onClick={() => this.onSaveConsultancies()}>
        {this.state.isEdit === false ? "Enviar" : "Actualizar"}
       </button>
      </div>
     )}
    </div>
   </Fragment>
  );
 }
}

const mapStateToProps = (state) => {
 return {
  isLoading: state.consultanciesReducer.isLoading,
  consultanciesData: state.consultanciesReducer.consultanciesData,
  candidates: state.candidateReducer.candidates,
  isSave: state.consultanciesReducer.isSave,
  consultanciesData2: state.consultanciesReducer.consultanciesData2,
  modulePath: state.allyReducer.modulePath,
  allyData: state.allyReducer.allyData,
  searchCandidate: state.candidateReducer.searchCandidate,
 };
};

const mapDispatchToProps = (dispatch) => {
 return {
  add_consultancies: bindActionCreators(add_consultancies, dispatch),
  getAllCandidates: bindActionCreators(getAllCandidates, dispatch),
  searchCandidateDoc: bindActionCreators(searchCandidateDoc, dispatch),
 };
};

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(ColaboradorAcompanamiento);
