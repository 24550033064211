import React, { Component, Fragment } from "react";
import "./seguimiento.styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Header from "../../header/Header";
import {
  getTracingByRangeNuevo,
  setCount,
} from "../../../redux/actions/selectionActions";
import NotificacionAcompanamiento from "../notificaciones/notificaciones";
import ModalUpOk from "../../seleccion/popUpOk/popUpOk";
import { Col, FormGroup, Input } from "reactstrap";
import Pagination from "react-js-pagination";

function LinkedRow(props) {
  const candidates = props.list.candidate;
  const tracing = props.list;

  return (
    <tr id="tr" key={candidates.id.toString()}>
      <td className="titleItemRow">{candidates.fullName}</td>
      <td className="titleItemRow">{candidates.cellphone}</td>
      <td className="titleItemRow">{candidates.cellphoneTwo}</td>
      <td className="titleItemRow">{candidates.positions[0].nameClient}</td>
      <td className="titleItemRow">{candidates.allyName}</td>
      <td className="titleItemRow">{candidates.positions[0].nameVacancy}</td>
      <td className="titleItemRow">{tracing.total}</td>
      <td>
        <FormGroup>
          <Input type="textarea" disabled value={tracing.observations} />
        </FormGroup>
      </td>
      <td>
        <div className="actionIcons">
          <Link to={`seguimiento/view/${candidates.id}`}>
            <i
              className="fa fa-eye"
              style={{ color: "#17a2b8" }}
              aria-hidden="true"
            ></i>
          </Link>
          <Link to={`seguimiento/new/${candidates.id}`}>
            <i
              className="fa fa-plus-circle"
              style={{ color: "#28a745" }}
              aria-hidden="true"
            ></i>
          </Link>
        </div>
      </td>
    </tr>
  );
}

class SeguimientoAcompanamientoNuevo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  componentDidMount = () => {
    this.props.setCount(0);
    let min = this.props.match.params.min;
    let max = this.props.match.params.max;
    let month = this.props.match.params.month;
    let year = this.props.match.params.year;
    this.props.getTracingByRangeNuevo(min, max, month, year);
  };

  componentDidUpdate = () => {
    // console.log('Entrevistas', this.props);
  };

  handlePageChange = (e) => {
    window.scroll(0, 0);
    let min = this.props.match.params.min;
    let max = this.props.match.params.max;
    let month = this.props.match.params.month;
    let year = this.props.match.params.year;
    this.props.getTracingByRangeNuevo(min, max, month, year, e);

    this.setState({
      activePage: e,
    });
  };

  changeSelectPage = (e) => {
    this.handlePageChange(e.target.value);
    this.setState({
      activePage: parseInt(e.target.value),
    });
  };

  renderOptionsPage = () => {
    let options = [];
    if (this.props.pageCount !== 0) {
      let base = 50;
      let pages = 0;
      pages = Math.ceil(this.props.pageCount / base);

      for (let i = 0; i < pages; i++) {
        options.push(i + 1);
      }
    }
    return (
      <FormGroup>
        <Input
          style={{ marginLeft: 10 }}
          type="select"
          value={this.state.activePage}
          onChange={this.changeSelectPage}
        >
          {options.map((i) => {
            return (
              <option key={i.toString()} value={i}>
                {i}
              </option>
            );
          })}
        </Input>
      </FormGroup>
    );
  };

  render() {
    return (
      <Fragment>
        <Header />
        <div className="col-12 bgResumenSeleccion" style={{ height: "100%" }}>
          <div className="customRow mb-5 text-left">
            <p>
              <strong className="titleSeguimiento text-left">
                Seguimientos
              </strong>
            </p>
            <p className="subtitleSeguimiento">
              Listado de los empleados vinculados.
            </p>
          </div>

          <div className="col-12 table-responsiveSeguimiento containerResumenSeleccion">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" className="titleTable">
                    Nombre
                  </th>
                  <th scope="col" className="titleTable">
                    Celular 1
                  </th>
                  <th scope="col" className="titleTable">
                    Celular 2
                  </th>
                  <th scope="col" className="titleTable">
                    Empresa
                  </th>
                  <th scope="col" className="titleTable">
                    Programa
                  </th>
                  <th scope="col" className="titleTable">
                    Cargo
                  </th>
                  <th scope="col" className="titleTable">
                    Puntaje Total
                  </th>
                  <th scope="col" className="titleTable">
                    Observaciones
                  </th>
                  <th scope="col" className="titleTable"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.getSelectionByState &&
                this.props.getSelectionByState.length !== 0
                  ? this.props.getSelectionByState.results.map(
                      (item, index) => {
                        return <LinkedRow key={index.toString()} list={item} />;
                      }
                    )
                  : null}
              </tbody>
            </table>
          </div>

          <Col
            md={12}
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={50}
              totalItemsCount={this.props.pageCount}
              pageRangeDisplayed={5}
              onChange={(e) => this.handlePageChange(e)}
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={5}
              pageRangeDisplayed={10}
            />
            {this.renderOptionsPage()}
          </Col>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.selectionReducer.isLoading,
    getSelectionByState: state.selectionReducer.getSelectionByState,
    pageCount: state.selectionReducer.pageCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTracingByRangeNuevo: bindActionCreators(
      getTracingByRangeNuevo,
      dispatch
    ),
    setCount: bindActionCreators(setCount, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeguimientoAcompanamientoNuevo);
