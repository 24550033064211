import { GET_COURSES, ADD_COURSES, ISLOADING } from '../constants/action-type';
import urls from '../../apiConf';
import axios from 'axios';
import swal from 'sweetalert';

export const isLoading = bool => ({
  type: ISLOADING,
  isLoading: bool
});

export const setCourses = (courses, countCourse) => ({
  type: GET_COURSES,
  courses,
  countCourse
});

export const getAllCourses = (e=1) => {
  return async (dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCourses = await axios.get(`${urls.url_base}/Y291cnNl/?page=${e}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCourses.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setCourses(data, count));
      } else {
        dispatch(setCourses({ results: [] }, 0));
        swal('No hay información de talleres/cursos disponible.');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const getCoursesBySector = (sector) => {
  return async (dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCourses = await axios.get(`${urls.url_base}/Y291cnNl/?sector=${sector}`, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCourses.data;
      const count = data.count;
      if (data.results.length !== 0) {
        dispatch(setCourses(data, count));
      } else {
        dispatch(setCourses({ results: [] }, 0));
        swal('No hay información de talleres/cursos disponible.');
      }
    } catch (err) {
      dispatch(isLoading(false))
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
    }
  }
}

export const addCourse = (objPost) => {
  return async (dispatch) => {
    dispatch(isLoading(true))
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseCourses = await axios.post(`${urls.url_base}/Y291cnNl/`, objPost, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseCourses.data;
      if (data) {
        dispatch(isLoading(false));
        return data;
      }
    } catch (err) {
      dispatch(isLoading(false));
      alert(`Lo sentimos, ha sucedido un error, intenta más tarde. ${err.message}`);
      return false;
    }
  }
}

export const saveCourseAssistance = (obj) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const reponseToken = await axios.post(`${urls.url_base}/dG9rZW4/`, urls.body, {
        headers: { 'Content-Type': 'application/json' }
      });
      const token = await reponseToken.data.token;
      const responseAssistance = await axios.post(`${urls.url_base}/Y291cnNlQ/`, obj, { headers: { 'Authorization': `JWT ${token}` } });
      const data = await responseAssistance.data;
      swal('Genial!', 'La asistencia fue tomada correctamente', 'success');
    } catch (err) {
      swal('Lo sentimos, no pudimos almacenar la información, intenta nuevamente');
    }
  }
}