import React, { Component, Fragment } from 'react';
import './relacionamiento.styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Header from '../../header/Header';
import { setCandidateBus } from '../../../redux/actions/candidateActions';
import { getSummaryTotal } from '../../../redux/actions/summaryActions';
import NotificacionAprendiz from '../notificaciones/notificaciones';


class RelacionamientoAprendiz extends Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    componentDidMount = () => {
        this.props.getSummaryTotal();
        this.props.setCandidateBus([]);
    }

    componentDidUpdate = () => {
    }

    navigateDetail = () => {
        this.props.history.push(`tablaRelacionamiento`);
    }

    render() {
        return (
          <>
            <Header />
            <div className="col-12 bgResumenSeleccion">
                <div className="customRow mb-2 text-left">
                    <p><strong className="titleSeguimiento text-left">
                        Resumen
                    </strong>
                    </p>
                    <p className="subtitleSeguimiento">
                        Resumen y notificaciones candidatos remitidos.
                    </p>
                </div>
    
              <div className="col-12 mb-3">
                {/*<NotificacionAprendiz />*/}
                <div className="alert alert-danger" role="alert" style={{display: 'none'}}>
                  Victimas nacionales ha ingresado 5 nuevos candidatos remitidos
                </div>
              </div>

              <div className="table-responsiveRelacionamiento containerResumenSeleccion">
                <table className="table tableResumenSeleccion">
                  <thead>
                    <tr>
                      <th scope="col" className="titleTable">
                        Estado
                      </th>
                      <th scope="col" className="titleTable">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr id="tr">
                      <td className="titleItemRow">Remitidos</td>
                      <td className="titleItemRow">{
                          this.props.summaryTotal.length !== 0 ?
                            this.props.summaryTotal[4].total : 0
                      }</td>
                      <td>
                        <button className="btn btn-info" onClick={this.navigateDetail}>
                            <strong>Ver Detalle</strong>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
    
            </div>
          </>
        );
    }
}

const mapStateToProps = state => {
    return{
        summaryTotal: state.summariesReducer.summaryTotal
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setCandidateBus: bindActionCreators(setCandidateBus, dispatch),
        getSummaryTotal: bindActionCreators(getSummaryTotal, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelacionamientoAprendiz);